import axios from 'axios';
import { proxyurl } from './requestHelper';
const production = true;

    // https://sandbox.loomis-express.com/axis2/services /USSRatingService?wsdl
    // https://sandbox.loomis-express.com/axis2/services /USSBusinessService?wsdl
    // https://sandbox.loomis-express.com/axis2/services /USSAddonsService?wsdl

// DD Loomis Ground
// DE Loomis Express 18:00
// DN Loomis Express 12:00
// D9 Loomis Express 9:00
// WD Loomis Express Economy Select
// WM Loomis Express International 10:30
// WN Loomis Express International 12:00
// WW Loomis Express Worldwide
// W9 Loomis Express International 9:00

const baseURL = production ? proxyurl + "https://ws.business.uss.transforce.ca/" : proxyurl +"https://sandbox.loomis-express.com/axis2/services/USSBusinessService?wsdl";
const headers = {
    "headers": {
        "content-type": "text/xml",
        "accept": "application/json",
    }
};
export const testData ={
    "Authorization": {
        "User": "ashlinbpg_integ",
        "Pass": "3g98hn243tg98hnerfa",
        "Key": "98jmnasoi252nfsd9voybazs232iunfhw2332bn4",
        "Account": "Ashlin"
    },
    "OrderHeader":{
        "OrderNumber": "StaplesAdvantage-EW76372813",
        "EndCustomerOrderNo": "EW76372813",
        "ThirdPartyOrderNo": "EW76372813",
        "OrderDateCode": "CR",
        "OrderDate": "2018-11-30 09:00:00",
        "Comment": "Please use 3rd Party UPS Account Y05E08*** ---Preferred Packaging: BUBBLE PACK",
        "ShipTo": {
            "Name" : "EMPLOYMENT STANDARDS",
            "AddressLine1"	: "1870 ALBERT ST 3RD FLOOR",
            "AddressLine2"	: "REGINA DISTRICT OFFICE",
            "AddressLine3"	: "",
            "City"	:	"REGINA",
            "State"	:"SK",
            "CountryCode"	:	"CA",
            "ContactName"	:	"SAMEEMA HAQUE",
            "ContactPhone"	:	"(306)787-2432",
            "ContactPhoneExt":"",
            "Zip"	:	"S4P4W1"
        },
        "ShipFrom": {
            "Name" : "WAREHOUSE"
        },
        "WarehouseCode": "14",
        "OrderStatus": "NEW",
        "OrderType": "NO",
        "Priority": "",
        "Version": ""
    },
    "OrderDetail":[
        {
            "OrderLine": "1",
            "ItemCode": "P8888-18-01",
            "ItemQty": 1,
            "ItemName": "Slim Underarm Portfolio - Legal plus laptop tablet Preferred Packaging: CARTON",
            "ItemDescription": "Slim Underarm Portfolio - Legal plus laptop tablet Preferred Packaging: CARTON ",
            "uofm": "EACH",
            "per_pack": "1"
        }
    ]
    ,
    "OrderDelivery":{
        "CarrierCode":"UPS",
        "ServiceCode":"11",
        "FreightCharges":"3RDPARTY",
        "ChargeToAccount":"Y05E08"
    }
}







export const credentials = {
    userId:"admin@uss.com",
    password:"uss",
    account:"HB4499",
};





export const leanSupplyToLoomis = (credentials,LS,weightInKg)=>{

    const today = new Date().toISOString().slice(0,10).replace(/\D/g,""); //YYYMMMDDD
    return(`<?xml version="1.0" encoding="UTF-8"?>
            <soapenv:Envelope xmlns:soapenv="https://schemas.xmlsoap.org/soap/envelope/" xmlns:ws="https://ws.business.uss.transforce.ca" xmlns:xsd="https://ws.business.uss.transforce.ca/xsd" xmlns:xsd1="https://dto.uss.transforce.ca/xsd">
               <soapenv:Header/>
               <soapenv:Body>
                  <ws:processShipment>
                     <ws:request>
                        <xsd:user_id>${credentials.userId}</xsd:user_id>
                        <xsd:password>${credentials.password}</xsd:password>
                        <xsd:shipment>
                       <xsd1:dimension_unit>I</xsd1:dimension_unit>
                       <xsd1:pickup_address_line_1>6115 EDWARDS BLVD.</xsd1:pickup_address_line_1>
                       <xsd1:pickup_address_line_2></xsd1:pickup_address_line_2>
                       <xsd1:pickup_city>MISSISSAUGA</xsd1:pickup_city>
                       <xsd1:pickup_email></xsd1:pickup_email>
                       <xsd1:pickup_name>WALMART.CA</xsd1:pickup_name>
                       <xsd1:pickup_phone></xsd1:pickup_phone>
                       <xsd1:pickup_postal_code>L5T2W7</xsd1:pickup_postal_code>
                       <xsd1:pickup_province>ON</xsd1:pickup_province>
                       
                       <xsd1:reported_weight_unit>K</xsd1:reported_weight_unit>
                       
                       
                       <xsd1:shipper_num>${credentials.account}</xsd1:shipper_num>
                       <xsd1:collect_shipper_num>${credentials.account}</xsd1:collect_shipper_num>
                       
                       
                       <xsd1:shipment_info_str>
                          <xsd1:name>nsr</xsd1:name>
                          <xsd1:value>true</xsd1:value>
                       </xsd1:shipment_info_str>
                       
                       
                       <xsd1:service_type>DD</xsd1:service_type> 
                       
                       <xsd1:delivery_name>${LS.OrderHeader.ShipTo.ContactName}</xsd1:delivery_name>
                       <xsd1:delivery_address_line_1>${LS.OrderHeader.ShipTo.AddressLine1}</xsd1:delivery_address_line_1>
                       <xsd1:delivery_address_line_2>${LS.OrderHeader.ShipTo.AddressLine2}</xsd1:delivery_address_line_2>
                       <xsd1:delivery_address_line_3>${LS.OrderHeader.ShipTo.AddressLine3}</xsd1:delivery_address_line_3>       
                       <xsd1:delivery_city>${LS.OrderHeader.ShipTo.City}</xsd1:delivery_city>
                       <xsd1:delivery_province>${LS.OrderHeader.ShipTo.State}</xsd1:delivery_province>
                       <xsd1:delivery_country>${LS.OrderHeader.ShipTo.CountryCode}</xsd1:delivery_country>
                       <xsd1:delivery_postal_code>${LS.OrderHeader.ShipTo.Zip.replace(' ','')}</xsd1:delivery_postal_code>
                   
                       <xsd1:delivery_phone>${LS.OrderHeader.ShipTo.ContactPhone.replace(/\D/g,"")}</xsd1:delivery_phone>
                       <xsd1:delivery_extension>${LS.OrderHeader.ShipTo.ContactPhoneExt}</xsd1:delivery_extension>
                       
                       
                       
                       
                       <xsd1:shipping_date>${today}</xsd1:shipping_date>    
                       <xsd1:shipment_info_str>
                          <xsd1:name>reference</xsd1:name>
                          <xsd1:value>${LS.OrderHeader.ThirdPartyOrderNo}</xsd1:value>
                       </xsd1:shipment_info_str>
			   
                       
                       <xsd1:shipment_info_str>
                          <xsd1:name>attention</xsd1:name>
                          <xsd1:value>${LS.OrderHeader.ShipTo.ContactName}</xsd1:value>
                       </xsd1:shipment_info_str>
			  
			   
                       <xsd1:packages>
                          <xsd1:reported_weight>${weightInKg}</xsd1:reported_weight> 
                       </xsd1:packages>
                        </xsd:shipment>
                     </ws:request>
                  </ws:processShipment>
               </soapenv:Body>
            </soapenv:Envelope>`)
};

export const createShipment = async(shipment)=>{
    try{
        const res = await axios.post(baseURL,shipment,headers);
        return res.data;
    }catch (e) {
        return "";
    }

};

const labelRequestObj = (credentials,id)=>{
    return `<?xml version="1.0" encoding="UTF-8"?>
            <soapenv:Envelope xmlns:soapenv="https://schemas.xmlsoap.org/soap/envelope/" xmlns:ws="https://ws.business.uss.transforce.ca" xmlns:xsd="https://ws.business.uss.transforce.ca/xsd">
               <soapenv:Header/>
               <soapenv:Body>
                  <ws:getLabelsAdvancedV2>
                     <ws:request>
                        <xsd:format>PNG</xsd:format>
                        <xsd:id>${id}</xsd:id>
                        <xsd:password>${credentials.password}</xsd:password>
                        <xsd:user_id>${credentials.userId}</xsd:user_id>
                     </ws:request>
                  </ws:getLabelsAdvancedV2>
               </soapenv:Body>
            </soapenv:Envelope>`;
};
export const getShipingLabel = async(credentials,shipmentId)=>{
    try {
        const xml = labelRequestObj(credentials,shipmentId);
        const res = await axios.post(baseURL,xml,headers);
        return res.data;
    }catch (e) {
        return ""
    }
};

