import React from 'react';
import ListItem from './discount_sku_list_item';
const discount_sku_list = ({searchBox,onChange,list,skuOnSelect})=>{
    //console.log(props);
    return(
        <div className={'list-group'}>
            <input type={'text'} name={'searchBox'} value={searchBox}
                   style={{width:'100%',lineHeight:'30px',fontSize:'30px',border:'5px solid gray',fontWeight:''}}
                   placeholder={'Search SKU here'} onChange={onChange}
            />
            {list.map((item,index)=><ListItem item={item} key={index} skuOnSelect={skuOnSelect} index={index}
            />)}
        </div>
    )
};
export default discount_sku_list;