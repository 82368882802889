import axios from "axios";
import { findBestMatch } from "string-similarity";
import { createShipment, getShipingLabel, leanSupplyToLoomis } from "./loomis";
import { b64toBlob, baseURL, headers, postAveryLabel } from "./requestHelper";

/*************    Order Records Start   ****************/
export const getOrderRecord = async (bpId) => {
  return (await axios.get(`${baseURL}api/OrderRecords/${bpId}`, { headers }))
    .data;
};
export const postOrderRecords = async (records) => {
  return (await axios.post(`${baseURL}api/OrderRecords`, records, { headers }))
    .data;
};
export const putOrderRecord = async (record) => {
  return (
    await axios.put(
      `${baseURL}api/OrderRecords/${record.BrightpearlOrderId}`,
      record,
      { headers }
    )
  ).data;
};
/*************   Order Records End    ****************/
export const exportColours = async (type) => {
  try {
    const response = await axios({
      url: `${baseURL}api/export/colours/${type}`,
      method: "GET",
      responseType: "blob", // important
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${type}_colours.xlsx`);
    document.body.appendChild(link);
    link.click();
  } catch (e) {
    return "";
  }
};
export const exportMaterials = async (type) => {
  try {
    const response = await axios({
      url: `${baseURL}api/export/materials/${type}`,
      method: "GET",
      responseType: "blob", // important
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${type}_materials.xlsx`);
    document.body.appendChild(link);
    link.click();
  } catch (e) {
    return "";
  }
};

export const exportSubFamilies = async (type) => {
  try {
    const response = await axios({
      url: `${baseURL}api/export/subfamilies/${type}`,
      method: "GET",
      responseType: "blob", // important
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${type}_families.xlsx`);
    document.body.appendChild(link);
    link.click();
  } catch (e) {
    return "";
  }
};

export const exportUPCs = async () => {
  try {
    const response = await axios({
      url: `${baseURL}api/export/UPCs`,
      method: "GET",
      responseType: "blob", // important
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `UPCs.xlsx`);
    document.body.appendChild(link);
    link.click();
  } catch (e) {
    return "";
  }
};
export const exportFamilies = async (type) => {
  try {
    const response = await axios({
      url: `${baseURL}api/export/families/${type}`,
      method: "GET",
      responseType: "blob", // important
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${type}_super_families.xlsx`);
    document.body.appendChild(link);
    link.click();
  } catch (e) {
    return "";
  }
};

export const exportDesigns = async (type) => {
  try {
    const response = await axios({
      url: `${baseURL}api/export/designs/${type}`,
      method: "GET",
      responseType: "blob", // important
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${type}_designs.xlsx`);
    document.body.appendChild(link);
    link.click();
  } catch (e) {
    return "";
  }
};

export const exportSKUs = async (type) => {
  try {
    const response = await axios({
      url: `${baseURL}api/export/skus/${type}`,
      method: "GET",
      responseType: "blob", // important
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${type}_skus.xlsx`);
    document.body.appendChild(link);
    link.click();
  } catch (e) {
    return "";
  }
};

export const exportSKUDetails = async (type) => {
  try {
    const response = await axios({
      url: `${baseURL}api/export/skudetails/${type}`,
      method: "GET",
      responseType: "blob", // important
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${type}_sku_details.xlsx`);
    document.body.appendChild(link);
    link.click();
  } catch (e) {
    return "";
  }
};

export const exportASIProducts = async (currency) => {
  try {
    const response = await axios({
      url: `${baseURL}api/export/asi/${currency}`,
      method: "GET",
      responseType: "blob", // important
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `37138_Product_Export_${currency}.xlsx`);
    document.body.appendChild(link);
    link.click();
  } catch (e) {
    return "";
  }
};

export const exportAveryUPC = async (data, price) => {
  //console.log(data);
  try {
    const response = await axios({
      url: `${baseURL}api/PrintUPCLabel/excel/${price}`,
      method: "POST",
      data: data,
      responseType: "blob", // important
    });
    //console.log(response);

    //blobFile, fileName, folderName
    await postAveryLabel(response.data, "InputData.xlsx");
  } catch (e) {
    return "";
  }
};

export const getUPCLabelInfo = async (SKU) => {
  try {
    return (await axios.get(`${baseURL}api/LegacySku/upcLabelInfo/${SKU}`))
      .data[0];
  } catch (e) {
    return null;
  }
};
export const createUPCLabel = async (labelInfo) => {
  try {
    const response = await axios({
      url: `${baseURL}api/PrintUPCLabel`,
      method: "POST",
      data: labelInfo,
      headers: headers,
      responseType: "blob", // important
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${labelInfo.SKU}.pdf`);
    document.body.appendChild(link);
    link.click();
    //return (await axios.post(`${baseURL}api/PrintUPCLabel`,labelInfo,{headers})).data;
  } catch (e) {
    return null;
  }
};

export const getHTSDetail = async (code) => {
  try {
    return (await axios.get(`${baseURL}api/Tphs/${code}`)).data;
  } catch (e) {
    return "";
  }
};
export const getCredential = async (credential) => {
  try {
    return (await axios.get(`${baseURL}api/${credential}`)).data;
  } catch (e) {
    return "";
  }
};

export const getAshlinManualOrders = async () => {
  try {
    return (await axios.get(`${baseURL}api/AshlinManualOrders`)).data;
  } catch (e) {
    return [];
  }
};
export const createAshlinManualOrder = async (ashlinManualOrder) => {
  const d = new Date(
    new Date().toString().split("GMT")[0] + " UTC"
  ).toISOString();
  ashlinManualOrder.LastUpdated = d.slice(0, 19);
  try {
    return (
      await axios.post(`${baseURL}api/AshlinManualOrders`, ashlinManualOrder, {
        headers,
      })
    ).data;
  } catch (e) {
    return null;
  }
};

export const createAshlinManualOrderItems = async (items) => {
  return axios.post(`${baseURL}api/ChitChatsOrderItems`, items, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getAshlinMaualOrderItems = async (orderId) => {
  try {
    return (
      await axios.get(`${baseURL}api/ChitChatsOrderItems/${orderId}`, {
        headers,
      })
    ).data;
  } catch (e) {
    return [];
  }
};
export const updateAshlinManualOrder = async (ashlinManualOrder) => {
  const d = new Date(
    new Date().toString().split("GMT")[0] + " UTC"
  ).toISOString();
  ashlinManualOrder.LastUpdated = d.slice(0, 19);
  try {
    return (
      await axios.put(
        `${baseURL}api/AshlinManualOrders/${ashlinManualOrder.Id}`,
        ashlinManualOrder,
        { headers }
      )
    ).data;
  } catch (e) {
    return null;
  }
};
export const getBundledItems = async () => {
  try {
    return (await axios.get(`${baseURL}api/BundledItems`)).data;
  } catch (e) {
    return [];
  }
};
export const replaceBundledItems = (orderLines, bundledItems) => {
  let replacedLines = [];
  orderLines.forEach((line) => {
    //line.ItemCode ='RFIDP221TAG04-18-01';
    const bundled = bundledItems.find((b) => b.Sku === line.ItemCode);
    //console.log(bundled)
    if (bundled) {
      //console.log(bundled);
      bundled.Items.forEach((i) => {
        const data = {
          OrderLine: `${1 + replacedLines.length}`,
          ItemCode: i.Item,
          ItemQty: line.ItemQty,
          ItemName: i.ItemName,
          ItemDescription: i.ItemDescription,
          uofm: "EACH",
          per_pack: "1",
        };
        replacedLines.push(data);
      });
    } else {
      replacedLines.push(line);
    }
  });

  return replacedLines;
};
export const postundledItems = async (data) => {
  try {
    return (await axios.post(`${baseURL}api/BundledItems`, data, { headers }))
      .data;
  } catch (e) {
    return [];
  }
};
export const deleteundledItems = async (data) => {
  try {
    return (await axios.delete(`${baseURL}api/BundledItems`, { data })).data;
  } catch (e) {
    return [];
  }
};

export const createShippingPackage = async (shippingPackage) => {
  try {
    return (
      await axios.post(`${baseURL}api/ShippingPackages`, shippingPackage, {
        headers,
      })
    ).data;
  } catch (e) {
    return null;
  }
};
export const getShippingPackages = async () => {
  try {
    return (await axios.get(`${baseURL}api/ShippingPackages`)).data;
  } catch (e) {
    return [];
  }
};
export const updateShippingPackage = async (shippingPackage) => {
  try {
    return (
      await axios.put(
        `${baseURL}api/ShippingPackages/${shippingPackage.Id}`,
        shippingPackage,
        { headers }
      )
    ).data;
  } catch (e) {
    return null;
  }
};

export const createShippingMatrix = async (shippingMatrix) => {
  try {
    return (
      await axios.post(`${baseURL}api/ShippingMatrices`, shippingMatrix, {
        headers,
      })
    ).data;
  } catch (e) {
    return null;
  }
};
export const getShippingMatrices = async () => {
  try {
    return (await axios.get(`${baseURL}api/ShippingMatrices`)).data;
  } catch (e) {
    return [];
  }
};
export const updateShippingMatrix = async (shippingMatrix) => {
  try {
    return (
      await axios.put(
        `${baseURL}api/ShippingMatrices/${shippingMatrix.Id}`,
        shippingMatrix,
        { headers }
      )
    ).data;
  } catch (e) {
    return null;
  }
};

export const getBrightpearlChannelInfos = async () => {
  try {
    return (await axios.get(`${baseURL}api/BrightPearlChannelInfoes`)).data;
  } catch (e) {
    return [];
  }
};
export const updateBrightpearlChannelInfo = async (brightpearlChannelInfo) => {
  const d = new Date(
    new Date().toString().split("GMT")[0] + " UTC"
  ).toISOString();
  brightpearlChannelInfo.LastModifiedDate = d.slice(0, 19);
  try {
    return (
      await axios.put(
        `${baseURL}api/BrightPearlChannelInfoes/${brightpearlChannelInfo.Id}`,
        brightpearlChannelInfo,
        { headers }
      )
    ).data;
  } catch (e) {
    return null;
  }
};
export const createBrightPearlChannelInfo = async (brightpearlChannelInfo) => {
  try {
    return (
      await axios.post(
        `${baseURL}api/BrightPearlChannelInfoes`,
        brightpearlChannelInfo,
        { headers }
      )
    ).data;
  } catch (e) {
    return null;
  }
};
export const getSubmittedOrdersDetail = async () => {
  try {
    return (await axios.get(`${baseURL}api/lean-supply/orders/submittedDetail`))
      .data;
  } catch (e) {
    return [];
  }
};
export const getCompanyInfoById = async (id) => {
  try {
    return (await axios.get(`${baseURL}api/CompanyInfo/${id}`)).data;
  } catch (e) {
    return null;
  }
};
export const getSignatureById = async (id) => {
  try {
    return (await axios.get(`${baseURL}api/Signature/${id}`)).data;
  } catch (e) {
    return null;
  }
};

export const getCompanyInfos = async () => {
  try {
    return (await axios.get(`${baseURL}api/CompanyInfo`)).data;
  } catch (e) {
    return null;
  }
};
export const updategetCompanyInfo = async (companyInfo) => {
  try {
    return (
      await axios.put(
        `${baseURL}api/CompanyInfo/${companyInfo.Id}`,
        companyInfo,
        { headers }
      )
    ).data;
  } catch (e) {
    return null;
  }
};
export const getSignatures = async () => {
  try {
    return (await axios.get(`${baseURL}api/Signature`)).data;
  } catch (e) {
    return null;
  }
};
export const updateSignature = async (signature) => {
  try {
    return (
      await axios.put(`${baseURL}api/Signature/${signature.Id}`, signature, {
        headers,
      })
    ).data;
  } catch (e) {
    return null;
  }
};
export const getShippingOptions = async () => {
  try {
    return (await axios.get(`${baseURL}api/ShippingOption`)).data;
  } catch (e) {
    return null;
  }
};
export const getEmailConfigurations = async () => {
  try {
    return (await axios.get(`${baseURL}api/ConfigurableEmails`)).data;
  } catch (e) {
    return null;
  }
};
export const createEmailConfiguration = async (emailConfiguration) => {
  try {
    return (
      await axios.post(`${baseURL}api/ConfigurableEmails`, emailConfiguration, {
        headers,
      })
    ).data;
  } catch (e) {
    return null;
  }
};
export const updateEmailConfiguration = async (emailConfiguration) => {
  try {
    return (
      await axios.put(
        `${baseURL}api/ConfigurableEmails/${emailConfiguration.Id}`,
        emailConfiguration,
        { headers }
      )
    ).data;
  } catch (e) {
    return null;
  }
};
export const getDesignsPacking = async () => {
  try {
    return (
      await axios.get(`${baseURL}api/LegacyDesigns/designsPackaging`, {
        headers,
      })
    ).data;
  } catch (e) {
    return null;
  }
};
export const getEmailConfigurationById = async (id) => {
  try {
    return (await axios.get(`${baseURL}api/ConfigurableEmails/${id}`)).data;
  } catch (e) {
    return null;
  }
};
export const getShippingOptionById = async (id) => {
  try {
    return (await axios.get(`${baseURL}api/ShippingOption/${id}`)).data;
  } catch (e) {
    return null;
  }
};
export const updateShippingOption = async (shippingOption) => {
  try {
    return (
      await axios.put(
        `${baseURL}api/ShippingOption/${shippingOption.Id}`,
        shippingOption,
        { headers }
      )
    ).data;
  } catch (e) {
    return null;
  }
};
export const createShippingOption = async (shippingOption) => {
  try {
    return (
      await axios.post(`${baseURL}api/ShippingOption`, shippingOption, {
        headers,
      })
    ).data;
  } catch (e) {
    return null;
  }
};
export const getAmazonFBALocations = async () => {
  try {
    return (await axios.get(`${baseURL}api/AmazonFBALocations`)).data;
  } catch (e) {
    return null;
  }
};
export const getBackEndScheduler = async () => {
  try {
    return (await axios.get(`${baseURL}api/Schedulers`)).data;
  } catch (e) {
    return null;
  }
};
export const updateBackEndScheduler = async (scheduler) => {
  const d = new Date(
    new Date().toString().split("GMT")[0] + " UTC"
  ).toISOString();
  scheduler.LastModifiedDate = d.slice(0, 19);
  try {
    return (
      await axios.put(`${baseURL}api/Schedulers/${scheduler.Id}`, scheduler, {
        headers,
      })
    ).data;
  } catch (e) {
    return null;
  }
};
export const createAmazonFBALocations = async (location) => {
  try {
    return await axios.post(`${baseURL}api/AmazonFBALocations`, location, {
      headers,
    });
  } catch (e) {
    return null;
  }
};

export const updateAmazonFBALocations = async (location) => {
  try {
    return await axios.put(
      `${baseURL}api/AmazonFBALocations/${location.AmazonFBALocationId}`,
      location,
      { headers }
    );
  } catch (e) {
    return null;
  }
};

export const legacyColoursURL = baseURL + "api/LegacyColours/";
export const sendEmail = async (
  Recipients,
  Subject,
  MessageBody,
  Attachment,
  AttachmentName
) => {
  const data = {
    Recipients,
    Subject,
    MessageBody,
    Attachment,
    AttachmentName,
  };
  return axios.post(`${baseURL}api/email-notification`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const sendPDFEmail = async (
  Recipients,
  Subject,
  MessageBody,
  Attachment,
  AttachmentName
) => {
  const data = {
    Recipients,
    Subject,
    MessageBody,
    Attachment,
    AttachmentName,
  };
  return axios.post(`${baseURL}api/email-pdf`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const legacyColoursModelMapper = (obj) => {
  const active = obj.Active ? 1 : 0;
  return {
    colorCode: obj.ColourCode,
    pickedColor: obj.AsiColour !== null ? obj.AsiColour : "",
    shortENG:
      obj.ColourDescriptionShort !== null ? obj.ColourDescriptionShort : "",
    shortFR:
      obj.ColourDescriptionShortFr !== null ? obj.ColourDescriptionShortFr : "",
    longENG:
      obj.ColourDescriptionExtended !== null
        ? obj.ColourDescriptionExtended
        : "",
    longFR:
      obj.ColourDescriptionExtendedFr !== null
        ? obj.ColourDescriptionExtendedFr
        : "",
    webENG: obj.ColourOnline !== null ? obj.ColourOnline : "",
    webFR: obj.ColourOnlineFr !== null ? obj.ColourOnlineFr : "",
    active: active,
    searchKeyWords: obj.SearchKeywords !== null ? obj.SearchKeywords : "",
  };
};
export const legacyColorUpdateModel = (obj, mode) => {
  const d = new Date(
    new Date().toString().split("GMT")[0] + " UTC"
  ).toISOString();
  const n = d.slice(0, 19);
  const keywords = obj.searchKeyWords !== undefined ? obj.searchKeyWords : "";
  if (mode === "addColor") {
    return {
      ColourCode: obj.colorCode,
      ColourDescriptionExtended: obj.longENG,
      ColourDescriptionShort: obj.shortENG,
      ColourDescriptionExtendedFr: obj.longENG,
      ColourDescriptionShortFr: obj.longFR,
      Active: 0,
      DateAdded: n,
      ColourOnline: obj.webENG,
      ColourOnlineFr: obj.webFR,
      SearchKeywords: keywords,
      AsiColour: obj.pickedColor,
    };
  } else {
    return {
      ColourCode: obj.colorCode,
      ColourDescriptionExtended: obj.longENG,
      ColourDescriptionShort: obj.shortENG,
      ColourDescriptionExtendedFr: obj.longENG,
      ColourDescriptionShortFr: obj.longFR,
      Active: obj.active,
      DateUpdated: n,
      ColourOnline: obj.webENG,
      ColourOnlineFr: obj.webFR,
      SearchKeywords: keywords,
      AsiColour: obj.pickedColor,
    };
  }
};
///////////////////////////////////////////////////////////////////////////////////////////////////////////
export const legacyMaterialsURL = baseURL + "api/LegacyMaterials/";
export const legacyMaterialsModelMapper = (obj) => {
  const active = obj.Active ? 1 : 0;
  return {
    materialCode: obj.MaterialCode,
    pickedMaterial: obj.AsiMaterial !== null ? obj.AsiMaterial : "",
    shortENG: obj.MaterialDescriptionShort,
    shortFR: obj.MaterialDescriptionShortFr,
    longENG: obj.MaterialDescriptionExtended,
    longFR: obj.MaterialDescriptionExtendedFr,
    webENG: obj.MaterialOnline,
    webFR: obj.MaterialOnlineFr,
    active: active,
    materialHTSDescription: obj.HTSDescription,
    searchKeyWords: obj.SearchKeywords !== null ? obj.SearchKeywords : "",
  };
};
export const legacyMaterialUpdateModel = (obj, mode) => {
  const d = new Date(
    new Date().toString().split("GMT")[0] + " UTC"
  ).toISOString();
  const n = d.slice(0, 19);
  const keywords = obj.searchKeyWords !== undefined ? obj.searchKeyWords : "";
  if (mode === "addMaterial") {
    return {
      MaterialCode: obj.materialCode,
      MaterialDescriptionExtended: obj.longENG,
      MaterialDescriptionShort: obj.shortENG,
      MaterialDescriptionExtendedFr: obj.longENG,
      MaterialDescriptionShortFr: obj.longFR,
      Active: 0,
      DateAdded: n,
      MaterialOnline: obj.webENG,
      MaterialOnlineFr: obj.webFR,
      SearchKeywords: keywords,
      AsiMaterial: obj.pickedMaterial,
      HTSDescription: obj.materialHTSDescription,
    };
  } else {
    return {
      MaterialCode: obj.materialCode,
      MaterialDescriptionExtended: obj.longENG,
      MaterialDescriptionShort: obj.shortENG,
      MaterialDescriptionExtendedFr: obj.longENG,
      MaterialDescriptionShortFr: obj.longFR,
      Active: obj.active,
      DateUpdated: n,
      MaterialOnline: obj.webENG,
      MaterialOnlineFr: obj.webFR,
      SearchKeywords: keywords,
      AsiMaterial: obj.pickedMaterial,
      HTSDescription: obj.materialHTSDescription,
    };
  }
};
///////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const legacyFamiliesURL = baseURL + "api/LegacyFamilies/";
export const legacyFamiliesModelMapper = (obj) => {
  const active = obj.Active ? 1 : 0;
  return {
    familyCode: obj.SuperFamily,
    pickedCollection: obj.Collections,
    shortENG: obj.SuperFamilyDescription,
    shortFR: obj.SuperFamilyDescriptionFr,
    longENG: obj.SuperFamilyExtended,
    longFR: obj.SuperFamilyExtendedFr,
    active: active,
  };
};
export const legacyFamilyUpdateModel = (obj, mode) => {
  if (mode === "addFamily") {
    return {
      SuperFamily: obj.familyCode,
      SuperFamilyDescription: obj.shortENG,
      SuperFamilyDescriptionFr: obj.shortFR,
      SuperFamilyExtended: obj.longENG,
      SuperFamilyExtendedFr: obj.longFR,
      Active: 0,
      Collections: obj.pickedCollection,
    };
  } else {
    return {
      SuperFamily: obj.familyCode,
      SuperFamilyDescription: obj.shortENG,
      SuperFamilyDescriptionFr: obj.shortFR,
      SuperFamilyExtended: obj.longENG,
      SuperFamilyExtendedFr: obj.longFR,
      Active: obj.active,
      Collections: obj.pickedCollection,
    };
  }
};
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const legacyHtsCaURL = baseURL + "api/LegacyHtsCa/";
export const legacyHtsCaModelMapper = (obj) => {
  return {
    id: obj.HtsCa1,
    value: obj.CaDuty,
  };
};
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const legacyHtsUsURL = baseURL + "api/LegacyHtsUs/";
export const legacyHtsUsModelMapper = (obj) => {
  return {
    id: obj.HtsUs1,
    value: obj.UsDuty,
  };
};
export const getSkuDetail = async (Sku) => {
  try {
    const res = await axios.get(legacySkusURL + `/detail/${Sku}`);
    return res.data[0];
  } catch (e) {
    alert(`Read ${Sku} information error,please check database!`);
  }
};

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const legacySubFamiliesURL = baseURL + "api/LegacySubFamilies/";
export const legacySubFamiliesModelMapper = (obj) => {
  const active = obj.Active ? 1 : 0;
  return {
    subFamilyCode: obj.DesignServiceFamilyCode,
    pickedFamily: obj.SuperFamily,
    shortENG: obj.DesignServiceFamilyDescription,
    shortFR: obj.DesignServiceFamilyDescriptionFr,
    metaTitle: obj.MetaTitle,
    metaDescription: obj.MetaDescription,
    generalKeywords: obj.DesignServiceFamilyKeyWordsGeneral,
    gender: obj.Gender,
    amazonKeywords1: obj.KeyWordsAmazon1,
    amazonKeywords2: obj.KeyWordsAmazon2,
    amazonKeywords3: obj.KeyWordsAmazon3,
    amazonKeywords4: obj.KeyWordsAmazon4,
    amazonKeywords5: obj.KeyWordsAmazon5,
    amazonCaNode1: obj.AmazonBrowseNodes1Cda,
    amazonCaNode2: obj.AmazonBrowseNodes2Cda,
    amazonComNode1: obj.AmazonBrowseNodes1Usa,
    amazonComNode2: obj.AmazonBrowseNodes2Usa,
    htsCaCode: obj.HtsCa,
    htsCaValue: obj.CaDuty,
    htsUsCode: obj.HtsUs,
    htsUsValue: obj.UsDuty,
    eBayCategoryID: obj.EBayCategoryId,
    staplesClassID: obj.StaplesClassId,
    staplesClassName: obj.StaplesClassIdName,
    pricingTier: obj.PricingTier,
    ReorderQty: obj.ReorderQuantity,
    ReorderLevel: obj.ReorderLevel,
    sageCategory: obj.DesignServiceFamilyCategorySage,
    sageTheme: obj.DesignServiceFamilyThemesSage,
    esp: obj.DesignServiceFamilyCategoryEsp,
    promoMarketing: obj.DesignServiceFamilyCategoryPromoMarketing,
    uduCate: obj.DesignServiceFamilyCategoryUducat,
    distributorCentral: obj.DesignServiceFamilyCategoryDistributorCentral,
    asiTheme: obj.AsiThemes,
    asiIndustry: obj.AsiIndustries,
    active: active,
  };
};
export const legacySubFamilyUpdateModel = (obj, mode) => {
  const d = new Date(
    new Date().toString().split("GMT")[0] + " UTC"
  ).toISOString();
  const n = d.slice(0, 19);
  if (mode === "addSubFamily") {
    return {
      DesignServiceFamilyCode: obj.subFamilyCode,
      DesignServiceFamilyDescription: obj.shortENG,
      DesignServiceFamilyDescriptionFr: obj.shortFR,
      DesignServiceFamilyKeyWordsGeneral: obj.generalKeywords,
      DesignServiceFamilyCategorySage: obj.sageCategory,
      DesignServiceFamilyCategoryEsp: obj.esp,
      DesignServiceFamilyCategoryUducat: obj.uduCate,
      DesignServiceFamilyThemesSage: obj.sageTheme,
      Active: 0,
      DateAdded: n,
      KeyWordsAmazon1: obj.amazonKeywords1,
      KeyWordsAmazon2: obj.amazonKeywords2,
      KeyWordsAmazon3: obj.amazonKeywords3,
      KeyWordsAmazon4: obj.amazonKeywords4,
      KeyWordsAmazon5: obj.amazonKeywords5,
      AmazonBrowseNodes1Cda: obj.amazonCaNode1,
      AmazonBrowseNodes2Cda: obj.amazonCaNode2,
      AmazonBrowseNodes1Usa: obj.amazonComNode1,
      AmazonBrowseNodes2Usa: obj.amazonComNode2,
      HtsCa: obj.htsCaCode,
      HtsUs: obj.htsUsCode,
      CaDuty: obj.htsCaValue,
      UsDuty: obj.htsUsValue,
      PricingTier: obj.pricingTier,
      ReorderQuantity: obj.ReorderQty,
      ReorderLevel: obj.ReorderLevel,
      Gender: obj.gender,
      EBayCategoryId: obj.eBayCategoryID,
      EBayCategoryIdUs: obj.eBayCategoryID,
      StaplesClassId: obj.staplesClassID,
      StaplesClassIdName: obj.staplesClassName,
      SuperFamily: obj.pickedFamily,
      MetaTitle: obj.metaTitle,
      MetaDescription: obj.metaDescription,
      AsiThemes: obj.asiTheme,
      AsiIndustries: obj.asiIndustry,
    };
  } else {
    return {
      DesignServiceFamilyCode: obj.subFamilyCode,
      DesignServiceFamilyDescription: obj.shortENG,
      DesignServiceFamilyDescriptionFr: obj.shortFR,
      DesignServiceFamilyKeyWordsGeneral: obj.generalKeywords,
      DesignServiceFamilyCategorySage: obj.sageCategory,
      DesignServiceFamilyCategoryEsp: obj.esp,
      DesignServiceFamilyCategoryUducat: obj.uduCate,
      DesignServiceFamilyThemesGeneral: obj.sageTheme,
      DesignServiceFamilyThemesSage: obj.sageTheme,
      Active: obj.active,
      DateUpdated: n,
      KeyWordsAmazon1: obj.amazonKeywords1,
      KeyWordsAmazon2: obj.amazonKeywords2,
      KeyWordsAmazon3: obj.amazonKeywords3,
      KeyWordsAmazon4: obj.amazonKeywords4,
      KeyWordsAmazon5: obj.amazonKeywords5,
      AmazonBrowseNodes1Cda: obj.amazonCaNode1,
      AmazonBrowseNodes2Cda: obj.amazonCaNode2,
      AmazonBrowseNodes1Usa: obj.amazonComNode1,
      AmazonBrowseNodes2Usa: obj.amazonComNode2,
      HtsCa: obj.htsCaCode,
      HtsUs: obj.htsUsCode,
      CaDuty: obj.htsCaValue,
      UsDuty: obj.htsUsValue,
      PricingTier: obj.pricingTier,
      ReorderQuantity: obj.ReorderQty,
      ReorderLevel: obj.ReorderLevel,
      Gender: obj.gender,
      EBayCategoryId: obj.eBayCategoryID,
      EBayCategoryIdUs: obj.eBayCategoryID,
      StaplesClassId: obj.staplesClassID,
      StaplesClassIdName: obj.staplesClassName,
      SuperFamily: obj.pickedFamily,
      MetaTitle: obj.metaTitle,
      MetaDescription: obj.metaDescription,
      AsiThemes: obj.asiTheme,
      AsiIndustries: obj.asiIndustry,
    };
  }
};
//////////////////////////////////////////////////////////////////////////////////////////////////////////
export const legacyDesignsURL = baseURL + "api/LegacyDesigns/";
export const getDesignByCode = async (designCode) => {
  try {
    return (await axios.get(`${legacyDesignsURL}${designCode}`)).data;
  } catch (e) {
    //console.log(e);
    return null;
  }
};
export const legacyDesignsModelMapper = (obj) => {
  const active = obj.Active ? 1 : 0;
  const AuthenticityCard = obj.AuthenticityCard ? 1 : 0;
  const GiftBox = obj.GiftBox ? 1 : 0;
  const Imprintable = obj.Imprintable ? 1 : 0;
  const FlatShippable = obj.FlatShippable ? 1 : 0;
  const FoldShippable = obj.FoldShippable ? 1 : 0;
  const Strap = obj.Strap ? 1 : 0;
  const DetachableStrap = obj.DetachableStrap ? 1 : 0;
  const ZipperedEnclosure = obj.ZipperedEnclosure ? 1 : 0;
  const WebsiteFlag = obj.WebsiteFlag ? 1 : 0;
  const Monogram = obj.Monogram ? 1 : 0;
  const ProtectiveFeet = obj.ProtectiveFeet ? 1 : 0;
  const InnerPocket = obj.InnerPocket ? 1 : 0;
  const OutsidePocket = obj.OutsidePocket ? 1 : 0;
  const DustBag = obj.DustBag ? 1 : 0;
  const OnCorporate = obj.OnCorporate ? 1 : 0;

  return {
    designCode: obj.DesignServiceCode,
    designHTSDescription: obj.HTSDescription,
    pickedSubFamily: obj.DesignServiceFamilyCode,
    brand: obj.Brand,
    designServiceFlag: obj.DesignServiceFlag,
    giftBox: GiftBox,
    internalAshlinName: obj.DesignServiceFashionNameAshlin,
    generalName: obj.GeneralName,
    onlineName: obj.DesignServiceFashionNameAshlin,
    country: obj.Country,
    shippingPackaging: obj.ShippingPackaging,
    shortENG: obj.ShortDescription,
    shortFR: obj.ShortDescriptionFr,
    longENG: obj.ExtendedDescription,
    longFR: obj.ExtendedDescriptionFr,
    webENG: obj.DesignOnline,
    webFR: obj.DesignOnlineFr,
    shortTrendENG: obj.TrendShortDescription,
    shortTrendFR: obj.TrendShortDescriptionFr,
    longTrendENG: obj.TrendExtendedDescription,
    longTrendFR: obj.TrendExtendedDescriptionFr,
    monogrammed: Monogram,
    imprinted: Imprintable,
    strap: Strap,
    detachable: DetachableStrap,
    enclosure: ZipperedEnclosure,
    flat: FlatShippable,
    folded: FoldShippable,
    imprintWidth: obj.ImprintWidthCm,
    imprintHeight: obj.ImprintHeightCm,
    productHeight: obj.HeightCm,
    productWidth: obj.WidthCm,
    productDepth: obj.DepthCm,
    productWeight: obj.WeightGrams,
    shipmentHeight: obj.ShippableHeightCm,
    shipmentWidth: obj.ShippableWidthCm,
    shipmentDepth: obj.ShippableDepthCm,
    shipmentWeight: obj.ShippableWeightGrams,
    numComponent: obj.Components,
    shoulderDropLength: obj.ShoulderDropLength,
    handleTotalLength: obj.HandleStrapDropLength,
    cardSlots: obj.CardSlot,
    notableStrap: obj.NotableStrapGeneralFeatures,
    sizeDifferentiation: obj.SizeDifferentiation,
    billCompartment: obj.BillCompartment,
    style: obj.Style,
    closure: obj.Closure,
    protectiveFeet: ProtectiveFeet,
    innerPocket: InnerPocket,
    outsidePocket: OutsidePocket,
    dustBagIncluded: DustBag,
    authenticityCardIncluded: AuthenticityCard,
    op1ENG: obj.Option1,
    op1FR: obj.Option1Fr,
    op2ENG: obj.Option2,
    op2FR: obj.Option2Fr,
    op3ENG: obj.Option3,
    op3FR: obj.Option3Fr,
    op4ENG: obj.Option4,
    op4FR: obj.Option4Fr,
    op5ENG: obj.Option5,
    op5FR: obj.Option5Fr,
    displayOnWeb: WebsiteFlag,
    displayOnCorporate: OnCorporate,
    active: active,
    SEOShortENG: obj.SeoShortDescription,
    SEOShortFR: obj.SeoShortDescriptionFr,
    SEOLongENG: obj.SeoExtendedDescription,
    SEOLongFR: obj.SeoExtendedDescriptionFr,
    SEOOp1ENG: obj.SeoOption1,
    SEOOp1FR: obj.SeoOption1Fr,
    SEOOp2ENG: obj.SeoOption2,
    SEOOp2FR: obj.SeoOption2Fr,
    SEOOp3ENG: obj.SeoOption3,
    SEOOp3FR: obj.SeoOption3Fr,
    SEOOp4ENG: obj.SeoOption4,
    SEOOp4FR: obj.SeoOption4Fr,
    SEOOp5ENG: obj.SeoOption5,
    SEOOp5FR: obj.SeoOption5Fr,
    SEOMetaTitle: obj.MetaTitle,
    SEOMetaKeywords: obj.MetaKeywords,
    SEOMetaDescription: obj.MetaDescription,
    SEOTitle: obj.DesignSeoTitle,
    SEODescription: obj.DesignSeoDesc,
  };
};
export const legacyDesignUpdateModel = (obj, mode) => {
  const d = new Date(
    new Date().toString().split("GMT")[0] + " UTC"
  ).toISOString();
  const n = d.slice(0, 19);
  if (mode === "addDesign") {
    return {
      DesignServiceCode: obj.designCode,
      HTSDescription: obj.designHTSDescription,
      Brand: obj.brand,
      DesignServiceFlag: obj.designServiceFlag,
      DesignServiceFamilyCode: obj.pickedSubFamily,
      DesignServiceFashionNameAshlin: obj.internalAshlinName,
      DesignServiceFashionNameTscCa: obj.onlineName,
      DesignServiceFashionNameTheBay: obj.onlineName,
      DesignServiceFashionNameBestbuyCa: obj.onlineName,
      DesignServiceFashionNameAmazonCa: obj.onlineName,
      DesignServiceFashionNameAmazonCom: obj.onlineName,
      DesignServiceFashionNameStaplesCa: obj.onlineName,
      DesignServiceFashionNameWalmart: obj.onlineName,
      ShortDescription: obj.shortENG,
      ShortDescriptionFr: obj.shortFR,
      ExtendedDescription: obj.longENG,
      ExtendedDescriptionFr: obj.longFR,
      Imprintable: parseInt(obj.imprinted, 10),
      ImprintHeightCm: obj.imprintHeight,
      ImprintWidthCm: obj.imprintWidth,
      WidthCm: obj.productWidth,
      HeightCm: obj.productHeight,
      DepthCm: obj.productDepth,
      WeightGrams: obj.productWeight,
      FlatShippable: parseInt(obj.flat, 10),
      FoldShippable: parseInt(obj.folded, 10),
      ShippableWidthCm: obj.shipmentWidth,
      ShippableHeightCm: obj.shipmentHeight,
      ShippableDepthCm: obj.shipmentDepth,
      ShippableWeightGrams: obj.shipmentWeight,
      Components: obj.numComponent,
      Strap: parseInt(obj.strap, 10),
      DetachableStrap: parseInt(obj.detachable, 10),
      ZipperedEnclosure: parseInt(obj.enclosure, 10),
      Option1: obj.op1ENG,
      Option1Fr: obj.op1FR,
      Option2: obj.op2ENG,
      Option2Fr: obj.op2FR,
      Option3: obj.op3ENG,
      Option3Fr: obj.op3FR,
      Option4: obj.op4ENG,
      Option4Fr: obj.op4FR,
      Option5: obj.op5ENG,
      Option5Fr: obj.op5FR,
      WebsiteFlag: parseInt(obj.displayOnWeb, 10),
      Active: 0,
      DateAdded: n,
      DesignUrl: "URL NEED TO FINISH",
      Monogram: parseInt(obj.monogrammed, 10),
      TrendShortDescription: obj.shortTrendENG,
      TrendExtendedDescription: obj.longTrendENG,
      TrendShortDescriptionFr: obj.shortTrendFR,
      TrendExtendedDescriptionFr: obj.longTrendFR,
      DesignOnline: obj.webENG,
      DesignOnlineFr: obj.webFR,
      GiftBox: obj.giftBox,
      Country: obj.country,
      ShoulderDropLength: obj.shoulderDropLength,
      HandleStrapDropLength: obj.handleTotalLength,
      NotableStrapGeneralFeatures: obj.notableStrap,
      ProtectiveFeet: parseInt(obj.protectiveFeet, 10),
      Closure: obj.closure,
      InnerPocket: parseInt(obj.innerPocket, 10),
      OutsidePocket: parseInt(obj.outsidePocket, 10),
      SizeDifferentiation: obj.sizeDifferentiation,
      DustBag: parseInt(obj.dustBagIncluded, 10),
      AuthenticityCard: parseInt(obj.authenticityCardIncluded, 10),
      BillCompartment: parseInt(obj.billCompartment, 10),
      CardSlot: obj.cardSlots,
      GeneralName: obj.generalName,
      Style: obj.style,
      OnCorporate: parseInt(obj.displayOnCorporate, 10),
      MetaKeywords: obj.SEOMetaKeywords,
      MetaTitle: obj.SEOMetaTitle,
      MetaDescription: obj.SEODescription,
      SeoShortDescription: obj.SEOShortENG,
      SeoExtendedDescription: obj.SEOLongENG,
      SeoShortDescriptionFr: obj.SEOShortFR,
      SeoExtendedDescriptionFr: obj.SEOLongFR,
      SeoOption1: obj.SEOOp1ENG,
      SeoOption2: obj.SEOOp2ENG,
      SeoOption3: obj.SEOOp3ENG,
      SeoOption4: obj.SEOOp4ENG,
      SeoOption5: obj.SEOOp5ENG,
      SeoOption1Fr: obj.SEOOp1FR,
      SeoOption2Fr: obj.SEOOp2FR,
      SeoOption3Fr: obj.SEOOp3FR,
      SeoOption4Fr: obj.SEOOp4FR,
      SeoOption5Fr: obj.SEOOp5FR,
      DesignSeoTitle: obj.SEOTitle,
      DesignSeoDesc: obj.SEODescription,
      BigCommerceId: "",
      ShippingPackaging: obj.shippingPackaging,
    };
  } else {
    return {
      DesignServiceCode: obj.designCode,
      HTSDescription: obj.designHTSDescription,
      Brand: obj.brand,
      DesignServiceFlag: obj.designServiceFlag,
      DesignServiceFamilyCode: obj.pickedSubFamily,
      DesignServiceFashionNameAshlin: obj.internalAshlinName,
      DesignServiceFashionNameTscCa: obj.onlineName,
      DesignServiceFashionNameTheBay: obj.onlineName,
      DesignServiceFashionNameBestbuyCa: obj.onlineName,
      DesignServiceFashionNameAmazonCa: obj.onlineName,
      DesignServiceFashionNameAmazonCom: obj.onlineName,
      DesignServiceFashionNameStaplesCa: obj.onlineName,
      DesignServiceFashionNameWalmart: obj.onlineName,
      ShortDescription: obj.shortENG,
      ShortDescriptionFr: obj.shortFR,
      ExtendedDescription: obj.longENG,
      ExtendedDescriptionFr: obj.longFR,
      Imprintable: parseInt(obj.imprinted, 10),
      ImprintHeightCm: obj.imprintHeight,
      ImprintWidthCm: obj.imprintWidth,
      WidthCm: obj.productWidth,
      HeightCm: obj.productHeight,
      DepthCm: obj.productDepth,
      WeightGrams: obj.productWeight,
      FlatShippable: parseInt(obj.flat, 10),
      FoldShippable: parseInt(obj.folded, 10),
      ShippableWidthCm: obj.shipmentWidth,
      ShippableHeightCm: obj.shipmentHeight,
      ShippableDepthCm: obj.shipmentDepth,
      ShippableWeightGrams: obj.shipmentWeight,
      Components: obj.numComponent,
      Strap: parseInt(obj.strap, 10),
      DetachableStrap: parseInt(obj.detachable, 10),
      ZipperedEnclosure: parseInt(obj.enclosure, 10),
      Option1: obj.op1ENG,
      Option1Fr: obj.op1FR,
      Option2: obj.op2ENG,
      Option2Fr: obj.op2FR,
      Option3: obj.op3ENG,
      Option3Fr: obj.op3FR,
      Option4: obj.op4ENG,
      Option4Fr: obj.op4FR,
      Option5: obj.op5ENG,
      Option5Fr: obj.op5FR,
      WebsiteFlag: parseInt(obj.displayOnWeb, 10),
      Active: obj.active,
      DateUpdated: n,
      DesignUrl: "URL NEED TO FINISH",
      Monogram: parseInt(obj.monogrammed, 10),
      TrendShortDescription: obj.shortTrendENG,
      TrendExtendedDescription: obj.longTrendENG,
      TrendShortDescriptionFr: obj.shortTrendFR,
      TrendExtendedDescriptionFr: obj.longTrendFR,
      DesignOnline: obj.webENG,
      DesignOnlineFr: obj.webFR,
      GiftBox: obj.giftBox,
      Country: obj.country,
      ShoulderDropLength: obj.shoulderDropLength,
      HandleStrapDropLength: obj.handleTotalLength,
      NotableStrapGeneralFeatures: obj.notableStrap,
      ProtectiveFeet: parseInt(obj.protectiveFeet, 10),
      Closure: obj.closure,
      InnerPocket: parseInt(obj.innerPocket, 10),
      OutsidePocket: parseInt(obj.outsidePocket, 10),
      SizeDifferentiation: obj.sizeDifferentiation,
      DustBag: parseInt(obj.dustBagIncluded, 10),
      AuthenticityCard: parseInt(obj.authenticityCardIncluded, 10),
      BillCompartment: parseInt(obj.billCompartment, 10),
      CardSlot: obj.cardSlots,
      GeneralName: obj.generalName,
      Style: obj.style,
      OnCorporate: parseInt(obj.displayOnCorporate, 10),
      MetaKeywords: obj.SEOMetaKeywords,
      MetaTitle: obj.SEOMetaTitle,
      MetaDescription: obj.SEODescription,
      SeoShortDescription: obj.SEOShortENG,
      SeoExtendedDescription: obj.SEOLongENG,
      SeoShortDescriptionFr: obj.SEOShortFR,
      SeoExtendedDescriptionFr: obj.SEOLongFR,
      SeoOption1: obj.SEOOp1ENG,
      SeoOption2: obj.SEOOp2ENG,
      SeoOption3: obj.SEOOp3ENG,
      SeoOption4: obj.SEOOp4ENG,
      SeoOption5: obj.SEOOp5ENG,
      SeoOption1Fr: obj.SEOOp1FR,
      SeoOption2Fr: obj.SEOOp2FR,
      SeoOption3Fr: obj.SEOOp3FR,
      SeoOption4Fr: obj.SEOOp4FR,
      SeoOption5Fr: obj.SEOOp5FR,
      DesignSeoTitle: obj.SEOTitle,
      DesignSeoDesc: obj.SEODescription,
      BigCommerceId: "",
      ShippingPackaging: obj.shippingPackaging,
    };
  }
};
export const getAshlinDesignCode = async () => {
  return await axios.get(`${legacyDesignsURL}designs`);
};

export const getSkuShippingInfo = async () => {
  return (await axios.get(`${legacySkusURL}shipping`)).data;
};
export const legacySkusURL = baseURL + "api/LegacySku/";
export const legacyUpc9 = baseURL + "api/LegacyUpc";
export const SkuPantoneColorUrl = baseURL + "api/SkuPantoneColors";
const headersConfig = {
  headers: {
    "Content-Type": "application/json",
  },
};
/********************************SKU UPDATE START***********************************/
export const ProductPromotionUrl = baseURL + "api/ProductPromotions";

export const getPromotionEndProducts = async () => {
  try {
    const res = await axios.get(`${ProductPromotionUrl}/End`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res.data;
  } catch (e) {
    return null;
  }
};
export const postProductPromotionan = async (promotionList) => {
  try {
    return await axios.post(ProductPromotionUrl, promotionList, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) {
    return null;
  }
};
export const putdateProductPromotion = async (promotion) => {
  try {
    return await axios.put(
      `${ProductPromotionUrl}/${promotion.ProductPromotionId}`,
      promotion,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  } catch (e) {
    return null;
  }
};

export const getAllSKUNumberList = async () => {
  const res = await axios.get(`${legacySkusURL}all`);
  return res.data;
};
export const getSKU = async (skuNum) => {
  const res = await axios.get(`${legacySkusURL}${skuNum}`);
  return res.data;
};
export const getHtsCa = async () => {
  const res = await axios.get(legacyHtsCaURL);
  return res.data;
};
export const getHtsUs = async () => {
  const res = await axios.get(legacyHtsUsURL);
  return res.data;
};
export const getSkuWithHts = async () => {
  const res = await axios.get(`${legacySkusURL}HtsList`);
  return res.data;
};
export const getSkuWithUpc = async () => {
  const res = await axios.get(`${legacySkusURL}Upc`);
  return res.data;
};
export const getGetSkuPantoneColor = async (sku) => {
  try {
    const res = await axios.get(`${SkuPantoneColorUrl}/${sku}`);
    return res.data;
  } catch (e) {
    return "No record";
  }
};
export const addSkuPantoneColor = async (pantoneColor) => {
  const res = await axios.post(SkuPantoneColorUrl, pantoneColor, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return res.data;
};
export const updateSkuPantoneColor = async (pantoneColor) => {
  const res = await axios.put(
    `${SkuPantoneColorUrl}/${pantoneColor.SkuPantoneColorId}`,
    pantoneColor,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return res.data;
};
/********************************SKU UPDATE FINISH***********************************/
export const legacySKUUpdateModel = (obj) => {
  const d = new Date(
    new Date().toString().split("GMT")[0] + " UTC"
  ).toISOString();
  const n = d.slice(0, 19);
  //console.log(obj);
  return {
    SkuAshlin:
      obj.design.designCode +
      "-" +
      obj.material.materialCode +
      "-" +
      obj.color.colorCode,
    DesignServiceCode: obj.design.designCode,
    MaterialCode: obj.material.materialCode,
    ColourCode: obj.color.colorCode,
    SkuStaplesAdvantage: "",
    SkuTheBay: "",
    SkuBestbuyCa: "",
    SkuAmazonCa: "",
    SkuAmazonCom: "",
    SkuStaplesCa: "",
    SkuWalmartCa: "",
    SkuWalmartCom: "",
    SkuEbayCa: "",
    SkuEbayCom: "",
    LocationWh: 0,
    LocationShelf: 0,
    LocationColIndex: 0,
    LocationRack: 0,
    LocationFull: "WH-R-S-C",
    BasePrice: obj.newSKU.basicInfo.selectedBasePrice,
    MinOrder: obj.newSKU.basicInfo.selectedMinimumOrder,
    UpcCode9: obj.newSKU.basicInfo.upc9,
    UpcCode10: obj.newSKU.basicInfo.upc10,
    AshlinUrl: "",
    HtsCdn: obj.subFamily.htsCaCode,
    HtsUs: obj.subFamily.htsUsCode,
    DutyCdn: obj.subFamily.htsCaValue,
    DutyUs: obj.subFamily.htsUsValue,
    Image1Path: "",
    Image2Path: "",
    Image3Path: "",
    Image4Path: "",
    Image5Path: "",
    Image6Path: "",
    Image7Path: "",
    Image8Path: "",
    Image9Path: "",
    Image10Path: "",
    ImageGroup1Path: "",
    ImageGroup2Path: "",
    ImageGroup3Path: "",
    ImageGroup4Path: "",
    ImageGroup5Path: "",
    ImageModel1Path: "",
    ImageModel2Path: "",
    ImageModel3Path: "",
    ImageModel4Path: "",
    ImageModel5Path: "",
    ImageLife1Path: "",
    ImageLife2Path: "",
    ImageLife3Path: "",
    ImageLife4Path: "",
    ImageLife5Path: "",
    ImageSmall1Path: "",
    ImageSmall2Path: "",
    ImageSmall3Path: "",
    ImageSmall4Path: "",
    ImageSmall5Path: "",
    ImageSmall6Path: "",
    ImageSmall7Path: "",
    ImageSmall8Path: "",
    ImageSmall9Path: "",
    ImageSmall10Path: "",
    Active: true,
    DateAdded: n,
    DateUpdated: "",
    DateActivated: "",
    DateDeactivated: "",
    AltTextImage1Path: "",
    AltTextImage2Path: "",
    AltTextImage3Path: "",
    AltTextImage4Path: "",
    AltTextImage5Path: "",
    AltTextImage6Path: "",
    AltTextImage7Path: "",
    AltTextImage8Path: "",
    AltTextImage9Path: "",
    AltTextImage10Path: "",
    AltTextImageGroup1Path: "",
    AltTextImageGroup2Path: "",
    AltTextImageGroup3Path: "",
    AltTextImageGroup4Path: "",
    AltTextImageGroup5Path: "",
    AltTextImageModel1Path: "",
    AltTextImageModel2Path: "",
    AltTextImageModel3Path: "",
    AltTextImageModel4Path: "",
    AltTextImageModel5Path: "",
    AltTextImageLife1Path: "",
    AltTextImageLife2Path: "",
    AltTextImageLife3Path: "",
    AltTextImageLife4Path: "",
    AltTextImageLife5Path: "",
    SsmaTimeStamp: null,
    TemplateUrl1: "",
    TemplateUrl2: "",
    SkuWebsite: obj.design.displayOnWeb,
    AsiXid: "NEED TO CHECK",
    PricingTier: obj.subFamily.pricingTier,
    ReorderLevel: obj.subFamily.ReorderLevel,
    ReorderQuantity: obj.subFamily.ReorderQty,
    LiningMaterial: obj.newSKU.basicInfo.selectedLiningMaterial,
    Trim: obj.newSKU.basicInfo.selectedTrim,
    HandleMaterial: obj.newSKU.basicInfo.selectedHandleMaterial,
    HardwareColour: obj.newSKU.basicInfo.selectedHardwareColor,
    SkuAmazonUk: "",
    WinWalmartCa: "",
    WinWalmartCom: "",
    SkuSageCan: "",
    SkuSageUsa: "",
    SkuBestbuyCaActive: null,
    SkuStaplesCaActive: null,
    SkuStaplesAdvantageActive: null,
    SkuSearsCaActive: null,
    SkuWalmartCaActive: null,
    SkuWalmartComActive: null,
    SkuTheBayActive: null,
    SkuAmazonCaActive: null,
    SkuAmazonComActive: null,
    SkuAmazonUkActive: null,
    SkuEbayCaActive: null,
    SkuEbayComActive: null,
    QtyOnHand: 0,
    OnCorporate: obj.design.displayOnCorporate,
    MetaKeywords: obj.design.SEOMetaKeywords,
    Collection: obj.family.Collections,
    On6hide: false,
    MetaTitle: obj.design.SEOMetaTitle,
    MetaDescription: obj.design.SEOMetaDescription,
    SkuSeoTitle: obj.design.SEOTitle,
    SkuSeoDesc: obj.design.SEODescription,
    Subfamily: obj.subFamily.subFamilyCode,
    Family: obj.family.SuperFamily,
    AsiCategory: obj.newSKU.basicInfo.selectedAsiCategory,
    LatestAsiPostSuccessful: false,
    AsiUpdateTimestamp: null,
    LatestSagePostSuccessful: null,
    SageUpdateTimestamp: null,
    BigCommerceId: "",
    ImprintedMinOrder: "N/A",
  };
};
export const updateSKU = async (sku) => {
  const res = await axios.put(
    `${legacySkusURL}${sku.SkuAshlin}`,
    sku,
    headersConfig
  );
  return res.data;
};

export const SkuImageUrl = baseURL + "api/SkuImageUrls";
export const postNewImage = async (data) => {
  //const data = []; // 8/24/18check whether this is needed
  //data.push(obj);
  return axios.post(SkuImageUrl, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getSKUImageforASI = async (XID) => {
  const res = await axios.get(`${SkuImageUrl}/SMALL/${XID}`);
  return res.data;
};
export const getSKUImageByHostAndType = async (Sku, Host, Type) => {
  const res = await axios.get(`${SkuImageUrl}/Sku/${Sku}/${Host}/${Type}`);
  return res.data;
};
export const SkuImageUrlModel = (obj, sku, lastModified) => {
  let urls = [];
  obj.base.forEach((i) => {
    urls.push({
      Sku: sku,
      ImageType: "BASE",
      ImageUrl: i,
      Host: "Google",
      LastModifiedDate: lastModified,
    });
  });
  obj.group.forEach((i) => {
    urls.push({
      Sku: sku,
      ImageType: "GROUP",
      ImageUrl: i,
      Host: "Google",
      LastModifiedDate: lastModified,
    });
  });
  obj.liftStyle.forEach((i) => {
    urls.push({
      Sku: sku,
      ImageType: "LIFE",
      ImageUrl: i,
      Host: "Google",
      LastModifiedDate: lastModified,
    });
  });
  obj.model.forEach((i) => {
    urls.push({
      Sku: sku,
      ImageType: "MODEL",
      ImageUrl: i,
      Host: "Google",
      LastModifiedDate: lastModified,
    });
  });
  obj.sftp.forEach((i) => {
    urls.push({
      Sku: sku,
      ImageType: "BASE",
      ImageUrl: i,
      Host: "Hostinger",
      LastModifiedDate: lastModified,
    });
  });
  obj.upc9.forEach((i) => {
    urls.push({
      Sku: sku,
      ImageType: "UPC11",
      ImageUrl: i,
      Host: "Google",
      LastModifiedDate: lastModified,
    });
  });
  obj.upc10.forEach((i) => {
    urls.push({
      Sku: sku,
      ImageType: "UPC12",
      ImageUrl: i,
      Host: "Google",
      LastModifiedDate: lastModified,
    });
  });
  obj.small.forEach((i) => {
    urls.push({
      Sku: sku,
      ImageType: "SMALL",
      ImageUrl: i,
      Host: "Google",
      LastModifiedDate: lastModified,
    });
  });
  obj.template.forEach((i) => {
    urls.push({
      Sku: sku,
      ImageType: "TEMPLATE",
      ImageUrl: i,
      Host: "Google",
      LastModifiedDate: lastModified,
    });
  });
  obj.main.forEach((i) => {
    urls.push({
      Sku: sku,
      ImageType: "MAIN",
      ImageUrl: i,
      Host: "Google",
      LastModifiedDate: lastModified,
    });
  });
  obj.sftpMain.forEach((i) => {
    urls.push({
      Sku: sku,
      ImageType: "MAIN",
      ImageUrl: i,
      Host: "Hostinger",
      LastModifiedDate: lastModified,
    });
  });

  return urls;
};

export const SkuChannelListingUrl = baseURL + "api/SkuChannelListings";

export const getDistinctSku = async () => {
  try {
    const res = await axios.get(`${SkuChannelListingUrl}/distinct`);
    return res.data;
  } catch (e) {
    return [];
  }
};
export const getChannelSKU = async (Channel, Sku) => {
  try {
    const res = await axios.get(
      `${SkuChannelListingUrl}/detail/${Channel}/${Sku}`
    );
    return res.data;
  } catch (e) {
    return "";
  }
};
export const searchChannelSKU = async (Channel, Sku) => {
  try {
    const res = await axios.get(
      `${SkuChannelListingUrl}/search/${Channel}/${Sku}`
    );
    return res.data;
  } catch (e) {
    return "";
  }
};
export const SkuChannelListingModel = (obj, sku) => {
  const d = new Date(
    new Date().toString().split("GMT")[0] + " UTC"
  ).toISOString();
  const last = d.slice(0, 19);
  let channelList = [];
  if (obj.amazon) {
    channelList.push({
      Sku: sku,
      ChannelName: "Amazon",
      Active: 1,
      LastModifiedDate: last,
    });
  }
  if (obj.asi) {
    channelList.push({
      Sku: sku,
      ChannelName: "Asi",
      Active: 1,
      LastModifiedDate: last,
    });
  }
  if (obj.bigCommerceAshlin) {
    channelList.push({
      Sku: sku,
      ChannelName: "BigCommerce Ashlin",
      Active: 1,
      LastModifiedDate: last,
    });
  }
  if (obj.bigCommercePoppyBuzz) {
    channelList.push({
      Sku: sku,
      ChannelName: "BigCommerce PoppyBuzz",
      Active: 1,
      LastModifiedDate: last,
    });
  }
  if (obj.brightPearl) {
    channelList.push({
      Sku: sku,
      ChannelName: "BrightPearl",
      Active: 1,
      LastModifiedDate: last,
    });
  }
  if (obj.database) {
    channelList.push({
      Sku: sku,
      ChannelName: "Database",
      Active: 1,
      LastModifiedDate: last,
    });
  }
  if (obj.leanSupply) {
    channelList.push({
      Sku: sku,
      ChannelName: "LeanSupply",
      Active: 1,
      LastModifiedDate: last,
    });
  }
  if (obj.sage) {
    channelList.push({
      Sku: sku,
      ChannelName: "Sage",
      Active: 1,
      LastModifiedDate: last,
    });
  }
  if (obj.staples) {
    channelList.push({
      Sku: sku,
      ChannelName: "Staples",
      Active: 1,
      LastModifiedDate: last,
    });
  }
  if (obj.theBay) {
    channelList.push({
      Sku: sku,
      ChannelName: "theBay",
      Active: 1,
      LastModifiedDate: last,
    });
  }
  // if(obj.walmart){
  //     channelList.push({
  //         "Sku": sku,
  //         "ChannelName": "WalmartCa",
  //         "Active":0,
  //         "LastModifiedDate":last
  //     })
  // }
  // if(obj.bestBuy){
  //     channelList.push({
  //         "Sku": sku,
  //         "ChannelName": "BestBuy",
  //         "Active":0,
  //         "LastModifiedDate":last
  //     })
  // }
  // if(obj.staplesCa){
  //     channelList.push({
  //         "Sku": sku,
  //         "ChannelName": "StaplesCa",
  //         "Active":0,
  //         "LastModifiedDate":last
  //     })
  // }
  return channelList;
};
export const getChannelList = async () => {
  return await axios.get(`${SkuChannelListingUrl}/channel`);
};
export const getListedSkus = async (channelName) => {
  return await axios.get(`${SkuChannelListingUrl}/channel/${channelName}`);
};
export const getUnlistedSku = async (channelName) => {
  return await axios.get(`${legacySkusURL}/unlisted/${channelName}`);
};
export const getListedSkusWithBrightId = async (channelName) => {
  return await axios.get(`${SkuChannelListingUrl}/inventory/${channelName}`);
};
export const lastModifiedTime = () => {
  const d = new Date(
    new Date().toString().split("GMT")[0] + " UTC"
  ).toISOString();
  return d.slice(0, 19);
};
export const getECommerceList = async () => {
  return await axios.get(`${SkuChannelListingUrl}/eCommerce`);
};
export const postSkuChannelListing = async (items, channelName) => {
  const d = new Date(
    new Date().toString().split("GMT")[0] + " UTC"
  ).toISOString();
  const n = d.slice(0, 19);

  const data = items.map((i) => {
    const merchanSku = i.MerchanSku !== undefined ? i.MerchanSku : null;
    return {
      Sku: i.Sku,
      Active: 1,
      ChannelName: channelName,
      MerchanSku: merchanSku,
      LastModifiedDate: n,
    };
  });
  return await axios.post(SkuChannelListingUrl, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const updateSkuChannelListing = async (item, action = "") => {
  const active = action === "deactive" ? 0 : 1;
  const d = new Date(
    new Date().toString().split("GMT")[0] + " UTC"
  ).toISOString();
  const n = d.slice(0, 19);
  const SkuChannelListingItem = {
    Sku: item.Sku,
    Active: active,
    ChannelName: item.ChannelName,
    LastModifiedDate: n,
    MerchanSku: item.MerchanSku,
    SkuChannelListingId: item.SkuChannelListingId,
  };

  return await axios.put(
    `${SkuChannelListingUrl}/${item.SkuChannelListingId}`,
    SkuChannelListingItem,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};
export const updateSkuChannelListing2 = async (skuChannelListing) => {
  return await axios.put(
    `${SkuChannelListingUrl}/${skuChannelListing.SkuChannelListingId}`,
    skuChannelListing,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};
export const deleteSkuChannelListing = async (channelName, Sku) => {
  return await axios.delete(`${SkuChannelListingUrl}/${channelName}/${Sku}`);
};

export const SkuPantoneColorModel = (obj, sku) => {
  return {
    Sku: sku,
    PantoneColorName: obj.pantoneColor,
    Red: obj.colorR,
    Green: obj.colorG,
    Blue: obj.colorB,
    DesignerColorName: obj.designerColorName,
  };
};

export const getCostMatrix = async (id) => {
  return await axios.get(`${baseURL}api/LegacyCostMatrices/${id}`);
};

export const updateSkuTierCost = async (skuCost, create) => {
  if (create) {
    return await axios.post(`${baseURL}api/LegacySkuCosts`, skuCost, {
      headers,
    });
  } else {
    return await axios.put(
      `${baseURL}api/LegacySkuCosts/${skuCost.Sku}`,
      skuCost,
      { headers }
    );
  }
};
function isNumeric(val) {
  return /^-?\d+$/.test(val);
}

export const getSkuTierCost = async (sku) => {
  try {
    var res = await axios.get(`${baseURL}api/LegacySkuCosts/${sku}`, {
      headers,
    });
    res.data.ReadOnlyProductId = isNumeric(res.data.ProductId);
    // console.log(res.data);
    return res.data;
  } catch (e) {
    return {
      Sku: sku,
      MinimumOrderQuantity: 0,
      KaplUnitCostCadS: 0,
      PricingTier: 0,
    };
  }
};

export const getDesignKittedList = async () => {
  return await axios.get(`${baseURL}api/DesignKitted`);
};

/****************************Price Calculation Start******************************/
const calculateSkuCost = (skuCost, tierMatrix) => {
  return {
    QTY1: (parseFloat(skuCost) * tierMatrix._1CStandardDelivery).toFixed(2),
    QTY6: (parseFloat(skuCost) * tierMatrix._6CStandardDelivery).toFixed(2),
    QTY24: (parseFloat(skuCost) * tierMatrix._24CStandardDelivery).toFixed(2),
    QTY50: (parseFloat(skuCost) * tierMatrix._50CStandardDelivery).toFixed(2),
    QTY100: (parseFloat(skuCost) * tierMatrix._100CStandardDelivery).toFixed(2),
    QTY250: (parseFloat(skuCost) * tierMatrix._250CStandardDelivery).toFixed(2),
    QTY500: (parseFloat(skuCost) * tierMatrix._500CStandardDelivery).toFixed(2),
    QTY1000: (parseFloat(skuCost) * tierMatrix._1000CStandardDelivery).toFixed(
      2
    ),
    QTY2500: (parseFloat(skuCost) * tierMatrix._2500CStandardDelivery).toFixed(
      2
    ),
  };
};
const costMatrixTier = async (sku) => {
  const res = await axios.get(`${baseURL}api/LegacyCostMatrices/${sku}`);
  return res.data;
};
const discountMatrixTier = async (tier) => {
  const res = await axios.get(`${baseURL}api/Discount/${tier}`);
  return res.data;
};
const calculateCStandard = (price, discountMatrix, currentExchangeRate) => {
  return {
    QTY1: (
      parseFloat(price) *
      discountMatrix._1CStandardDelivery *
      currentExchangeRate
    ).toFixed(2),
    QTY6: (
      parseFloat(price) *
      discountMatrix._6CStandardDelivery *
      currentExchangeRate
    ).toFixed(2),
    QTY24: (
      parseFloat(price) *
      discountMatrix._24CStandardDelivery *
      currentExchangeRate
    ).toFixed(2),
    QTY50: (
      parseFloat(price) *
      discountMatrix._50CStandardDelivery *
      currentExchangeRate
    ).toFixed(2),
    QTY100: (
      parseFloat(price) *
      discountMatrix._100CStandardDelivery *
      currentExchangeRate
    ).toFixed(2),
    QTY250: (
      parseFloat(price) *
      discountMatrix._250CStandardDelivery *
      currentExchangeRate
    ).toFixed(2),
    QTY500: (
      parseFloat(price) *
      discountMatrix._500CStandardDelivery *
      currentExchangeRate
    ).toFixed(2),
    QTY1000: (
      parseFloat(price) *
      discountMatrix._1000CStandardDelivery *
      currentExchangeRate
    ).toFixed(2),
    QTY2500: (
      parseFloat(price) *
      discountMatrix._2500CStandardDelivery *
      currentExchangeRate
    ).toFixed(2),
  };
};
const calculateCStandardImprinted = (priceList, components) => {
  const qyt1Fee =
    priceList.QTY1 * 0.05 < 1
      ? 1
      : priceList.QTY1 * 0.05 > 8
      ? 8
      : priceList.QTY1 * 0.05;
  const qyt6Fee =
    priceList.QTY6 * 0.05 < 1
      ? 1
      : priceList.QTY6 * 0.05 > 8
      ? 8
      : priceList.QTY6 * 0.05;
  const qyt24Fee =
    priceList.QTY24 * 0.05 < 1
      ? 1
      : priceList.QTY24 * 0.05 > 8
      ? 8
      : priceList.QTY24 * 0.05;
  const qyt50Fee =
    priceList.QTY50 * 0.05 < 1
      ? 1
      : priceList.QTY50 * 0.05 > 8
      ? 8
      : priceList.QTY50 * 0.05;
  const qyt100Fee =
    priceList.QTY100 * 0.05 < 1
      ? 1
      : priceList.QTY100 * 0.05 > 8
      ? 8
      : priceList.QTY100 * 0.05;
  const qyt250Fee =
    priceList.QTY250 * 0.05 < 1
      ? 1
      : priceList.QTY250 * 0.05 > 8
      ? 8
      : priceList.QTY250 * 0.05;
  const qyt500Fee =
    priceList.QTY500 * 0.05 < 1
      ? 1
      : priceList.QTY500 * 0.05 > 8
      ? 8
      : priceList.QTY500 * 0.05;
  const qyt1000Fee =
    priceList.QTY1000 * 0.05 < 1
      ? 1
      : priceList.QTY1000 * 0.05 > 8
      ? 8
      : priceList.QTY1000 * 0.05;
  const qyt2500Fee =
    priceList.QTY2500 * 0.05 < 1
      ? 1
      : priceList.QTY2500 * 0.05 > 8
      ? 8
      : priceList.QTY2500 * 0.05;
  const additionalComponentFee = (components - 1) * 1.0;
  return {
    QTY1: (
      parseFloat(priceList.QTY1) +
      qyt1Fee +
      additionalComponentFee
    ).toFixed(2),
    QTY6: (
      parseFloat(priceList.QTY6) +
      qyt6Fee +
      additionalComponentFee
    ).toFixed(2),
    QTY24: (
      parseFloat(priceList.QTY24) +
      qyt24Fee +
      additionalComponentFee
    ).toFixed(2),
    QTY50: (
      parseFloat(priceList.QTY50) +
      qyt50Fee +
      additionalComponentFee
    ).toFixed(2),
    QTY100: (
      parseFloat(priceList.QTY100) +
      qyt100Fee +
      additionalComponentFee
    ).toFixed(2),
    QTY250: (
      parseFloat(priceList.QTY250) +
      qyt250Fee +
      additionalComponentFee
    ).toFixed(2),
    QTY500: (
      parseFloat(priceList.QTY500) +
      qyt500Fee +
      additionalComponentFee
    ).toFixed(2),
    QTY1000: (
      parseFloat(priceList.QTY1000) +
      qyt1000Fee +
      additionalComponentFee
    ).toFixed(2),
    QTY2500: (
      parseFloat(priceList.QTY2500) +
      qyt2500Fee +
      additionalComponentFee
    ).toFixed(2),
  };
};
const calculateNetStandard = (price, discountMatrix, currentExchangeRate) => {
  return {
    QTY1: (
      parseFloat(price) *
      discountMatrix._1NetStandardDelivery *
      currentExchangeRate
    ).toFixed(2),
    QTY6: (
      parseFloat(price) *
      discountMatrix._6NetStandardDelivery *
      currentExchangeRate
    ).toFixed(2),
    QTY24: (
      parseFloat(price) *
      discountMatrix._24NetStandardDelivery *
      currentExchangeRate
    ).toFixed(2),
    QTY50: (
      parseFloat(price) *
      discountMatrix._50NetStandardDelivery *
      currentExchangeRate
    ).toFixed(2),
    QTY100: (
      parseFloat(price) *
      discountMatrix._100NetStandardDelivery *
      currentExchangeRate
    ).toFixed(2),
    QTY250: (
      parseFloat(price) *
      discountMatrix._250NetStandardDelivery *
      currentExchangeRate
    ).toFixed(2),
    QTY500: (
      parseFloat(price) *
      discountMatrix._500NetStandardDelivery *
      currentExchangeRate
    ).toFixed(2),
    QTY1000: (
      parseFloat(price) *
      discountMatrix._1000NetStandardDelivery *
      currentExchangeRate
    ).toFixed(2),
    QTY2500: (
      parseFloat(price) *
      discountMatrix._2500NetStandardDelivery *
      currentExchangeRate
    ).toFixed(2),
  };
};
const calculateRush = (priceList, discount) => {
  return {
    QTY1: (parseFloat(priceList.QTY1) * discount).toFixed(2),
    QTY6: (parseFloat(priceList.QTY6) * discount).toFixed(2),
    QTY24: (parseFloat(priceList.QTY24) * discount).toFixed(2),
    QTY50: (parseFloat(priceList.QTY50) * discount).toFixed(2),
    QTY100: (parseFloat(priceList.QTY100) * discount).toFixed(2),
    QTY250: (parseFloat(priceList.QTY250) * discount).toFixed(2),
    QTY500: (parseFloat(priceList.QTY500) * discount).toFixed(2),
    QTY1000: (parseFloat(priceList.QTY1000) * discount).toFixed(2),
    QTY2500: (parseFloat(priceList.QTY2500) * discount).toFixed(2),
  };
};
export const priceList = async (product) => {
  const costMatrix = await costMatrixTier(product.CostPricingTier);
  const costPriceList = calculateSkuCost(product.Cost, costMatrix);

  const price = product.Price * 1.05;
  const CADtoUSD = 0.85;
  const CAD = 1;

  const discountMatrix = await discountMatrixTier(product.PricingTier);

  const Retail_CODED_STD_BLANK_CAD = calculateCStandard(
    price,
    discountMatrix,
    CAD
  );
  const Retail_CODED_STD_BLANK_USD = calculateCStandard(
    price,
    discountMatrix,
    CADtoUSD
  );
  const Distributor_NET_STD_BLANK_CAD = calculateNetStandard(
    price,
    discountMatrix,
    CAD
  );
  const Distributor_NET_STD_BLANK_USD = calculateNetStandard(
    price,
    discountMatrix,
    CADtoUSD
  );

  const Retail_CODED_STD_IMP_CAD = calculateCStandardImprinted(
    Retail_CODED_STD_BLANK_CAD,
    product.Components
  );
  const Retail_CODED_STD_IMP_USD = calculateCStandardImprinted(
    Retail_CODED_STD_BLANK_USD,
    product.Components
  );
  const Distributor_NET_STD_IMP_CAD = calculateCStandardImprinted(
    Distributor_NET_STD_BLANK_CAD,
    product.Components
  );
  const Distributor_NET_STD_IMP_USD = calculateCStandardImprinted(
    Distributor_NET_STD_BLANK_USD,
    product.Components
  );

  const Retail_CODED_RUSH_BLANK_CAD = calculateRush(
    Retail_CODED_STD_BLANK_CAD,
    discountMatrix.RushC25Wks
  );
  const Retail_CODED_RUSH_BLANK_USD = calculateRush(
    Retail_CODED_STD_BLANK_USD,
    discountMatrix.RushC25Wks
  );
  const Distributor_NET_RUSH_BLANK_CAD = calculateRush(
    Distributor_NET_STD_BLANK_CAD,
    discountMatrix.RushNet25Wks
  );
  const Distributor_NET_RUSH_BLANK_USD = calculateRush(
    Distributor_NET_STD_BLANK_USD,
    discountMatrix.RushNet25Wks
  );

  const Retail_CODED_RUSH_IMP_CAD = calculateRush(
    Retail_CODED_STD_IMP_CAD,
    discountMatrix.RushC25Wks
  );
  const Retail_CODED_RUSH_IMP_USD = calculateRush(
    Retail_CODED_STD_IMP_USD,
    discountMatrix.RushC25Wks
  );
  const Distributor_NET_RUSH_IMP_CAD = calculateRush(
    Distributor_NET_STD_IMP_CAD,
    discountMatrix.RushNet25Wks
  );
  const Distributor_NET_RUSH_IMP_USD = calculateRush(
    Distributor_NET_STD_IMP_USD,
    discountMatrix.RushNet25Wks
  );

  const Retail_CAD = Retail_CODED_STD_BLANK_CAD.QTY1;
  const Wholesale_CAD = (Retail_CAD * discountMatrix.WholesaleNet).toFixed(2);
  return {
    costPriceList,
    Retail_CAD,
    Wholesale_CAD,

    Retail_CODED_STD_BLANK_CAD,
    Retail_CODED_STD_IMP_CAD,
    Retail_CODED_RUSH_BLANK_CAD,
    Retail_CODED_RUSH_IMP_CAD,
    Distributor_NET_STD_BLANK_CAD,
    Distributor_NET_STD_IMP_CAD,
    Distributor_NET_RUSH_BLANK_CAD,
    Distributor_NET_RUSH_IMP_CAD,

    Retail_CODED_STD_BLANK_USD,
    Retail_CODED_STD_IMP_USD,
    Retail_CODED_RUSH_BLANK_USD,
    Retail_CODED_RUSH_IMP_USD,
    Distributor_NET_STD_BLANK_USD,
    Distributor_NET_STD_IMP_USD,
    Distributor_NET_RUSH_BLANK_USD,
    Distributor_NET_RUSH_IMP_USD,
  };
};

export const legacySwatchURL = baseURL + "api/SwatchImages";
export const getSwatchURL = async (name) => {
  try {
    const res = await axios.get(`${legacySwatchURL}/${name}`);
    return res.data.SwatchURL;
  } catch (e) {
    return "Not Found";
  }
};

/****************************Price Calculation Finish******************************/
export const getCanadaPostLabel = async (url, credentials) => {
  const response = await axios({
    url: `${baseURL}api/CanadaPost`,
    method: "POST",
    data: {
      LabelURL: url,
      Token: credentials,
    },
    responseType: "blob", // important
  });

  return new Blob([response.data], { type: "application/pdf" });
};

export const createLoomisLabelOnSftp = async (
  LSOrder,
  WeightInKg,
  credentials
) => {
  const LoomisData = leanSupplyToLoomis(credentials, LSOrder, WeightInKg);
  const shipmentRes = await createShipment(LoomisData);
  const XML = new DOMParser().parseFromString(shipmentRes, "text/xml");
  const idNodes = XML.getElementsByTagName("ax27:id");
  let labelIdNode;
  for (let i = 0; i < idNodes.length; i++) {
    if (idNodes[i].parentNode.localName === "shipment") {
      labelIdNode = idNodes[i];
      break;
    }
  }
  const labelId = labelIdNode.innerHTML;
  const label = await getShipingLabel(credentials, labelId);
  const realData = new DOMParser()
    .parseFromString(label, "text/xml")
    .getElementsByTagName("ax25:label")[0].innerHTML;
  const blob = b64toBlob(realData, "image/png");
  const data = new FormData();
  data.append("image", blob, `${LSOrder.OrderHeader.OrderNumber}.png`);
  data.append(
    "vince",
    "8sHTj5LV527LqdWAQBZSDEXrohTSBx9FXyPnGBamXaj60vNCsDMpUTEsyqXDF4MSuDij4tEoZB7O1vdgVR1H8FLezeBgcRGndRb5MSd70mhYgO5d82wA1lIfPg95W3fXyQn7PVlcisOJFbjSMEOt6P6W2p5Xu4IMFodO5z94Hm25rQGr6iodKTGOwZruXtUX9zv7NFx9"
  );
  await axios.post("https://ashlinapp.xyz/api/loomis-label-upload.php", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const getHtsDescription = (hts, name) => {
  const list = htsDetail
    .filter((h) => h.Hts === hts)
    .map((d) => d.Des.toUpperCase());
  return findBestMatch(name.toUpperCase(), list).bestMatch.target;
};
const htsDetail = [
  //CANADA
  { Hts: "4202.91.90.00", Des: "Leather Bags", Duty: 0.07 },
  { Hts: "4202.91.90.00", Des: "Leather Cases", Duty: 0 },
  { Hts: "4205.00.00.00", Des: "Leather Shaving Kits", Duty: 0 },
  { Hts: "4205.00.00.00", Des: "Leather BINDERS", Duty: 0 },
  { Hts: "4205.00.00.00", Des: "Leather COVER", Duty: 0 },
  { Hts: "4205.00.00.00", Des: "Leather TAGS", Duty: 0 },
  { Hts: "4202.31.00.10", Des: "Leather Wallets", Duty: 0.085 },
  { Hts: "3924.10.00.99", Des: "Leather Coasters", Duty: 0.065 },
  { Hts: "4205.00.00.00", Des: "Leather Certificate covers", Duty: 0 },
  { Hts: "4205.00.00.00", Des: "Leather Paperweigt covers", Duty: 0 },
  { Hts: "4205.00.00.00", Des: "Leather DESK covers", Duty: 0 },
  { Hts: "3926.10.00.90", Des: "Rexene Cases", Duty: 0.065 },
  { Hts: "3926.10.00.90", Des: "Rexene wallets", Duty: 0.065 },
  { Hts: "3926.10.00.90", Des: "Rexene BINDERS", Duty: 0.065 },
  { Hts: "3926.10.00.90", Des: "Rexene Coasters", Duty: 0.065 },
  { Hts: "3926.10.00.90", Des: "Rexene Placemats", Duty: 0.065 },
  { Hts: "3926.90.48.00", Des: "Rexene Photo frame", Duty: 0.034 },
  { Hts: "3926.90.33.00", Des: "Rexene PU Handbag", Duty: 0 },
  {
    Hts: "6215.10.00.25",
    Des: "Ties, bow ties and cravats of manmade fibres",
    Duty: 0,
  },
  //US
  { Hts: "4202.21.60.00", Des: "Leather Bags", Duty: 0 },
  { Hts: "4202.21.90.00", Des: "Leather Bags", Duty: 0 },
  { Hts: "4201.00.30.00", Des: "Leather Cases", Duty: 0 },
  { Hts: "4201.00.30.00", Des: "Leather BriefCases", Duty: 0 },
  { Hts: "4205.00.8000", Des: "Leather BINDERS", Duty: 0 },
  { Hts: "4205.00.8000", Des: "Leather Shaving Kits", Duty: 0 },
  { Hts: "4205.00.8000", Des: "Leather Folders", Duty: 0 },
  { Hts: "4205.00.8000", Des: "Leather TAGS", Duty: 0 },
  { Hts: "4202.31.60", Des: "Leather Wallets", Duty: 0 },
  { Hts: "4205.00.8000", Des: "Leather Placemats", Duty: 0 },
  { Hts: "4205.00.8000", Des: "Leather CERTIFICATE HOLDERS", Duty: 0 },
  { Hts: "4205.00.8000", Des: "Leather Coasters", Duty: 0 },
];

//========================================Api for viewing or editing all back end exception=====================

export const getExceptionRecords = async () => {
  return (await axios.get(`${baseURL}api/ExceptionRecords`, { headers })).data;
};

export const deleteExceptionById = async (record) => {
  return (
    await axios.delete(`${baseURL}api/ExceptionRecords/${record.Id}`, {
      headers,
    })
  ).data;
};

export const fixExceptionById = async (record) => {
  record.Status = "Fixed";
  return (
    await axios.put(`${baseURL}api/ExceptionRecords/${record.Id}`, record, {
      headers,
    })
  ).data;
};
