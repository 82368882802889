import React, { Component } from 'react';
import axios from 'axios';
import {
    findTheBestCategory,
    findProductBySku,
    getVariantOptions,
    addNewProduct,
    addVariant,
    addVariantOption,
    addProduct,
    getStoreInfo,
    getVariants,
    getImages,
    deleteProductImage,
    addImage
} from '../bigCommerce';



class BigCommerceApi extends Component{
    constructor(props){
        super(props);
        this.state={
            sku:"",
            isSync:false,
            minOrder:[
                {
                    "SkuAshlin":"304-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"304-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"309-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"6025-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"309-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"5704-07-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"6025-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"6025-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"6025-48-37",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"6025-48-14",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"6036-MM08-01",
                    "MinOrder":"25",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"6340-07-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"6316-07-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"6402-07-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"6447-07-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"699-07-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"699-07-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"699-07-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"699-07-03",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"699-07-47",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"699-07-31",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"7409-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"7409-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"751-07-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"7503-07-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"7512-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"7503-48-47",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"755-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"7512-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"7517-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"7517-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"7714-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"7728-48-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"7728-18-04",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"7728-09-38",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"7714-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"7739-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"7739-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"7748-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"7739-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"7748-18-04",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"7748-48-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"7770-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"7748-09-38",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"7770-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"7786-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"7929-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"7931-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"7931-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"7953-07-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"7971-07-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"7976-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"7976-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"7976-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"7988-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"9775-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"9775-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"9775-18-09",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"9775-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"B619-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"6"
                },
                {
                    "SkuAshlin":"B619-18-09",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"6"
                },
                {
                    "SkuAshlin":"B620-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"6"
                },
                {
                    "SkuAshlin":"B619-MM08-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"6"
                },
                {
                    "SkuAshlin":"B620-18-09",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"6"
                },
                {
                    "SkuAshlin":"7924-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"B8145-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"B8145-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"B8145-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"B8147-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"B620-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"6"
                },
                {
                    "SkuAshlin":"B8147-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"B8262-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"B8262-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"B8147-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"B8644-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"B8644-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"B8801-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"B8644-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"B8801-18-09",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"B8801-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"B8802-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"B8801-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"B8802-18-09",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"B8802-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"B8819-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"B8819-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"B8839-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"B8839-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"B8850-28-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"B8850-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"B8850-28-47",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"B8850-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"B8924-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"B8850-48-47",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"B8954-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"B8924-18-09",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"B9100-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"B8954-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"B9100-18-09",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"B8954-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"B9100-48-37",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"B9103-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"B9103-48-14",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"B9100-48-14",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"B9103-48-37",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"B9104-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"B9104-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"B9104-48-07",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"B9104-18-09",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"B9108-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"B9103-48-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"B9108-48-07",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"B9108-48-14",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"B9931-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"B9104-48-14",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"BOOKMK02-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"B9931-18-09",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"BOOKMK02-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"BOTTLHLDR-08-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"B9108-48-37",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"BOTTLHLDR-08-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"BOWTIE01-08-06",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"BOWTIE01-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"BOOKMK02-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"BRACELT01-00-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"100"
                },
                {
                    "SkuAshlin":"BOWTIE01-48-47",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"BRACELT01-00-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"100"
                },
                {
                    "SkuAshlin":"BRACELT01-00-16",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"100"
                },
                {
                    "SkuAshlin":"BRACELT01-00-101",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"100"
                },
                {
                    "SkuAshlin":"BRACELT01-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"BRACELT01-00-47",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"100"
                },
                {
                    "SkuAshlin":"BOWTIE01-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"BRACELT01-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"BRACELT02-00-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"100"
                },
                {
                    "SkuAshlin":"BRACELT01-00-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"100"
                },
                {
                    "SkuAshlin":"BRACELT02-00-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"100"
                },
                {
                    "SkuAshlin":"BRACELT02-00-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"100"
                },
                {
                    "SkuAshlin":"BRACELT02-00-47",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"100"
                },
                {
                    "SkuAshlin":"BRACELT01-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"BRACELT02-00-101",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"100"
                },
                {
                    "SkuAshlin":"BRACELT02-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"BRACELT02-00-16",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"100"
                },
                {
                    "SkuAshlin":"BY02-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"BRACELT02-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"CDNTAG042X-48-47",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"CERTIF01-08-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"BRACELT02-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"CERTIF02-08-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"CERTIF02-00-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"CERTIF01-00-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"COAST01-00-01",
                    "MinOrder":"2",
                    "ImprintedMinOrder":"100"
                },
                {
                    "SkuAshlin":"COAST01-00-06",
                    "MinOrder":"2",
                    "ImprintedMinOrder":"100"
                },
                {
                    "SkuAshlin":"COAST01-00-02",
                    "MinOrder":"2",
                    "ImprintedMinOrder":"100"
                },
                {
                    "SkuAshlin":"COAST01-00-10",
                    "MinOrder":"2",
                    "ImprintedMinOrder":"100"
                },
                {
                    "SkuAshlin":"CERTIF22-00-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"COAST01-08-47",
                    "MinOrder":"2",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"COAST01-00-08",
                    "MinOrder":"2",
                    "ImprintedMinOrder":"100"
                },
                {
                    "SkuAshlin":"COAST01-08-02",
                    "MinOrder":"2",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"COAST02-00-01",
                    "MinOrder":"2",
                    "ImprintedMinOrder":"100"
                },
                {
                    "SkuAshlin":"COAST02-00-02",
                    "MinOrder":"2",
                    "ImprintedMinOrder":"100"
                },
                {
                    "SkuAshlin":"COAST01-18-08",
                    "MinOrder":"2",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"COAST02-00-06",
                    "MinOrder":"2",
                    "ImprintedMinOrder":"100"
                },
                {
                    "SkuAshlin":"COAST02-00-10",
                    "MinOrder":"2",
                    "ImprintedMinOrder":"100"
                },
                {
                    "SkuAshlin":"COAST02-00-08",
                    "MinOrder":"2",
                    "ImprintedMinOrder":"100"
                },
                {
                    "SkuAshlin":"COAST02-18-01",
                    "MinOrder":"2",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"COAST02-18-02",
                    "MinOrder":"2",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"COAST02-48-47",
                    "MinOrder":"2",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"COAST02-18-08",
                    "MinOrder":"2",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"COAST08-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"COAST03-18-01",
                    "MinOrder":"`",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"COAST114-49-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"F202-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"COAST01-18-01",
                    "MinOrder":"2",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"F7550-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"F203-08-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"F7550-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"FOB08-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"F202-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"FOB08-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"F7550-49-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"FOB08-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"FOB09-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"FOB09-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"F7550-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"FOB10-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"FOB10-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"FOB10-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"FOB14-18-09",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"100"
                },
                {
                    "SkuAshlin":"FOB119-48-37",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"FOB09-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"FOB119-48-14",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"FOB19-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"FOB19-18-09",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"FOB19-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"FOB24-18-01",
                    "MinOrder":"2",
                    "ImprintedMinOrder":"100"
                },
                {
                    "SkuAshlin":"FOB24-18-02",
                    "MinOrder":"2",
                    "ImprintedMinOrder":"100"
                },
                {
                    "SkuAshlin":"FR7-08-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"FOB24-18-08",
                    "MinOrder":"2",
                    "ImprintedMinOrder":"100"
                },
                {
                    "SkuAshlin":"GOLF04-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"FOB19-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"GOLF05-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"GOLF04-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"GOLF06-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"GOLF05-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"GOLF08-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"GOLF14-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"GOLF14-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"GOLF06-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"JEWEL06-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"JOURNALA19-ECOPAPER-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"100"
                },
                {
                    "SkuAshlin":"JOURNAL9R7IRX-ECOPAPER-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"500"
                },
                {
                    "SkuAshlin":"GOLF18-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"K256-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"GOLF14-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"K258-07-87",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"K256-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"K258-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"K258-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"K258-49-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"K258-48-47",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"K258-60-230",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"n/a"
                },
                {
                    "SkuAshlin":"K258-07-76",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"K258-60-231",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"n/a"
                },
                {
                    "SkuAshlin":"K262-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"K258-60-235",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"n/a"
                },
                {
                    "SkuAshlin":"K265-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"K258-60-234",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"n/a"
                },
                {
                    "SkuAshlin":"K262-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"K265-18-09",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"K265-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"L01-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"K265-48-14",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"L01-48-47",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"L01-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"L7902-07-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"L8772-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"K265-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"L8772-18-09",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"L8773-18-09",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"L02-07-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"L8773-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"L8773-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"L8981-18-09",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"L8981-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"L9010-18-09",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"L8981-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"L9010-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"L9016-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"L9010-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"L9016-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"L9018-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"L9018-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"L9950-00-101",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"L9951-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"L9016-18-09",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"L9950-00-147",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"L9951-48-47",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"MOUS02-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"NECKTIE02-MICRO-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"MOUS022-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"0"
                },
                {
                    "SkuAshlin":"NIKNAKHOLDER01-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"NECKTIE02-MICRO-06",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"P2211-48-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"L9951-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"P2211-48-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"P2211-48-06",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"P2211-48-47",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"P2211-48-14",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"P312-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"P2211-07-76",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"P312-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"P3122-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"P3122-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"P7207A-00-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"P3122-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"P7207A-08-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"NECKTIE02-MICRO-18",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"P7206-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"P7207B-08-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"P7207C-08-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"P7207AA-08-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"NECKTIE02-MICRO-22",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"P7300-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"6"
                },
                {
                    "SkuAshlin":"P7207D-08-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"P7300-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"6"
                },
                {
                    "SkuAshlin":"P7207D-00-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"P7301-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"6"
                },
                {
                    "SkuAshlin":"P7301-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"6"
                },
                {
                    "SkuAshlin":"P7302-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"6"
                },
                {
                    "SkuAshlin":"P7302-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"6"
                },
                {
                    "SkuAshlin":"P7301-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"6"
                },
                {
                    "SkuAshlin":"P7314-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"6"
                },
                {
                    "SkuAshlin":"P7302-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"6"
                },
                {
                    "SkuAshlin":"P7314-MM08-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"P7300-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"6"
                },
                {
                    "SkuAshlin":"P8338-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"P8338-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"P7425-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"P8339-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"P8332-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"P8811-00-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"P8339-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"P8818-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"P8818-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"P8884-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"6"
                },
                {
                    "SkuAshlin":"P8339-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"P8888-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"6"
                },
                {
                    "SkuAshlin":"P8895-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"0"
                },
                {
                    "SkuAshlin":"P8884-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"6"
                },
                {
                    "SkuAshlin":"P8895-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"0"
                },
                {
                    "SkuAshlin":"P7414-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"P8895-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"0"
                },
                {
                    "SkuAshlin":"P7414-00-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"P9003-00-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"P8884-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"6"
                },
                {
                    "SkuAshlin":"P9004-00-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"P8895-48-07",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"0"
                },
                {
                    "SkuAshlin":"P9004-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"P9024-00-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"P9004-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"P9004-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"P9024-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"P9038-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"P9064-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"P9087-07-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"P9003-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"P9064-48-47",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"P9087-07-31",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"P9064-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"P9087-07-47",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"P9087-07-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"P9088-07-76",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"P9087-07-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"P9088-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"P9088-48-06",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"P9088-48-14",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"P9180-09-38",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"P9088-48-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"P9180-48-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"P9180-48-14",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"P9180-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"P9180-48-37",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"P9088-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"PAPERWT01-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"PAPERWT01-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"PAPERWT01-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"P9088-48-47",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"PAPERWT02-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"PAPERWT02-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"PAPERWT02-48-47",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"P9180-48-47",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"PDT102-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"PDT102-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"PDT102-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"PAPERWT02-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"PDT103-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"PDT103-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"PDT37-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"0"
                },
                {
                    "SkuAshlin":"PDT103-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"PDT507-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"PDT507-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"PDT511-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"PDT407-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"0"
                },
                {
                    "SkuAshlin":"PDT511-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"PDT517-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"PDT517-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"PDT507-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"PEN02-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"PDT517-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"PEN02-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"PDT511-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"PEN06-08-06",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"PDT102-48-37",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"PEN06-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"PEN21-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"PEN02-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"PEN07-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"PEN21-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"PEN21-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"PENBOX04-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"PENBOX04-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"PENBOX04-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"PENBOX06-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"PENBOX06-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"PENBOX06-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"PENBOX08-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"PENROLLUP06-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"PHONE50-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"0"
                },
                {
                    "SkuAshlin":"PHONE55-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"PHONE55-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"PHONE55-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"PHONE55-18-09",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"PLACE10-00-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"PLACE10-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"POUCH06-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"POUCH06-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"POUCH83-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"POUCH83-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"POUCH83-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"POUCH84-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"POUCH84-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"POUCH84-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"RCMP7786-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"PEN06-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"RCMP7786-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"RCMPP9004-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"RFID5728-07-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"RFID5728-00-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"RFID5748-07-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"RFID5748-07-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"RFID5728-07-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"RCMP9787-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"RFID6036-00-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"RFID6036-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"RFID700-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"RFID6036-48-47",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"RFID700-48-47",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"RFID700-00-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"RFID756-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"RFID7728-00-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"RFID756-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"RFIDL5910-00-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"RFID7728-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"RFIDL5910-07-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"RFID701-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"RFIDP221-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"RFIDP221-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"RFIDP221-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"RFIDL5910-48-47",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"RFIDP221-00-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"RFIDP221-48-47",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"RFIDP221TAG04-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"RFIDT7774-00-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"25"
                },
                {
                    "SkuAshlin":"SPEC11-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"RFIDP221-48-37",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"SPEC11-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"RFIDT7774-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"SPEC11-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"SPEC11-48-14",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"SPEC11-48-37",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"SPEC11-48-47",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"T7444-48-47",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"T7524-07-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"T7529-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"T7444-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"T7529-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"T7444-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"T7529-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"T7529-48-47",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"T7529-48-37",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"T7538-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"T7538-00-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"T7538-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"T7540-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"T7540-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"T7529-48-14",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"T7544-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"T7540-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"T7544-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"T7538-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"T7544-48-47",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"T7544-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"T7546-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"T7546-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"T7546-48-47",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"T7783-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"T7794-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"T7794-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"T7794-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"T7794-18-09",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"TAG04-00-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"100"
                },
                {
                    "SkuAshlin":"T7546-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"TAG04-00-06",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"100"
                },
                {
                    "SkuAshlin":"TAG04-00-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"100"
                },
                {
                    "SkuAshlin":"TAG04-00-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"100"
                },
                {
                    "SkuAshlin":"TAG04-00-10",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"100"
                },
                {
                    "SkuAshlin":"TAG04-00-04",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"100"
                },
                {
                    "SkuAshlin":"TAG04-00-16",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"100"
                },
                {
                    "SkuAshlin":"TAG04-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"TAG04-08-06",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"100"
                },
                {
                    "SkuAshlin":"TAG04-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"TAG042X-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"TAG0444-00-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"TAG04-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"TAG0444-00-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"TAG47-07-76",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"TAG47-48-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"TAG47-48-06",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"TAG47-48-47",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"TAG64-00-16",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"TAG64-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"TAG47-48-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"TAG64-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"TAG47-48-14",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"TAG64-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"TAG78-48-06",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"TAG78-48-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"TAG78-48-47",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"TAG78-48-14",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"TAG78-48-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"TAG78-07-76",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"TR1-00-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"TR1-07-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"TR2-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"TR2-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"W4005-ECC-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"TR2-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"W4005-ECC-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"WINE202-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"6026-18-01",
                    "MinOrder":"25",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"5728-MM08-01",
                    "MinOrder":"25",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"700-MM08-01",
                    "MinOrder":"25",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"9779-18-01",
                    "MinOrder":"25",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"9778-18-01",
                    "MinOrder":"25",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"B7863-07-01",
                    "MinOrder":"24",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"B613-18-01",
                    "MinOrder":"6",
                    "ImprintedMinOrder":"6"
                },
                {
                    "SkuAshlin":"BRACELT01X-18-01",
                    "MinOrder":"100",
                    "ImprintedMinOrder":"100"
                },
                {
                    "SkuAshlin":"WINE02-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"BRACELT01X-18-02",
                    "MinOrder":"100",
                    "ImprintedMinOrder":"100"
                },
                {
                    "SkuAshlin":"BRACELT01X-18-08",
                    "MinOrder":"100",
                    "ImprintedMinOrder":"100"
                },
                {
                    "SkuAshlin":"TR2-18-09",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"F202-49-01",
                    "MinOrder":"24",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"BRACELT02X-18-01",
                    "MinOrder":"100",
                    "ImprintedMinOrder":"100"
                },
                {
                    "SkuAshlin":"FOB04-18-01",
                    "MinOrder":"100",
                    "ImprintedMinOrder":"100"
                },
                {
                    "SkuAshlin":"BRACELT02X-18-08",
                    "MinOrder":"100",
                    "ImprintedMinOrder":"100"
                },
                {
                    "SkuAshlin":"K262-48-37",
                    "MinOrder":"24",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"P7207B-00-01",
                    "MinOrder":"24",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"P7207C-00-01",
                    "MinOrder":"24",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"P8331-18-01",
                    "MinOrder":"24",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"SWATCHLARGE-00-99",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"SWATCHSMALL-18-99",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"TAG04-48-14",
                    "MinOrder":"50",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"F204-18-01",
                    "MinOrder":"24",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"L5910-MM08-01",
                    "MinOrder":"24",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"TAG04-49-01",
                    "MinOrder":"50",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"TAG04-48-47",
                    "MinOrder":"50",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"TAG04-48-37",
                    "MinOrder":"50",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"TRAY05-18-01",
                    "MinOrder":"24",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"TAG08-18-01",
                    "MinOrder":"50",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"TRAY16-00-01",
                    "MinOrder":"12",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"WINE01RND-00-02",
                    "MinOrder":"24",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"WINE01RND-00-01",
                    "MinOrder":"24",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"5728-07-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"100"
                },
                {
                    "SkuAshlin":"TAG08-18-08",
                    "MinOrder":"50",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"WINE01-18-01",
                    "MinOrder":"24",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"5770-07-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"100"
                },
                {
                    "SkuAshlin":"304-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"5748-07-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"100"
                },
                {
                    "SkuAshlin":"6026-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"100"
                },
                {
                    "SkuAshlin":"700-49-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"100"
                },
                {
                    "SkuAshlin":"6000-07-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"100"
                },
                {
                    "SkuAshlin":"7520-52-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"100"
                },
                {
                    "SkuAshlin":"5728-49-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"100"
                },
                {
                    "SkuAshlin":"7521-52-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"100"
                },
                {
                    "SkuAshlin":"7728-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"100"
                },
                {
                    "SkuAshlin":"7728-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"100"
                },
                {
                    "SkuAshlin":"7728-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"100"
                },
                {
                    "SkuAshlin":"7748-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"100"
                },
                {
                    "SkuAshlin":"7748-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"100"
                },
                {
                    "SkuAshlin":"B613-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"6036-49-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"100"
                },
                {
                    "SkuAshlin":"B619-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"7988-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"100"
                },
                {
                    "SkuAshlin":"B8262-18-09",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"B613-18-09",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"B620-18-09",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"B8620-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"B8915-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"B8915-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"B8924-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"B8915-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"B8950-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"B8926-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"B9100-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"B9103-49-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"B9103-00-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"B9108-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"100"
                },
                {
                    "SkuAshlin":"B9104-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"B9108-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"100"
                },
                {
                    "SkuAshlin":"B9100-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"B9915-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"B9915-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"B8924-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"B9920-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"B9931-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"BOOKMK01-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"100"
                },
                {
                    "SkuAshlin":"B9931-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"CERTIF22-08-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"COAST02-088-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"100"
                },
                {
                    "SkuAshlin":"F200-048-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"BOOKMK01-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"100"
                },
                {
                    "SkuAshlin":"BOOKMK01-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"100"
                },
                {
                    "SkuAshlin":"F200-048-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"COAST11-048-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"100"
                },
                {
                    "SkuAshlin":"F202-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"F200-048-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"FOB06B-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"100"
                },
                {
                    "SkuAshlin":"F204-00-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"B9920-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"FOB06C-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"100"
                },
                {
                    "SkuAshlin":"FOB119-00-076",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"FOB119-00-24",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"FOB10-48-07",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"FOB119-48-16",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"F202-00-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"FOB15-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"FOB119-00-16",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"FOB15-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"FOB119-48-14",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"GOLF04-00-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"GOLF04-00-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"GOLF04-00-14",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"FOB15-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"GOLF04-00-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"GOLF04-00-66",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"GOLF04-00-37",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"GOLF08-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"GOLF04-00-47",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"K258-65-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"GOLF06-00-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"K258-48-10",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"K256-00-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"K262-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"K258-65-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"K265-48-37",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"K262-48-14",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"L8772-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"L9028-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"MEMHOLD02-00-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"K265-48-07",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"MEMHOLD02-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"L9028-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"MEMHOLD02-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"MOUS01-048-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"MOUS01-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"MOUS01-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"MOUS03-98-988",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"P221-07-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"P221-49-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"P223-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"P223-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"P232-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"P7217A-00-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"P7217A-08-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"P7217C-08-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"P7217D-08-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"P7300-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"P7301-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"P7315-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"P7559-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"12"
                },
                {
                    "SkuAshlin":"P8331-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"P8818-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"P9004-49-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"P9004-77-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"P9004-77-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"PDT102-08-06",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"PDT103-48-37",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"PDT344-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"PDT407-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"PEN06-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"PEN06-48-14",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"PEN06-48-37",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"PEN06-48-47",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"PENBOX02-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"PENBOX02-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"PENBOX02-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"PENBOX08-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"PENBOX101-08-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"PENBOX08-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"PENBOX11-08-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"PHONE50-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"PHONE50-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"PHONE55-48-47",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"PLACE10-MM08-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"PLACE1514-08-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"PLACE1912-00-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"PLACE1912-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"PLACE2118-00-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"POUCH03-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"RFID5748-00-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"RFID7052-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"RFID7052-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"RFID7052-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"RFID750-00-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"RFID750-0700-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"RFIDL8910-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"RFIDL8352-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"RFIDP221TAG04-48-14",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"RFIDP221TAG04-48-37",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"RFIDP221-48-14",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"RFIDP221TAG04-48-47",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"T1B5748-49-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"T2B5748-49-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"T2W5748-49-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"T1W5748-49-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"T3B5748-49-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"T3W5748-49-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"T4W5748-49-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"T4B5748-49-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"T7444-65-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"T7444-65-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"T7545-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"T7545-18-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"T7545-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"T7545-48-47",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"T7560-48-47",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"T7560-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"TAG04-07-26",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"TAG04-48-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"TAG04-48-10",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"TAG24-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"TAG24-18-08",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"50"
                },
                {
                    "SkuAshlin":"TASSEL03-48-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"100"
                },
                {
                    "SkuAshlin":"TASSEL03-48-02",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"100"
                },
                {
                    "SkuAshlin":"TASSEL03-48-37",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"100"
                },
                {
                    "SkuAshlin":"TASSEL03-48-14",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"100"
                },
                {
                    "SkuAshlin":"TIE01-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"TR4-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"TR4-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                },
                {
                    "SkuAshlin":"TRAY16-18-01",
                    "MinOrder":"1",
                    "ImprintedMinOrder":"24"
                }
            ]
        }
    }
    handleOnchange=(e)=>{
        this.setState({
            sku:e.target.value
        })
    };
    //'WINE01RND-00-01'
    handleSyncProduct = async(e)=> {
        e.preventDefault();
        this.setState({
            isSync:true
        },async()=>{
            const storeInfo = await getStoreInfo('BigCommerce Ashlin');
            const findRes = await findProductBySku(storeInfo,this.state.sku );
            const productBG = findRes.data.data[0];
            //console.log(findRes);
            const findVariantRes = await getVariants(storeInfo,productBG.id);
            const variants = findVariantRes.data.data;
            //console.log(variants);


            const oldProductImagesRes = await getImages(storeInfo,productBG.id);

            const oldImages = oldProductImagesRes.data.data;

            const deleteImagesRes  = await oldImages.map(async (image)=>{
                return deleteProductImage(storeInfo,image.product_id,image.id);
            });

            await Promise.all(deleteImagesRes).then(()=>{
            });

            const addNewImagesRes = variants.map(async(pro,index)=>{
                const productDetailRes = await axios.get(`http://localhost:52233/api/LegacySku/detail/${pro.sku}`);
                const product= productDetailRes.data[0];

                const imageDescription = product.Brand + " DESIGNER | " + product.Name + " " + product.ShortDescription.replace('.','')+" "+product.Sku;
                let mainImageRes,imagesGROUPRes;

                if(product.Urls.HostingerUrls.MAIN[0]!==undefined) {
                    //console.log(index);
                    if(index === 0) {
                        mainImageRes = await addImage(storeInfo, productBG.id, true, 1, imageDescription, product.Urls.HostingerUrls.MAIN[0]);
                    }else{
                        mainImageRes = await addImage(storeInfo, productBG.id, false, 1, imageDescription, product.Urls.HostingerUrls.MAIN[0]);
                    }
                }

                const imagesRes =await product.Urls.GoogleDriveUrls.BASE.map(async (img,index)=>{
                    return await addImage(storeInfo,productBG.id,false,(3),imageDescription+` BASE ${index}`,img);
                });
                const imagesModelRes =await product.Urls.GoogleDriveUrls.MODEL.map(async (img,index)=>{
                    return await addImage(storeInfo,productBG.id,false,(5),imageDescription+` MODEL ${index}`,img);
                });
                const imagesLIFERes =await product.Urls.GoogleDriveUrls.LIFE.map(async (img,index)=>{
                    return await addImage(storeInfo,productBG.id,false,(7),imageDescription+` LIFE ${index}`,img);
                });
                if(index ===0) {
                    imagesGROUPRes = await product.Urls.GoogleDriveUrls.GROUP.map(async (img, index) => {
                        return await addImage(storeInfo, productBG.id, false, (9), imageDescription + ` GROUP ${index}`, img);
                    });
                }
                await Promise.all([mainImageRes,imagesRes,imagesModelRes,imagesLIFERes,imagesGROUPRes]).then(()=>{
                });
            });

            await Promise.all(addNewImagesRes).then(()=>{
                alert("Finish Sync");
                this.setState({
                    isSync:false
                });
            });
        });
    };
    handleUpdateMinOrder=(e)=>{
        e.preventDefault();
        this.state.minOrder.forEach(async minO=>{
          //await axios.patch('http://localhost:52233/api/LegacySku/imprinted',minO);
        })
    };
    render(){
        return(
            <div>
                <h1 style={{textAlign:'center'}}>BigCommerce Sync Product Images and Image Description</h1>
                <br/><br/><br/><br/><br/>
                {this.state.isSync &&<div className={"text-center text-success display-4"}><i className="fa fa-spinner fa-spin"></i> &nbsp; Updating, please wait...<br/><br/></div>}
                <div className={'row justify-content-md-center'}>
                    <div className={'col-md-auto'}>
                        <input className={'center'} value={this.state.sku} onChange={this.handleOnchange}/>
                    </div>
                    <div className={'col-md-auto'}>
                        <button className={'btn center'} onClick={this.handleSyncProduct}>Sync Product Image</button>
                    </div>
                </div>
                <br/>
                <br/>
                <br/>
                <hr/>
                <button className={'btn btn-danger'} onClick={this.handleUpdateMinOrder}>UPDATE MIN ORDER</button>

            </div>)
    }
}

export default BigCommerceApi;