import React, { Component } from 'react';
import MainSideBar from '../sideBar/MainSideBar';
import async from "async";
import {
    getSkuDetail,
    getListedSkus,
    lastModifiedTime,
    sendPDFEmail,updateSkuChannelListing2,
    getEmailConfigurationById, sendEmail
} from "../../Utils/ashlinDesign";
import {getBPheader, updateProductPriceList} from '../../Utils/brightpearl';
import UpdateBrightPearlListModal from './components/updatePriceModal';
class BrightPearlProduct extends Component{
    _mounted = false;
    constructor(props){
        super(props);
        this.state={
            isUpdating: false,
            skuList:[],
            updateMode:"All Active",
            START:'',
            END:'',
            PriceListEmail:undefined
        }
    }
    componentWillUnmount() {
        this._mounted = false;
    }
    componentDidMount(){
        this._mounted = true;
        this.getData();
    }
    getData=async()=>{
        this.setState({
            isDownLoading:true
        },async()=>{
            let skuList = (await getListedSkus('BrightPearl')).data;
            const PriceListEmail = await getEmailConfigurationById('4F0DEE93-C0EE-4ABE-B30A-E0FD644FE8F6');
            skuList = skuList.sort((a,b)=>{
                const nameA = a.Sku;
                const nameB = b.Sku;
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                // names must be equal
                return 0;
            });
            const START = skuList[0].Sku;
            const END = skuList[0].Sku;
            this.setState({
                skuList,
                START,
                END,
                PriceListEmail,
                isDownLoading:false
            });
        })
    };
    handleOnchange=(e)=>{
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        const name = e.target.name;
        if(name === "START"){
            const index = this.state.skuList.findIndex(i=>i.Sku === value);
            const END = this.state.skuList[(index+1)].Sku;
            this.setState({
                START:value,
                END
            })
        }else{
            this.setState({
                [name]:value
            })
        }

    };
    handleUpdatePriceList = async()=>{
        this.setState({
            isUpdating:true
        },async()=>{
            let toUpdateSku;
            if(this.state.updateMode==="By Range"){
                const startIndex = this.state.skuList.findIndex(i=>i.Sku === this.state.START);
                const endIndex = this.state.skuList.findIndex(i=>i.Sku === this.state.END);
                toUpdateSku = this.state.skuList.slice(startIndex,endIndex+1)
            }else{
                toUpdateSku = this.state.skuList;
            }
            let failed = [];
            let successed = [];
            const bpHeaders = await getBPheader();
            const lastModified = lastModifiedTime();
            async.mapSeries(toUpdateSku,async (product,callback)=>{
                product.Active = 1;
                product.LastModifiedDate = lastModified;
                try{
                    const skuDetail = await getSkuDetail(product.Sku);
                    //console.log(skuDetail);
                    await updateProductPriceList(skuDetail,product.MerchanSku,bpHeaders);
                    await updateSkuChannelListing2(product);
                    successed.push(product);
                }catch (e) {
                    failed.push(product);
                }
                await setTimeout(()=>{
                    return callback(null,"");

                },1000);

            }, async(err, results) => {

                let attachment = "Brightpearl Price List Update Report\n\n\n";
                if(successed.length > 0){
                    attachment += "Completed set of products where prices were successfully updated:\n\n";
                    successed.forEach(i=>{
                        attachment += `${i.Sku}  : price update successful: time stamped : ${i.LastModifiedDate.replace("T"," ")}\n`;
                    });
                    attachment +="\n\n";
                }
                if(failed.length > 0){
                    attachment += "Incomplete set of products where prices did not get updated:\n\n";
                    failed.forEach(i=>{
                        attachment += `${i.Sku}  : price update failed: time stamped : ${i.LastModifiedDate.replace("T"," ")}\n`;
                    });
                }

                const recipients = this.state.PriceListEmail .Recipient.split("\n").map(email=>email.trim()).filter(email=>email!=="");
                const subject = this.state.PriceListEmail.Subject;
                const emailBody = this.state.PriceListEmail.EmailBody.replace("[ERP]","Brightpearl");
                await sendEmail(recipients,subject,emailBody,attachment,"Brightpearl_Price_List_Update_Report.txt");

                const START = this.state.skuList[0].Sku;
                const END = this.state.skuList[0].Sku;
                this.setState({
                    isUpdating:false,
                    START,
                    END,
                    updateMode:"All Active",
                })
            });
        });
    };

    render(){
        return(
            <div>
                <div className='container-fluid row'>
                    <div className={'col-3'}>
                        <MainSideBar/>
                    </div>
                    <div className={'col-9'}>
                        <br/>
                        <h1 className={'text-center'}>Brightpearl Update Product</h1>
                        <br/>
                        {this.state.isUpdating &&<div className={"text-center text-success display-4"}><i className="fa fa-spinner fa-spin"></i> &nbsp; Updating Data...</div>}
                        <br/>
                        <br/>
                        <UpdateBrightPearlListModal updatePriceList={this.handleUpdatePriceList}
                                                    handleOnModeChange={this.handleOnchange}
                                                    updateMode={this.state.updateMode} START = {this.state.START} END={this.state.END}
                                                    skuList={this.state.skuList} isUpdating={this.state.isUpdating}
                                                    />
                        <br/>
                        <button type={'button'} className={'btn btn-success'}  data-toggle="modal" data-target="#updateBrightPearlListModal">Update Price List</button>
                        {/*<button type={'button'} className={'btn btn-danger'} onClick={this.handleUpdatePriceList}>Update Price List</button>*/}
                    </div>
                </div>
            </div>
        )
    }
}

export default BrightPearlProduct;