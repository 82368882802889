import React, { Component } from 'react';
import {getSkuDetail, getChannelSKU, searchChannelSKU, postSkuChannelListing} from '../../Utils/ashlinDesign';
import MainSideBar from '../sideBar/MainSideBar';
import {
    calculateBulKPrice, deleteBulkPricingRules,
    filterBulkPriceRule,
    findProductById, getBulkPricingRules,
    getStoreInfo,getAllProducts,
    productDescription, updateProduct
} from "../../Utils/bigCommerce";
import $ from "jquery";
import async from "async";
class BulkUpdateDescription extends Component{
    constructor(props){
        super(props);
        this.state={
            skuInput:'',
            isUpdating:false,
            pageNumber:'1',

            updateName:true,
            updateDescription:true,
            updatePrice:false,
            updateMinimumOrder:false
        }
    }
    handleOnchange=(e)=>{
      const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
      const name = e.target.name;
      this.setState({
          [name]:value
      },()=>console.log(this.state))
    };
    handleUpdateAll=()=>{
        this.setState({
            isUpdating:true
        },async()=>{
            const storeInfo =await getStoreInfo('BigCommerce Ashlin');

            // 1-7
            const requestNumber = this.state.pageNumber;
            const allProducts = (await getAllProducts(storeInfo,requestNumber)).data.data;
            //console.log(allProducts);
            let index = 0;

            async.mapSeries(allProducts,async (product,callback)=>{
                //searchChannelSKU
                //console.log("Star updating...");
                try{
                    const Sku = (await searchChannelSKU("BigCommerce Ashlin",product.sku))[0].Sku;
                    const skuDetail = await getSkuDetail(Sku);

                    const bulkRuleRes = await calculateBulKPrice(skuDetail.Price,skuDetail.PricingTier);
                    const bulkPriceRule = bulkRuleRes.Bulk;
                    const finalBulkPriceRule=filterBulkPriceRule(bulkPriceRule,parseInt(skuDetail.MinOrder));
                    const finalImprintedPriceRule =filterBulkPriceRule(bulkPriceRule,parseInt(skuDetail.ImprintedMinOrder));


                    if(this.state.updateName){
                        product.name = skuDetail.Brand + " DESIGNER | " + skuDetail.Name + " " + skuDetail.ShortDescription.replace('.','') + ` | ${skuDetail.Material} | [${product.sku}]`;
                    }
                    if(this.state.updateDescription){
                        product.description = productDescription(skuDetail,finalImprintedPriceRule);
                    }
                    if(this.state.updateMinimumOrder){
                        product.order_quantity_minimum = skuDetail.MinOrder;
                    }

                    if(this.state.updatePrice){
                        product.price = (skuDetail.Price*0.85*1.05).toFixed(2);
                        product.calculated_price = (skuDetail.Price*0.85*1.05).toFixed(2);
                        product.bulk_pricing_rules = finalBulkPriceRule;

                        const oldBulkPriceRuleRes = await getBulkPricingRules(storeInfo,product.id);
                        const deleteOldBulkPriceRuleRes = await oldBulkPriceRuleRes.data.data.map(async r => {
                            return await deleteBulkPricingRules(storeInfo, product.id, r.id);
                        });
                        Promise.all(deleteOldBulkPriceRuleRes).then(async () => {
                            const updateProductRes = await updateProduct(storeInfo, product.id, product);
                            //console.log(updateProductRes);
                        });
                    }else{
                        const updateProductRes = await updateProduct(storeInfo, product.id, product);
                        //console.log(updateProductRes);
                    }
                }catch (e) {
                    //console.log(e);
                    //console.log(product.sku);
                }
                await setTimeout(()=>{
                    console.log(`Waiting...${index}`);
                    index++;
                    return callback(null,"");

                },1000);

            }, (err, results) => {
                this.setState({
                    isUpdating:false,
                    pageNumber : (parseInt(requestNumber)+1).toString()
                },()=>alert("Update Finish!!!"));

            });
        })

    };
    handleBulkUpdate=async()=>{
        const list = this.state.skuInput.split(';');
        const storeInfo =await getStoreInfo('BigCommerce Ashlin');
        const bulkUpdateResponse = list.map(async Sku=>{
            const bigCommerceRecord = await getChannelSKU('BigCommerce Ashlin',Sku);
            // console.log(bigCommerceRecord);
            if(bigCommerceRecord!==""){
                const Ids = bigCommerceRecord.MerchanSku.split('+');
                const bigCommerceID = Ids[0];
                const bigCommerceDesignRes = await findProductById(storeInfo,bigCommerceID);
                //Get Product Detail Information from BigCommerce
                const bigCommerceDesign = bigCommerceDesignRes.data.data;
                // console.log(bigCommerceDesign);
                //Read data from Ashlin Database
                const skuDetail = await getSkuDetail(Sku);
                // console.log(skuDetail)

                const bulkRuleRes = await calculateBulKPrice(skuDetail.Price,skuDetail.PricingTier);
                const bulkPriceRule = bulkRuleRes.Bulk;

                const finalBulkPriceRule=filterBulkPriceRule(bulkPriceRule,parseInt(skuDetail.MinOrder));
                const finalImprintedPriceRule =filterBulkPriceRule(bulkPriceRule,parseInt(skuDetail.ImprintedMinOrder));
                const newDescription = productDescription(skuDetail,finalImprintedPriceRule );


                bigCommerceDesign.description = newDescription;
                bigCommerceDesign.bulk_pricing_rules = finalBulkPriceRule;
                bigCommerceDesign.price = (skuDetail.Price*0.85*1.05).toFixed(2);
                bigCommerceDesign.calculated_price = (skuDetail.Price*0.85*1.05).toFixed(2);
                bigCommerceDesign.order_quantity_minimum = skuDetail.MinOrder;
                // console.log(bigCommerceDesign);
                //
                // console.log(newDescription);

                const oldBulkPriceRuleRes = await getBulkPricingRules(storeInfo,bigCommerceDesign.id);
                //console.log(oldBulkPriceRuleRes);
                const deleteOldBulkPriceRuleRes = await oldBulkPriceRuleRes.data.data.map(async r => {
                    return await deleteBulkPricingRules(storeInfo, bigCommerceDesign.id, r.id);
                });
                Promise.all(deleteOldBulkPriceRuleRes).then(async () => {
                    const updateProductRes = await updateProduct(storeInfo, bigCommerceDesign.id, bigCommerceDesign);
                    //console.log(updateProductRes);
                    return updateProductRes;
                });

            }else{
                alert(`No record for ${Sku} in the database`);
            }
      });
      Promise.all(bulkUpdateResponse).then(()=>{
          alert("Finish Update!");
      })
    };
    render(){
        return(
            <div>
                <div className='container-fluid row'>
                    <div className={'col-3'}>
                        <MainSideBar/>
                    </div>
                    <div className={'col-9'}>
                        {this.state.isUpdating &&<div className={"text-center text-success display-4"}><i className="fa fa-spinner fa-spin"></i> &nbsp; Updating Data...</div>}
                        <br/>
                        <h1 className={'text-center'}>BigCommerce Update Detail</h1>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <h1>Page Number: ( Enter 1-7 )&nbsp;&nbsp;&nbsp;<input type={'text'} name={'pageNumber'} value={this.state.pageNumber} style={{fontSize:'20px',width:'200px'}} onChange={this.handleOnchange}/> <button type={'button'} className={'btn btn-danger float-right'} onClick={this.handleUpdateAll}>Update Detail</button></h1>
                        <table className="table" style={{margin:'100px auto',width:"500px"}}>
                            <tbody>
                            <tr>
                                <th style={{fontSize:'30px'}}>Update Title</th>
                                <td>
                                    <input type="checkbox"  name={'updateName'} style={{width:'30px',height:'30px'}}  checked={this.state.updateName} onChange={this.handleOnchange}/>
                                </td>
                            </tr>
                            <tr>
                                <th style={{fontSize:'30px'}}>Update Description</th>
                                <td>
                                    <input type="checkbox"  name={'updateDescription'} style={{width:'30px',height:'30px'}}  checked={this.state.updateDescription} onChange={this.handleOnchange}/>
                                </td>
                            </tr>
                            <tr>
                                <th style={{fontSize:'30px'}}>Update Price</th>
                                <td>
                                    <input type="checkbox"  name={'updatePrice'} style={{width:'30px',height:'30px'}}  checked={this.state.updatePrice} onChange={this.handleOnchange}/>
                                </td>
                            </tr>
                            <tr>
                                <th style={{fontSize:'30px'}}>Update Minimum Order</th>
                                <td>
                                    <input type="checkbox"  name={'updateMinimumOrder'} style={{width:'30px',height:'30px'}}  checked={this.state.updateMinimumOrder} onChange={this.handleOnchange}/>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}

export default BulkUpdateDescription;