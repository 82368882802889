import React, { Component } from 'react';
import { Picky } from 'react-picky';
import 'react-picky/dist/picky.css';
import MainSideBar from '../sideBar/MainSideBar';
import {getChannelList, getListedSkus, legacySkusURL, updateSkuChannelListing} from '../../Utils/ashlinDesign';
import { walmartRetireProduct } from '../../Utils/walmartMarketplace';
import SelectInput from '../bootstrapComponent/selectInput';
import axios from "axios/index";
import $ from "jquery";
import SkuDetailModal from '../Modal/SkuDetailModal';
class BulKRetireProduct extends Component{
    constructor(props){
        super(props);
        this.state = {
            channelList:[],
            selectChannel:'',
            arrayValue: [],
            skuList:[],
            selectedSkus:[],
            detailedSku:{},
            retireSkus:[]
        };
    }
    componentDidMount(){
        this.loadData();
    }
    loadData = async()=>{
        const channelList = await getChannelList();
        this.setState({
            channelList :channelList.data
        })
    };
    handleOnchange=async (e)=>{

        const selectChannel = e.target.value;
        if(selectChannel !== 'optionDefaultHeader'){
            //get Skus listed on the selected Channel
            const listedSkus = await getListedSkus(selectChannel);
            const skuList = listedSkus.data.map(s=>s.Sku);
            this.setState({
                selectChannel,
                skuList:skuList,
                skuListDetail:listedSkus.data
            });
        }
    };
    selectMultipleOption=async (value)=> {
        const newAdded = value.filter(added => !this.state.arrayValue.includes(added));
        let selectedSkus = this.state.selectedSkus;
        let retireSkus = this.state.retireSkus;
        if(newAdded.length !==0){
            const addedDetailRes = await axios.get(legacySkusURL+`/detail/${newAdded}`);
            const addedDetail = addedDetailRes.data[0];
            if(addedDetail!==undefined && !selectedSkus.some(s=>s.Sku === addedDetail.Sku)){
                selectedSkus.push(addedDetail);
                const retire = this.state.skuListDetail.find(r=>r.Sku === addedDetail.Sku);
                retireSkus.push(retire);
            }
        }else {
            const newRemoved = this.state.arrayValue.filter(removed =>!value.includes(removed));
            //console.log("newRemove",newRemoved);
            const index = selectedSkus.findIndex(re => re.Sku === newRemoved[0]);
            const index2 = retireSkus.findIndex(re => re.Sku === newRemoved[0]);
            //console.log(index);
            if(index>=0){
                selectedSkus.splice(index,1);
                retireSkus.splice(index,1);
            }
        }
        const selectedValue = selectedSkus.map(s=>s.Sku);
        this.setState({
            arrayValue: selectedValue,
            selectedSkus,
            retireSkus
        });
    };
    handleDetail=(e,sku)=>{
        e.preventDefault();
        this.setState({
            detailedSku:sku
        },()=>$('#skuDetailModal').modal('show'))
    };
    handleUpload=async(e)=>{
        e.preventDefault();
        const label = this.state.retireSkus.map(r=>r.Sku).join(" \n");
        let confirmBox = window.confirm(`Do you confirm to retire selected products:\n${label}?`);
        if (confirmBox) {
            //console.log('Delete');
            const res = this.state.retireSkus.map(async(item)=>{

                const walmartRes =  await walmartRetireProduct(item.Sku);
                //console.log(item);
                const ashlinRes = await updateSkuChannelListing(item,'deactive');
                //console.log(ashlinRes);
                return Promise.all([walmartRes,ashlinRes])
            });

            Promise.all(res).then(()=>{

            });
        }
    };
    render(){

        return(
            <div>
                <div className='container-fluid row'>
                    <div className={'col-3'}>
                        <MainSideBar/>
                    </div>
                    <div className={'col-9'}>
                        {this.state.detailedSku !== null?<SkuDetailModal
                            sku={this.state.detailedSku}
                        />:null}
                        <br/>
                        <div className={'row'}>
                            <br/>
                            <div className={'col'}>
                                <h1 className={'text-center'}>Bulk Retire Products</h1>
                            </div>
                            <div className={'col'}>
                                {
                                    this.state.channelList.length !==0 &&
                                    <SelectInput value={this.state.channelList}
                                                 style={{fontWeight:'bold',fontSize:'20px'}}
                                                 label={"Channel Name"} onChange={(e)=>this.handleOnchange(e)}/>
                                }
                            </div>
                            <div className={'col-2'}>
                            </div>
                        </div>

                        <br/>
                        <div className={'row'}>
                            <div className={'col-4'}>
                                <h2 className={'text-center'}>Sku List</h2>
                                {this.state.skuList.length !==0 &&
                                <Picky
                                    value={this.state.arrayValue}
                                    options={this.state.skuList}
                                    onChange={this.selectMultipleOption}
                                    open={true}
                                    valueKey="id"
                                    labelKey="name"
                                    multiple={true}
                                    includeSelectAll={false}
                                    includeFilter={true}
                                    dropdownHeight={600}
                                />
                                }
                            </div>
                            <div className={'col-4'} >
                                <h2 className={'text-center'}>Selected Sku</h2>
                                <div style={{maxHeight:'640px',minWidth:'360px',overflowY:'auto'}}>
                                    {this.state.selectedSkus.map((sku,index)=>{
                                        return <div  key={index}>
                                            <button className={'btn btn-info btn-block'}  onClick={(e)=>this.handleDetail(e,sku)}>{sku.Sku}</button>
                                        </div>

                                    })}
                                </div>
                            </div>
                            <div className={'col-4'}>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                {this.state.selectedSkus[0] &&
                                <button className={'btn btn-danger'} style={{position:'absolute',right:'50px',top:'600px'}}
                                        onClick={(e)=>this.handleUpload(e)}>Retire Products</button>
                                }
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default BulKRetireProduct;