import React, {Component} from 'react';
import {connect} from 'react-redux';
export const require_auth =(ComposedComponent)=>{
    class Authentication extends Component{
        componentDidMount() {
            if(!this.props.authenticated) {
                this.props.history.push('/');
            }
            if(localStorage.getItem('auth')===null){
                this.props.history.push('/');
            }
        }
        UNSAFE_componentWillUpdate(nextProps) {
            //console.log(nextProps.authenticated);
            if(!nextProps.authenticated) {
                this.props.history.push('/');
            }
            if(localStorage.getItem('auth')===null){
                this.props.history.push('/');
            }
        }
        render(){
            return(
                <ComposedComponent {...this.props}/>
            )
        }
    }
    const mapStateToProps=(state)=>{
        return { authenticated: state.authenticated};
    };
    return connect(mapStateToProps)(Authentication);
};
export default require_auth;
