import React, {Component} from 'react';
import $ from "jquery";
import { createBrightPearlChannelInfo } from '../../../Utils/ashlinDesign';
class NewBrightpearlChannelInfo extends Component{
    constructor(props){
        super(props);
        this.state={
            ChannelName:'',
            ChannelId:'',
            AshlinChannelId:'',
            ContactId:'',
            ShippingMethodId:'',
            DiscountRate:'',
            PreferredShippingCarrier:''
        }
    }
    newBrightpearlChannelInfoOnChange=(e)=>{
        const name = e.target.name;
        const value = e.target.value;
        this.setState({
            [name]:value
        })
    };
    handleClose=()=>{
        this.setState({
            ChannelName:'',
            ChannelId:'',
            AshlinChannelId:'',
            ContactId:'',
            ShippingMethodId:'',
            DiscountRate:'',
            PreferredShippingCarrier:''
        },()=>{
            $('#newBrightpearlChannelInfo').modal('hide');
        })
    };
    handleCreateNewBrightpearlChannelInfo=async()=>{
        const d = new Date();
        const n = d.toISOString().slice(0,19);
        const data = this.state;
        data.LastModifiedDate = n;
        const resData = await createBrightPearlChannelInfo(data);
        this.props.addNewBrightpearlChannelInfoToList(resData);
        this.setState({
            ChannelName:'',
            AshlinChannelId:'',
            ChannelId:'',
            ContactId:'',
            ShippingMethodId:'',
            DiscountRate:'',
            PreferredShippingCarrier:''
        },()=>{
            $('#newBrightpearlChannelInfo').modal('hide');
        })
    };
    render(){
        return(<div className="modal fade" id="newBrightpearlChannelInfo" tabIndex="-1" role="dialog"
                    aria-labelledby="newBrightpearlChannelInfo" aria-hidden="true" style={{backgroundColor:'floralwhite'}}>
            <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div className="modal-content" style={{backgroundColor:'floralwhite'}}>
                    <div className="modal-header">
                        <h3 className="modal-title text-center" id="newBrightpearlChannelInfoTitle" style={{fontWeight:'bold'}}>New Brightpearl Channel</h3>
                        <button type="button" className="close"
                                onClick={this.handleClose}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <table className={'table table-bordered newBrightpearlChannelInfoDetail'}>
                            <thead>
                            </thead>
                            <tbody>
                            <tr>
                                <th>Channel Name</th>
                                <td><input type={'text'} onChange={this.newBrightpearlChannelInfoOnChange}
                                           style={{width:'100%'}}  name={'ChannelName'} value={this.state.ChannelName} /></td>
                            </tr>
                            <tr>
                                <th>Ashlin Id</th>
                                <td><input type={'text'} onChange={this.newBrightpearlChannelInfoOnChange}
                                           style={{width:'100%'}}  name={'AshlinChannelId'} value={this.state.AshlinChannelId} /></td>
                            </tr>
                            <tr>
                                <th>Channel Id</th>
                                <td><input type={'text'} onChange={this.newBrightpearlChannelInfoOnChange}
                                           style={{width:'100%'}}  name={'ChannelId'} value={this.state.ChannelId} /></td>
                            </tr>
                            <tr>
                                <th>Contact Id</th>
                                <td><input type={'text'} onChange={this.newBrightpearlChannelInfoOnChange}
                                           style={{width:'100%'}}  name={'ContactId'} value={this.state.ContactId} /></td>
                            </tr>
                            <tr>
                                <th>Preferred Carrier</th>
                                <td><input type={'text'} onChange={this.newBrightpearlChannelInfoOnChange}
                                           style={{width:'100%'}}  name={'PreferredShippingCarrier'} value={this.state.PreferredShippingCarrier} /></td>
                            </tr>
                            <tr>
                                <th>Shipping Method Id</th>
                                <td><input type={'text'} onChange={this.newBrightpearlChannelInfoOnChange}
                                           style={{width:'100%'}}  name={'ShippingMethodId'} value={this.state.ShippingMethodId} /></td>
                            </tr>
                            <tr>
                                <th>Discount Rate</th>
                                <td><input type={'text'} onChange={this.newBrightpearlChannelInfoOnChange}
                                           style={{width:'100%'}}  name={'DiscountRate'} value={this.state.DiscountRate} /></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="modal-footer row" style={{width:'90%',margin:"0 auto"}}>
                        <div className={'col'}> </div>
                        <div className={'col'}>
                            <button className={'btn btn-danger'} onClick={this.handleCreateNewBrightpearlChannelInfo} >Create Brightpearl Channel Info</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
    }
}
export default NewBrightpearlChannelInfo;