import React from 'react'
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete'

class LocationSearchInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = { address: '' }
    }

    handleChange = (address) => {
        this.setState({ address })
    };


    handleSelect = async (address) => {
        const result = (await geocodeByAddress(address))[0].formatted_address;
        this.setState({
            address:result
        },()=>this.props.setFormLocation(result));
    };

    render() {
        const renderInput = ({ getInputProps, getSuggestionItemProps, suggestions }) => (
            <div className="autocomplete-root">
                <input className="form-control" {...getInputProps()} />
                <div className="autocomplete-dropdown-container">
                    {suggestions.map(suggestion => (
                        <div {...getSuggestionItemProps(suggestion)} className="suggestion">
                            <span>{suggestion.description}</span>
                        </div>
                    ))}
                </div>
            </div>
        );


        const searchOptions = {
            types: ['address']
        };

        return (
            <PlacesAutocomplete
                value={this.state.address}
                onChange={this.handleChange}
                onSelect={this.handleSelect}
                // Pass the search options prop
                searchOptions={searchOptions}
            >
                {renderInput}
            </PlacesAutocomplete>
        );
    }
}

export default LocationSearchInput