import React from 'react';
import ListItem from './email_configuration_list_item';
import $ from "jquery";
const email_configuration_list = (props)=>{
    return(
        <table className={'table table-bordered'}>
            <thead>
            <tr>
                <th>DESCRIPTION</th>
                <th>RECIPIENTS</th>
                <th>SUBJECT</th>
                <th><button className={'btn-success'} onClick={()=>{
                    $('#newEmailConfigurationDetail').modal('show');
                }}>Add Email Configuration </button></th>
            </tr>
            </thead>
            <tbody>
            {props.list.map((item,index)=><ListItem item={item} index={index} key={index}  updateEmailConfiguration={props.updateEmailConfiguration} selectedEmailConfiguration={props.selectedEmailConfiguration}
                                                    emailConfigurationOnChange={props.emailConfigurationOnChange} showEmailConfigurationOptionDetail={props.showEmailConfigurationOptionDetail}
                                                    testSendEmail={props.testSendEmail} emailConfigurationEditorOnchange={props.emailConfigurationEditorOnchange}  selectedEmailConfigurationIndex={props.selectedEmailConfigurationIndex}
            />)}
            </tbody>
        </table>
    )
};
export default email_configuration_list;
