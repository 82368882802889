import React from 'react';
const textArea = (props)=>{
    const value = props.value !==null? props.value:"";
    return(
        <div className={'row'} >
            <div className={'col-4'}>
                <h5 style={{textAlign:'right'}}>{props.label}</h5>
            </div>
            <div className={'col-8'}>
                    <textarea style={{width:'100%',resize:'none'}} value={value} rows={props.rows}
                              onChange={props.onChange} name={props.name}>
                    </textarea>
            </div>
        </div>
    )
};
export default textArea;