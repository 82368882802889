import { PRODUCTION, ITSUPPORTEMAILS } from "../requestHelper"
import { sendEmail } from "../ashlinDesign"

// Module for handling error and sending Email to IT team for the system
export const handleError = (errorObj, functionName)=>{

    try{
        //send email to all IT teams
        if (PRODUCTION) {
            sendEmail(ITSUPPORTEMAILS, `Error throwing out for function ${functionName}`, "", "", "")
        }
        let emailStrings = ""
        ITSUPPORTEMAILS.forEach((itEmail) => {
            emailStrings+= itEmail + "\n"
        })
        alert(`There are some error with function ${functionName} comming out Please take a screen shot and Contact IT development team with following emails:\n`+ emailStrings+`${errorObj}`)
    } catch (error){
        alert("handleError error")
    }
    
};