import React from 'react';
import TierCostModal from './TierCostModal';
const UpdateSKUInfo = ({skuNumList,selectedSkuDetail,skuPantoneColor,HtsCdn,HtsUs,SkuPantoneColorOnChange,
                           SKUNunberOnchange,SKUDetailOnChange,HtsOnchange,changeTierPrice,pricingTierOnchange,
                           tierCostMatrix,skuCost,skuCostOnchange,upDateCostMatrix,newSkuCost
                       })=>{
    return(
        <div>
            {tierCostMatrix !== null?
                <TierCostModal
                    tierCostMatrix = {tierCostMatrix }
                    skuCost = {skuCost}
                    onChange ={skuCostOnchange}
                    upDateCostMatrix = {upDateCostMatrix}
                    create = {newSkuCost}
                    pricingTierOnchange={pricingTierOnchange}
                />:null}
            <table className={'table'}>
                <tbody>
                    <tr>
                        <th scope="col">SKU: &nbsp;
                            <select name={'selectedSKUNumber'}  onChange={SKUNunberOnchange} className={'update-sku-input'} >
                                <option value="optionDefaultHeader" >-- select a SKU --</option>
                                {skuNumList.map((op,i)=>{
                                    return <option value={op} key={i}>{op}</option>
                                })}
                            </select>
                        </th>
                        <th scope="col">BASE Price: Can$ &nbsp;
                            <input type={'text'} name={'BasePrice'} onChange={SKUDetailOnChange} className={'update-sku-input'}
                                   value={selectedSkuDetail.BasePrice !==undefined? selectedSkuDetail.BasePrice:"" } />
                        </th>
                        <th scope="col" className={'text-danger'}>ACTIVE: &nbsp;
                            <input type="checkbox"  name={'Active'} onChange={SKUDetailOnChange}
                                   value={selectedSkuDetail.Active !==undefined && selectedSkuDetail.Active } checked={selectedSkuDetail.Active !==undefined && selectedSkuDetail.Active}   />
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            {Object.keys(selectedSkuDetail).length !== 0 && <button className={'btn btn-danger'} onClick={changeTierPrice}>Change Cost Price Tier</button>}
                        </th>
                    </tr>
                    <tr>
                        <th scope={"col"}>
                            UPC 11: &nbsp; <input  type={'text'} value={selectedSkuDetail.UpcCode9 !== undefined?selectedSkuDetail.UpcCode9:""}  disabled className={'update-sku-input'}/>
                        </th>
                        <th scope={"col"}>
                            UPC 12: &nbsp; <input  type={'text'} value={selectedSkuDetail.UpcCode10 !== undefined?selectedSkuDetail.UpcCode10:""}  disabled className={'update-sku-input'}/>
                        </th>
                        <th scope={"col"}>
                            Collection: &nbsp; <input  type={'text'} value={selectedSkuDetail.Collection !== undefined?selectedSkuDetail.Collection:""} disabled className={'update-sku-input'}/>
                        </th>
                    </tr>
                    <tr>
                        <th scope={"col"}>
                            Pricing Tier: &nbsp;
                            <input  type={'text'} value={selectedSkuDetail.PricingTier !== undefined?selectedSkuDetail.PricingTier:""}
                                    onChange={SKUDetailOnChange} name={'PricingTier'} className={'update-sku-input'}/>
                        </th>
                        <th scope={"col"}>
                            Reorder Level: &nbsp;
                            <input  type={'text'} value={selectedSkuDetail.ReorderLevel !== undefined?selectedSkuDetail.ReorderLevel:""}
                                    onChange={SKUDetailOnChange} name={'ReorderLevel'} className={'update-sku-input'}/>
                        </th>
                        <th scope={"col"}>
                            Reorder Quantity: &nbsp;
                            <input  type={'text'} value={selectedSkuDetail.ReorderQuantity !== undefined?selectedSkuDetail.ReorderQuantity:""}
                                    onChange={SKUDetailOnChange} name={'ReorderQuantity'} className={'update-sku-input'}/>
                        </th>
                    </tr>
                    <tr>
                        <th scope={"col"}>
                            Minimum Order: &nbsp;
                            <input  type={'text'} value={selectedSkuDetail.MinOrder !== undefined?selectedSkuDetail.MinOrder:""}
                                    onChange={SKUDetailOnChange} name={'MinOrder'} className={'update-sku-input'}/>
                        </th>
                        <th scope={"col"}>
                            Minimum Imprinted Order: &nbsp;
                            <input  type={'text'} value={selectedSkuDetail.ImprintedMinOrder !== undefined?selectedSkuDetail.ImprintedMinOrder:""}
                                    onChange={SKUDetailOnChange} name={'ImprintedMinOrder'} className={'update-sku-input'}/>
                        </th>
                        <th scope={"col"}>
                            ACTIVE on Web site & Channels: &nbsp;&nbsp;
                            <input type="checkbox"  name={'SkuWebsite'} onChange={SKUDetailOnChange}
                                   value={selectedSkuDetail.SkuWebsite !==undefined && selectedSkuDetail.SkuWebsite } checked={selectedSkuDetail.SkuWebsite !==undefined && selectedSkuDetail.SkuWebsite}   />
                            <br/>
                            SPECIAL ORDER on Web site: &nbsp;&nbsp;
                            <input type="checkbox"  name={'OnCorporate'} onChange={SKUDetailOnChange}
                                   value={selectedSkuDetail.OnCorporate !==undefined && selectedSkuDetail.OnCorporate } checked={selectedSkuDetail.OnCorporate !==undefined && selectedSkuDetail.OnCorporate}   />
                        </th>
                    </tr>
                    <tr>
                        <th scope={"col"}>
                            Designer Color Name: &nbsp;
                            <input  type={'text'} value={skuPantoneColor.DesignerColorName !== undefined?skuPantoneColor.DesignerColorName:""}
                                    onChange={SkuPantoneColorOnChange} name={'DesignerColorName'} className={'update-sku-input'}/>
                        </th>
                        <th scope={"col"}>
                            Pantone Color Name: &nbsp;
                            <input  type={'text'} value={skuPantoneColor.PantoneColorName !== undefined?skuPantoneColor.PantoneColorName:""}
                                    onChange={SkuPantoneColorOnChange} name={'PantoneColorName'} className={'update-sku-input'}/>
                        </th>
                        <th scope={"col row"}>
                            <div className={'col'}>
                                R: &nbsp;
                                <input  type={'text'} value={skuPantoneColor.Red !== undefined?skuPantoneColor.Red:""}
                                        onChange={SkuPantoneColorOnChange} name={'Red'}  />
                            </div>
                            <div className={'col'}>
                                G: &nbsp;
                                <input  type={'text'} value={skuPantoneColor.Green !== undefined?skuPantoneColor.Green:""}
                                        onChange={SkuPantoneColorOnChange} name={'Green'} />
                            </div>
                            <div className={'col'}>
                                B: &nbsp;
                                <input  type={'text'} value={skuPantoneColor.Blue !== undefined?skuPantoneColor.Blue:""}
                                        onChange={SkuPantoneColorOnChange} name={'Blue'} />
                            </div>
                        </th>
                    </tr>
                </tbody>
            </table>
            <table className={'table'}>
                <tbody>
                <tr>
                    <th scope={"col"}>
                        Hts CA: &nbsp;
                        <select name={'HtsCdn'}  onChange={HtsOnchange}  value={selectedSkuDetail.HtsCdn}>
                            <option value="optionDefaultHeader" > </option>
                            {HtsCdn.map((op,i)=>{
                                return <option value={op.HtsCa1} key={i}>{op.HtsCa1}</option>
                            })}
                        </select> &nbsp;
                        <input  type={'text'} value={selectedSkuDetail.DutyCdn !== undefined?selectedSkuDetail.DutyCdn:""} disabled />
                    </th>
                    <th scope={"col"}>
                        Hts US: &nbsp;
                        <select name={'HtsUs'}  onChange={HtsOnchange}  value={selectedSkuDetail.HtsUs}>
                            <option value="optionDefaultHeader" > </option>
                            {HtsUs.map((op,i)=>{
                                return <option value={op.HtsUs1} key={i}>{op.HtsUs1}</option>
                            })}
                        </select> &nbsp;
                        <input  type={'text'} value={selectedSkuDetail.DutyUs !== undefined?selectedSkuDetail.DutyUs:""} disabled />
                    </th>
                </tr>
                </tbody>
            </table>
        </div>
    )
};

export default UpdateSKUInfo;