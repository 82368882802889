import axios from "axios";
import { LeanSupplyModelMapper } from "../Utils/orderModels";
import {
  getActivedOrders,
  getNewOrderURL,
  getSKUURK,
  headers,
  loginURL,
  logoutURL,
  submittedOrdersURL,
} from "../Utils/requestHelper";
import { SKUModelMapper } from "../Utils/sKUModels";
import {
  CHANGE_AUTH,
  FETCH_ALL_SKUS,
  FETCH_ERROR_MESSAGE,
  FETCH_NEW_ORDERS,
  FETCH_SUBMITTED_ORDERS,
} from "./types";

export const login = (data) => async (dispatch) => {
  try {
    const response = await axios.post(loginURL, data, headers);
    if (response.status === 200) {
      const user = response.data.user.split("@")[0];
      localStorage.setItem("user", user);
      dispatch({ type: CHANGE_AUTH, payload: response.data.roles });
      dispatch({ type: FETCH_ERROR_MESSAGE, payload: "" });
    }
  } catch (e) {
    dispatch({
      type: FETCH_ERROR_MESSAGE,
      payload: "Wrong user name or password",
    });
  }
};
export const logout = () => async (dispatch) => {
  const response = await axios.get(logoutURL);
  if (response.status === 200) {
    localStorage.removeItem("auth");
    localStorage.removeItem("user");
    dispatch({ type: CHANGE_AUTH, payload: false });
  }
};
export const cleanAuth = () => (dispatch) => {
  localStorage.removeItem("auth");
  localStorage.removeItem("user");
  dispatch({ type: CHANGE_AUTH, payload: false });
};

export const anthenticate = (isLoggedIn) => (dispatch) => {
  dispatch({ type: CHANGE_AUTH, payload: isLoggedIn });
};
export const fetchNewOrder = () => async (dispatch) => {
  const response = await axios.get(getNewOrderURL);

  const activedOrders = await getActivedOrders();
  //console.log(activedOrders);
  const result = response.data.map((i) => LeanSupplyModelMapper(i));
  //console.log(result);
  const results = result.filter(
    (i) => activedOrders.indexOf(i.OrderHeader.OrderNumber) === -1
  );
  //console.log(results);
  dispatch({ type: FETCH_NEW_ORDERS, payload: results });
};

export const fetchSubmittedOrder = () => async (dispatch) => {
  const response = await axios.get(submittedOrdersURL);
  const result = response.data; //.map(i=>SubmittedOrderModel(i)); // 8/20/18 this mapping is now unnecessary and impedes maintenance
  console.log(result);
  dispatch({ type: FETCH_SUBMITTED_ORDERS, payload: result });
};

export const fetchAllSKUs = () => async (dispatch) => {
  const response = await axios.get(getSKUURK);
  //console.log(response.data);
  const result = response.data.map((i) => SKUModelMapper(i));
  dispatch({ type: FETCH_ALL_SKUS, payload: result });
};

export const fetchErrorMessage = (message) => (dispatch) => {
  dispatch({ type: FETCH_ERROR_MESSAGE, payload: message });
};
