import React from 'react';
import SelectTrueFalse from '../bootstrapComponent/selectInputWithTrueFalse';
import TextArea from '../bootstrapComponent/textArea'
import TextAreaShort from '../bootstrapComponent/textAreaShort';
const NewSkuSEO = ({translate, selectedDesign, onChange})=>{

    return(
        <div className={'container'}>
            <label className={'bg-info text-white btn-block'} style={{fontSize:'20px',textAlign:'center'}}>SEO SECTION</label>
            <div className={'row'} >
                <div className={'col-3'}>
                </div>
                <div className={'col-4'}>
                    <h5 style={{textAlign:'center'}}>ENGLISH</h5>
                </div>
                <div className={'col-1'} >
                    <button className={'btn-info'} style={{fontSize:'15px !important',marginLeft:'-35px'}} onClick={(e)=>{translate(e,'selectedDesign','SEOSections')}}>TRANSLATE</button>
                </div>
                <div className={'col-4'}>
                    <h5 style={{textAlign:'center'}}>FRENCH</h5>
                </div>
            </div>
            <div className={'row'} >
                <div className={'col-3'}>
                    <h5 style={{textAlign:'center'}}>SEO Short Description</h5>
                </div>
                <div className={'col-4'}>
                    <TextAreaShort style={{width:'100%'}} value={selectedDesign.SEOShortENG !==undefined ? selectedDesign.SEOShortENG:"" }
                                   onChange={(e)=>onChange(e,'selectedDesign')} name={'SEOShortENG'}>
                    </TextAreaShort>
                </div>
                <div className={'col-1'}>
                </div>
                <div className={'col-4'}>
                    <TextAreaShort style={{width:'100%'}} value={selectedDesign.SEOShortFR !==undefined ? selectedDesign.SEOShortFR:""}
                                   onChange={(e)=>onChange(e,'selectedDesign')} name={'SEOShortFR'}>
                    </TextAreaShort>
                </div>
            </div>
            <div className={'row'} >
                <div className={'col-3'}>
                    <h5 style={{textAlign:'center',marginTop:'15px'}}>SEO Long Description</h5>
                </div>
                <div className={'col-4'}>
                    <TextAreaShort style={{width:'100%'}} value={selectedDesign.SEOLongENG !==undefined ? selectedDesign.SEOLongENG :""} rows="5"
                                   onChange={(e)=>onChange(e,'selectedDesign')} name={'SEOLongENG'}>
                    </TextAreaShort>
                </div>
                <div className={'col-1'}>
                </div>
                <div className={'col-4'}>
                    <TextAreaShort style={{width:'100%'}} value={selectedDesign.SEOLongFR !==undefined ? selectedDesign.SEOLongFR:""} rows="5"
                                   onChange={(e)=>onChange(e,'selectedDesign')} name={'SEOLongFR'}>
                    </TextAreaShort>
                </div>
            </div>
            <div className={'row'} >
                <div className={'col-3'}>
                    <h5 style={{textAlign:'center'}}>SEO Option 1</h5>
                </div>
                <div className={'col-4'}>
                    <TextAreaShort style={{width:'100%',reSize:'none'}} value={selectedDesign.SEOOp1ENG !==undefined ? selectedDesign.SEOOp1ENG:""} rows={'1'}
                                   onChange={(e)=>onChange(e,'selectedDesign')} name={'SEOOp1ENG'}>
                    </TextAreaShort>
                </div>
                <div className={'col-1'}>
                </div>
                <div className={'col-4'}>
                    <TextAreaShort style={{width:'100%',reSize:'none'}} value={selectedDesign.SEOOp1FR !==undefined ? selectedDesign.SEOOp1FR:""} rows={'1'}
                                   onChange={(e)=>onChange(e,'selectedDesign')} name={'SEOOp1FR'}>
                    </TextAreaShort>
                </div>
            </div>
            <div className={'row'} >
                <div className={'col-3'}>
                    <h5 style={{textAlign:'center'}}>SEO Option 2</h5>
                </div>
                <div className={'col-4'}>
                    <TextAreaShort style={{width:'100%',reSize:'none'}} value={selectedDesign.SEOOp2ENG !==undefined ? selectedDesign.SEOOp2ENG:""} rows={'1'}
                                   onChange={(e)=>onChange(e,'selectedDesign')} name={'SEOOp2ENG'}>
                    </TextAreaShort>
                </div>
                <div className={'col-1'}>
                </div>
                <div className={'col-4'}>
                    <TextAreaShort style={{width:'100%',reSize:'none'}} value={selectedDesign.SEOOp2FR !==undefined ? selectedDesign.SEOOp2FR:""} rows={'1'}
                                   onChange={(e)=>onChange(e,'selectedDesign')} name={'SEOOp2FR'}>
                    </TextAreaShort>
                </div>
            </div>
            <div className={'row'} >
                <div className={'col-3'}>
                    <h5 style={{textAlign:'center'}}>SEO Option 3</h5>
                </div>
                <div className={'col-4'}>
                    <TextAreaShort style={{width:'100%',reSize:'none'}} value={selectedDesign.SEOOp3ENG !==undefined ? selectedDesign.SEOOp3ENG:""} rows={'1'}
                                   onChange={(e)=>onChange(e,'selectedDesign')} name={'SEOOp3ENG'}>
                    </TextAreaShort>
                </div>
                <div className={'col-1'}>
                </div>
                <div className={'col-4'}>
                    <TextAreaShort style={{width:'100%',reSize:'none'}} value={selectedDesign.SEOOp3FR !==undefined ? selectedDesign.SEOOp3FR:""} rows={'1'}
                                   onChange={(e)=>onChange(e,'selectedDesign')} name={'SEOOp3FR'}>
                    </TextAreaShort>
                </div>
            </div>
            <div className={'row'} >
                <div className={'col-3'}>
                    <h5 style={{textAlign:'center'}}>SEO Option 4</h5>
                </div>
                <div className={'col-4'}>
                    <TextAreaShort style={{width:'100%',reSize:'none'}} value={selectedDesign.SEOOp4ENG !==undefined ? selectedDesign.SEOOp4ENG:""} rows={'1'}
                                   onChange={(e)=>onChange(e,'selectedDesign')} name={'SEOOp4ENG'}>
                    </TextAreaShort>
                </div>
                <div className={'col-1'}>
                </div>
                <div className={'col-4'}>
                    <TextAreaShort style={{width:'100%',reSize:'none'}} value={selectedDesign.SEOOp4FR !==undefined ? selectedDesign.SEOOp4FR:""} rows={'1'}
                                   onChange={(e)=>onChange(e,'selectedDesign')} name={'SEOOp4FR'}>
                    </TextAreaShort>
                </div>
            </div>
            <div className={'row'} >
                <div className={'col-3'}>
                    <h5 style={{textAlign:'center'}}>SEO Option 5</h5>
                </div>
                <div className={'col-4'}>
                    <TextAreaShort style={{width:'100%',reSize:'none'}} value={selectedDesign.SEOOp5ENG !==undefined ? selectedDesign.SEOOp5ENG:""} rows={'1'}
                                   onChange={(e)=>onChange(e,'selectedDesign')} name={'SEOOp5ENG'}>
                    </TextAreaShort>
                </div>
                <div className={'col-1'}>
                </div>
                <div className={'col-4'}>
                    <TextAreaShort style={{width:'100%',reSize:'none'}} value={selectedDesign.SEOOp5FR !==undefined ? selectedDesign.SEOOp5FR:""} rows={'1'}
                                   onChange={(e)=>onChange(e,'selectedDesign')} name={'SEOOp5FR'}>
                    </TextAreaShort>
                </div>
            </div>
            <TextArea label={'SEO Meta Title'} value={selectedDesign.SEOMetaTitle !==undefined ? selectedDesign.SEOMetaTitle:""} rows={'1'}
                      onChange={(e)=>onChange(e,'selectedDesign')} name={'SEOMetaTitle'} />
            <TextArea label={'SEO Meta Description'} value={selectedDesign.SEOMetaDescription !==undefined ? selectedDesign.SEOMetaDescription:""}
                      onChange={(e)=>onChange(e,'selectedDesign')} name={'SEOMetaDescription'} />
            <TextArea label={'SEO Meta Keywords'} value={selectedDesign.SEOMetaKeywords !==undefined ? selectedDesign.SEOMetaKeywords:""}
                      onChange={(e)=>onChange(e,'selectedDesign')} name={'SEOMetaKeywords'} />
            <TextArea label={'SEO Title'} value={selectedDesign.SEOTitle !==undefined ? selectedDesign.SEOTitle:""} rows={'1'}
                      onChange={(e)=>onChange(e,'selectedDesign')} name={'SEOTitle'} />
            <TextArea label={'SEO Description'} value={selectedDesign.SEODescription !==undefined ? selectedDesign.SEODescription:""}
                      onChange={(e)=>onChange(e,'selectedDesign')} name={'SEODescription'} />


            <hr/>
            {/*<SelectTrueFalse id={'update_design_display_web'} label={'Display on the website?'} name={'displayOnWeb'} width={'450px'} defaultValue={selectedDesign.displayOnWeb}*/}
                             {/*style={{fontWeight:'bold',marginBottom:'0'}}      onChange={(e)=>onChange(e,'selectedDesign')}/>*/}
            {/*<SelectTrueFalse id={'update_design_display_corporate'} label={'Display on the corporate site?'} name={'displayOnCorporate'} width={'450px'} defaultValue={selectedDesign.displayOnCorporate}*/}
                             {/*style={{fontWeight:'bold',marginBottom:'0'}}      onChange={(e)=>onChange(e,'selectedDesign')}/>*/}
            <hr/>
            <br/>

        </div>
    )
};
export default NewSkuSEO;