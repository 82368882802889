import React from 'react';
import ShippingOptionListItemDetail from "./shipping_option_list_item_detail";
const shipping_option_list_item = ({item,index,shippingOptionOnChange,updateShippingOption,showShippingOptionDetail,selectedShippingOption})=>{
    return(
        <tr>
            <td>
                <span style={{fontWeight:'bold'}}>{item.ChannelName}</span>
            </td>
            <td>
                {item.OptionLevel}
            </td>
            <td>
                {item.CarrierName}
            </td>
            <td>
                {item.ServiceType}
            </td>
            <td>
                <button type={'button'} onClick={()=>showShippingOptionDetail(index)}>View Detail</button>
                <ShippingOptionListItemDetail item={selectedShippingOption} index={index} shippingOptionOnChange={shippingOptionOnChange} updateShippingOption={updateShippingOption}/>
            </td>
        </tr>
    )

};
export default shipping_option_list_item;