import React from 'react';
import TextInputParallel from '../../bootstrapComponent/textInputParallel';
import TextArea from '../../bootstrapComponent/textArea';
import SelectInput from '../../bootstrapComponent/selectInput';
import OnlineCategory from './onlinCategory';
const AddSubFamiliesModal = ({id,families,addSubFamily,onChange, addItem, Activate,
                                 DeActivate,translate,htsCa,htsUs,handleCategory,subFamilies,
                                 sageCategories,sageThemes,ESPCategories,uDuCatCategories,asiCategories,asiIndustries})=>{
    const ashlinSubFamilyCode = subFamilies.map(i=>i.subFamilyCode);
    return(
        <div className="modal fade" id={id} tabIndex="-1" role="dialog" aria-labelledby="addSubFamilyModalLabel"
             aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h3 className="modal-title" id="addSubFamilyModalLabel" style={{textAlign:'center !important'}}>Add A Sub-Family</h3>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <TextInputParallel id='add_subFamily_code' label={'Sub-Family Code'}
                        value={addSubFamily.subFamilyCode} name='subFamilyCode' onChange={(e)=>onChange(e,'addSubFamily')}/>
                        <SelectInput  id={'add_ashlin_sub_families'} label={'Associated Family'} name={'pickedFamily'} width={'450px'}
                                      style={{fontWeight:'bold'}}     value={families}    onChange={(e)=>onChange(e,'addSubFamily')}/>
                        <hr/>
                            <div className={'row'} >
                                <div className={'col-2'}>
                                </div>
                                <div className={'col-4'}>
                                    <h5 style={{textAlign:'center'}}>ENGLISH</h5>
                                </div>
                                <div className={'col-2'}>
                                    <button className={'btn-info'} style={{fontSize:'15px !important'}} onClick={()=>translate('addSubFamily')}>TRANSLATE</button>
                                </div>
                                <div className={'col-4'}>
                                    <h5 style={{textAlign:'center'}}>FRENCH</h5>
                                </div>
                            </div>
                            <div className={'row'} >
                                <div className={'col-2'}>
                                    <h5 style={{textAlign:'center'}}>Short Description</h5>
                                </div>
                                <div className={'col-4'}>
                                <textarea style={{width:'100%'}} value={addSubFamily.shortENG}
                                          onChange={(e)=>onChange(e,'addSubFamily')} name={'shortENG'}>
                                </textarea>
                                        </div>
                                        <div className={'col-2'}>
                                        </div>
                                        <div className={'col-4'}>
                                <textarea style={{width:'100%'}} value={addSubFamily.shortFR}
                                          onChange={(e)=>onChange(e,'addSubFamily')} name={'shortFR'}>
                                </textarea>
                                </div>
                            </div>
                        <hr/>
                        <TextArea label={'Meta Title'} value={addSubFamily.metaTitle}
                                  onChange={(e)=>onChange(e,'addSubFamily')} name={'metaTitle'} />
                        <TextArea label={'Meta Description'} value={addSubFamily.metaDescription}
                                  onChange={(e)=>onChange(e,'addSubFamily')} name={'metaDescription'} />
                        <TextArea label={'General Keywords'} value={addSubFamily.generalKeywords}
                                  onChange={(e)=>onChange(e,'addSubFamily')} name={'generalKeywords'} />
                        <div className='row'>
                            <div className='col-4' style={{margin:'auto',fontWeight:'bold',textAlign:'right'}}>
                                <h5>Gender</h5>
                            </div>
                            <div className='col-8' style={{marginBottom:'7px'}}>
                                <select name={'gender'} onChange={(e)=>onChange(e,'addSubFamily')} className="form-control" id='add_sub_family_gender'>
                                    <option value='' aria-readonly>-- select an option --</option>
                                    <option value="Men's">Men's</option>
                                    <option value="Women's">Women's</option>
                                    <option value='UNISEX'>UNISEX</option>
                                </select>
                            </div>
                        </div>
                        <div className={'row'} style={{marginTop:'5px'}}>
                            <div className={'col-4'} style={{textAlign:'right'}}>
                                <h6>Canadian HTS</h6>
                            </div>
                            <div className={'col-8'}>
                                <select style={{width:'48%'}} onChange={(e)=>onChange(e,'addSubFamily',htsCa)} name={'htsCa'}>
                                    <option value=''>-- select an option --</option>
                                    {htsCa.map(i=>{
                                        return(
                                            <option value={i.id} key={i.id}>
                                                {i.id}
                                            </option>
                                        )
                                    })}
                                </select>
                                <input style={{width:'48%',marginLeft:'4%'}}   value={addSubFamily.htsCaValue} readOnly/>
                            </div>
                        </div>
                        <div className={'row'} style={{marginTop:'5px'}}>
                            <div className={'col-4'} style={{textAlign:'right'}}>
                                <h6>US HTS</h6>
                            </div>
                            <div className={'col-8'}>
                                <select style={{width:'48%'}} onChange={(e)=>onChange(e,'addSubFamily',htsUs)} name={'htsUs'}>
                                    <option value=''>-- select an option --</option>
                                    {htsUs.map(i=>{
                                        return(
                                            <option value={i.id} key={i.id}>
                                                {i.id}
                                            </option>
                                        )
                                    })}
                                </select>
                                <input style={{width:'48%',marginLeft:'4%'}}   value={addSubFamily.htsUsValue} readOnly/>
                            </div>
                        </div>
                        <br/>
                        <div className={'row container'}>
                            <div className={'col-4'}>
                                <div className={'row'}>
                                    <h6 className={'col'} style={{textAlign:'right',margin:'auto 0'}}>Pricing Tier</h6>
                                    <input className={'col'} style={{}} name={'pricingTier'}  value={addSubFamily.pricingTier} onChange={(e)=>onChange(e,'addSubFamily')}/>
                                </div>
                            </div>
                            <div className={'col-4'}>
                                <div className={'row'}>
                                    <h6 className={'col'} style={{textAlign:'right',margin:'auto 0'}}>Reorder Qty</h6>
                                    <input className={'col'} style={{}} name={'ReorderQty'}  value={addSubFamily.ReorderQty} onChange={(e)=>onChange(e,'addSubFamily')}/>
                                </div>
                            </div>
                            <div className={'col-4'}>
                                <div className={'row'}>
                                    <h6 className={'col'} style={{textAlign:'right',margin:'auto 0'}}>Reorder Lvl</h6>
                                    <input className={'col'} style={{}} name={'ReorderLevel'}  value={addSubFamily.ReorderLevel} onChange={(e)=>onChange(e,'addSubFamily')}/>
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <TextArea label={'Amazon Keywords 1'} value={addSubFamily.amazonKeywords1} rows={'1'}
                                  onChange={(e)=>onChange(e,'addSubFamily')} name={'amazonKeywords1'} />
                        <TextArea label={'Amazon Keywords 2'} value={addSubFamily.amazonKeywords2} rows={'1'}
                                  onChange={(e)=>onChange(e,'addSubFamily')} name={'amazonKeywords2'} />
                        <TextArea label={'Amazon Keywords 3'} value={addSubFamily.amazonKeywords3} rows={'1'}
                                  onChange={(e)=>onChange(e,'addSubFamily')} name={'amazonKeywords3'} />
                        <TextArea label={'Amazon Keywords 4'} value={addSubFamily.amazonKeywords4} rows={'1'}
                                  onChange={(e)=>onChange(e,'addSubFamily')} name={'amazonKeywords4'} />
                        <TextArea label={'Amazon Keywords 5'} value={addSubFamily.amazonKeywords5} rows={'1'}
                                  onChange={(e)=>onChange(e,'addSubFamily')} name={'amazonKeywords5'} />

                        <div className={'row'}>
                            <div className={'col-4'} style={{textAlign:'right',margin:'auto 0'}}>
                                <h6>Amazon.ca Browse Node</h6>
                            </div>
                            <div className={'col-8'}>
                                <input style={{width:'48%'}} name={'amazonCaNode1'}  value={addSubFamily.amazonCaNode1} onChange={(e)=>onChange(e,'addSubFamily')}/>
                                <input style={{width:'48%',marginLeft:'4%'}} name={'amazonCaNode2'}  value={addSubFamily.amazonCaNode2} onChange={(e)=>onChange(e,'addSubFamily')}/>
                            </div>
                        </div>

                        <div className={'row'} style={{marginTop:'5px'}}>
                            <div className={'col-4'} style={{textAlign:'right',margin:'auto 0'}}>
                                <h6>Amazon.com Browse Node</h6>
                            </div>
                            <div className={'col-8'} style={{marginBottom:'5px'}}>
                                <input style={{width:'48%'}} name={'amazonComNode1'}  value={addSubFamily.amazonComNode1} onChange={(e)=>onChange(e,'addSubFamily')}/>
                                <input style={{width:'48%',marginLeft:'4%'}} name={'amazonComNode2'}  value={addSubFamily.amazonComNode2} onChange={(e)=>onChange(e,'addSubFamily')}/>
                            </div>
                        </div>
                        <TextArea label={'eBay Category ID'} value={addSubFamily.eBayCategoryID} rows={'1'}
                                  onChange={(e)=>onChange(e,'addSubFamily')} name={'eBayCategoryID'} />
                        <div className={'row'} >
                            <div className={'col-4'} style={{textAlign:'right',margin:'auto 0'}}>
                                <h5>staples Class ID/Name</h5>
                            </div>
                            <div className={'col-8'}>
                                <input style={{width:'48%'}} name={'staplesClassID'}  value={addSubFamily.staplesClassID} onChange={(e)=>onChange(e,'addSubFamily')}/>
                                <input style={{width:'48%',marginLeft:'4%'}} name={'staplesClassName'}  value={addSubFamily.staplesClassName} onChange={(e)=>onChange(e,'addSubFamily')}/>
                            </div>
                        </div>
                        <hr/>
                        <OnlineCategory label={'SAGE[CATEGORY]'} categories={sageCategories} value={''}
                                        name={'sageCategory'} handleCategory={(a,b)=>handleCategory(a,b,'addSubFamily')} />
                        <OnlineCategory label={'sageTheme'} categories={sageThemes}  value={''}
                                        name={'sageTheme'} handleCategory={(a,b)=>handleCategory(a,b,'addSubFamily')} />
                        <OnlineCategory label={'esp'} categories={ESPCategories}  value={''}
                                        name={'esp'} handleCategory={(a,b)=>handleCategory(a,b,'addSubFamily')} />
                        <OnlineCategory label={'uduCate'} categories={uDuCatCategories}  value={''}
                                        name={'uduCate'} handleCategory={(a,b)=>handleCategory(a,b,'addSubFamily')} />
                        <OnlineCategory label={'asiTheme'} categories={asiCategories}  value={''}
                                        name={'asiTheme'} handleCategory={(a,b)=>handleCategory(a,b,'addSubFamily')} />
                        <OnlineCategory label={'asiIndustry'} categories={asiIndustries}  value={''}
                                        name={'asiIndustry'} handleCategory={(a,b)=>handleCategory(a,b,'addSubFamily')} />
                        <br/>
                        <div className={'row'}>
                            <button type="button" className="btn btn-primary btn-block" onClick={(e)=>addItem(e,'addSubFamily',ashlinSubFamilyCode)}>Add Sub-Family</button>
                        </div>
                        <br/>
                        {/*<br/><br/>*/}
                        {/*<div className={'row modal-footer'}>*/}
                            {/*<button type="button" className="btn btn-danger col" onClick={(e)=>DeActivate(e,'addSubFamily','subFamilyCode')} >Deactivate Sub-Family</button>*/}
                            {/*<button type="button" className="btn btn-success col" onClick={(e)=>Activate(e,'addSubFamily','subFamilyCode')}>Activate Sub-Family</button>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        </div>
    )
};
export default AddSubFamiliesModal;