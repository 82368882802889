import React, { Component } from 'react';
import MainSideBar from '../sideBar/MainSideBar';
import * as actions from '../../actions';
import { connect } from 'react-redux';
import NavItem from '../bootstrapComponent/navItem';
import TabContentItem from '../bootstrapComponent/tabContentItem';
import ProductColors from './colors/productColors';
import ProductMaterials from './materials/productMaterials';
import ProductFamilies from './families/productFamilies';
import ProductSubFamilies from './subFamilies/productSubFamilies';
import ProductDesigns from './designs/productDesigns';
import {
    legacyColoursURL, legacyColorUpdateModel, legacyMaterialsURL, legacyMaterialUpdateModel,
    legacyFamiliesURL, legacyFamilyUpdateModel, legacySubFamiliesURL, legacySubFamilyUpdateModel,
    legacyDesignsURL, legacyDesignUpdateModel, getAllSKUNumberList
} from '../../Utils/ashlinDesign';
import { googleTranslate } from '../../Utils/googleTranslate';
import axios from 'axios';
import CreateNewSKU from './CreateNewSKU';
import UpdateSKU from './UpdateSKU';
class ProductConcepts extends Component{
    constructor(props){
        super(props);
        this.state={
            role:'operator',
            asiColors:[],
            addColor:{
                colorCode:'',
                pickedColor:'',
                shortENG:'',
                shortFR:'',
                longENG:'',
                longFR:'',
                webENG:'',
                webFR:''
            },
            updateColor:{
                colorCode:'',
                pickedColor:'',
                shortENG:'',
                shortFR:'',
                longENG:'',
                longFR:'',
                webENG:'',
                webFR:'',
                keyword:'',
                searchKeyWords:''
            },
            addMaterial:{
                materialCode:'',
                pickedMaterial:'',
                shortENG:'',
                shortFR:'',
                longENG:'',
                longFR:'',
                webENG:'',
                webFR:'',
                materialHTSDescription:''
            },
            updateMaterial:{
                materialCode:'',
                pickedMaterial:'',
                shortENG:'',
                shortFR:'',
                longENG:'',
                longFR:'',
                webENG:'',
                webFR:'',
                keyword:'',
                searchKeyWords:'',
                materialHTSDescription:''
            },
            addFamily:{
                familyCode:'',
                pickedCollection:'',
                shortENG:'',
                shortFR:'',
                longENG:'',
                longFR:''
            },
            updateFamily:{
                familyCode:'',
                pickedCollection:'',
                shortENG:'',
                shortFR:'',
                longENG:'',
                longFR:''
            },
            addSubFamily:{
                subFamilyCode:'',
                pickedFamily:'',
                shortENG:'',
                shortFR:'',
                metaTitle:'',
                metaDescription:'',
                generalKeywords:'',
                gender:'',
                amazonKeywords1:'',
                amazonKeywords2:'',
                amazonKeywords3:'',
                amazonKeywords4:'',
                amazonKeywords5:'',
                amazonCaNode1:'',
                amazonCaNode2:'',
                amazonComNode1:'',
                amazonComNode2:'',
                htsCaCode:'',
                htsCaValue:'',
                htsUsCode:'',
                htsUsValue:'',
                eBayCategoryID:'',
                staplesClassID:'',
                staplesClassName:'',
                pricingTier:'',
                ReorderQty:'',
                ReorderLevel:'',
                sageCategory:'',
                sageTheme:'',
                esp:"",
                uduCate:'',
                asiTheme:'',
                asiIndustry:''
            },
            updateSubFamily:{
                subFamilyCode:'',
                pickedFamily:'',
                shortENG:'',
                shortFR:'',
                metaTitle:'',
                metaDescription:'',
                generalKeywords:'',
                gender:'',
                amazonKeywords1:'',
                amazonKeywords2:'',
                amazonKeywords3:'',
                amazonKeywords4:'',
                amazonKeywords5:'',
                amazonCaNode1:'',
                amazonCaNode2:'',
                amazonComNode1:'',
                amazonComNode2:'',
                htsCaCode:'',
                htsCaValue:'',
                htsUsCode:'',
                htsUsValue:'',
                eBayCategoryID:'',
                staplesClassID:'',
                staplesClassName:'',
                pricingTier:'',
                ReorderQty:'',
                ReorderLevel:'',
                sageCategory:'',
                sageTheme:'',
                esp:"",
                promoMarketing:'',
                uduCate:'',
                distributorCentral:'',
                asiTheme:'',
                asiIndustry:''
            },
            addDesign:{
                designCode:'',
                designHTSDescription:'',
                pickedSubFamily:'',
                brand:'',
                designServiceFlag:'design',
                giftBox:0,
                internalAshlinName:'',
                generalName:'',
                onlineName:'',
                country:'India',
                shippingPackaging:"BUBBLE PACK",
                shortENG:'',
                shortFR:'',
                longENG:'',
                longFR:'',
                webENG:'',
                webFR:'',
                shortTrendENG:'',
                shortTrendFR:'',
                longTrendENG:'',
                longTrendFR:'',
                monogrammed:'1',
                imprinted:'1',
                strap:'0',
                detachable:'0',
                enclosure:'0',
                flat:'0',
                folded:'0',
                imprintWidth:'',
                imprintHeight:'',
                productHeight:'',
                productWidth:'',
                productDepth:'',
                productWeight:'',
                shipmentHeight:'',
                shipmentWidth:'',
                shipmentDepth:'',
                shipmentWeight:'',
                numComponent:'',
                shoulderDropLength:'',
                handleTotalLength:'',
                cardSlots:'',
                notableStrap:"1 long",
                sizeDifferentiation:"Medium",
                billCompartment:'0',
                style:'',
                closure:'Zippered',
                protectiveFeet:'0',
                innerPocket:'0',
                outsidePocket:'0',
                dustBagIncluded:'1',
                authenticityCardIncluded:'1',
                op1ENG:'',
                op1FR:'',
                op2ENG:'',
                op2FR:'',
                op3ENG:'',
                op3FR:'',
                op4ENG:'',
                op4FR:'',
                op5ENG:'',
                op5FR:'',
                displayOnWeb:'1',
                displayOnCorporate:'1',
                SEOShortENG:'',
                SEOShortFR:'',
                SEOLongENG:'',
                SEOLongFR:'',
                SEOOp1ENG:'',
                SEOOp1FR:'',
                SEOOp2ENG:'',
                SEOOp2FR:'',
                SEOOp3ENG:'',
                SEOOp3FR:'',
                SEOOp4ENG:'',
                SEOOp4FR:'',
                SEOOp5ENG:'',
                SEOOp5FR:'',
                SEOMetaTitle:'',
                SEOMetaKeywords:'',
                SEOMetaDescription:'',
                SEOTitle:'',
                SEODescription:''

            },
            updateDesign:{
                designCode:'',
                designHTSDescription:'',
                pickedSubFamily:'',
                brand:'',
                designServiceFlag:'design',
                giftBox:0,
                internalAshlinName:'',
                generalName:'',
                onlineName:'',
                country:'India',
                shippingPackaging:"BUBBLE PACK",
                shortENG:'',
                shortFR:'',
                longENG:'',
                longFR:'',
                webENG:'',
                webFR:'',
                shortTrendENG:'',
                shortTrendFR:'',
                longTrendENG:'',
                longTrendFR:'',
                monogrammed:'1',
                imprinted:'1',
                strap:'0',
                detachable:'0',
                enclosure:'0',
                flat:'0',
                folded:'0',
                imprintWidth:'',
                imprintHeight:'',
                productHeight:'',
                productWidth:'',
                productDepth:'',
                productWeight:'',
                shipmentHeight:'',
                shipmentWidth:'',
                shipmentDepth:'',
                shipmentWeight:'',
                numComponent:'',
                shoulderDropLength:'',
                handleTotalLength:'',
                cardSlots:'',
                notableStrap:"1 long",
                sizeDifferentiation:"Medium",
                billCompartment:'None',
                style:'',
                closure:'Zippered',
                protectiveFeet:'0',
                innerPocket:'0',
                outsidePocket:'0',
                dustBagIncluded:'1',
                authenticityCardIncluded:'1',
                op1ENG:'',
                op1FR:'',
                op2ENG:'',
                op2FR:'',
                op3ENG:'',
                op3FR:'',
                op4ENG:'',
                op4FR:'',
                op5ENG:'',
                op5FR:'',
                displayOnWeb:'1',
                displayOnCorporate:'1',
                SEOShortENG:'',
                SEOShortFR:'',
                SEOLongENG:'',
                SEOLongFR:'',
                SEOOp1ENG:'',
                SEOOp1FR:'',
                SEOOp2ENG:'',
                SEOOp2FR:'',
                SEOOp3ENG:'',
                SEOOp3FR:'',
                SEOOp4ENG:'',
                SEOOp4FR:'',
                SEOOp5ENG:'',
                SEOOp5FR:'',
                SEOMetaTitle:'',
                SEOMetaKeywords:'',
                SEOMetaDescription:'',
                SEOTitle:'',
                SEODescription:''
            },
            skuNumberList:[]
        }
    }
    UNSAFE_componentWillMount() {
        // if(this.state.role === 'operator') {
        //     this.props.fetchErrorMessage('You are not admin, please login with administrator account!');
        //     this.props.history.push('/');
        // }

    }
    handleTranslate= async(state)=>{
        const editState = this.state[state];
        if(editState.shortENG==='' ){
            alert('Must enter english description first before translate!!!');
        }else{
            editState.shortFR = await googleTranslate(editState.shortENG,'fr');

            if(editState.longENG!== undefined && editState.longENG!=='' ){
                editState.longFR = await googleTranslate(editState.longENG,'fr');
            }
            if(editState.webENG !== undefined && editState.webENG !==''){
                editState.webFR = await googleTranslate(editState.webENG,'fr');
            }
            if(editState.shortTrendENG !== undefined && editState.shortTrendENG !==''){
                editState.shortTrendFR = await googleTranslate(editState.shortTrendENG ,'fr');
            }
            if(editState.longTrendENG !== undefined && editState.longTrendENG!==''){
                editState.longTrendFR = await googleTranslate(editState.longTrendENG ,'fr');
            }
            this.setState({
                [state]:editState
            });
        }

    };

    // SEOOp1ENG:'',
    // SEOOp1FR:'',
    // SEOOp2ENG:'',
    // SEOOp2FR:'',
    // SEOOp3ENG:'',
    // SEOOp3FR:'',
    // SEOOp4ENG:'',
    // SEOOp4FR:'',
    // SEOOp5ENG:'',
    // SEOOp5FR:'',
    handleTranslateWithField= async(state,field)=>{
        const editState = this.state[state];
        if(field==='options'){
            if(editState.op1ENG!== undefined && editState.op1ENG!=='' ){
                editState.op1FR = await googleTranslate(editState.op1ENG,'fr');
            }
            if(editState.op2ENG!== undefined && editState.op2ENG!=='' ){
                editState.op2FR = await googleTranslate(editState.op2ENG,'fr');
            }
            if(editState.op3ENG!== undefined && editState.op3ENG!=='' ){
                editState.op3FR = await googleTranslate(editState.op3ENG,'fr');
            }
            if(editState.op4ENG!== undefined && editState.op4ENG!=='' ){
                editState.op4FR = await googleTranslate(editState.op4ENG,'fr');
            }
            if(editState.op5ENG!== undefined && editState.op5ENG!=='' ){
                editState.op5FR = await googleTranslate(editState.op5ENG,'fr');
            }
        }else if(field==='SEOSections'){
            if(editState.SEOShortENG!== undefined && editState.SEOShortENG!=='' ){
                editState.SEOShortFR = await googleTranslate(editState.SEOShortENG,'fr');
            }
            if(editState.SEOLongENG!== undefined && editState.SEOLongENG!=='' ){
                editState.SEOLongFR = await googleTranslate(editState.SEOLongENG,'fr');
            }
            if(editState.SEOOp1ENG!== undefined && editState.SEOOp1ENG!=='' ){
                editState.SEOOp1FR = await googleTranslate(editState.SEOOp1ENG,'fr');
            }
            if(editState.SEOOp2ENG!== undefined && editState.SEOOp2ENG!=='' ){
                editState.SEOOp2FR = await googleTranslate(editState.SEOOp2ENG,'fr');
            }
            if(editState.SEOOp3ENG!== undefined && editState.SEOOp3ENG!=='' ){
                editState.SEOOp3FR = await googleTranslate(editState.SEOOp3ENG,'fr');
            }
            if(editState.SEOOp4ENG!== undefined && editState.SEOOp4ENG!=='' ){
                editState.SEOOp4FR = await googleTranslate(editState.SEOOp4ENG,'fr');
            }
            if(editState.SEOOp5ENG!== undefined && editState.SEOOp5ENG!=='' ){
                editState.SEOOp5FR = await googleTranslate(editState.SEOOp5ENG,'fr');
            }

        }
        this.setState({
            [state]:editState
        });
    };
    handleShipmentCalculation=(e,field)=>{

        const state = this.state[field];
        if(state.flat === "1"){
            state.shipmentHeight = parseFloat(state.productHeight) * 1.2;
            state.shipmentWidth= parseFloat(state.productWidth) * 1.2;
            state.shipmentDepth = parseFloat(state.productDepth) * 0.3;
            state.shipmentWeight = parseFloat(state.productWeight) * 1.2;
        }else{
            state.shipmentHeight = parseFloat(state.productHeight);
            state.shipmentWidth= parseFloat(state.productWidth) ;
            state.shipmentDepth = parseFloat(state.productDepth) ;
            state.shipmentWeight = parseFloat(state.productWeight) * 1.2;
        }
        this.setState({
            [field]:state
        },()=>console.log(this.state[field]));
    };
    handleOnChange=(e,field,backValue)=>{
        const name = e.target.name;
        let value =  e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        if(value==="optionDefaultHeader"){
           return
        }
       if(field==='updateColor'&&name==='colorCode'){
            const selectColor = backValue.find(color=>color.colorCode === value);
            selectColor.keyword = '';
            this.setState({
                updateColor:selectColor
            },()=>console.log(this.state.updateColor))
        }
        else if(field==='updateMaterial'&&name==='materialCode'){
            const selectMaterial = backValue.find(material=>material.materialCode === value);
            selectMaterial.keyword = '';
            this.setState({
                updateMaterial:selectMaterial
            },()=>console.log(this.state.updateMaterial));
        }else if(field==='updateFamily'&&name==='familyCode'){
            const selectFamily = backValue.find(family=>family.familyCode === value);
            this.setState({
                updateFamily:selectFamily
            },()=>console.log(this.state.updateFamily));
        }else if(field==='updateSubFamily'&&name==='subFamilyCode'){
            const selectSubFamily = backValue.find(subFamily=>subFamily.subFamilyCode === value);
            this.setState({
                updateSubFamily:selectSubFamily
            },()=>console.log(this.state.updateSubFamily));
        }else if(field==='updateDesign'&&name==='designCode'){
            const selectDesign = backValue.find(design=>design.designCode === value);
            //console.log(selectDesign);
            this.setState({
                updateDesign:selectDesign
            },()=>console.log(this.state.updateDesign));
        }else if(field==='addSubFamily'&&(name==='htsCa')&&value!==""){
            const selectHts = backValue.find(hts=>hts.id===value);
            const addSubFamily = this.state.addSubFamily;
            addSubFamily.htsCaCode = selectHts.id;
            addSubFamily.htsCaValue = selectHts.value;
            this.setState({
                addSubFamily
            },()=>console.log(this.state.addSubFamily));
        }
        else if(field==='addSubFamily'&&(name==='htsUs')&&value!==""){
            const selectHts = backValue.find(hts=>hts.id===value);
            const addSubFamily = this.state.addSubFamily;
            addSubFamily.htsUsCode = selectHts.id;
            addSubFamily.htsUsValue = selectHts.value;
            this.setState({
                addSubFamily
            },()=>console.log(this.state.addSubFamily));
        }
        else{
            if(name==='active'){
                value = value? 1: 0;
            }
            if(name==='giftBox'){
                value = value? 1: 0;
            }
            const fieldValue = this.state[field];
            fieldValue[name] = value;
            this.setState({
                [field]:fieldValue
            },()=>console.log(this.state[field]));
        }
    };
    handleAddItem= async (e,field,backValue)=>{
        e.preventDefault();
        const fieldValue = this.state[field];
        //console.log(backValue);
        if(field==='addColor'){
            const result = backValue.findIndex(c=>c === fieldValue.colorCode);
            if(result === -1){
                const uploadData = legacyColorUpdateModel(fieldValue,"addColor");
                //console.log(uploadData);
                const response = await axios.post(legacyColoursURL,uploadData);
                //console.log(response);
            }else {
                alert("Color code already exist, pick a different one.")
            }
        }else if(field==='addMaterial'){
            const result = backValue.findIndex(m=>m === fieldValue.materialCode);
            if(result === -1){
                const uploadData = legacyMaterialUpdateModel(fieldValue,"addMaterial");
                //console.log(uploadData);
                const response = await axios.post(legacyMaterialsURL,uploadData);
               // console.log(response);
            }else {
                alert("Material code already exist, pick a different one.")
            }
        }else if(field==='addFamily'){
            const result = backValue.findIndex(f=>f === fieldValue.familyCode);
            if(result === -1){
                const uploadData = legacyFamilyUpdateModel(fieldValue,"addFamily");
                //console.log(uploadData);
                const response = await axios.post(legacyFamiliesURL,uploadData);
                //console.log(response);
            }else {
                alert("Family code already exist, pick a different one.")
            }
        }else if(field==='addSubFamily'){
            //console.log(fieldValue);
            const result = backValue.findIndex(s=>s === fieldValue.subFamilyCode);
            if(result === -1){
                const uploadData = legacySubFamilyUpdateModel(fieldValue,"addSubFamily");
                //console.log(uploadData);
                const response = await axios.post(legacySubFamiliesURL,uploadData);
                //console.log(response);
            }else {
                alert("Sub Family code already exist, pick a different one.")
            }
        }else if(field==='addDesign'){
            //console.log(fieldValue);
            //console.log(backValue);
            const designCodeResult = backValue.ashlinDesignCode.findIndex(s=>s === fieldValue.designCode);
            const designNameResult = backValue.ashlinDesignName.findIndex(s=>s === fieldValue.internalAshlinName);

            if(designCodeResult === -1){
                //console.log(fieldValue);
                const uploadData = legacyDesignUpdateModel(fieldValue,"addDesign");
                //console.log(uploadData);
                const response = await axios.post(legacyDesignsURL,uploadData);
                //console.log(response);
            }else {
                if(designCodeResult !== -1)
                    alert("Design code already exist, pick a different one.");
                if(designNameResult !== -1)
                    alert("Design Name already exist, pick a different one.");
            }
        }



        //reset default value to ''
        // Object.keys(fieldValue).forEach(v=>fieldValue[v]='');
        // console.log(fieldValue);
        // this.setState({
        //     [field]:fieldValue
        // })
    };

    handleUpdate=async(e,field)=>{
        e.preventDefault();
        const fieldValue = this.state[field];
        if(field==='updateColor'){
            const uploadData = legacyColorUpdateModel(fieldValue,"updateColor");
            const deleteResponse = await axios.delete(legacyColoursURL+uploadData.ColourCode);
            uploadData.DateAdded = deleteResponse.data.DateAdded;
            const updateResponse = await  axios.post(legacyColoursURL,uploadData);
            if(updateResponse.status===201){
                alert("Update successfully!!!");
            }
        }else if(field==='updateMaterial'){
            const uploadData = legacyMaterialUpdateModel(fieldValue,"updateMaterial");
            const deleteResponse = await axios.delete(legacyMaterialsURL+uploadData.MaterialCode);
            uploadData.DateAdded = deleteResponse.data.DateAdded;
            const updateResponse = await  axios.post(legacyMaterialsURL,uploadData);
            if(updateResponse.status===201){
                alert("Update successfully!!!");
            }
        }else if(field==='updateFamily'){
            const uploadData = legacyFamilyUpdateModel(fieldValue,"updateFamily");
            const updateResponse = await  axios.put(legacyFamiliesURL+uploadData.SuperFamily,uploadData);
            if(updateResponse.status===204){
                alert("Update successfully!!!");
            }
        }else if(field==='updateSubFamily'){
            const uploadData = legacySubFamilyUpdateModel(fieldValue,"updateSubFamily");
            const deleteResponse = await axios.delete(legacySubFamiliesURL+uploadData.DesignServiceFamilyCode);
            uploadData.DateAdded = deleteResponse.data.DateAdded;
            const updateResponse = await axios.post(legacySubFamiliesURL,uploadData);
            if(updateResponse.status===201){
                alert("Update successfully!!!");
            }
        }else if(field==='updateDesign'){
            const uploadData = legacyDesignUpdateModel(fieldValue,"updateDesign");
             const updateResponse = await axios.put(legacyDesignsURL+uploadData.DesignServiceCode,uploadData);
             if(updateResponse.status===204){
                 alert("Update successfully!!!");
             }
        }

        // Object.keys(fieldValue).forEach(v=>fieldValue[v]='');
        // console.log(fieldValue);
        // this.setState({
        //     [field]:fieldValue
        // })
    };

    handleUpdateModalClose = async() =>{
      //  console.log("Update Modal Closed!");
        this.setState({
            updateDesign:{
                designCode:'',
                designHTSDescription:'',
                pickedSubFamily:'',
                brand:'',
                designServiceFlag:'design',
                giftBox:0,
                internalAshlinName:'',
                generalName:'',
                onlineName:'',
                country:'India',
                shippingPackaging:"BUBBLE PACK",
                shortENG:'',
                shortFR:'',
                longENG:'',
                longFR:'',
                webENG:'',
                webFR:'',
                shortTrendENG:'',
                shortTrendFR:'',
                longTrendENG:'',
                longTrendFR:'',
                monogrammed:'1',
                imprinted:'1',
                strap:'0',
                detachable:'0',
                enclosure:'0',
                flat:'0',
                folded:'0',
                imprintWidth:'',
                imprintHeight:'',
                productHeight:'',
                productWidth:'',
                productDepth:'',
                productWeight:'',
                shipmentHeight:'',
                shipmentWidth:'',
                shipmentDepth:'',
                shipmentWeight:'',
                numComponent:'',
                shoulderDropLength:'',
                handleTotalLength:'',
                cardSlots:'',
                notableStrap:"1 long",
                sizeDifferentiation:"Medium",
                billCompartment:'None',
                style:'',
                closure:'Zippered',
                protectiveFeet:'0',
                innerPocket:'0',
                outsidePocket:'0',
                dustBagIncluded:'1',
                authenticityCardIncluded:'1',
                op1ENG:'',
                op1FR:'',
                op2ENG:'',
                op2FR:'',
                op3ENG:'',
                op3FR:'',
                op4ENG:'',
                op4FR:'',
                op5ENG:'',
                op5FR:'',
                displayOnWeb:'1',
                displayOnCorporate:'1',
                SEOShortENG:'',
                SEOShortFR:'',
                SEOLongENG:'',
                SEOLongFR:'',
                SEOOp1ENG:'',
                SEOOp1FR:'',
                SEOOp2ENG:'',
                SEOOp2FR:'',
                SEOOp3ENG:'',
                SEOOp3FR:'',
                SEOOp4ENG:'',
                SEOOp4FR:'',
                SEOOp5ENG:'',
                SEOOp5FR:'',
                SEOMetaTitle:'',
                SEOMetaKeywords:'',
                SEOMetaDescription:'',
                SEOTitle:'',
                SEODescription:''
            }
        })
    }

    handleAddKeyword=(searchKeywords, keyword,field,property)=>{
        searchKeywords = searchKeywords.trim();
        if(searchKeywords.charAt( searchKeywords.length-1 )!==";"){
            //console.log('Add ; to the end');
            searchKeywords = searchKeywords + ";";
        }
        const keywords = searchKeywords.split(";").slice(0, -1);
        const result = keywords.findIndex(i=>i.toUpperCase() === keyword.trim().toUpperCase());
        if(result === -1){
            let value = searchKeywords + keyword.trim() + ';';
            if(value[0]===";"){
                value = value.slice(1);
            }
            const fieldValue = this.state[field];
            fieldValue[property] = value;
            fieldValue.keyword = '';
            this.setState({
                [field]:fieldValue
            },()=>console.log(this.state));
        }else{
            alert('Keyword already exists!');
        }
    };
    handleDeleteKeyword=(searchKeywords, keyword,field,property)=>{
        let keywords = searchKeywords.trim().split(";");
        if(keywords[keywords.length-1]===""){
            //console.log('need remove');
           keywords = keywords.slice(0, -1);
        }
        //console.log(keywords);
        const result = keywords.findIndex(i=>i.trim().toUpperCase() === keyword.trim().toUpperCase());
        if(result!==-1){
            keywords.splice(result,1);
            const value = keywords.join(';');
            const fieldValue = this.state[field];
            fieldValue[property] = value;
            fieldValue.keyword = '';
            this.setState({
                [field]:fieldValue
            },()=>console.log(this.state));
        }else {
            alert('Search Keywords does not contain entered keyword');
        }
    };
    handleCategory=(name,value,state)=>{

        const fieldValue = this.state[state];

        fieldValue[name] = value;
        this.setState({
            [state]:fieldValue
        },()=>console.log(this.state[state]));
    };
    handleActivate = (e,field,primaryKey)=>{
        e.preventDefault();
        const fieldValue = this.state[field];
        const pk = fieldValue[primaryKey];
        //console.log(pk)
    };
    handleDeActivate = (e,field,primaryKey)=>{
        e.preventDefault();
        const fieldValue = this.state[field];
        const pk = fieldValue[primaryKey];
        //console.log(pk)
    };
    handleGetSKUList=async()=>{
        const skuNumberList = await getAllSKUNumberList();
        this.setState({
            skuNumberList
        })
    };
    handleGenerateSEOSection=(e,obj,name)=>{
        e.preventDefault();
        //console.log(obj);
        const toBeGenerated = this.state[name];
        toBeGenerated.SEOShortENG=`${obj.brand} ${obj.shortENG}`;
        toBeGenerated.SEOLongENG=`${obj.brand} ${obj.longENG}`;
        toBeGenerated.SEOOp1ENG=obj.op1ENG;
        toBeGenerated.SEOOp2ENG=obj.op2ENG;
        toBeGenerated.SEOOp3ENG=obj.op3ENG;
        toBeGenerated.SEOOp4ENG=obj.op4ENG;
        toBeGenerated.SEOOp5ENG=obj.op5ENG;
        toBeGenerated.SEOMetaTitle=`${obj.brand} ${obj.internalAshlinName} ${obj.shortENG}`;
        toBeGenerated.SEOMetaKeywords=`${obj.brand} | ${obj.internalAshlinName} | ${obj.shortENG} | ${obj.style}`;
        toBeGenerated.SEOMetaDescription=`${obj.brand} ${obj.webENG}`;
        toBeGenerated.SEOTitle= `${obj.brand} ${obj.internalAshlinName} ${obj.shortENG}`;
        toBeGenerated.SEODescription=`${obj.brand} ${obj.longTrendENG}`;
        this.setState({
            [name]:toBeGenerated
        })
    };
    render(){
        return(
            <div className='container-fluid row'>
                <div className={'col-3'}>
                    <MainSideBar/>
                </div>
                <div className={'col-9'}>
                    <br/>
                    <h1 style={{textAlign:'center'}}>Manage Product Concepts</h1>
                    <br/>
                    <ul className="nav nav-tabs nav-fill">
                        <NavItem toggleType='tab' id="#product_concept_colors" data="COLORS" active={true} />
                        <NavItem toggleType='tab' id="#product_concept_materials" data="MATERIALS"  />
                        <NavItem toggleType='tab' id="#product_concept_families" data="FAMILIES"  />
                        <NavItem toggleType='tab' id="#product_concept_subfamilies" data="SUB-FAMILIES"  />
                        <NavItem toggleType='tab' id="#product_concept_designs" data="DESIGNS"  />
                        <NavItem toggleType='tab' id="#product_concept_add_skus" data="Add SKU"  />
                        <NavItem toggleType='tab' id="#product_concept_update_skus" data="UpdateSKU" onClick={this.handleGetSKUList} />
                    </ul>
                    <div className="tab-content">
                        <TabContentItem id="product_concept_colors"  style={{height:"400px",margin:'30px 0 10px'}} active={true}>
                             <ProductColors
                             addColor={this.state.addColor}  updateColor={this.state.updateColor}
                             onChange={this.handleOnChange}  translate={this.handleTranslate}
                             addItem={this.handleAddItem} updateItem={this.handleUpdate}
                             addKeyword = {this.handleAddKeyword} deleteKeyword={this.handleDeleteKeyword}
                             Activate={this.handleActivate} DeActivate={this.handleDeActivate}
                             />
                        </TabContentItem>
                        <TabContentItem id="product_concept_materials"  style={{height:"500px",margin:'30px 0 10px'}} >
                            <ProductMaterials  translate={this.handleTranslate}
                                addMaterial = {this.state.addMaterial} updateMaterial={this.state.updateMaterial}
                                onChange={this.handleOnChange} addItem={this.handleAddItem} updateItem={this.handleUpdate}
                                addKeyword = {this.handleAddKeyword} deleteKeyword={this.handleDeleteKeyword}
                                Activate={this.handleActivate} DeActivate={this.handleDeActivate}/>
                        </TabContentItem>
                        <TabContentItem id="product_concept_families"  style={{height:"450px",margin:'30px 0 10px'}} >
                            <ProductFamilies
                                addFamily={this.state.addFamily}  updateFamily={this.state.updateFamily}
                                onChange={this.handleOnChange}
                                addItem={this.handleAddItem} updateItem={this.handleUpdate}
                                Activate={this.handleActivate} DeActivate={this.handleDeActivate}
                                translate={this.handleTranslate}
                            />
                        </TabContentItem>
                        <TabContentItem id="product_concept_subfamilies" style={{height:"450px",margin:'30px 0 10px'}}  >
                           <ProductSubFamilies
                               addSubFamily={this.state.addSubFamily}  updateSubFamily={this.state.updateSubFamily}
                               onChange={this.handleOnChange}
                               addItem={this.handleAddItem} updateItem={this.handleUpdate}
                               Activate={this.handleActivate} DeActivate={this.handleDeActivate}
                               translate={this.handleTranslate} handleCategory = {this.handleCategory}
                           />
                        </TabContentItem>
                        <TabContentItem id="product_concept_designs" style={{height:"450px",margin:'30px 0 10px'}} >
                            <ProductDesigns
                                addDesign={this.state.addDesign}  updateDesign={this.state.updateDesign}
                                onChange={this.handleOnChange}
                                addItem={this.handleAddItem} updateItem={this.handleUpdate}
                                Activate={this.handleActivate} DeActivate={this.handleDeActivate}
                                translate={this.handleTranslate} translateWithField={this.handleTranslateWithField}
                                shipmentCalculation={this.handleShipmentCalculation}
                                generateSEOSection={this.handleGenerateSEOSection}
                                onUpdateModalClose={this.handleUpdateModalClose}
                            />
                        </TabContentItem>

                        <TabContentItem id="product_concept_add_skus" style={{margin:'10px 0 '}} >
                            <CreateNewSKU/>
                        </TabContentItem>
                        <TabContentItem id="product_concept_update_skus" style={{margin:'10px 0 '}} >
                           <UpdateSKU skuNumberList={this.state.skuNumberList}/>
                        </TabContentItem>
                        {/*<hr/>*/}
                    </div>
                </div>
            </div>
        )
    }
}
export default connect(null,actions)(ProductConcepts);