import React from 'react';
import ListItem from './shipping_matrix_list_item';
const shipping_matrix_list = (props)=>{
    return(
        <div>
            <table className={'table table-bordered'}>
                <thead>
                <tr>
                    <th>Carrier</th>
                    <th>Origin</th>
                    <th>Destination</th>
                    <th>ServiceCode</th>
                    <th>DisplayedName</th>
                    <th>Is Express</th>

                    <th>BrightPearlCode</th>
                    <th>BigCommerceCode</th>
                    <th>3PLCode</th>
                    <th>AmazonCode</th>
                    <th>EbayCode</th>
                    <th><button className={'btn-success'} type={'button'} data-toggle="modal" data-target="#newShippingMatrix">Add New</button></th>
                </tr>
                </thead>
                <tbody>
                {props.list.map((item,index)=><ListItem item={item} key={index} index={index} update={props.upDateShippingMatrix}
                                                        onChange={props.shippingMatrixOnchange}/>)}
                </tbody>
            </table>
        </div>
    )
};
export default shipping_matrix_list;