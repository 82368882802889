import React,{Component} from 'react';
import OrderSideBar from './OrderSideBar';
import ProductSideBar from './ProductSideBar';
import ChannelSideBar from './ChannelSideBar';
import BrightOrderSidebar from './BrightOrderSidebar';
import {connect} from "react-redux";
import './sideBar.css'
import * as actions from "../../actions/index";
import {Link} from "react-router-dom";


class MainSideBar extends Component{

    render(){
        const path = window.location.pathname;
        const newOrders = this.props.newOrders.length===undefined?0:this.props.newOrders.length;
        const submittedOrders = this.props.submittedOrders.length===undefined?0:this.props.submittedOrders.length;
        const allSKUs = this.props.allSKUs.length===undefined?0:this.props.allSKUs.length;
        return(
            <div className='ashlinMainSideBar'>
                <div className="wrapper">
                    <nav id="sidebar">
                        <div className="components">
                            <div className={'field'} >
                                <a href="#orderSubmenu" data-toggle="collapse" aria-expanded="false"
                                   className="dropdown-toggle ">Orders</a>
                                <div className="collapse " id="orderSubmenu">
                                    <OrderSideBar newOrders={newOrders} submittedOrders={submittedOrders}/>
                                </div>
                            </div>
                            {/*<div className={'field'}>*/}
                            {/*    <a href="#brightPearlOrderSubmenu" data-toggle="collapse" aria-expanded="false"*/}
                            {/*       className="dropdown-toggle">Bright Pearl Orders</a>*/}
                            {/*    <div className="collapse " id="brightPearlOrderSubmenu">*/}
                            {/*        <BrightOrderSidebar />*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <div className={'field'}>
                                <a href="#channelSubmenu" data-toggle="collapse" aria-expanded="false"
                                   className="dropdown-toggle">Channels</a>
                                <div className="collapse " id="channelSubmenu">
                                    <ChannelSideBar />
                                </div>
                            </div>
                            <div className={'field'}>
                                <a href="#productSubmenu" data-toggle="collapse" aria-expanded="false"
                                   className="dropdown-toggle">Product Manager</a>
                                <div className="collapse " id="productSubmenu">
                                    <ProductSideBar allSKUs={allSKUs}/>
                                </div>
                            </div>
                            <div className={'field'}>
                                <Link to='/proforma' className={`${path==="/proforma" ? "sidebar-selected" : ""}`} style={{padding:"0"}} >
                                    <span style={{fontSize:"35px"}}>Proforma Invoice</span>
                                </Link>
                            </div>
                            <div className={'field'}>
                                <Link to='/priceManager' className={`${path==="/priceManager" ? "sidebar-selected" : ""}`} style={{padding:"0"}} >
                                    <span style={{fontSize:"35px"}}>Price Manager</span>
                                </Link>
                            </div>
                            <div className={'field'}>
                                <Link to='/marketplacePriceManager' className={`${path==="/marketplacePriceManager" ? "sidebar-selected" : ""}`} style={{padding:"0"}} >
                                    <span style={{fontSize:"35px"}}>Marketplace Price</span>
                                </Link>
                            </div>
                        </div>
                    </nav>

                </div>
            </div>
        )
    }
}
const mapStateToProps=(state)=>{
    return {
        newOrders:state.newOrders,
        submittedOrders: state.submittedOrders,
        allSKUs: state.allSKUs
    }
};

export default connect(mapStateToProps,actions)(MainSideBar);