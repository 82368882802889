import React from 'react';
const company_info_list_item = ({item, index,  companyInfoOnChange , updateCompanyInfo })=>{
    return(
        <tr>
            <th><input type={'text'} onChange={(e)=>companyInfoOnChange(e,index)}  style={{width:'100%'}} name={'Name'} value={item.Name} /></th>
            <td><input type={'text'} onChange={(e)=>companyInfoOnChange(e,index)}  style={{width:'100%'}} name={'LogoURL'} value={item.LogoURL} /></td>
            <td><input type={'text'} onChange={(e)=>companyInfoOnChange(e,index)}  style={{width:'100%'}} name={'Address1'} value={item.Address1} /></td>
            <td><input type={'text'} onChange={(e)=>companyInfoOnChange(e,index)}  style={{width:'100%'}} name={'Address2'} value={item.Address2} /></td>
            <td><input type={'text'} onChange={(e)=>companyInfoOnChange(e,index)}  style={{width:'100%'}} name={'City'} value={item.City} /></td>
            <td><input type={'text'} onChange={(e)=>companyInfoOnChange(e,index)}  style={{width:'100%'}} name={'State'} value={item.State} /></td>
            <td><input type={'text'} onChange={(e)=>companyInfoOnChange(e,index)}  style={{width:'100%'}} name={'Country'} value={item.Country} /></td>
            <td><input type={'text'} onChange={(e)=>companyInfoOnChange(e,index)}  style={{width:'100%'}} name={'Zip'} value={item.Zip} /></td>

            <td><input type={'text'} onChange={(e)=>companyInfoOnChange(e,index)}  style={{width:'100%'}} name={'ContactName'} value={item.ContactName} /></td>
            <td><input type={'text'} onChange={(e)=>companyInfoOnChange(e,index)}  style={{width:'100%'}} name={'Phone'} value={item.Phone} /></td>
            <td><input type={'text'} onChange={(e)=>companyInfoOnChange(e,index)}  style={{width:'100%'}} name={'Email'} value={item.Email} /></td>
            <td><input type={'text'} onChange={(e)=>companyInfoOnChange(e,index)}  style={{width:'100%'}} name={'GST'} value={item.GST} /></td>
            <td><input type={'text'} onChange={(e)=>companyInfoOnChange(e,index)}  style={{width:'100%'}} name={'FEIN'} value={item.FEIN} /></td>
            <td>
                <button className={'btn-danger'} type={'button'} onClick={()=>updateCompanyInfo(item)}>Update</button>
            </td>
        </tr>
    );
};
export default company_info_list_item;