import { combineReducers } from 'redux';
import newOrderReducer from './newOrderReducer';
import submittedOrderReducer from './submittedOrderReducer'
import allSKUsReducer from './allSKUsReducer';
import authenticationReducer from './authentication';
import errorMessageReducer from './errorMessageReducer';
export default combineReducers({
    newOrders: newOrderReducer,
    submittedOrders:submittedOrderReducer,
    allSKUs:allSKUsReducer,
    authenticated:authenticationReducer,
    errorMessage: errorMessageReducer
})