import axios from "axios/index";
import momentTimeZone from "moment-timezone";
export const USING_PRODUCTION_SERVER = true;
export const PRODUCTION = true;
export const ENABLE_BRIGHTPEARL = true;
export const ENABLE_LEANSUPPLY = true;

export const ENABLE_KITTED_ITEM = false;

/****************************************************************************/
export const CANADA_POST_TEST_ENVIRONMENT = false;
export const CANPAR_TEST_ENVIRONMENT = false;
export const UPS_TEST_ENVIRONMENT = false;
export const CHIT_CHATS_TEST_ENVIRONMENT = false;
export const FEDEX_TEST_ENVIRONMENT = false;
export const PUROLATOR_TEST_ENVIRONMENT = false;
/*******************************************************************************/

/**********************  IT support emails *****************************/
export const ITSUPPORTEMAILS = ["archit.sandesara@consulero.com"];
/*************************************************************************/

/*******************************Google Apis**********************************/
export const GOOGLE_MAP_INFO_URL =
  "https://maps.googleapis.com/maps/api/geocode/json?address=";
export const GOOGLE_PROJECT_API_KEY =
  "&key=AIzaSyCjQzBFzg2sE90L1uQ1PHCIoD3JGfC0mKM";

export const proxyurl = USING_PRODUCTION_SERVER
  ? "http://ashlin-cors-proxy.canadacentral.cloudapp.azure.com/"
  : "http://ashlin-cors-proxy.canadacentral.cloudapp.azure.com/";

  //"https://powerful-bastion-86760.herokuapp.com/"

/*********************   Ashlin Request Start ******************************/

export const baseURL = USING_PRODUCTION_SERVER
  ? "https://websku20180529110046.azurewebsites.net/"
  : "http://localhost:51615/";

export const getNewOrderURL = baseURL + "api/lean-supply/orders/new";
export const submittedOrdersURL = baseURL + "api/lean-supply/orders/submitted";
export const getSKUURK = baseURL + "api/lean-supply/items";
export const loginURL = baseURL + "Account/LoginFrontEnd";
export const logoutURL = baseURL + "Account/LogoutFrontEnd";
export const getBPHeaderURL = baseURL + "api/bright-pearl";
const getLSHeaderURL = baseURL + "api/lean-supply";
export const inventoryURL = baseURL + "api/inventory-update";
/**********************  Ashlin Request End ******************************/

/************* Common Start *************/
export const sleep = (milliseconds) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};
export const getAuth = async () => {
  if (PRODUCTION) {
    const response = await axios.get(getLSHeaderURL);
    return {
      User: response.data.User,
      Pass: response.data.Pass,
      Key: response.data.Key,
      Account: response.data.Account,
    };
  } else {
    return {
      User: "ashlinbpg_test",
      Pass: "hhsh458834234232342",
      Key: "8jasdu234j2kjjdvnk345920930mei34j87n23m",
      Account: "Ashlin",
    };
  }
};

export const headers = {
  "Content-Type": "application/json"
};
/************* Common End *************/

/************* Item Start *************/
export const submitItemURL =
  "http://4pl.leansupplysolutions.com/portal/api/submitItem/";
export const changeItemURL =
  "http://4pl.leansupplysolutions.com/portal/api/changeItem/";
export const SubmitItemModel = (obj) => {
  const auth = JSON.parse(localStorage.getItem("auth"));
  return {
    Authorization: auth,
    Item: obj,
  };
};
/*************  Item End **************/

/************* Order Start ************/
export const submitOrderURL =
  "http://4pl.leansupplysolutions.com/portal/api/submitOrder/";
export const changeOrderURL =
  "http://4pl.leansupplysolutions.com/portal/api/changeOrder/";
export const getOrderStatusURL =
  "http://4pl.leansupplysolutions.com/portal/api/getStatus/";
export const cancelOrderURL =
  "http://4pl.leansupplysolutions.com/portal/api/cancelOrder/";
export const getBalanceURL =
  "http://4pl.leansupplysolutions.com/portal/api/getBalance/";
export const sendOrderToLeanSupply = async (data) => {
  const url = proxyurl + submitOrderURL;
  return await axios.post(url, data, { headers });
};
export const getBlanceByWarehouseCode = async (warehouseCode) => {
  console.log('Api Called')
  const url = proxyurl + getBalanceURL;
  const auth = JSON.parse(localStorage.getItem("auth"));
  const data = {
    Authorization: auth,
    ItemInquiry: {
      WarehouseCode: warehouseCode,
    },
  };
  try {
    const res = await axios.post(url, data, { headers });
    console.log(res);
    return res.data.BalanceList;
  } catch (error) {
    console.log(error)
  }
};
export const getTrackingNumber = async (data) => {
  try {
    const url = proxyurl + getOrderStatusURL;
    const res = await axios.post(url, data, { headers });
    if (res.data.CallResponse.ReturnValue.length > 0) {
      const data = res.data.CallResponse.ReturnValue.filter(
        (val) => val.OrderStatus !== "Canceled" && val.TrackingNumber !== ""
      )[0];
      return data.TrackingNo;
    } else {
      return res.data.CallResponse.ReturnValue.TrackingNo;
    }
  } catch (e) {
    return "";
  }
};
export const getOrderId = async (bpId) => {
  const auth = JSON.parse(localStorage.getItem("auth"));
  const data = {
    Authorization: auth,
    OrderHeader: {
      OrderHandle: `Ashlin-${bpId}`,
    },
  };
  try {
    const url = proxyurl + getOrderStatusURL;
    const res = await axios.post(url, data, { headers });
    return res.data.CallResponse.ReturnValue.OrderID;
  } catch (e) {
    return "";
  }
};
export const calculateAge = (stringDate) => {
  const age = new Date(Date.now() - Date.parse(stringDate));
  const day = age.getDate();
  const hour = age.getHours();
  return day > 0 ? `${day} d ${hour} h ago` : `${hour} h ago`;
};
export const formatDate = (stringDate) => {
  return stringDate.slice(0, 10);
};
export const SubmitOrderModel = (obj) => {
  const auth = JSON.parse(localStorage.getItem("auth"));
  return {
    Authorization: auth,
    OrderHeader: obj.OrderHeader,
    OrderDetail: obj.OrderDetail,
    OrderDelivery: obj.OrderDelivery,
  };
};
export const cancelOrderModel = (handler) => {
  const auth = JSON.parse(localStorage.getItem("auth"));
  return {
    Authorization: auth,
    OrderHeader: {
      OrderHandle: handler,
    },
  };
};
export const getActivedOrders = async () => {
  return (await axios.get(`${getLSHeaderURL}/orders/active`)).data;
};
/************* Order End **************/

export const localSave = (name, value) => {
  localStorage.setItem(name, JSON.stringify(value));
};
export const localGet = (name) => {
  return localStorage[name];
};

/*******************BP Start*************************/

/*******************BP End*************************/

/**************************Google Translate Start********************/
export const translateKeyURL = baseURL + "api/google-translate";
export const getGoogleTranslateKey = async () => {
  const response = await axios.get(translateKeyURL);
  return response.data;
};

/**************************Google Translate End*******************/
export const uploadImageURL = baseURL + "api/Image/UploadFiles";

export const b64toBlob = (b64Data, contentType, sliceSize) => {
  contentType = contentType || "";
  sliceSize = sliceSize || 512;

  let byteCharacters = atob(b64Data);
  let byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    let slice = byteCharacters.slice(offset, offset + sliceSize);

    let byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    let byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
};

export const currentDateTime = () => {
  const d = new Date();
  const myTimezone = "America/Toronto";
  const myDatetimeFormat = "YYYY-MM-DD HH:mm:ss";
  return momentTimeZone(d).tz(myTimezone).format(myDatetimeFormat);
};

export const base64ToNewTab = (encoded64, mimeType) => {
  const byteCharacters = atob(encoded64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: `${mimeType};base64` });
};
export const base64ToPDF = (encode64) => {
  const byteCharacters = atob(encode64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const file = new Blob([byteArray], { type: `application/pdf;base64` });
  return URL.createObjectURL(file);
};

export const postLabel = async (blobFile, fileName, folderName) => {
  if (blobFile !== undefined) {
    const data = new FormData();
    data.append("image", blobFile, fileName);
    data.append("folder", folderName);
    data.append(
      "vince",
      "8sHTj5LV527LqdWAQBZSDEXrohTSBx9FXyPnGBamXaj60vNCsDMpUTEsyqXDF4MSuDij4tEoZB7O1vdgVR1H8FLezeBgcRGndRb5MSd70mhYgO5d82wA1lIfPg95W3fXyQn7PVlcisOJFbjSMEOt6P6W2p5Xu4IMFodO5z94Hm25rQGr6iodKTGOwZruXtUX9zv7NFx9"
    );
    await axios.post("https://ashlinapp.xyz/api/labels-upload.php", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
};

export const postAveryLabel = async (blobFile, fileName) => {
  if (blobFile !== undefined) {
    const data = new FormData();
    data.append("image", blobFile, fileName);
    data.append(
      "vince",
      "8sHTj5LV527LqdWAQBZSDEXrohTSBx9FXyPnGBamXaj60vNCsDMpUTEsyqXDF4MSuDij4tEoZB7O1vdgVR1H8FLezeBgcRGndRb5MSd70mhYgO5d82wA1lIfPg95W3fXyQn7PVlcisOJFbjSMEOt6P6W2p5Xu4IMFodO5z94Hm25rQGr6iodKTGOwZruXtUX9zv7NFx9"
    );
    await axios.post("https://ashlinapp.xyz/api/avery-upload.php", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
};

export const postManifest = async (blobFile, fileName, folderName, carrier) => {
  if (blobFile !== undefined) {
    const data = new FormData();
    data.append("image", blobFile, fileName);
    data.append("folder", folderName);
    data.append("carrier", carrier);
    data.append(
      "vince",
      "8sHTj5LV527LqdWAQBZSDEXrohTSBx9FXyPnGBamXaj60vNCsDMpUTEsyqXDF4MSuDij4tEoZB7O1vdgVR1H8FLezeBgcRGndRb5MSd70mhYgO5d82wA1lIfPg95W3fXyQn7PVlcisOJFbjSMEOt6P6W2p5Xu4IMFodO5z94Hm25rQGr6iodKTGOwZruXtUX9zv7NFx9"
    );
    await axios.post("https://ashlinapp.xyz/api/manifest-upload.php", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
};

export const getGoogleMapInfo = async (address) => {
  let formattedAddress = address.replace(" ", "+");
  let googleMapInfo = await axios.get(
    GOOGLE_MAP_INFO_URL + formattedAddress + GOOGLE_PROJECT_API_KEY
  );
  return googleMapInfo;
};
