export const sageThemesObjects=[
    { SageThemesID: '0', SageThemes: '3D' },
    { SageThemesID: '1', SageThemes: "50's" },
    { SageThemesID: '2', SageThemes: "60's" },
    { SageThemesID: '3', SageThemes: "70's" },
    { SageThemesID: '4', SageThemes: "80's" },
    { SageThemesID: '5', SageThemes: "90's" },
    { SageThemesID: '6', SageThemes: 'Accessories' },
    { SageThemesID: '7', SageThemes: 'American' },
    { SageThemesID: '8', SageThemes: 'Amusement' },
    { SageThemesID: '9', SageThemes: 'Animal' },
    { SageThemesID: '10', SageThemes: 'Anniversary' },
    { SageThemesID: '11', SageThemes: 'Automotive' },
    { SageThemesID: '12', SageThemes: 'Aviation' },
    { SageThemesID: '13', SageThemes: 'Award' },
    { SageThemesID: '14', SageThemes: 'Awareness' },
    { SageThemesID: '15', SageThemes: 'Baby' },
    { SageThemesID: '16', SageThemes: 'Bag' },
    { SageThemesID: '17', SageThemes: 'Banking' },
    { SageThemesID: '18', SageThemes: 'Barware' },
    { SageThemesID: '19', SageThemes: 'Baseball' },
    { SageThemesID: '20', SageThemes: 'Basketball' },
    { SageThemesID: '21', SageThemes: 'Bathroom' },
    { SageThemesID: '22', SageThemes: 'Beach' },
    { SageThemesID: '23', SageThemes: 'Beauty' },
    { SageThemesID: '24', SageThemes: 'Beverage' },
    { SageThemesID: '25', SageThemes: 'Biking' },
    { SageThemesID: '26', SageThemes: 'Birthday' },
    { SageThemesID: '27', SageThemes: 'Black' },
    { SageThemesID: '28', SageThemes: 'Boating' },
    { SageThemesID: '29', SageThemes: 'Bowling' },
    { SageThemesID: '30', SageThemes: 'Boxing' },
    { SageThemesID: '31', SageThemes: 'Breast Cancer Awareness' },
    { SageThemesID: '32', SageThemes: 'Business' },
    { SageThemesID: '33', SageThemes: 'Camping' },
    { SageThemesID: '34', SageThemes: 'Carpentry' },
    { SageThemesID: '35', SageThemes: 'Celebration' },
    { SageThemesID: '36', SageThemes: 'Chanukah' },
    { SageThemesID: '37', SageThemes: 'Cheerleading' },
    { SageThemesID: '38', SageThemes: 'Children' },
    { SageThemesID: '39', SageThemes: 'Christmas' },
    { SageThemesID: '40', SageThemes: 'Church' },
    { SageThemesID: '41', SageThemes: 'Cinema' },
    { SageThemesID: '42', SageThemes: 'Cleaning' },
    { SageThemesID: '43', SageThemes: 'Clothing' },
    { SageThemesID: '44', SageThemes: 'Club' },
    { SageThemesID: '45', SageThemes: 'College' },
    { SageThemesID: '46', SageThemes: 'Communication' },
    { SageThemesID: '47', SageThemes: 'Computer' },
    { SageThemesID: '48', SageThemes: 'Concert' },
    { SageThemesID: '49', SageThemes: 'Construction' },
    { SageThemesID: '50', SageThemes: 'Convention' },
    { SageThemesID: '51', SageThemes: 'Cooking' },
    { SageThemesID: '52', SageThemes: 'Country' },
    { SageThemesID: '53', SageThemes: 'Cutting' },
    { SageThemesID: '54', SageThemes: 'Cycling' },
    { SageThemesID: '55', SageThemes: 'Dancing' },
    { SageThemesID: '56', SageThemes: 'Decoration' },
    { SageThemesID: '57', SageThemes: 'Dentist' },
    { SageThemesID: '58', SageThemes: 'Drinking' },
    { SageThemesID: '59', SageThemes: 'Earth Day' },
    { SageThemesID: '60', SageThemes: 'Easter' },
    { SageThemesID: '61', SageThemes: 'Eating' },
    { SageThemesID: '62', SageThemes: 'Emergency' },
    { SageThemesID: '63', SageThemes: 'Entertainment' },
    { SageThemesID: '64', SageThemes: 'Environmental' },
    { SageThemesID: '65', SageThemes: 'Executive' },
    { SageThemesID: '66', SageThemes: 'Exercise' },
    { SageThemesID: '67', SageThemes: 'Eyewear' },
    { SageThemesID: '68', SageThemes: 'Fall' },
    { SageThemesID: '69', SageThemes: 'Fashion' },
    { SageThemesID: '70', SageThemes: 'Fire' },
    { SageThemesID: '71', SageThemes: 'Fishing' },
    { SageThemesID: '72', SageThemes: 'Fitness' },
    { SageThemesID: '73', SageThemes: 'Food' },
    { SageThemesID: '74', SageThemes: 'Football' },
    { SageThemesID: '75', SageThemes: 'Footwear' },
    { SageThemesID: '76', SageThemes: 'Formal Event' },
    { SageThemesID: '77', SageThemes: 'Fun' },
    { SageThemesID: '78', SageThemes: 'Fundraising' },
    { SageThemesID: '79', SageThemes: 'Funeral' },
    { SageThemesID: '80', SageThemes: 'Gambling' },
    { SageThemesID: '81', SageThemes: 'Game' },
    { SageThemesID: '82', SageThemes: 'Games' },
    { SageThemesID: '83', SageThemes: 'Gardening' },
    { SageThemesID: '86', SageThemes: 'Graduation' },
    { SageThemesID: '87', SageThemes: 'Gymnastics' },
    { SageThemesID: '88', SageThemes: 'Halloween' },
    { SageThemesID: '89', SageThemes: 'Handyman' },
    { SageThemesID: '90', SageThemes: 'Headwear' },
    { SageThemesID: '91', SageThemes: 'Health' },
    { SageThemesID: '92', SageThemes: 'Hiking' },
    { SageThemesID: '93', SageThemes: 'Hockey' },
    { SageThemesID: '94', SageThemes: 'Holiday' },
    { SageThemesID: '95', SageThemes: 'Home' },
    { SageThemesID: '96', SageThemes: 'Hospital' },
    { SageThemesID: '97', SageThemes: 'Hotel' },
    { SageThemesID: '98', SageThemes: 'Household' },
    { SageThemesID: '99', SageThemes: 'Hunting' },
    { SageThemesID: '100', SageThemes: 'Hygiene' },
    { SageThemesID: '101', SageThemes: 'Jewelry' },
    { SageThemesID: '102', SageThemes: 'Jogging' },
    { SageThemesID: '103', SageThemes: 'July 4th' },
    { SageThemesID: '104', SageThemes: 'Kitchen' },
    { SageThemesID: '105', SageThemes: 'Lacrosse' },
    { SageThemesID: '106', SageThemes: 'Legal' },
    { SageThemesID: '107', SageThemes: 'Library' },
    { SageThemesID: '108', SageThemes: 'Lifeguard' },
    { SageThemesID: '109', SageThemes: 'Light' },
    { SageThemesID: '110', SageThemes: 'Magic' },
    { SageThemesID: '111', SageThemes: 'Mardi Gras' },
    { SageThemesID: '112', SageThemes: 'Martial Arts' },
    { SageThemesID: '113', SageThemes: 'Medical' },
    { SageThemesID: '114', SageThemes: 'Military' },
    { SageThemesID: '115', SageThemes: 'Money' },
    { SageThemesID: '116', SageThemes: 'Motel' },
    { SageThemesID: '117', SageThemes: "Mother's Day" },
    { SageThemesID: '118', SageThemes: 'Music' },
    { SageThemesID: '119', SageThemes: 'Nature' },
    { SageThemesID: '120', SageThemes: 'New Year' },
    { SageThemesID: '121', SageThemes: 'Newspaper' },
    { SageThemesID: '122', SageThemes: 'NonSmoking' },
    { SageThemesID: '123', SageThemes: 'Office' },
    { SageThemesID: '124', SageThemes: 'Oktoberfest' },
    { SageThemesID: '125', SageThemes: 'Olympic' },
    { SageThemesID: '126', SageThemes: 'Organization' },
    { SageThemesID: '127', SageThemes: 'Outdoor' },
    { SageThemesID: '128', SageThemes: 'Outside' },
    { SageThemesID: '129', SageThemes: 'Party' },
    { SageThemesID: '130', SageThemes: 'Patio' },
    { SageThemesID: '131', SageThemes: 'Patriotic' },
    { SageThemesID: '132', SageThemes: 'Pet' },
    { SageThemesID: '133', SageThemes: 'Pharmacy' },
    { SageThemesID: '134', SageThemes: 'Phone' },
    { SageThemesID: '135', SageThemes: 'Photography' },
    { SageThemesID: '136', SageThemes: 'Picnic' },
    { SageThemesID: '137', SageThemes: 'Plumbing' },
    { SageThemesID: '138', SageThemes: 'Police' },
    { SageThemesID: '139', SageThemes: 'Politics' },
    { SageThemesID: '140', SageThemes: 'Racing' },
    { SageThemesID: '141', SageThemes: 'Reading' },
    { SageThemesID: '142', SageThemes: 'Real Estate' },
    { SageThemesID: '143', SageThemes: 'Recycling' },
    { SageThemesID: '144', SageThemes: 'Religion' },
    { SageThemesID: '145', SageThemes: 'Resort' },
    { SageThemesID: '146', SageThemes: 'Restaurant' },
    { SageThemesID: '147', SageThemes: "Roaring 20's" },
    { SageThemesID: '148', SageThemes: 'Rugby' },
    { SageThemesID: '149', SageThemes: 'Running' },
    { SageThemesID: '150', SageThemes: 'Safety' },
    { SageThemesID: '151', SageThemes: 'School' },
    { SageThemesID: '152', SageThemes: 'Science' },
    { SageThemesID: '153', SageThemes: 'Security' },
    { SageThemesID: '154', SageThemes: 'Sewing' },
    { SageThemesID: '155', SageThemes: 'Shopping' },
    { SageThemesID: '156', SageThemes: 'Skating' },
    { SageThemesID: '157', SageThemes: 'Skiing' },
    { SageThemesID: '158', SageThemes: 'Smoking' },
    { SageThemesID: '159', SageThemes: 'Soccer' },
    { SageThemesID: '160', SageThemes: 'Softball' },
    { SageThemesID: '161', SageThemes: 'Spa' },
    { SageThemesID: '162', SageThemes: 'Spanish' },
    { SageThemesID: '84', SageThemes: 'Gift' },
    { SageThemesID: '85', SageThemes: 'Golf' },
    { SageThemesID: '163', SageThemes: 'Sport' },
    { SageThemesID: '164', SageThemes: 'Sports' },
    { SageThemesID: '165', SageThemes: 'Spring' },
    { SageThemesID: '166', SageThemes: "St. Patrick's Day" },
    { SageThemesID: '167', SageThemes: 'State' },
    { SageThemesID: '168', SageThemes: 'Summer' },
    { SageThemesID: '169', SageThemes: 'Sun' },
    { SageThemesID: '170', SageThemes: 'Swimming' },
    { SageThemesID: '171', SageThemes: 'Tailgating' },
    { SageThemesID: '172', SageThemes: 'Television' },
    { SageThemesID: '173', SageThemes: 'Tennis' },
    { SageThemesID: '174', SageThemes: 'Thanksgiving' },
    { SageThemesID: '175', SageThemes: 'Time' },
    { SageThemesID: '176', SageThemes: 'Towing' },
    { SageThemesID: '177', SageThemes: 'Trade Show' },
    { SageThemesID: '178', SageThemes: 'Transportation' },
    { SageThemesID: '179', SageThemes: 'Travel' },
    { SageThemesID: '180', SageThemes: 'Tropical' },
    { SageThemesID: '181', SageThemes: 'Vacation' },
    { SageThemesID: '182', SageThemes: "Valentine's Day" },
    { SageThemesID: '183', SageThemes: 'Vintage' },
    { SageThemesID: '184', SageThemes: 'Volleyball' },
    { SageThemesID: '185', SageThemes: 'Walking' },
    { SageThemesID: '186', SageThemes: 'Weather' },
    { SageThemesID: '187', SageThemes: 'Wedding' },
    { SageThemesID: '188', SageThemes: 'Western' },
    { SageThemesID: '189', SageThemes: 'Winter' },
    { SageThemesID: '190', SageThemes: 'Wrestling' },
    { SageThemesID: '14', SageThemes: 'Awards' },
    { SageThemesID: '34', SageThemes: 'Carnival' },
    { SageThemesID: '86', SageThemes: 'GiftDecoration' },
    { SageThemesID: '111', SageThemes: 'LightCollege' },
    { SageThemesID: '115', SageThemes: 'Masquerade' },
    { SageThemesID: '125', SageThemes: 'Non-Smoking' },
    { SageThemesID: '193', SageThemes: 'Writing' },
    { SageThemesID: '194', SageThemes: 'Writing Collegee' },
    { SageThemesID: '6', SageThemes: 'America' },
    { SageThemesID: '52', SageThemes: 'Cosntruction' },
    { SageThemesID: '13', SageThemes: 'Award ;Business ;Executive ;Office' },
    { SageThemesID: '30', SageThemes: 'Box' },
    { SageThemesID: '54', SageThemes: 'Cooling' },
    { SageThemesID: '61', SageThemes: 'DecorationGift' },
    { SageThemesID: '64', SageThemes: 'Drinkware' },
    { SageThemesID: '89', SageThemes: 'Garden' },
    { SageThemesID: '94', SageThemes: 'Grill' },
    { SageThemesID: '112', SageThemes: 'Kits-Tool' },
    { SageThemesID: '136', SageThemes: 'Organizations' },
    { SageThemesID: '189', SageThemes: 'Tradeshow' },
    { SageThemesID: '191', SageThemes: 'transportion' },
    { SageThemesID: '56', SageThemes: 'Decorations' },
    { SageThemesID: '59', SageThemes: 'Drinkning' },
    { SageThemesID: '129', SageThemes: 'Outdoors' },
    { SageThemesID: '138', SageThemes: 'Picture' },
    { SageThemesID: '175', SageThemes: 'Thank You' },
    { SageThemesID: '180', SageThemes: 'Trade Shown' }
];

export const sageThemes = sageThemesObjects.map(theme=>theme.SageThemes);
