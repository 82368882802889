import React from 'react';
import TextInputParallel from '../../bootstrapComponent/textInputParallel';
import SelectInput from '../../bootstrapComponent/selectInput';
const AddMaterialModal = ({id,materials,addMaterial,onChange, addItem, Activate, DeActivate,translate,ashlinMaterials})=>{
    const ashlinMaterialCode = ashlinMaterials.map(i=>i.materialCode);
    return(
        <div className="modal fade" id={id} tabIndex="-1" role="dialog" aria-labelledby="addMaterialModalLabel"
             aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h3 className="modal-title" id="addMaterialModalLabel" style={{textAlign:'center !important'}}>Add A Material</h3>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <TextInputParallel id='add_material_code' label={'Material Code'}
                                           value={addMaterial.materialCode} name='materialCode' onChange={(e)=>onChange(e,'addMaterial')}/>
                        <SelectInput  id={'add_asi_material'} label={'Associated ASI Materials'} name={'pickedMaterial'} width={'450px'}
                                      style={{fontWeight:'bold'}}     value={materials}    onChange={(e)=>onChange(e,'addMaterial')}/>
                        <TextInputParallel id='add_material_hts' label={'Material HTS Description'}
                                           value={addMaterial.materialHTSDescription} name='materialHTSDescription' onChange={(e)=>onChange(e,'addMaterial')}/>
                        <br/>
                        <div>
                            <div className={'row'} >
                                <div className={'col-2'}>
                                </div>
                                <div className={'col-4'}>
                                    <h5 style={{textAlign:'center'}}>ENGLISH</h5>
                                </div>
                                <div className={'col-2'}>
                                    <button className={'btn-info'} style={{fontSize:'15px !important'}} onClick={()=>{translate('addMaterial')}}>TRANSLATE</button>
                                </div>
                                <div className={'col-4'}>
                                    <h5 style={{textAlign:'center'}}>FRENCH</h5>
                                </div>
                            </div>
                            <div className={'row'} >
                                <div className={'col-2'}>
                                    <h5 style={{textAlign:'center'}}>Short Description</h5>
                                </div>
                                <div className={'col-4'}>
                                    <textarea style={{width:'100%'}} value={addMaterial.shortENG}
                                              onChange={(e)=>onChange(e,'addMaterial')} name={'shortENG'}>
                                    </textarea>
                                </div>
                                <div className={'col-2'}>
                                </div>
                                <div className={'col-4'}>
                                    <textarea style={{width:'100%'}} value={addMaterial.shortFR}
                                              onChange={(e)=>onChange(e,'addMaterial')} name={'shortFR'}>
                                    </textarea>
                                </div>
                            </div>
                            <div className={'row'} >
                                <div className={'col-2'}>
                                    <h5 style={{textAlign:'center'}}>Long Description</h5>
                                </div>
                                <div className={'col-4'}>
                                    <textarea style={{width:'100%'}} value={addMaterial.longENG} rows="5"
                                              onChange={(e)=>onChange(e,'addMaterial')} name={'longENG'}>
                                    </textarea>
                                </div>
                                <div className={'col-2'}>
                                </div>
                                <div className={'col-4'}>
                                    <textarea style={{width:'100%'}} value={addMaterial.longFR} rows="5"
                                              onChange={(e)=>onChange(e,'addMaterial')} name={'longFR'}>
                                    </textarea>
                                </div>
                            </div>
                            <div className={'row'} >
                                <div className={'col-2'}>
                                    <h5 style={{textAlign:'center'}}>Web Description</h5>
                                </div>
                                <div className={'col-4'}>
                                    <textarea style={{width:'100%'}} value={addMaterial.webENG} rows="5"
                                              onChange={(e)=>onChange(e,'addMaterial')} name={'webENG'}>
                                    </textarea>
                                </div>
                                <div className={'col-2'}>
                                </div>
                                <div className={'col-4'}>
                                    <textarea style={{width:'100%'}} value={addMaterial.webFR} rows="5"
                                              onChange={(e)=>onChange(e,'addMaterial')} name={'webFR'}>
                                    </textarea>
                                </div>
                            </div>

                        </div>
                        <br/>
                        <div className={'row'}>
                            <button type="button" className="btn btn-primary btn-block" onClick={(e)=>addItem(e,'addMaterial',ashlinMaterialCode)}>Add Material</button>
                        </div>
                        <br/>
                        {/*<br/><br/>*/}
                        {/*<div className={'row modal-footer'}>*/}
                            {/*<button type="button" className="btn btn-danger col" onClick={(e)=>DeActivate(e,'addMaterial','materialCode')} >Deactivate Material</button>*/}
                            {/*<button type="button" className="btn btn-success col" onClick={(e)=>Activate(e,'addMaterial','materialCode')}>Activate Material</button>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        </div>
    )
};
export default AddMaterialModal;