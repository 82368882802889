import React from 'react';
import $ from "jquery";

const updateBrightPearlListModal =({updatePriceList, skuList ,updateMode,handleOnModeChange,START,END,isUpdating})=>{

    return(<div className="modal fade" id="updateBrightPearlListModal" tabIndex="-1" role="dialog"
                aria-labelledby="updateBrightPearlListModal" aria-hidden="true" style={{backgroundColor:'floralwhite'}}>
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div className="modal-content" style={{backgroundColor:'floralwhite'}}>
                <div className="modal-header">
                    <h3 className="modal-title text-center" id="updateBrightPearlListModalTitle" style={{fontWeight:'bold'}}>Update Price List</h3>
                    <button type="button" className="close"
                            onClick={()=>{$('#updateBrightPearlListModal').modal('hide')}}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    {isUpdating &&<div className={"text-center text-success display-4"}> <i className="fa fa-spinner fa-spin"> </i> &nbsp; Updating Data...</div>}
                    <table className="table  table-striped  bpUpdatePriceTable col" style={{margin:'16px 0 0'}}>
                        <tbody style={{textAlign:'center'}}>
                        <tr>
                            <th>UPDATE MODE</th>
                            <td>
                                <select name={'updateMode'}  style={{width:'100%',height:'30px'}} onChange={(e)=>handleOnModeChange(e)} value={updateMode}>
                                    <option value="All Active">All Active</option>
                                    <option value="By Range">By Range</option>
                                </select>
                            </td>
                        </tr>
                        {updateMode === "By Range" &&
                            <tr>
                                <th>START</th>
                                <td>
                                    <select name={'START'}  style={{width:'100%',height:'30px'}}  onChange={(e)=>handleOnModeChange(e)} value={START}>
                                        {skuList.map((sku,i)=>{
                                            return <option value={sku.Sku} key={i}>{sku.Sku}</option>
                                        })}
                                    </select>
                                </td>
                            </tr>
                        }
                        {updateMode === "By Range" &&
                        <tr>
                            <th>END</th>
                            <td>
                                <select name={'END'}  style={{width:'100%',height:'30px'}} onChange={(e)=>handleOnModeChange(e)} value={END}>
                                    {skuList.map((sku,i)=>{
                                        return <option value={sku.Sku} key={i}>{sku.Sku}</option>
                                    })}
                                </select>
                            </td>
                        </tr>
                        }
                        </tbody>
                    </table>

                </div>
                <div className="modal-footer row" style={{width:'90%',margin:"0 auto"}}>
                    <div className={'col'}>
                        <button className={'btn btn-danger'} onClick={updatePriceList} >Update</button>
                    </div>
                </div>
            </div>
        </div>
    </div>)
};

export default updateBrightPearlListModal;