import React, { Component } from 'react';
import MainSideBar from '../../sideBar/MainSideBar';
import { getAmazonFBALocations, updateAmazonFBALocations ,createAmazonFBALocations } from '../../../Utils/ashlinDesign';
import Lists from './components/fba_location_list';
class UpdateFBALocation extends Component{
    _mounted = false;
    constructor() {
        super();
        this.state = {
            FBALocations:[],
            shownFBALocations:[],
            editingIndex:null,
            selectedArea:'All',
            addingNewFBA:false,

            Address1: "",
            Address2: "",
            City: "",
            CountryCode: "",
            LocationCode: "",
            Name: "",
            State: "",
            Zip: ""
        };
    }
    componentDidMount(){
        this._mounted = true;
        this.getFBALocations();
    }

    getFBALocations=async()=>{
        const FBALocations = await getAmazonFBALocations();
        this.setState({
            isLoading:false,
            FBALocations,
            shownFBALocations:FBALocations
        });
    };
    componentWillUnmount() {
        this._mounted = false;
    }
    handleOnchange=(e)=>{
        const name = e.target.name;
        const value = e.target.type === "checkbox"? e.target.checked: e.target.value;
        this.setState({
            [name]:value
        })
    };
    handleFBALocationOnchange=(e,index)=>{
        const name = e.target.name;
        const value =  e.target.value;
        const shownFBALocations= this.state.shownFBALocations;
        shownFBALocations[index][name] = value;
        this.setState({
            shownFBALocations
        })
    };
    enableFBAEditing=(index)=>{
        this.setState({
            editingIndex:index
        })
    };
    updateFBALocation = async(index) =>{
        const toUpdate = this.state.shownFBALocations[index];
        await updateAmazonFBALocations(toUpdate);
        this.setState({
            editingIndex:null
        })
    };
    cancelUpdate = ()=>{
        this.setState({
            editingIndex:null
        })
    };
    selectArea=(e)=>{
        const value =  e.target.value;
        let shownFBALocations;
        if(value==="All"){
            shownFBALocations = this.state.FBALocations;
        }else {
            shownFBALocations = this.state.FBALocations.filter(location=>location.CountryCode.toUpperCase().includes(value));
        }
        this.setState({
            shownFBALocations
        })
    };
    handleCreateFBALocation=async ()=>{
        const newFBA = {
            Address1: this.state.Address1,
            Address2:this.state.Address2,
            City: this.state.City,
            CountryCode: this.state.CountryCode,
            LocationCode: this.state.LocationCode,
            Name: this.state.Name,
            State: this.state.State,
            Zip: this.state.Zip
        };

        //console.log(newFBA);
        const createRes = await createAmazonFBALocations(newFBA);
        //console.log(createRes);
        alert("New FBA Location Created");
        const FBALocations = this.state.FBALocations;
        FBALocations.push(createRes.data);
        this.setState({
            Address1: "",
            Address2: "",
            City: "",
            CountryCode: "",
            LocationCode: "",
            Name: "",
            State: "",
            Zip: "",
            FBALocations,
            addingNewFBA:false
        })
    };
    render() {
        return (
            <div className='container-fluid row'>
                <div className={'col-3'}>
                    <MainSideBar/>
                </div>
                <div className={'col'}>
                    <br />
                    <h1 className={"text-center"}>FBA Locations</h1>
                    <hr/>
                    {!this.state.addingNewFBA &&
                        <div>
                            <div className={'row'}>
                                <div className={'col-6'}>
                                    <h3>Location Filter</h3>
                                    <select name={'selectedArea'}  style={{width:'100%'}} onChange={(e)=>this.selectArea(e)} >
                                        <option value="All" >All FBA Locations</option>
                                        <option value="CA" >Canada FBA Locations</option>
                                        <option value="US" >US FBA Locations</option>
                                    </select>
                                </div>
                                <div className={'col-6'}>
                                    <button className={'btn btn-success float-right'} type={'button'} onClick={()=>{
                                        this.setState({addingNewFBA:true})
                                    }}>Add New FBA Location</button>
                                </div>
                            </div>
                            <br/>
                            {
                                this.state.shownFBALocations.length!==0&&
                                <Lists list={this.state.shownFBALocations}
                                       editingIndex = {this.state.editingIndex}
                                       FBALocationOnchange={this.handleFBALocationOnchange}
                                       enableFBAEditing = {this.enableFBAEditing}
                                       updateFBALocation={this.updateFBALocation}
                                       cancelUpdate={this.cancelUpdate}
                                />
                            }
                        </div>
                    }
                    {this.state.addingNewFBA &&
                        <div>
                            <div className={'row'}>
                                <div className={'col-6'}>
                                    <button className={'btn  btn-block'} type={'button'} onClick={()=>{
                                        this.setState({addingNewFBA:false})
                                    }}>Go Back</button>
                                </div>
                                <div className={'col-6'}>
                                    <button className={'btn btn-success btn-block'} type={'button'} onClick={this.handleCreateFBALocation}>Create New FBA Location</button>
                                </div>
                            </div>
                            <table className={'table table-bordered table-dark'}  style={{marginBottom:'0'}}>
                                <thead>
                                    <tr>
                                        <th style={{textAlign:'center'}}>
                                         Location Name
                                        </th>
                                        <th colSpan={'3'}>
                                            <input value={this.state.Name} style={{width:'100%'}} name={'Name'}  onChange={this.handleOnchange} />
                                        </th>
                                        <th style={{textAlign:'center'}}>
                                            Location Code
                                        </th>
                                        <th>
                                            <input value={this.state.LocationCode} style={{width:'100%'}} name={'LocationCode'}  onChange={this.handleOnchange} />
                                        </th>
                                    </tr>
                                </thead>
                                <thead>
                                <tr>
                                    <th style={{textAlign:'right'}}>Address Line1</th>
                                    <td>
                                        <input value={this.state.Address1} style={{width:'100%'}}  name={'Address1'} onChange={this.handleOnchange}/>
                                    </td>
                                    <th style={{textAlign:'right'}}>Address Line2

                                    </th>
                                    <td>
                                        <input value={this.state.Address2}  style={{width:'100%'}}   name={'Address2'} onChange={this.handleOnchange}/>
                                    </td>
                                    <th style={{textAlign:'right'}}>PostCode

                                    </th>
                                    <td>
                                        <input value={this.state.Zip} style={{width:'100%'}}   name={'Zip'} onChange={this.handleOnchange}/>
                                    </td>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <th style={{textAlign:'right'}}>City

                                    </th>
                                    <td>
                                        <input value={this.state.City} style={{width:'100%'}}  name={'City'} onChange={this.handleOnchange}/>
                                    </td>
                                    <th style={{textAlign:'right'}}>State

                                    </th>
                                    <td>
                                        <input value={this.state.State} style={{width:'100%'}}  name={'State'} onChange={this.handleOnchange}/>
                                    </td>
                                    <th style={{textAlign:'right'}}>Country

                                    </th>
                                    <td>
                                        <input value={this.state.CountryCode} style={{width:'100%'}}   name={'CountryCode'}  onChange={this.handleOnchange}/>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            </div>
        );
    }
}
export default UpdateFBALocation;
