import axios from "axios/index";
import { postSkuChannelListing, priceList } from "./ashlinDesign";
import { currentDateTime, getBPHeaderURL, proxyurl } from "./requestHelper";

const getBPIdURL =
  proxyurl +
  "https://ws-use.brightpearl.com/2.0.0/ashlin/product-service/product-search?SKU=";
const getBPStockURL =
  proxyurl +
  "https://ws-use.brightpearl.com/2.0.0/ashlin/warehouse-service/product-availability/";

const brightPearlOrderURL =
  proxyurl +
  "https://ws-use.brightpearl.com/public-api/ashlin/order-service/order/";
const brightPearlWareHouseURL =
  proxyurl +
  "https://ws-use.brightpearl.com/public-api/ashlin/warehouse-service/order/";
const brightPearlProductURL =
  proxyurl +
  "https://ws-use.brightpearl.com/public-api/ashlin/product-service/product/";
const brightPearlProductCustomFieldURL =
  proxyurl +
  "https://ws-use.brightpearl.com/public-api/ashlin/product-service/product/";
const brightPearlProductPriceURL =
  proxyurl +
  "https://ws-use.brightpearl.com/public-api/ashlin/product-service/product-price/";
const brightWareHouseURL =
  proxyurl +
  "https://ws-use.brightpearl.com/public-api/ashlin/warehouse-service/warehouse/";
const brightContactURL =
  proxyurl + "https://ws-use.brightpearl.com/2.0.0/ashlin/contact-service";
////////     NEW /////////////////
const orderServiceURL =
  proxyurl + "https://ws-use.brightpearl.com/2.0.0/ashlin/order-service";
const accountingServiceURL =
  proxyurl + "https://ws-use.brightpearl.com/2.0.0/ashlin/accounting-service";
const wareHouseService =
  proxyurl +
  "https://ws-use.brightpearl.com/public-api/ashlin/warehouse-service";

export const checkOrderExpress = (orderRows) => {
  let isExpress = false;

  const conditions = ["Expedited", "Express", "Air"];

  orderRows.forEach((row) => {
    if (conditions.some((el) => row.productName.includes(el))) {
      isExpress = true;
    }
  });
  return isExpress;
};

export const createGoodsOutNote = async (id, note, headers) => {
  try {
    const res = await axios.post(
      `${brightPearlWareHouseURL}${id}/goods-note/goods-out`,
      note,
      headers
    );
    return res.data.response;
  } catch (e) {
    return "";
  }
};
export const searchGoodsOutNote = async (id, headers) => {
  try {
    const res = await axios.get(
      `${wareHouseService}/goods-note/goods-out-search?orderId=${id}`,
      headers
    );
    if (res.data.response.results.length !== 0) {
      return res.data.response.results[0][0];
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
};
export const updateGoodsOutNote = async (id, newNote, headers) => {
  try {
    const res = await axios.put(
      `${wareHouseService}/goods-note/goods-out/${id}`,
      newNote,
      headers
    );
    //console.log(res);
    return res.data;
  } catch (e) {
    return "";
  }
};
export const postShippedEvent = async (id, headers) => {
  //let d = new Date();
  let date = currentDateTime();
  date = date.replace(" ", "T");
  const shipped = {
    events: [
      {
        eventCode: "SHW",
        occured: date,
        eventOwnerId: 4,
      },
    ],
  };
  //console.log(shipped);
  try {
    const res = await axios.post(
      `${wareHouseService}/goods-note/goods-out/${id}/event`,
      shipped,
      headers
    );
    return res.data;
  } catch (e) {
    return null;
  }
};

export const getTaxCodes = async (headers) => {
  const res = await axios.get(`${accountingServiceURL}/tax-code`, headers);
  return res.data.response;
};
export const createPostalAddress = async (data, headers) => {
  try {
    const res = await axios.post(
      `${brightContactURL}/postal-address/`,
      data,
      headers
    );
    return res.data.response;
  } catch (e) {
    return "";
  }
};
export const createContact = async (data, headers) => {
  try {
    const res = await axios.post(`${brightContactURL}/contact/`, data, headers);
    return res.data.response;
  } catch (e) {
    return "";
  }
};
export const getBPheader = async () => {
  const response = await axios.get(getBPHeaderURL);
  //console.log(response);
  return {
    headers: {
      "brightpearl-account-token": response.data.token,
      "brightpearl-app-ref": response.data.app,
      /* 'brightpearl-account-token':'OYJfb9xasyGeZFD2KoCpjmhmqQ1/TzhBAr9Qdrz55W0=',
            'brightpearl-app-ref':'ashlin_bpg-1002'*/
    },
  };
};
export const createStockTransfer = async (
  WarehouseId,
  targetWarehouseId,
  headers
) => {
  const data = {
    targetWarehouseId: targetWarehouseId,
  };
  try {
    const res = await axios.post(
      `${brightWareHouseURL}${WarehouseId}/stock-transfer`,
      data,
      headers
    );
    return res.data.response;
  } catch (e) {
    return "";
  }
};
export const createExternalTransfer = async (
  sourceWarehouseId,
  data,
  headers
) => {
  try {
    const res = await axios.post(
      `${brightWareHouseURL}${sourceWarehouseId}/external-transfer`,
      data,
      headers
    );
    return res.data.response;
  } catch (e) {
    return "";
  }
};
export const getAllWarehouses = async (headers) => {
  try {
    const res = await axios.get(`${brightWareHouseURL}`, headers);
    return res.data.response;
  } catch (e) {
    return [];
  }
};
export const getUSCustomerFEINNumber = async (id) => {
  const headers = await getBPheader();
  try {
    const res = await axios.get(`${brightContactURL}/contact/${id}`, headers);
    return res.data.response[0].financialDetails.taxNumber;
  } catch (e) {
    return "";
  }
};
export const searchCustomer = async (condition, headers) => {
  try {
    const res = await axios.get(
      `${brightContactURL}/contact-search?${condition}`,
      headers
    );
    return res.data.response.results;
  } catch (e) {
    return null;
  }
};

export const getCustomerInfo = async (id, headers) => {
  try {
    const res = await axios.get(`${brightContactURL}/contact/${id}`, headers);
    return res.data.response[0];
  } catch (e) {
    return null;
  }
};
export const getPostalAddress = async (id, headers) => {
  try {
    const res = await axios.get(
      `${brightContactURL}/postal-address/${id}`,
      headers
    );
    return res.data.response[0];
  } catch (e) {
    return null;
  }
};

export const getInventoryById = async (Id) => {
  const headers = await getBPheader();
  const response = await axios.get(getBPStockURL + Id, headers);
  let inventory = 0;
  if (response.data.response[Id].warehouses[2] !== undefined) {
    inventory += response.data.response[Id].warehouses[2].onHand;
  }
  if (response.data.response[Id].warehouses[5] !== undefined) {
    inventory += response.data.response[Id].warehouses[5].onHand;
  }
  return inventory;
};
export const getRangeInventory = async (start, end) => {
  const headers = await getBPheader();
  const response = await axios.get(`${getBPStockURL}${start}-${end}`, headers);
  return response.data.response;
};
export const findInventoryFromInventoryList = (
  item,
  inventoryList,
  warehouseList,
  removeZero
) => {
  const stock = inventoryList[item.BrightPearlId];
  let inventory = 0;
  if (warehouseList.main) {
    inventory +=
      stock !== undefined &&
      stock.warehouses !== undefined &&
      stock.warehouses[2] !== undefined
        ? stock.warehouses[2].onHand
        : 0;
    item.main =
      stock !== undefined &&
      stock.warehouses !== undefined &&
      stock.warehouses[2] !== undefined
        ? stock.warehouses[2].onHand
        : 0;
  }
  if (warehouseList.leanSupply) {
    inventory +=
      stock !== undefined &&
      stock.warehouses !== undefined &&
      stock.warehouses[5] !== undefined
        ? stock.warehouses[5].onHand
        : 0;
    item.leanSupply =
      stock !== undefined &&
      stock.warehouses !== undefined &&
      stock.warehouses[5] !== undefined
        ? stock.warehouses[5].onHand
        : 0;
  }
  if (!removeZero && inventory === 0) {
    inventory = 1;
  }
  item.Inventory = inventory;
  return item;
};

export const getIdBySKU = async (SKU, headers) => {
  //const headers = await getBPheader();
  const response = await axios.get(getBPIdURL + SKU, headers);
  return response.data.response.results.length !== 0
    ? response.data.response.results[0][0]
    : "N/A";
};
export const getStockLevel = async (Id, warehouseNumber) => {
  try {
    const headers = await getBPheader();
    const response = await axios.get(getBPStockURL + Id, headers);
    return response.data.response[Id].warehouses[warehouseNumber] !== undefined
      ? response.data.response[Id].warehouses[warehouseNumber].onHand
      : 0;
  } catch (e) {
    return 0;
  }
};
export const getProductInventory = async (Id) => {
  const headers = await getBPheader();
  const response = await axios.get(getBPStockURL + Id, headers);
  return response.data.response[Id];
};
export const updateProductInventory = async (Id, data) => {
  const headers = await getBPheader();
  const res = await axios.post(
    `${brightWareHouseURL}${Id}/stock-correction`,
    data,
    headers
  );
  return res.data.response[0];
};
export const getProductByID = async (id) => {
  const headers = await getBPheader();
  const res = await axios.get(brightPearlProductURL + id, headers);
  return res.data.response[0];
};
export const getProductPriceListByProductID = async (id, headers) => {
  //const headers = await getBPheader();
  const res = await axios.get(brightPearlProductPriceURL + id, headers);
  return res.data.response[0].priceLists;
};
export const brightPearlPriceList = [
  {
    value: 1,
    key: "Cost-CAD",
  },
  {
    value: 3,
    key: "Retail-CAD",
  },
  {
    value: 2,
    key: "Wholesale-CAD",
  },
  {
    value: 9,
    key: "Retail-CODED-STD-BLANK-CAD",
  },
  {
    value: 12,
    key: "Retail-CODED-STD-IMP-CAD",
  },
  {
    value: 10,
    key: "Retail-CODED-RUSH-BLANK-CAD",
  },
  {
    value: 11,
    key: "Retail-CODED-RUSH-IMP-CAD",
  },
  {
    value: 6,
    key: "Distributor-NET-STD-BLANK-CAD",
  },
  {
    value: 5,
    key: "Distributor-NET-STD-IMP-CAD",
  },
  {
    value: 7,
    key: "Distributor-NET-RUSH-BLANK-CAD",
  },
  {
    value: 8,
    key: "Distributor-NET-RUSH-IMP-CAD",
  },
  {
    value: 19,
    key: "Retail-CODED-STD-BLANK-USD",
  },
  {
    value: 20,
    key: "Retail-CODED-STD-IMP-USD",
  },
  {
    value: 17,
    key: "Retail-CODED-RUSH-BLANK-USD",
  },
  {
    value: 18,
    key: "Retail-CODED-RUSH-IMP-USD",
  },
  {
    value: 15,
    key: "Distributor-NET-STD-BLANK-USD",
  },
  {
    value: 13,
    key: "Distributor-NET-STD-IMP-USD",
  },
  {
    value: 14,
    key: "Distributor-NET-RUSH-BLANK-USD",
  },
  {
    value: 16,
    key: "Distributor-NET-RUSH-IMP-USD",
  },
];

export const createAnBrightPearlOrder = async (order, headers) => {
  return await axios.post(brightPearlOrderURL, order, headers);
};
export const addRowItem = async (orderId, rowValue, headers) => {
  const url = `${brightPearlOrderURL}${orderId}/row`;
  return await axios.post(url, rowValue, headers);
};

export const BPAllocateProduct = async (
  orderId,
  warehouseId,
  reservationDetail
) => {
  const url = `${brightPearlWareHouseURL}${orderId}/reservation/warehouse/${warehouseId}`;
  const headers = await getBPheader();
  return await axios.post(url, reservationDetail, headers);
};
export const restoreAllocatedProduct = async (orderId) => {
  const url = `${brightPearlWareHouseURL}${orderId}/reservation`;
  const headers = await getBPheader();
  return await axios.delete(url, headers);
};

export const updateOrderStatus = async (
  orderID,
  statusID,
  message,
  headers
) => {
  const data = {
    orderStatusId: statusID,
    orderNote: {
      text: message,
      isPublic: false,
    },
  };
  return await axios.put(
    `${orderServiceURL}/order/${orderID}/status`,
    data,
    headers
  );
};
export const getOrderNotesByOrderID = async (id, headers) => {
  try {
    return (await axios.get(`${orderServiceURL}/order/${id}/note`, headers))
      .data.response;
  } catch (e) {
    return [];
  }
};
export const checkOrdersByStatusID = async (statusID, headers) => {
  try {
    const brightPearlOrders = (
      await axios.get(
        `${orderServiceURL}/order-search?orderStatusId=${statusID}`,
        headers
      )
    ).data.response.results;
    return brightPearlOrders.map((o) => {
      return {
        orderId: o[0],
        orderStatusId: o[3],
      };
    });
  } catch (e) {
    return [];
  }
};

export const updateDeliveryDate = async (orderId) => {
  const headers = await getBPheader();
  const url = brightPearlOrderURL + orderId;
  let d = new Date();
  d.setDate(d.getDate() + 30);
  const dayOfWeek = d.getDay();
  if (dayOfWeek === 0) {
    d.setDate(d.getDate() + 1);
  }
  if (dayOfWeek === 6) {
    d.setDate(d.getDate() + 2);
  }
  const date = d.toISOString().slice(0, 10);
  const data = [
    {
      op: "replace",
      path: "/delivery/deliveryDate",
      value: date,
    },
  ];
  return await axios.patch(url, data, headers);
};
export const updateShipmentMethodAndDeliveryDate = async (
  orderId,
  data,
  headers
) => {
  const url = brightPearlOrderURL + orderId;
  return await axios.patch(url, data, headers);
};

export const upDateStatusAndNote = async (orderId, trackingNumber) => {
  const headers = await getBPheader();
  const url = `${brightPearlOrderURL}${orderId}/status`;

  const message = `Tracking number :${trackingNumber}`;
  const data = {
    orderStatusId: 43,
    orderNote: {
      text: message,
      isPublic: true,
    },
  };

  return await axios.put(url, data, headers);
};

export const leanSupplyOrderToBrightPearlOrderMapper = (
  leanSupplyOrder,
  warehouseId
) => {
  let contactId, channelId, shippingMethodId, reference;
  switch (leanSupplyOrder.OrderHeader.MarketplaceCode) {
    case "Walmart Canada": {
      contactId = 3332;
      channelId = 6;
      shippingMethodId = 1;
      reference = `${leanSupplyOrder.OrderHeader.OrderNumber} - ${leanSupplyOrder.OrderHeader.ThirdPartyOrderNo}`;
      break;
    }
    case "Staples.ca": {
      contactId = 2991;
      channelId = 8;
      shippingMethodId = 42;
      reference = leanSupplyOrder.OrderHeader.ThirdPartyOrderNo;
      break;
    }
    case "Hudson's Bay Company": {
      contactId = 10871;
      channelId = 10;
      shippingMethodId = 1;
      reference = leanSupplyOrder.OrderHeader.ThirdPartyOrderNo;
      break;
    }
    case "StaplesAdvantage CAN": {
      contactId = 2992;
      channelId = 9;
      shippingMethodId = 42;
      reference = leanSupplyOrder.OrderHeader.ThirdPartyOrderNo;
      break;
    }
    case "Mirakl Best Buy Canada Marketplace": {
      contactId = 58672;
      channelId = 31;
      shippingMethodId = 22;
      reference = leanSupplyOrder.OrderHeader.ThirdPartyOrderNo;
      break;
    }
    case "Best Buy Canada": {
      contactId = 546;
      channelId = 7;
      shippingMethodId = 22;
      reference = leanSupplyOrder.OrderHeader.ThirdPartyOrderNo;
      break;
    }
    case "Walmart Marketplace": {
      contactId = 60230;
      channelId = 30;
      shippingMethodId = 22;
      reference = leanSupplyOrder.OrderHeader.ThirdPartyOrderNo;
      break;
    }
    default: {
    }
  }
  let d = new Date();
  d.setDate(d.getDate() + 30);
  const dayOfWeek = d.getDay();
  if (dayOfWeek === 0) {
    d.setDate(d.getDate() + 1);
  }
  if (dayOfWeek === 6) {
    d.setDate(d.getDate() + 2);
  }
  const date = d.toISOString().slice(0, 10);

  return {
    orderTypeCode: "SO",
    reference: reference,
    priceListId: 3,
    orderStatus: {
      orderStatusId: 43,
    },
    delivery: {
      shippingMethodId: shippingMethodId,
      deliveryDate: date,
    },
    currency: {
      orderCurrencyCode: "CAD",
    },
    parties: {
      customer: {
        contactId: contactId,
      },
      delivery: {
        addressFullName: leanSupplyOrder.OrderHeader.ShipTo.ContactName,
        companyName: leanSupplyOrder.OrderHeader.ShipTo.Name,
        addressLine1: leanSupplyOrder.OrderHeader.ShipTo.AddressLine1,
        addressLine2: leanSupplyOrder.OrderHeader.ShipTo.AddressLine2,
        addressLine3: leanSupplyOrder.OrderHeader.ShipTo.City,
        addressLine4: leanSupplyOrder.OrderHeader.ShipTo.State,
        postalCode: leanSupplyOrder.OrderHeader.ShipTo.Zip,
        countryIsoCode: leanSupplyOrder.OrderHeader.ShipTo.CountryCode,
        telephone: leanSupplyOrder.OrderHeader.ShipTo.ContactPhone,
        email: leanSupplyOrder.OrderHeader.ShipTo.ContactEmail,
      },
    },
    assignment: {
      current: {
        channelId: channelId,
      },
    },
    warehouseId: warehouseId,
  };
};
export const getBrightPearlReservationDetail = (rowId, rowValue) => {
  return {
    products: [
      {
        productId: rowValue.productId,
        salesOrderRowId: rowId,
        quantity: rowValue.quantity.magnitude,
      },
    ],
  };
};

export const ProvinceMapper = (province) => {
  switch (province) {
    case "Ontario":
      return "ON";
    case "Quebec":
      return "QC";
    case "Newfoundland and Labrador":
      return "NL";
    case "Prince Edward Island":
      return "PE";
    case "Nova Scotia":
      return "NS";
    case "New Brunswick":
      return "NB";
    case "Manitoba":
      return "MB";
    case "Saskatchewan":
      return "SK";
    case "Alberta":
      return "AB";
    case "British Columbia":
      return "BC";
    case "Yukon":
      return "YT";
    case "Northwest Territories":
      return "NT";
    case "Nunavut":
      return "NU";
    default:
      return province;
  }
};
export const getBrightPearlTaxCode = (province) => {
  switch (province) {
    case "NU":
      return "NV";
    case "QC":
      return "PQ";
    case "YN":
      return "YK";
    case "PE":
      return "PEI";
    case "MB":
      return "MAN";
    case "NL":
      return "NF";
    case "YT":
      return "YK";
    default:
      return province;
  }
};

export const getTaxRate = (taxCode) => {
  switch (taxCode) {
    case "AB":
      return 0.05;
    case "BC":
      return 0.05;
    case "MB":
      return 0.05;
    case "MAN":
      return 0.05;
    case "NV":
      return 0.05;
    case "PQ":
      return 0.05;
    case "QC":
      return 0.05;
    case "SK":
      return 0.05;
    case "YK":
      return 0.05;
    case "NT":
      return 0.05;
    case "ON":
      return 0.13;
    case "NB":
      return 0.15;
    case "NF":
      return 0.13;
    case "NL":
      return 0.13;
    case "PEI":
      return 0.14;
    case "NS":
      return 0.15;
    default:
      return 0.0;
  }
};
export const wait = (ms) => {
  const start = new Date().getTime();
  let end = start;
  while (end < start + ms) {
    end = new Date().getTime();
  }
};
const pricesMapper = (prices) => {
  return {
    "1": prices.QTY1,
    "6": prices.QTY6,
    "24": prices.QTY24,
    "50": prices.QTY50,
    "100": prices.QTY100,
    "250": prices.QTY250,
    "500": prices.QTY500,
    "1000": prices.QTY1000,
    "2500": prices.QTY2500,
  };
};

const priceListMapper = (priceList) => {
  return {
    priceLists: [
      {
        priceListId: 1,
        quantityPrice: pricesMapper(priceList.costPriceList),
      },
      {
        priceListId: 3,
        quantityPrice: {
          "1": priceList.Retail_CAD,
        },
      },
      {
        priceListId: 2,
        quantityPrice: {
          "1": priceList.Wholesale_CAD,
        },
      },
      {
        priceListId: 9,
        quantityPrice: pricesMapper(priceList.Retail_CODED_STD_BLANK_CAD),
      },
      {
        priceListId: 12,
        quantityPrice: pricesMapper(priceList.Retail_CODED_STD_IMP_CAD),
      },
      {
        priceListId: 10,
        quantityPrice: pricesMapper(priceList.Retail_CODED_RUSH_BLANK_CAD),
      },
      {
        priceListId: 11,
        quantityPrice: pricesMapper(priceList.Retail_CODED_RUSH_IMP_CAD),
      },
      {
        priceListId: 6,
        quantityPrice: pricesMapper(priceList.Distributor_NET_STD_BLANK_CAD),
      },
      {
        priceListId: 5,
        quantityPrice: pricesMapper(priceList.Distributor_NET_STD_IMP_CAD),
      },
      {
        priceListId: 7,
        quantityPrice: pricesMapper(priceList.Distributor_NET_RUSH_BLANK_CAD),
      },
      {
        priceListId: 8,
        quantityPrice: pricesMapper(priceList.Distributor_NET_RUSH_IMP_CAD),
      },
      {
        priceListId: 19,
        quantityPrice: pricesMapper(priceList.Retail_CODED_STD_BLANK_USD),
      },
      {
        priceListId: 20,
        quantityPrice: pricesMapper(priceList.Retail_CODED_STD_IMP_USD),
      },
      {
        priceListId: 17,
        quantityPrice: pricesMapper(priceList.Retail_CODED_RUSH_BLANK_USD),
      },
      {
        priceListId: 18,
        quantityPrice: pricesMapper(priceList.Retail_CODED_RUSH_IMP_USD),
      },
      {
        priceListId: 15,
        quantityPrice: pricesMapper(priceList.Distributor_NET_STD_BLANK_USD),
      },
      {
        priceListId: 13,
        quantityPrice: pricesMapper(priceList.Distributor_NET_STD_IMP_USD),
      },
      {
        priceListId: 14,
        quantityPrice: pricesMapper(priceList.Distributor_NET_RUSH_BLANK_USD),
      },
      {
        priceListId: 16,
        quantityPrice: pricesMapper(priceList.Distributor_NET_RUSH_IMP_USD),
      },
    ],
  };
};
export const addBrightPearlProduct = async (product) => {
  const headers = await getBPheader();
  const brandId = await searchBrand(product.Brand, headers);
  const categoryId = await searchCategory(
    product.DesignServiceFamilyDescription,
    headers
  );
  const data = brightPearlProductModel(product, brandId, categoryId);
  const createProductRes = await axios.post(
    brightPearlProductURL,
    data,
    headers
  );
  const productID = createProductRes.data.response;

  ///PATCH Custom Field
  const customFieldData = productCustomField(product);
  await axios.patch(
    `${brightPearlProductCustomFieldURL}${productID}/custom-field`,
    customFieldData,
    headers
  );

  ////////PUT PRICE
  const ashlinPriceList = await priceList(product);
  const brightPearlList = priceListMapper(ashlinPriceList);
  await upDatePriceList(productID, brightPearlList, headers);
  const skuChannelData = {
    MerchanSku: productID,
    Sku: product.Sku,
  };
  await postSkuChannelListing([skuChannelData], "BrightPearl");
  return productID;
};
export const updateProductPriceList = async (product, id, headers) => {
  const ashlinPriceList = await priceList(product);
  //console.log(ashlinPriceList);
  const brightPearlList = priceListMapper(ashlinPriceList);
  //console.log(brightPearlList);
  const res = await upDatePriceList(id, brightPearlList, headers);
  //console.log(res);
};

export const upDatePriceList = async (productID, priceList, headers) => {
  return await axios.put(
    `${brightPearlProductPriceURL}${productID}/price-list`,
    priceList,
    headers
  );
};
const productCustomField = (product) => {
  const node = product.Sku.split("-");
  const customField = [];
  if (node[2] !== undefined && node[2] !== "") {
    customField.push({
      op: "add",
      path: "/PCF_COLOURCO",
      value: node[2],
    });
  }
  if (
    product.Urls.HostingerUrls.MAIN[0] !== undefined &&
    product.Urls.HostingerUrls.MAIN[0] !== ""
  ) {
    customField.push({
      op: "add",
      path: "/PCF_IMAGEURL",
      value: product.Urls.HostingerUrls.MAIN[0],
    });
  }
  if (node[1] !== undefined && node[1] !== "") {
    customField.push({
      op: "add",
      path: "/PCF_MATERIAL",
      value: node[1],
    });
  }
  if (product.Name !== undefined && product.Name !== "") {
    customField.push({
      op: "add",
      path: "/PCF_DESIGNNA",
      value: product.Name,
    });
  }
  if (node[0] !== undefined && node[0] !== "") {
    customField.push({
      op: "add",
      path: "/PCF_DESIGNCO",
      value: node[0],
    });
  }
  if (product.DepthCm !== undefined && product.DepthCm !== "") {
    customField.push({
      op: "add",
      path: "/PCF_DEPTHCM",
      value: product.DepthCm,
    });
  }
  if (product.HeightCm !== undefined && product.HeightCm !== "") {
    customField.push({
      op: "add",
      path: "/PCF_HEIGHTC",
      value: product.HeightCm,
    });
  }
  if (product.ImprintHeightCm !== undefined && product.ImprintHeightCm !== "") {
    customField.push({
      op: "add",
      path: "/PCF_IMPRINTW",
      value: product.ImprintHeightCm,
    });
  }
  if (product.WidthCm !== undefined && product.WidthCm !== "") {
    customField.push({
      op: "add",
      path: "/PCF_WIDTHCM",
      value: product.WidthCm,
    });
  }
  if (product.Imprintable !== undefined && product.Imprintable !== "") {
    customField.push({
      op: "add",
      path: "/PCF_IMPRINTA",
      value: product.Imprintable,
    });
  }
  if (product.WeightGrams !== undefined && product.WeightGrams !== "") {
    customField.push({
      op: "add",
      path: "/PCF_ACTUALPR",
      value: product.WeightGrams,
    });
  }
  if (product.keyFeature1 !== undefined && product.keyFeature1 !== "") {
    customField.push({
      op: "add",
      path: "/PCF_OPTION1",
      value: product.keyFeature1,
    });
  }
  if (product.keyFeature2 !== undefined && product.keyFeature2 !== "") {
    customField.push({
      op: "add",
      path: "/PCF_OPTION2",
      value: product.keyFeature2,
    });
  }
  if (product.keyFeature3 !== undefined && product.keyFeature3 !== "") {
    customField.push({
      op: "add",
      path: "/PCF_OPTION3",
      value: product.keyFeature3,
    });
  }
  if (product.keyFeature4 !== undefined && product.keyFeature4 !== "") {
    customField.push({
      op: "add",
      path: "/PCF_OPTION4",
      value: product.keyFeature4,
    });
  }
  if (product.Material !== undefined && product.Material !== "") {
    customField.push({
      op: "add",
      path: "/PCF_MATERIAD",
      value: product.Material,
    });
  }
  if (product.Color !== undefined && product.Color !== "") {
    customField.push({
      op: "add",
      path: "/PCF_COLOURDE",
      value: product.Color,
    });
  }
  if (product.ImprintWidthCm !== undefined && product.ImprintWidthCm !== "") {
    customField.push({
      op: "add",
      path: "/PCF_IMPRINTH",
      value: product.ImprintWidthCm,
    });
  }
  customField.push({
    op: "add",
    path: "/PCF_PRODUCTT",
    value: product.DesignServiceFamilyDescription,
  });
  // customField.push({
  //     "op": "add",
  //     "path": "/PCF_RUNCHARG",
  //     "value": 0
  // });
  return customField;
};
const searchBrand = async (brandName, headers) => {
  const name = brandName.replace(/[^a-zA-Z0-9]/g, "");
  try {
    const res = await axios.get(
      proxyurl +
        `https://ws-use.brightpearl.com/2.0.0/ashlin/product-service/brand-search?brandName=${name}`,
      headers
    );
    const result = res.data.response.results.filter(
      (r) => r[1].toUpperCase() === name.toUpperCase()
    );
    return result[0][0];
  } catch (e) {
    alert(e);
  }
};
const searchProductType = async (type, headers) => {
  try {
    //https://{DATACENTER}.brightpearl.com/public-api/{ACCOUNT}/product-service/product-type-search
    const res = await axios.get(
      proxyurl +
        `https://ws-use.brightpearl.com/2.0.0/ashlin/product-service/product-type-search?name=${type}`,
      headers
    );
  } catch (e) {
    alert(e);
  }
};
export const searchSKU = async (sku, headers) => {
  try {
    const res = await axios.get(
      proxyurl +
        `https://ws-use.brightpearl.com/2.0.0/ashlin/product-service/product-search?SKU=${sku}`,
      headers
    );
    return res.data.response.results[0];
  } catch (e) {
    return null;
  }
};
const searchCategory = async (categoryName, headers) => {
  try {
    const res = await axios.get(
      proxyurl +
        `https://ws-use.brightpearl.com/2.0.0/ashlin/product-service/brightpearl-category-search?name=${categoryName}`,
      headers
    );
    return res.data.response.results[0][0];
  } catch (e) {
    alert(e);
  }
};
const brightPearlProductModel = (product, brandId, categoryId) => {
  return {
    brandId: brandId, //
    // "collectionId": 5,
    // "productTypeId": 1,
    identity: {
      sku: product.Sku,
      upc: product.Upc11,
      barcode: product.Upc11,
    },
    stock: {
      stockTracked: true,
      weight: {
        magnitude: product.WeightGrams, //g
      },
      dimensions: {
        width: product.WidthCm, //cm
        length: product.DepthCm, //cm
        height: product.HeightCm, //cm
      },
    },
    financialDetails: {
      taxable: false,
      // "taxCode": {
      //     "id": 7,
      //     "code": "T20"
      // }
    },
    salesChannels: [
      {
        salesChannelName: "Brightpearl",
        productName: product.Name,
        productCondition: "new",
        categories: [
          {
            categoryCode: categoryId,
          },
        ],
        description: {
          languageCode: "en",
          text: product.ExtendedDescription,
          format: "PLAINTEXT",
        },
        shortDescription: {
          languageCode: "en",
          text: product.ShortDescription,
          format: "PLAINTEXT",
        },
      },
    ],
    // "seasonIds": [
    //     1,
    //     2,
    //     3
    // ],
    productStatus: "LIVE",
  };
};

export const getOrderIDsByStatusID = async (statusID, headers) => {
  try {
    const res = await axios.get(
      `${orderServiceURL}/order-search?orderStatusId=${statusID}`,
      headers
    );
    const IDs = res.data.response.results.map((result) => result[0]);
    return IDs.join();
  } catch (e) {
    return "";
  }
};
//orderIDs should like 806302,806646,xxxxxx
export const getOrderDetailsByOrderIDList = async (orderIDs, headers) => {
  try {
    const res = await axios.get(
      `${orderServiceURL}/order/${orderIDs}`,
      headers
    );
    return res.data.response;
  } catch (e) {
    return [];
  }
};
export const getOrderDetailsByOrderID = async (orderID, headers) => {
  try {
    const res = await axios.get(`${orderServiceURL}/order/${orderID}`, headers);
    return res.data.response[0];
  } catch (e) {
    return null;
  }
};

export const getOrdersByStatusId = async (statusID) => {
  try {
    const headers = await getBPheader();
    const orderIDs = await getOrderIDsByStatusID(statusID, headers);
    if (orderIDs.length > 0) {
      return await getOrderDetailsByOrderIDList(orderIDs, headers);
    } else {
      return [];
    }
  } catch (e) {
    return [];
  }
};

export const shipMethod = [
  { value: 1, label: "Canada Post" },
  { value: 12, label: "Loomis" },
  { value: 6, label: "Canpar" },
  { value: 42, label: "UPS" },
  { value: 19, label: "PUROLATOR" },
];

export const shippimgMethodMapper = (delivery) => {
  let methodID;
  if (delivery.ServiceType === "CUSTOMERPICKUP") {
    methodID = 73;
  } else if (
    delivery.CarrierCode === "CANPAR" &&
    delivery.ServiceCode === "05"
  ) {
    methodID = 18;
  } else if (
    delivery.CarrierCode === "CANP" &&
    delivery.ServiceCode === "DOM.EP"
  ) {
    methodID = 22;
  } else if (
    delivery.CarrierCode === "CANP" &&
    delivery.ServiceCode === "DOM.XP"
  ) {
    methodID = 24;
  } else if (
    delivery.CarrierCode === "CANP" &&
    delivery.ServiceCode === "USA.EP"
  ) {
    methodID = 1;
  } else if (delivery.CarrierCode === "UPS" && delivery.ServiceCode === "11") {
    methodID = 7;
  } else if (delivery.CarrierCode === "UPS" && delivery.ServiceCode === "08") {
    methodID = 37;
  } else if (delivery.CarrierCode === "UPS" && delivery.ServiceCode === "03") {
    methodID = 8;
  } else if (
    delivery.CarrierCode === "FEDEX" &&
    delivery.ServiceCode === "FEDEX_GROUND"
  ) {
    methodID = 2;
  } else if (
    delivery.CarrierCode === "FEDEX" &&
    delivery.ServiceCode === "FEDEX_2DAY"
  ) {
    methodID = 26;
  } else if (
    delivery.CarrierCode === "FEDEX" &&
    delivery.ServiceCode === "FEDEX_OVERNITE"
  ) {
    methodID = 33;
  } else if (
    delivery.CarrierCode === "PUROLATOR" &&
    delivery.ServiceCode === "PRLA"
  ) {
    methodID = 19;
  } else if (
    delivery.CarrierCode === "PUROLATOR" &&
    delivery.ServiceCode === "PRLA_PM"
  ) {
    methodID = 67;
  } else {
    methodID = 6;
  }
  return methodID;
};

export const manualOrderCarrierMapping = (carrier, service, country) => {
  //console.log(carrier,service,country)
  let methodID;
  if (carrier === "Canada Post" && service === "Expedited Parcel") {
    methodID = 22;
  } else if (carrier === "Canada Post" && service === "Regular Parcel") {
    methodID = 1;
  } else if (carrier === "Canada Post" && service === "Xpresspost") {
    methodID = 24;
  } else if (carrier === "Canada Post" && service === "Priority") {
    methodID = 1;
  } else if (carrier === "Canpar" && service === "Ground") {
    methodID = 6;
  } else if (carrier === "Canpar" && service === "Select") {
    methodID = 18;
  } else if (carrier === "Canpar" && service === "OverNight") {
    methodID = 18;
  } else if (carrier === "UPS" && service === "02" && country === "CA") {
    methodID = 44;
  } else if (carrier === "UPS" && service === "13" && country === "CA") {
    methodID = 44;
  } else if (carrier === "UPS" && service === "12" && country === "CA") {
    methodID = 39;
  } else if (carrier === "UPS" && service === "01" && country === "CA") {
    methodID = 44;
  } else if (carrier === "UPS" && service === "11" && country === "CA") {
    methodID = 42;
  } else if (carrier === "UPS" && service === "02" && country === "US") {
    methodID = 37;
  } else if (carrier === "UPS" && service === "12" && country === "US") {
    methodID = 39;
  } else if (carrier === "UPS" && service === "03" && country === "US") {
    methodID = 42;
  } else if (carrier === "UPS" && service === "01" && country === "US") {
    methodID = 47;
  } else if (carrier === "FEDEX" && service === "FEDEX_GROUND") {
    methodID = 2;
  } else if (carrier === "FEDEX" && service === "FEDEX_2DAY") {
    methodID = 26;
  } else if (carrier === "FEDEX" && service === "FEDEX_OVERNITE") {
    methodID = 33;
  } else if (carrier === "PUROLATOR" && service === "PRLA") {
    methodID = 19;
  } else if (carrier === "PUROLATOR" && service === "PRLA_PM") {
    methodID = 67;
  } else if (carrier === "USPS") {
    methodID = 5;
  } else {
    methodID = 6;
  }
  return methodID;
};

export const channelsMapping = [
  { id: 2, name: "eBay: ashlinleather.com - ebay.ca Canada" },
  { id: 6, name: "Walmart" },
  { id: 7, name: "Best Buy" },
  { id: 8, name: "Staples" },
  { id: 9, name: "Staples-advantage" },
  { id: 10, name: "The Bay" },
  { id: 12, name: "AshlinLeather.com--AMAZON.AU AUSTRALIA" },
  { id: 13, name: "CORPORATE-USA" },
  { id: 14, name: "CORPORATE-CANADA" },
  { id: 16, name: "AshlinLeather.com--AMAZON.CA CANADA" },
  { id: 19, name: "eBay: ashlinleather.com - ebay.com USA" },
  { id: 25, name: "AshlinLeather.com-AMAZON.COM USA" },
  { id: 26, name: "AshlinLeather.com-AMAZON.CA - CANADA" },
  { id: 27, name: "AshlinLeather.com--AMAZON.COM USA" },
  { id: 29, name: "ASHLIN Leather - BIG COMMERCE" },
  { id: 30, name: "WALMART.CA Marketplace" },
  { id: 31, name: "BestBuy Marketplace" },
  { id: 32, name: "POPPYBUZZ - BIGCOMMERCE" },
  { id: 17, name: "ETSY.COM" },
  { id: 33, name: "AMAZON VENDOR CENTRAL" },
];

export const shippingMethods = [
  { value: 22, label: "Canada Post - Expedited Parcel" },
  { value: 6, label: "Canpar - GROUND" },
  { value: 2, label: "FEDEX-GROUND" },
  { value: 12, label: "Loomis Express - Basic Service" },
  { value: 19, label: "PUROLATOR - GROUND" },
  { value: 7, label: "UPS-GROUND" },

  { value: 24, label: "Canada Post - Xpresspost" },
  { value: 18, label: "Canpar - 2nd Day" },
  { value: 3, label: "FEDEX-EXPRESS" },
  { value: 13, label: "Loomis Express - Next Day" },
  { value: 67, label: "Purolator Express" },
  { value: 44, label: "UPS Express" },
  { value: 73, label: "CUSTOMER PICKUP" },

  { value: 1, label: "Canada Post - Regular Parcel" },
  { value: 23, label: "Canada Post - Expedited Parcel Signature Required" },
  { value: 54, label: "Canada Post - Lettermail" },
  { value: 25, label: "Canada Post - Xpresspost Signature Required" },
  { value: 26, label: "FedEx 2Day®" },
  { value: 27, label: "FedEx 2Day®, Signature Req" },
  { value: 28, label: "FedEx Economy®" },
  { value: 29, label: "FedEx Economy®, Signature Req" },
  { value: 30, label: "FedEx Home Delivery" },
  { value: 31, label: "FedEx Home Delivery, Signature Req" },
  { value: 59, label: "FedEx International Ground" },
  { value: 32, label: "FedEx Next Day (Standard), Signature Req" },
  { value: 33, label: "FedEx Priority Overnight®" },
  { value: 34, label: "FedEx Priority Overnight®, Saturday Delivery" },
  { value: 35, label: "FedEx Priority Overnight®, Signature Req" },
  { value: 36, label: "FedEx SmartPost" },
  { value: 4, label: "FEDEX-2 DAY ECONOMY" },
  { value: 66, label: "Purolator Courier, ground" },
  { value: 20, label: "Purolator Express 10:30AM" },
  { value: 21, label: "Purolator Express 10:30AM, Signature Req." },
  { value: 37, label: "UPS 2nd Day Air" },
  { value: 38, label: "UPS 2nd Day Air, Signature Required" },
  { value: 39, label: "UPS 3 Day Select" },
  { value: 40, label: "UPS 3 Day Select, Signature Required" },
  { value: 45, label: "UPS Express Saturday" },
  { value: 46, label: "UPS Express Saturday, Signature Required" },
  { value: 41, label: "UPS Express Saver Canada" },
  { value: 43, label: "UPS Ground, Signature Required" },
  { value: 47, label: "UPS Next Day Air, Signature Required" },
  { value: 48, label: "UPS SurePost" },
  { value: 8, label: "UPS-1DAY" },
  { value: 42, label: "UPS Ground" },
  { value: 10, label: "AIR CANADA CARGO" },
  { value: 9, label: "AIR CARGO" },
  { value: 49, label: "Alero Wordwide - Basic Service" },
  { value: 50, label: "AMJ Campbell - Delivery and Install" },
  { value: 51, label: "AMJ Campbell - Threshold Service" },
  { value: 52, label: "Apex Motor Express Ltd." },
  { value: 15, label: "ASL Distribution Services - Basic Service" },
  { value: 16, label: "ASL Distribution Services - Dedicated Service" },
  { value: 17, label: "ASL Distribution Services - Locker Dropoff Service" },
  { value: 53, label: "Barrie Direct Transportation - Basic Service" },
  { value: 11, label: "BRITISH AIRWAYS CARGO" },
  { value: 55, label: "CEVA Logistics - Delivery and Install" },
  { value: 56, label: "CEVA Logistics - Threshold Service" },
  { value: 57, label: "Day & Ross" },
  { value: 58, label: "Fastfrate" },
  { value: 60, label: "Gojit Inc" },
  { value: 61, label: "Home Direct USA - Basic Service" },
  { value: 62, label: "LTL (service unspecified)" },
  { value: 63, label: "Mackie Moving Systems" },
  { value: 64, label: "Maritime-Ontario Freight Lines" },
  { value: 65, label: "NLI International Corp. - Basic Service" },
  { value: 68, label: "Robert Transport" },
  { value: 14, label: "Sameday Worldwide - Expedited Ground" },
  { value: 69, label: "Specialized Transportation" },
  { value: 70, label: "Total eComm - Ground" },
  { value: 5, label: "USPS Parcel Select Ground" },
  { value: 71, label: "Vitran Canada" },
  { value: 72, label: "Western Express Inc" },
];
