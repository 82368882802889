import React from 'react';
import ImageDropZone from '../../../Utils/imageUpload/imageDropZone';
const AddImages = ({ mainImages,baseImages,modelImages,lifeImages,groupImages,
                       imageOnDrop,removeSelect})=>{
    return(

        <div style={{maxHeight:'600px',overflow: 'auto'}}>
            <div>
                <h3 className={'text-center text-secondary'}>Main Image- Thumbnail & First Image</h3>
                <div className={'row'}>
                {
                    mainImages.map((file,i)=> {
                    return (
                        <span key={i} className={'col-3'} style={{marginBottom:'20px'}}>
                        {(i !== mainImages.length-1 )&&
                            <button onClick={(e)=>removeSelect(e,i,'MAIN')}>X</button> }
                        {
                            (i === mainImages.length-1 )&&<button style={{visibility:'hidden'}}> X </button>
                        }
                        <ImageDropZone className={'col'} onDrop={(accepted) => imageOnDrop(accepted,i,'MAIN')}
                                       sizeDescription={""}
                                       file={file!==0?file:undefined}
                                       />

                        </span>
                    )
                    })
                }
                </div>
            </div>
            <hr/>
            <div>
                <h3 className={'text-center text-secondary'}>Base Image - Regular images, appear after the MAIN image</h3>
                <div className={'row'}>
                    {
                        baseImages.map((file,i)=> {
                            return (
                                <span key={i} className={'col-3'} style={{marginBottom:'20px'}}>
                        {(i !== baseImages.length-1 )&&
                        <button onClick={(e)=>removeSelect(e,i,'BASE')}>X</button> }
                                    {
                                        (i === baseImages.length-1 )&&<button style={{visibility:'hidden'}}> X </button>
                                    }
                                    <ImageDropZone className={'col'} onDrop={(accepted) => imageOnDrop(accepted,i,'BASE')}
                                                   sizeDescription={""}
                                                   file={file!==0?file:undefined}
                                    />

                        </span>
                            )
                        })
                    }
                </div>
            </div>
            <hr/>
            <div>
                <h3 className={'text-center text-secondary'}>Model Image - Image contains product and model</h3>
                <div className={'row'}>
                    {
                        modelImages.map((file,i)=> {
                            return (
                                <span key={i} className={'col-3'} style={{marginBottom:'20px'}}>
                        {(i !== modelImages.length-1 )&&
                        <button onClick={(e)=>removeSelect(e,i,'MODEL')}>X</button> }
                                    {
                                        (i === modelImages.length-1 )&&<button style={{visibility:'hidden'}}> X </button>
                                    }
                                    <ImageDropZone className={'col'} onDrop={(accepted) => imageOnDrop(accepted,i,'MODEL')}
                                                   sizeDescription={""}
                                                   file={file!==0?file:undefined}
                                    />

                        </span>
                            )
                        })
                    }
                </div>
            </div>
            <hr/>
            <div>
                <h3 className={'text-center text-secondary'}>Life Image - Image contains product and life elements</h3>
                <div className={'row'}>
                    {
                        lifeImages.map((file,i)=> {
                            return (
                                <span key={i} className={'col-3'} style={{marginBottom:'20px'}}>
                        {(i !== lifeImages.length-1 )&&
                        <button onClick={(e)=>removeSelect(e,i,'LIFE')}>X</button> }
                                    {
                                        (i === lifeImages.length-1 )&&<button style={{visibility:'hidden'}}> X </button>
                                    }
                                    <ImageDropZone className={'col'} onDrop={(accepted) => imageOnDrop(accepted,i,'LIFE')}
                                                   sizeDescription={""}
                                                   file={file!==0?file:undefined}
                                    />

                        </span>
                            )
                        })
                    }
                </div>
            </div>
            <hr/>
            <div>
                <h3 className={'text-center text-secondary'}>Group Image - Image contains products with the same design</h3>
                <div className={'row'}>
                    {
                        groupImages.map((file,i)=> {
                            return (
                                <span key={i} className={'col-3'} style={{marginBottom:'20px'}}>
                        {(i !== groupImages.length-1 )&&
                        <button onClick={(e)=>removeSelect(e,i,'GROUP')}>X</button> }
                                    {
                                        (i === groupImages.length-1 )&&<button style={{visibility:'hidden'}}> X </button>
                                    }
                                    <ImageDropZone className={'col'} onDrop={(accepted) => imageOnDrop(accepted,i,'GROUP')}
                                                   sizeDescription={""}
                                                   file={file!==0?file:undefined}
                                    />

                        </span>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
};

export default AddImages;