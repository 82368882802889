import React from 'react';
import TextInputParallel from '../../bootstrapComponent/textInputParallel';
import SelectInput from '../../bootstrapComponent/selectInput';
const AddFamiliesModal = ({id,addFamily,collections,onChange, addItem, Activate, DeActivate,translate,families})=>{
    const ashlinFamilyCode = families.map(i=>i.familyCode);
    return(
        <div className="modal fade" id={id} tabIndex="-1" role="dialog" aria-labelledby="addFamilyModalLabel"
             aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h3 className="modal-title" id="addFamilyModalLabel" style={{textAlign:'center !important'}}>Add A Family</h3>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <TextInputParallel id='add_family_code' label={'Product Family'}
                        value={addFamily.familyCode} name='familyCode' onChange={(e)=>onChange(e,'addFamily')}/>
                        <SelectInput  id={'add_collection'} label={'ASHLIN Collections'} name={'pickedCollection'} width={'450px'}
                        style={{fontWeight:'bold'}}     value={collections}    onChange={(e)=>onChange(e,'addFamily')}/>
                        <br/>
                        <div>
                        <div className={'row'} >
                        <div className={'col-2'}>
                        </div>
                        <div className={'col-4'}>
                        <h5 style={{textAlign:'center'}}>ENGLISH</h5>
                        </div>
                        <div className={'col-2'}>
                        <button className={'btn-info'} style={{fontSize:'15px !important'}} onClick={()=>translate('addFamily')}>TRANSLATE</button>
                        </div>
                        <div className={'col-4'}>
                        <h5 style={{textAlign:'center'}}>FRENCH</h5>
                        </div>
                        </div>
                        <div className={'row'} >
                        <div className={'col-2'}>
                        <h5 style={{textAlign:'center'}}>Short Description</h5>
                        </div>
                        <div className={'col-4'}>
                        <textarea style={{width:'100%'}} value={addFamily.shortENG}
                        onChange={(e)=>onChange(e,'addFamily')} name={'shortENG'}>
                        </textarea>
                        </div>
                        <div className={'col-2'}>
                        </div>
                        <div className={'col-4'}>
                        <textarea style={{width:'100%'}} value={addFamily.shortFR}
                        onChange={(e)=>onChange(e,'addFamily')} name={'shortFR'}>
                        </textarea>
                        </div>
                        </div>
                        <div className={'row'} >
                        <div className={'col-2'}>
                        <h5 style={{textAlign:'center'}}>Long Description</h5>
                        </div>
                        <div className={'col-4'}>
                        <textarea style={{width:'100%'}} value={addFamily.longENG} rows="5"
                        onChange={(e)=>onChange(e,'addFamily')} name={'longENG'}>
                        </textarea>
                        </div>
                        <div className={'col-2'}>
                        </div>
                        <div className={'col-4'}>
                        <textarea style={{width:'100%'}} value={addFamily.longFR} rows="5"
                        onChange={(e)=>onChange(e,'addFamily')} name={'longFR'}>
                        </textarea>
                        </div>
                        </div>
                        </div>
                        <br/>
                        <div className={'row'}>
                            <button type="button" className="btn btn-primary btn-block" onClick={(e)=>addItem(e,'addFamily',ashlinFamilyCode)}>Add Family</button>
                        </div>
                        <br/>
                        {/*<br/><br/>*/}
                        {/*<div className={'row modal-footer'}>*/}
                            {/*<button type="button" className="btn btn-danger col" onClick={(e)=>DeActivate(e,'addFamily','familyCode')} >Deactivate Family</button>*/}
                            {/*<button type="button" className="btn btn-success col" onClick={(e)=>Activate(e,'addFamily','familyCode')}>Activate Family</button>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        </div>
    )
};
export default AddFamiliesModal;