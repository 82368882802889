import React, { Component } from 'react';
import {priceList, getSkuDetail, postSkuChannelListing} from '../ashlinDesign';
import {
    getCredentials, getAuth, productModel, getAsiCategories, deleteProduct,
    getAsiProduct, getAsiProductTypes, postProduct, updateOldProduct, pricesDetail, generalPrices, postAsiProduct
} from '../asi';
import async from "async";
class AsiTest extends Component{

    constructor(){
        super();
        this.state={
            skuList:[




                'B9104-48',
                'B9103-18',
                '5728-49',

            ],
            headers : {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'AuthToken': 'eyJhbGciOiJSU0EtT0FFUCIsImVuYyI6IkEyNTZHQ00iLCJrZXlpZCI6IlZFTE8ifQ.C1tNHRCMY8MDVMJhDLCWdXNbmCZgf-Z2h3-VWHG10xmrlrv_dr3b6iUSiwl_G7_S0QM3g6h6UMNyawsBlZ88gEc4NbHNshvuuledZ9T_1hRMgg1b96gqL7NhZdkZGWEU-IL5ylhZSgVtxX4JLNgBjHFIBgO2wPzJ0KXO9pXxMLo.thMKxhIkCCmJlibK.u30Bhmo32srFsiGXOhsq8GlifRVKyykPusJN0gcuuu5yI7bV6LXoQC4ra6yA1AgiuHB1fDJVB5KaJNPo57Yh1Yp4S6nUIYQWT_0wcz-01yja809dbTjzjZt286oAklYNudIX35zh-GR5mZ6KHRdpvjRnaVX9BQIqUi9untmQ-3qqZXENraxuiSyt929VSEBe1FdUE64v08EsfjyvsfSXuWpzOYc_ekLg0SspkWn4_kGLlyK6I-7GK9i4uiqaZw2GjQkbIXg-8jwrrWwZbFAj6nSOYf04K2b8MauGFgtp1PZR3LsBjPsTC7zR7y_r9tH0e_-6jYuUbMRJ7rS9UQWgWBTSi-UstYnijO8i-T3RcufLVQGlXdXt5o0uYmCrafBUbzuryE_bfff24dNIMCZBCFB1W80c1j543jVhzoRI4Riqls9mBlA86eQ6U5AIC_8sHZG2Nx0NdNzXwq_bpENrxSYwzB4h7aSCSS_Rb9V8bzYVi19-df9gosNIBeEJgMI5q3kOhTo0vcIFMdHT.1O7-JiYpK4wy-QuugR5iEg' }
        }
    }
    handleGenerateToken = async()=>{
        const credentials = await getCredentials();
        //console.log(credentials);
        const token = await getAuth(credentials);
        if(token === "Authentication Error"){
            alert(token);
        }
    };
    handleGenerateNewProductObj = async ()=>{
        const ashlinSku =  {
            "Sku": "TAG88-18-01",
            "AsiCategory": "TAGS-GENERAL",
            "AsiMaterial": "LEATHER",
            "AsiIndustries": "Automotive; Corporate; Education; Finance; Healthcare; Non-Profit; Real Estate;",
            "AsiThemes": "ACADEMIC;HEALTH CARE;MEDICAL;PHARMACEUTICAL;TRADE SHOW",
            "SuperFamily": "Travel Bags",
            "Brand": "Ashlin®",
            "Name": "TENZING",
            "Upc": 626183004330,
            "Upc11": 62618300433,
            "Cost": "4.5",
            "CostPricingTier": "2",
            "Price": 20,
            "PricingTier": 2,
            "Components": 1,
            "MinOrder": "1",
            "ImprintedMinOrder": "N/A",
            "Status": "REGULAR ORDER on Web site",
            "Model": "TAG88",
            "Gender": "UNISEX",
            "Material": "Tuscany cowhide",
            "MaterialFr": "Peau de vache Toscane",
            "ColorFamily": "MEDIUM BLACK",
            "Color": "Black",
            "ColorFr": "Noir",
            "Style": "Envelope",
            "DepthCm": 0.35,
            "HeightCm": 9,
            "WidthCm": 25,
            "WeightGrams": 25,
            "ShippableWeightGrams": 20.4,
            "ShippableDepthCm": 0.42,
            "ShippableHeightCm": 9,
            "ShippableWidthCm": 9,
            "Imprintable": true,
            "ImprintHeightCm": 4,
            "ImprintWidthCm": 4,
            "Country": "India",
            "DesignServiceFamilyDescription": "Sports and Travel Accessories",
            "DesignServiceFamilyDescriptionFr": "Accessoires de sport et de voyage",
            "MetaKeywords": "Ashlin® | TENZING | Round luggage tag - 3.5 inch | Envelope",
            "keyFeature1": "Includes a covering flap for security when travelling.",
            "keyFeature2": "Suitable for golf tournaments or other similar events when a distinguished luggage tag is required.",
            "keyFeature3": "Adjustable strap with nickel finish.",
            "keyFeature4": "Designed in Toronto Canada.",
            "keyFeature5": "Elegantly appointed, yet functional.",
            "ShortDescription": "Round luggage tag - 3.5 inch",
            "ShortDescriptionFr": "Étiquette de bagage ronde - 3.5 pouces",
            "ExtendedDescription": "This elegantly crafted round luggage tag, is suitable for your GOLF tournament or golf cart. Can be used to identify your luggage or other belongings. Includes a covered ID section with a secured closure.",
            "ExtendedDescriptionFr": "Cette étiquette de bagage ronde au design élégant convient à votre tournoi de golf ou à votre voiturette de golf. Peut être utilisé pour identifier vos bagages ou autres biens. Comprend une section d'identification couverte avec une fermeture sécurisée.",
            "Urls": {
                "GoogleDriveUrls": {
                    "BASE": [
                        "https://drive.google.com/uc?export=download&id=1-zseR-10Bm_uztK3etCJb0dkELnAKETj",
                        "https://drive.google.com/uc?export=download&id=1Ky7lzsQSZGbj_PF5JCUh2_Ilzlb7sH-n",
                        "https://drive.google.com/uc?export=download&id=1svzd_Lh_P5R46p2O30bZjdAUbCR3EsSB",
                        "https://drive.google.com/uc?export=download&id=1TIj6opn7xYUQimB6PtbIV4xNhvE26mYj"
                    ],
                    "GROUP": [],
                    "TEMPLATEE": [],
                    "MODEL": [],
                    "LIFE": [],
                    "SMALL": [
                        "https://drive.google.com/uc?export=download&id=1LDL2r8tLPrCmaPUsjtPk_muCz6xWNRTX",
                        "https://drive.google.com/uc?export=download&id=17dfx32Ab5fw592YkEIA31gw7m7SZxNwO",
                        "https://drive.google.com/uc?export=download&id=1ZhVoq6vNQh_9cZPfyL8OUkvw0YxwSGAL",
                        "https://drive.google.com/uc?export=download&id=1tKGPphAdBNHu23pMNsgWwgWaauIGIehT"
                    ],
                    "MAIN": [
                        "https://drive.google.com/uc?export=download&id=1GlHK68w6ulhBNmopHCEH032ZDPFAoOg9"
                    ]
                },
                "HostingerUrls": {
                    "BASE": [
                        "https://ashlinapp.xyz/ProductMediaContent/TAG88/TAG88-18-01_BASE_1.jpg",
                        "https://ashlinapp.xyz/ProductMediaContent/TAG88/TAG88-18-01_BASE_4.jpg",
                        "https://ashlinapp.xyz/ProductMediaContent/TAG88/TAG88-18-01_BASE_3.jpg",
                        "https://ashlinapp.xyz/ProductMediaContent/TAG88/TAG88-18-01_BASE_2.jpg"
                    ],
                    "MAIN": [
                        "https://ashlinapp.xyz/ProductMediaContent/TAG88/TAG88-18-01_MAIN.jpg"
                    ]
                }
            }
        };

        const asiCategories = await getAsiCategories();
        const asiProductTypes = await getAsiProductTypes();


        // const credentials = await getCredentials();
        // const token = await getAuth(credentials);
        // const asiProduct = await getAsiProduct("B9104-18-02",token);

        const  prices = await  priceList(ashlinSku );

        const newProduct = productModel(ashlinSku, asiCategories, prices,asiProductTypes,null);
    };

    handleAsiCategories = async()=>{
       const res = await getAsiCategories();
       //console.log(res);
    };
    handlePostNewProduct=async()=>{
        const item =  {
            "Sku": "TAG88-18-08",
            "AsiCategory": "TAGS-GENERAL",
            "AsiMaterial": "LEATHER",
            "AsiIndustries": "Automotive; Corporate; Education; Finance; Healthcare; Non-Profit; Real Estate;",
            "AsiThemes": "ACADEMIC;HEALTH CARE;MEDICAL;PHARMACEUTICAL;TRADE SHOW",
            "SuperFamily": "Travel Bags",
            "Brand": "Ashlin®",
            "Name": "TENZING",
            "Upc": 626183004347,
            "Upc11": 62618300434,
            "Cost": "4.5",
            "CostPricingTier": "2",
            "Price": 20,
            "PricingTier": 2,
            "Components": 1,
            "MinOrder": "1",
            "ImprintedMinOrder": "N/A",
            "Status": "REGULAR ORDER on Web site",
            "Model": "TAG88",
            "Gender": "UNISEX",
            "Material": "Tuscany cowhide",
            "MaterialFr": "Peau de vache Toscane",
            "ColorFamily": "MEDIUM BROWN",
            "Color": "Brit.Tan",
            "ColorFr": "British Tan",
            "Style": "Envelope",
            "DepthCm": 0.35,
            "HeightCm": 9,
            "WidthCm": 25,
            "WeightGrams": 25,
            "ShippableWeightGrams": 20.4,
            "ShippableDepthCm": 0.42,
            "ShippableHeightCm": 9,
            "ShippableWidthCm": 9,
            "Imprintable": true,
            "ImprintHeightCm": 4,
            "ImprintWidthCm": 4,
            "Country": "India",
            "DesignServiceFamilyDescription": "Sports and Travel Accessories",
            "DesignServiceFamilyDescriptionFr": "Accessoires de sport et de voyage",
            "MetaKeywords": "Ashlin® | TENZING | Round luggage tag - 3.5 inch | Envelope",
            "keyFeature1": "Includes a covering flap for security when travelling.",
            "keyFeature2": "Suitable for golf tournaments or other similar events when a distinguished luggage tag is required.",
            "keyFeature3": "Adjustable strap with nickel finish.",
            "keyFeature4": "Designed in Toronto Canada.",
            "keyFeature5": "Elegantly appointed, yet functional.",
            "ShortDescription": "Round luggage tag - 3.5 inch",
            "ShortDescriptionFr": "Étiquette de bagage ronde - 3.5 pouces",
            "ExtendedDescription": "This elegantly crafted round luggage tag, is suitable for your GOLF tournament or golf cart. Can be used to identify your luggage or other belongings. Includes a covered ID section with a secured closure.",
            "ExtendedDescriptionFr": "Cette étiquette de bagage ronde au design élégant convient à votre tournoi de golf ou à votre voiturette de golf. Peut être utilisé pour identifier vos bagages ou autres biens. Comprend une section d'identification couverte avec une fermeture sécurisée.",
            "Urls": {
                "GoogleDriveUrls": {
                    "BASE": [
                        "https://drive.google.com/uc?export=download&id=1TnyNCJXMI3LrFrKePKv5i03tYZZVTxU2",
                        "https://drive.google.com/uc?export=download&id=1mYkjSI5068S_UBeoFLu9OgpwmFinsIhe",
                        "https://drive.google.com/uc?export=download&id=1yQdKXkGzRSdWQcGHtt5nkecp-pL9G8T2",
                        "https://drive.google.com/uc?export=download&id=1wu1SVAC3NVQf4FNGCF7rz3q17Z5UZKnZ"
                    ],
                    "GROUP": [],
                    "TEMPLATEE": [],
                    "MODEL": [],
                    "LIFE": [],
                    "SMALL": [
                        "https://drive.google.com/uc?export=download&id=1zY5ok8z7poSyB66keQLtAcfB6hhzI8p0",
                        "https://drive.google.com/uc?export=download&id=1FLOaELF1CIX5gwmhKxpNOFHHegpTRf-c",
                        "https://drive.google.com/uc?export=download&id=1L4n4xrcXAlnzZVeDj_itIJTxxU0grJaT",
                        "https://drive.google.com/uc?export=download&id=1Y9E0gL04CqIUZ0S065jv2TcgX3ktLzHI"
                    ],
                    "MAIN": []
                },
                "HostingerUrls": {
                    "BASE": [
                        "https://ashlinapp.xyz/ProductMediaContent/TAG88/TAG88-18-08_BASE_2.jpg",
                        "https://ashlinapp.xyz/ProductMediaContent/TAG88/TAG88-18-08_BASE_3.jpg",
                        "https://ashlinapp.xyz/ProductMediaContent/TAG88/TAG88-18-08_BASE_4.jpg",
                        "https://ashlinapp.xyz/ProductMediaContent/TAG88/TAG88-18-08_BASE_1.jpg"
                    ],
                    "MAIN": []
                }
            }
        }
        const IDs = item.Sku.split('-');
        const XID = `${IDs[0]}-${IDs[1]}`;
        const credentials = await getCredentials();

        const token = await getAuth(credentials);
        if(token === "Authentication Error"){
            alert(token);
        }else{
            const asiCategories = await getAsiCategories();
            const asiProductTypes = await getAsiProductTypes();
            const asiProduct = await getAsiProduct(XID,token);

            const res =await postProduct(item,asiProduct,asiCategories,asiProductTypes,token);
        }
    };
    updateProduct = async(sku,asiProductTypes,token,AsiCategories )=>{
        const ashlinProduct = await getSkuDetail(sku);

        try{
            const res =await updateOldProduct(asiProductTypes,token, ashlinProduct,AsiCategories);
            //console.log(res);
            if(res.status === 200){
                const res = await deleteProduct(sku,token);
                //console.log(res);
            }
        }catch (e) {
            alert(`Update ${sku} Failed`);
        }
    };
    handleUpdateProduct = async()=>{

        async.mapSeries(this.state.skuList,async (sku,callback)=>{
            const IDs = sku.split('-').map(id => id.trim());
            const xid = `${IDs[0]}-${IDs[1]}`;
            const asiProduct = await getAsiProduct(xid,this.state.headers.AuthToken);
            //console.log(asiProduct);

            const ashlinSKUDetail = await getSkuDetail(sku);
            const prices = await  priceList(ashlinSKUDetail);

            const details = pricesDetail(ashlinSKUDetail.BasePrice,prices)

            asiProduct.PriceGrids = [
                ...generalPrices,
                ...details
            ];
            asiProduct.MarketSegments = [
                "CANALL",
                "USAALL"
            ];
            //console.log(asiProduct);
            const res = await postAsiProduct(asiProduct,this.state.headers.AuthToken);
            //console.log(res);
            await setTimeout(()=>{
                return callback(null,sku);
            },1000);
        },async (err, results) => {
            console.log('results : ' + results);
        });
    };
    render(){
        return(
            <div>
                <h1 className={'text-center'}>ASI TESTING</h1>
                <br/>
                <br/>
                <button type={'button'} className={'btn btn-success'} onClick={this.handleGenerateToken}>Generate Token</button>
                <br/>
                <br/>
                <button type={'button'} className={'btn btn-success'} onClick={this.handleAsiCategories}>Get AsiCategories</button>
                <br/>
                <br/>
                <br/>
                <br/>
                <button type={'button'} className={'btn btn-success'} onClick={this.handleGenerateNewProductObj}>Generate New Product Object</button>
                <br/>
                <br/>
                <button type={'button'} className={'btn btn-success'} onClick={this.handlePostNewProduct}>Post New Product</button>



                <br/>
                <br/>
                <button type={'button'} className={'btn btn-danger'} onClick={this.handleUpdateProduct}>Update ASI Products</button>
            </div>
        )
    }
}

export default AsiTest;