import React, { Component } from 'react';
import { Bar } from 'react-chartjs-2';

class OrderLineChart extends  Component{

    render(){
        return(
          <div className="chart">
              <Bar
                  data={this.props.chartData}
                  width={200} height={50}
                  options={{
                      scales: {
                          yAxes: [{
                              ticks: {
                                  beginAtZero: true,
                                  callback: function(value) {if (value % 1 === 0) {return value;}}
                              }
                          }]
                      }
                  }}

              />
          </div>
        );
    }
}

export default OrderLineChart;