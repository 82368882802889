import axios from 'axios';
import { getCredential } from '../ashlinDesign';
import {CANADA_POST_TEST_ENVIRONMENT, proxyurl } from '../requestHelper'
import moment from 'moment';
const baseURL = CANADA_POST_TEST_ENVIRONMENT ? proxyurl + "https://ct.soa-gw.canadapost.ca" : proxyurl + "https://soa-gw.canadapost.ca";
export const getCanadaPostToken = async ()=>{
   if(CANADA_POST_TEST_ENVIRONMENT ){
       return "ZDM3NjExNzM2ZDY2MmFhZDplMDUxYjAzMjQ4M2Y0MzU4MDUzMjMz"
   }  else{
       const cre = await getCredential('canada-post');
       return window.btoa(`${cre.username}:${cre.password}`);
   }
};

export const generateCanadaPostGetRatesXML = (data)=>{
    const today = new Date().toJSON().slice(0,10);
    //console.log(data);
    let option;
    switch (data.Options){
        case 'Signature Required':
            option = 'SO';
            break;
        case 'Do not safe drop':
            option = "DNS";
            break;
        case "Leave at door":
            option = "LAD";
            break;
        default:
            option = data.Options;
    }
    let destination;
    if(data.ShipTo.CountryCode === "CA"){
        destination = `<destination><domestic><postal-code>${data.ShipTo.Zip}</postal-code></domestic></destination>`;

    }else if(data.ShipTo.CountryCode === "US"){
        destination = `<destination><united-states><zip-code>${data.ShipTo.Zip}</zip-code></united-states></destination>`;
    }
    else{
        destination = `<destination><international><country-code>${data.ShipTo.CountryCode}</country-code></international></destination>`;
    }
    let xml = `<?xml version="1.0" encoding="UTF-8"?><mailing-scenario xmlns="http://www.canadapost.ca/ws/ship/rate-v3">`;
        xml += `<customer-number>3950328</customer-number><contract-id>40033438</contract-id>`;
        xml += `<expected-mailing-date>${today}</expected-mailing-date>`;
        xml += `<options><option><option-code>DC</option-code></option>`;
        xml += option !== "No options"?`<option><option-code>${option}</option-code></option></options>`:`</options>`;
        xml += `<parcel-characteristics><weight>${data.PackageInfo.Weight}</weight>`;
        xml += `<dimensions><length>${data.PackageInfo.Length}</length><width>${data.PackageInfo.Width}</width><height>${data.PackageInfo.Height}</height></dimensions></parcel-characteristics>`;
        xml += `<origin-postal-code>${data.ShipFrom.Zip}</origin-postal-code>`;
        xml += destination;
        xml += `</mailing-scenario>`;

    return xml;
};

export const generateCanadaPostCreateShipment = (data)=>{
    const today = new Date().toJSON().slice(0,10);
    let option,Commodities,Options;
    switch (data.Options){
        case 'Signature Required':
            option = 'SO';
            break;
        case 'Do not safe drop':
            option = "DNS";
            break;
        case "Leave at door":
            option = "LAD";
            break;
        default:
            option = data.Options;
    }
    if(data.ShipTo.CountryCode!=="CA"){
        const list = data.PackageInfo.ItemDetails.map(item=>{
            return `<item>
                    <sku>${item.Name}</sku>
                    <customs-description>${item.Description}</customs-description>
                    <unit-weight>${(item.Weight/1000).toFixed(2)}</unit-weight>
                    <customs-value-per-unit>${item.Amount}</customs-value-per-unit>
                    <customs-number-of-units>1</customs-number-of-units>
                </item>`;
        });
        Commodities = `<customs>
                            <currency>CAD</currency>
                            <conversion-from-cad>0.85</conversion-from-cad>
                            <reason-for-export>OTH</reason-for-export>
                            <other-reason>Gift</other-reason>
                            <sku-list>
                                ${list}
                            </sku-list>
                        </customs>`;
        if(data.DeclareValueInsurance){
            let value = data.PackageInfo.TotalValue;
            value = value > 500? 500 : value;
            Options = `<options>
                          <option>
                            <option-code>RASE</option-code>
                          </option>
                          <option>
                            <option-code>COV</option-code>
                            <option-amount>${value}</option-amount>
                          </option>
                        </options>`;
        }else{
            Options = `<options>
                          <option>
                            <option-code>RTS</option-code>
                          </option>
                       </options>`;
        }

    }else{
        Commodities = "";
        if(data.DeclareValueInsurance){
            let value = data.PackageInfo.TotalValue;
            value = value > 500? 500 : value;
            Options = `<options>
                          <option>
                            <option-code>COV</option-code>
                            <option-amount>${value}</option-amount>
                          </option>
                        </options>`;
        }else{
            Options = "";
        }
    }


    // switch ( data.Service){
    //     case 'Expedited Parcel':
    //         service = 'DOM.EP';
    //         break;
    //     case 'Xpresspost':
    //         service = 'DOM.XP';
    //         break;
    //     case 'Priority':
    //         service = 'DOM.PC';
    //         break;
    //     default:
    //         service = 'DOM.RP';
    // }
    const groupId = `${today}-${data.GroupType}`;
    let xml = `<shipment xmlns="http://www.canadapost.ca/ws/shipment-v8">`;
        xml += `<group-id>${groupId}</group-id>`;
        xml += `<cpc-pickup-indicator>true</cpc-pickup-indicator>`;
        xml += `<requested-shipping-point>L5J4S7</requested-shipping-point>`;
        xml += `<expected-mailing-date>${today}</expected-mailing-date>`;
        xml += `<delivery-spec>`;
        xml +=`<service-code>${data.Service}</service-code>`;

        xml += `<sender>
                     <name>${data.ShipFrom.ContactName}</name>
                     <company>${data.ShipFrom.Name}</company>
                     <contact-phone>${data.ShipFrom.ContactPhone}</contact-phone>
                     <address-details>
                        <address-line-1>${data.ShipFrom.AddressLine1}</address-line-1>
                        <address-line-2>${data.ShipFrom.AddressLine2}</address-line-2>
                        <city>${data.ShipFrom.City}</city>
                        <prov-state>${data.ShipFrom.State}</prov-state>
                        <country-code>${data.ShipFrom.CountryCode}</country-code>
                        <postal-zip-code>${data.ShipFrom.Zip}</postal-zip-code>
                     </address-details>
	            </sender>`;
        xml +=`<destination>
                     <name>${data.ShipTo.ContactName}</name>
                     <company>${data.ShipTo.Name}</company>
                     <client-voice-number>${data.ShipTo.ContactPhone}</client-voice-number>
                     <address-details>
                        <address-line-1>${data.ShipTo.AddressLine1}</address-line-1>
                        <address-line-2>${data.ShipTo.AddressLine2}</address-line-2>
                        <city>${data.ShipTo.City}</city>
                        <prov-state>${data.ShipTo.State}</prov-state>
                        <country-code>${data.ShipTo.CountryCode}</country-code>
                        <postal-zip-code>${data.ShipTo.Zip}</postal-zip-code>
                     </address-details>
                </destination>`;
        xml +=`${Options}`;
        xml += `<print-preferences>
			        <output-format>4x6</output-format>
		        </print-preferences>`;
        xml += `<parcel-characteristics>
                 <weight>${data.PackageInfo.Weight}</weight>
                 <dimensions>
                    <length>${data.PackageInfo.Length}</length>
                    <width>${data.PackageInfo.Width}</width>
                    <height>${data.PackageInfo.Height}</height>
                 </dimensions>
              </parcel-characteristics>`;
        xml += `<preferences>
                <show-packing-instructions>true</show-packing-instructions>
              </preferences>`;
        xml += `<references>
                 <cost-centre>${data.Order.id}</cost-centre>
                 <customer-ref-1>${data.Order.reference.substring(0, 35)}</customer-ref-1>
	        </references>`;

        xml += `${Commodities}`;
        xml += `<settlement-info>
			        <contract-id>40033438</contract-id>
			        <intended-method-of-payment>Account</intended-method-of-payment>
		        </settlement-info>`;

        xml += `</delivery-spec></shipment>`;

    return xml
};

const createCanadaPostShipment = async (xml,token)=>{
    const headers = {
        "accept": "application/vnd.cpc.shipment-v8+xml",
        "content-type": "application/vnd.cpc.shipment-v8+xml",
        "accept-language": "en-CA",
        "authorization": `Basic ${token}`,
    };
    const res = (await axios.post(`${baseURL}/rs/3950328/3950328/shipment`,xml,{headers})).data;
    const document =  new DOMParser().parseFromString(res, 'application/xml');
    return {
        shipmentId : document.getElementsByTagName('shipment-id')[0].textContent,
        trackingNum : document.getElementsByTagName('tracking-pin')[0].textContent,
        self: document.querySelectorAll("link[rel='self']")[0].attributes['href'].nodeValue,
        details: document.querySelectorAll("link[rel='details']")[0].attributes['href'].nodeValue,
        group: document.querySelectorAll("link[rel='group']")[0].attributes['href'].nodeValue,
        price: document.querySelectorAll("link[rel='price']")[0].attributes['href'].nodeValue,
        labelURL: document.querySelectorAll("link[rel='label']")[0].attributes['href'].nodeValue,
    }
};


const postCanadaPostTransmitShipments = async(groupId,token)=>{
    //console.log(groupType)
   // const today = new Date().toJSON().slice(0,10);
    const headers = {
        "accept": "application/vnd.cpc.manifest-v8+xml",
        "content-type": "application/vnd.cpc.manifest-v8+xml",
        "accept-language": "en-CA",
        "authorization": `Basic ${token}`,
    };

   // const groupId = `${today}-${groupType}`;
    const xml = `<transmit-set xmlns="http://www.canadapost.ca/ws/manifest-v8">
                    <group-ids>
                        <group-id>${groupId}</group-id>
                    </group-ids>
                    <cpc-pickup-indicator>true</cpc-pickup-indicator>
                    <requested-shipping-point>L5J4S7</requested-shipping-point>
                    <manifest-address>
                        <manifest-name>Shipping Department</manifest-name>
                        <manifest-company>Ashlin BPG Marketing Inc.</manifest-company>
                        <phone-number>905-855-3027</phone-number>
                        <address-details>
                                <address-line-1>2351 Royal Windsor Dr</address-line-1>
                                <address-line-2>Unit #10</address-line-2>
                                <city>Mississauga</city>
                                <prov-state>ON</prov-state>
                                <!--<country-code>CA</country-code>-->
                                <postal-zip-code>L5J4S7</postal-zip-code>
                            </address-details>
                    </manifest-address>
                    <detailed-manifests>true</detailed-manifests>
                    <method-of-payment>Account</method-of-payment>
                </transmit-set>`;

    try{
        const res = (await axios.post(`${baseURL}/rs/3950328/3950328/manifest`,xml,{headers})).data;
        const document =  new DOMParser().parseFromString(res, 'application/xml');
        return Array.from(document.querySelectorAll("link[rel='manifest']")).map(x => x.attributes['href'].nodeValue)
    }catch (e) {
        console.log('Create Manifest error!')
        return null;
    }

};

const getCanadaPostManifest = async(manifestURLs, token)=>{
    var artifactUrls = [];
    const headers = {
        "accept": "application/vnd.cpc.manifest-v8+xml",
        "accept-language": "en-CA",
        "authorization": `Basic ${token}`,
    };
    try{
     await Promise.all( manifestURLs.map(async url =>{
            const res = (await axios.get(proxyurl+url,{headers})).data;
            const document =  new DOMParser().parseFromString(res, 'application/xml');
            artifactUrls.push(document.querySelectorAll("link[rel='artifact']")[0].attributes['href'].nodeValue);
        }))
      //  console.log(artifactUrls);
       return artifactUrls;
    }catch (e) {
        return 'Get Manifest error!'
    }
};

export const createCanadaPostContactShipment = async(data,token)=> {

    const shipmentReq = generateCanadaPostCreateShipment(data);
    try{
        const shipmentRes = await createCanadaPostShipment(shipmentReq, token);
        const labelDetail = {
            self:shipmentRes.self,
            details:shipmentRes.details,
            group: shipmentRes.group,
            price: shipmentRes.price,
            labelURL: shipmentRes.labelURL
        };
        return {
            "shipmentId": shipmentRes.shipmentId,
            "tracking": shipmentRes.trackingNum,
            "label":JSON.stringify(labelDetail)
        };
    }catch (e) {
        return {
            "shipmentId": undefined,
            "error" : e
        };
    }

};

export const voidCanadaPostShipment = async(voidURL,token)=>{
    const headers = {
        "accept": "application/vnd.cpc.shipment-v8+xml",
        "content-type": "application/vnd.cpc.shipment-v8+xml",
        "accept-language": "en-CA",
        "authorization": `Basic ${token}`,
    };
    try{
        await axios.delete(proxyurl+voidURL,{headers});
        return "SUCCESS"
    }catch (e) {
        return "Can't void label";
    }

};

export const postCanadaPostEndOfDay = async(date, warehouse ,token)=>{
    const groupId= moment(date).format("YYYY-MM-DD")+"-"+warehouse
    var manifest = await postCanadaPostTransmitShipments(groupId,token);

   // await sleep(2000);
    // if(manifest==='Create Manifest error!'){
    //   const startDate=moment(testDate).format("YYYYMMDD");
    //   manifest =  await getCPManifestLinkBetweenDateRange(startDate,startDate,token);  
    // }
   
    if(manifest===null || manifest.length===0){
        return null;
    }
    
    return await getCanadaPostManifest(manifest, token);
};

export const getCPManifestLinkBetweenDateRange = async(startDate,endDate, token)=>{
  // console.log("getCanadaPostManifestFromArtifact called. ")
    const headers = {
        "accept": "application/vnd.cpc.manifest-v8+xml",
        "accept-language": "en-CA",
        "authorization": `Basic ${token}`,
    };
   
    try{
        const res = (await axios.get(`${baseURL}/rs/3950328/3950328/manifest?start=${startDate}&end=${endDate}`,{headers})).data;
        const document =  new DOMParser().parseFromString(res, 'application/xml');
      //  console.log(Array.from(document.querySelectorAll("link[rel='manifest']")).map(x => x.attributes['href'].nodeValue));
        return await getCanadaPostManifest( Array.from(document.querySelectorAll("link[rel='manifest']")).map(x => x.attributes['href'].nodeValue), token);
    }catch (e) {
        console.log(e);
        return null;
    }
};

export const getCanadaPostManifestFromArtifact = async(artifactUrl,token)=>{
    const headers = {
        "accept": "application/pdf",
        "authorization": `Basic ${token}`,
    };
   
    const response = await axios({
        url: proxyurl +artifactUrl,
        method: 'GET',
        headers:headers,
        responseType: 'blob', // important
    });

    return new Blob([response.data], {type: "application/pdf"});

};

const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
};

export const getCanadaPostRates = async (data,token,isExpress,ShippingMatrices,shippingMethodsFilter)=>{
    const xml = generateCanadaPostGetRatesXML(data);
    const headers = {
        "accept": "application/vnd.cpc.ship.rate-v3+xml",
        "content-type": "application/vnd.cpc.ship.rate-v3+xml",
        "accept-language": "en-CA",
        "authorization": `Basic ${token}`,
    };
    try{
        const res = (await axios.post(`${baseURL}/rs/ship/price`,xml,{headers})).data;
        const document =  new DOMParser().parseFromString(res, 'application/xml');

        const shipments = [...document.querySelectorAll('price-quote')];

        const result = shipments.map(q=>{
            if(q){
                const serviceCode = q.getElementsByTagName('service-code')[0] === undefined? "" :q.getElementsByTagName('service-code')[0].textContent;

                const service = ShippingMatrices.find(s=>s.ServiceCode === serviceCode);

                if(service)
                    return {
                        serviceCode: serviceCode,
                        serviceName: service.DisplayedName,
                        isExpress: service.IsExpress,
                        totalCost:q.getElementsByTagName('due')[0] === undefined? 0 : q.getElementsByTagName('due')[0].textContent,
                        deliverByDate:q.getElementsByTagName('expected-delivery-date')[0] === undefined ? "" : q.getElementsByTagName('expected-delivery-date')[0].textContent,
                    };
                return service;
            }
        });
        //console.log(result);
        //console.log(shippingMethodsFilter);
        if(shippingMethodsFilter.length > 0){

            const conditions = shippingMethodsFilter.filter(s=>s.Carrier==="CANADA POST").map(s=>s.ServiceCode);
            return  result.filter(r=>r && conditions.some(el=> r.serviceCode.includes(el)))
        }
       return result.filter(r=>r && r.isExpress === isExpress);
    }catch (e) {
        //console.log(e);
        return [];
    }
};



const serviceIsExpress = (serviceCode)=>{
    switch (serviceCode) {
        case "DOM.RP":
            return false;
        case "DOM.EP":
            return false;
        case "DOM.XP":
            return true;
        case "DOM.XP.CERT":
            return true;
        case "DOM.PC":
            return true;
        case "DOM.LIB":
            return false;
        default:
            return false;
    }
};
