import axios from "axios/index";
import {headers, inventoryURL} from "./requestHelper";


export const CommerceHubWalmartInventoryFileGenerator=(list)=>{
    const now = Date.now();
    let str = "";
    str += `<?xml version="1.0" encoding="UTF-8"?><advice_file><advice_file_control_number>${now}</advice_file_control_number><vendorMerchID>walmartca</vendorMerchID>`;
    list.forEach(item=>{
        const detail = item.MerchanSku.split('_');
        str +=`<product><vendor_SKU>${item.Sku}</vendor_SKU><qtyonhand>${item.Inventory}</qtyonhand><available>yes</available><manufacturer_SKU>${detail[0]}</manufacturer_SKU><UPC>${detail[1]}</UPC><description>${detail[2].replace("&", "and")}</description><merchantSKU>${detail[3]}</merchantSKU></product>`;
    });
    str += `<advice_file_count>${list.length}</advice_file_count></advice_file>\n`;
    return str;
};

export const CommerceHubBestBuyInventoryFileGenerator=(list)=>{
    const now = Date.now();
    let str = "";
    str += `<?xml version="1.0" encoding="UTF-8"?><advice_file><advice_file_control_number>${now}</advice_file_control_number><vendorMerchID>bestbuyca</vendorMerchID>`;
    list.forEach(item=>{
        const detail = item.MerchanSku.split('_');
        str +=`<product><vendor_SKU>${item.Sku}</vendor_SKU><qtyonhand>${item.Inventory}</qtyonhand><available>Yes</available><UPC>${detail[0]}</UPC><description>${detail[1].replace("&", "and")}</description><unit_cost>${detail[2]}</unit_cost></product>`;
    });
    str += `<advice_file_count>${list.length}</advice_file_count></advice_file>\n`;
    return str;
};
export const CommerceHubTheBayInventoryFileGenerator=(list)=>{
    const now = Date.now();
    let str = "";
    str += `<?xml version="1.0" encoding="UTF-8"?><advice_file><advice_file_control_number>${now}</advice_file_control_number><vendor>ashlinbpg</vendor><vendorMerchID>HBC</vendorMerchID>`;
    list.forEach(item=>{
        const detail = item.MerchanSku.split('_');
        str +=`<product><vendor_SKU>${item.Sku}</vendor_SKU><qtyonhand>${item.Inventory}</qtyonhand><unitOfMeasure>EA</unitOfMeasure><available>Yes</available><UPC>${detail[0]}</UPC><description>${detail[1].replace("&", "and")}</description><unit_cost>${detail[2]}</unit_cost><merchantDept>BAY</merchantDept></product>`;
    });
    str += `<advice_file_count>${list.length}</advice_file_count></advice_file>\n`;
    return str;
};

export const CommerceHubInventoryUpdate=async(fileStream,ChannelName)=>{
    //console.log(fileStream);
    const data = {
        "ChannelName":ChannelName,
        "FileStream":fileStream
    };
    return await axios.post(inventoryURL,data,{headers});
};