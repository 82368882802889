import {
  CarTwoTone,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  LoadingOutlined,
} from "@ant-design/icons";
import { Col, message, Modal, Row, Tag } from "antd";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import async from "async";
import axios from "axios";
import $ from "jquery";
import React, { Component } from "react";
import { connect } from "react-redux";
import ReactTable from "react-table";
import "react-table/react-table.css";
import * as actions from "../../actions";
import {
  getBundledItems,
  getDesignKittedList,
  getSkuWithUpc,
  postOrderRecords,
  replaceBundledItems,
} from "../../Utils/ashlinDesign";
import {
  addRowItem,
  BPAllocateProduct,
  createAnBrightPearlOrder,
  getBPheader,
  getBrightPearlReservationDetail,
  getBrightPearlTaxCode,
  getIdBySKU,
  getTaxRate,
  leanSupplyOrderToBrightPearlOrderMapper,
} from "../../Utils/brightpearl";
import { handleError } from "../../Utils/ErrorHandler/ErrorHandler";
import { LeanSupplyModelMapper } from "../../Utils/orderModels";
import {
  calculateAge,
  currentDateTime,
  ENABLE_BRIGHTPEARL,
  ENABLE_KITTED_ITEM,
  ENABLE_LEANSUPPLY,
  formatDate,
  getBlanceByWarehouseCode,
  headers,
  PRODUCTION,
  proxyurl,
  SubmitOrderModel,
  submitOrderURL,
  submittedOrdersURL,
} from "../../Utils/requestHelper";
import SubmitOrderDetailModal from "../Modal/SubmitOrderItemDetailModal";
import MainSideBar from "../sideBar/MainSideBar";
class NewOrder extends Component {
  _mounted = false;
  constructor(props) {
    super(props);
    this.state = {
      orderLists: [],
      SKUList: [],
      BundledItems: [],
      selected: null,
      isDownLoading: true,
      isUpLoading: false,
      useCanadaPostForWalmart: false,
      processModalShow: false,

      manualOrderTrackingNum: "",
    };
  }
  componentWillUnmount() {
    this._mounted = false;
  }

  componentDidMount() {
    //this.downloadNewOrders();
    this._mounted = true;
    const value = this.state.useCanadaPostForWalmart;
    localStorage.setItem("useCPforWalmart", value.toString());
    const newOrders = this.props.fetchNewOrder();
    const allSKUs = this.props.fetchAllSKUs();
    this.loadData();

    Promise.all([newOrders, allSKUs]).then(() => {
      if (this._mounted) {
        this.setState({
          orderLists: this.props.newOrders,
          SKUList: this.props.SKUList,
          isDownLoading: false,
        });
      }
      $("#downloadOrders").hide();
    });
  }

  loadData = async () => {
    try {
      const BundledItems = await getBundledItems();
      this.setState({
        BundledItems,
      });
    } catch (error) {
      handleError(error, "loadData");
    }
  };

  checkFulFill = async () => {
    try {
      message.loading("checking if all order are valid...", 0);
      const orders = this.state.orderLists;
      if (orders.length !== 0) {
        const leanSupplyInventory = await getBlanceByWarehouseCode(14);
        //console.log(leanSupplyInventory);
        const SkuWithUpc = await getSkuWithUpc();
        //console.log(SkuWithUpc);
        let fulfill = true;
        const BPHeaders = await getBPheader();
        const request = await orders.map(async (order) => {
          //console.log(order);
          const response = order.OrderDetail.map(async (line) => {
            //console.log(line);

            if (order.ChannelName === "Hudson's Bay Company") {
              const UPC = line.LineRef.split("/")[0];
              //console.log(UPC);
              const rightSKU = SkuWithUpc.find((s) => s.Upc.toString() === UPC);
              //console.log(rightSKU);
              line.ItemCode = rightSKU.Sku;
            }
            const SKU = line.ItemCode;
            const Id = await getIdBySKU(SKU, BPHeaders);
            line.SKUID = Id;
            const stockLevel = await leanSupplyInventory.find(
              (s) => s.sku === SKU
            );
            if (Id === "N/A" && stockLevel === undefined) {
              fulfill = false;
              alert(
                `${SKU} not exist on BrightPearl and Lean Supply, please check the SKU`
              );
            } else if (Id === "N/A") {
              fulfill = false;
              alert(`${SKU} not exist on BrightPearl, please check the SKU`);
            } else if (stockLevel === undefined) {
              fulfill = false;
              alert(`${SKU} not exist on Lean Supply, please check the SKU`);
            } else {
            }
          });
          return Promise.all(response).then(() => {
            this.setState({
              orderLists: orders,
            });
          });
        });
        Promise.all(request).then(() => {
          if (fulfill) {
            message.destroy();
            message.success(
              "All orders are valid, please send orders to Lean Supply"
            );
            $("#submitOrders").show();
          }
        });
      } else {
        message.destroy();
        message.warning("No orders are detected");
      }

      setTimeout(() => {
        message.destroy();
      }, 3000);
    } catch (error) {
      handleError(error, "checkFulFill");
      message.destroy();
    }
  };

  //Download new order from Ashlin database
  // downloadNewOrders=async()=>{
  //     try{
  //     const response = await axios.get(getNewOrderURL);
  //     const result = response.data;
  //     this.setState({
  //         orderLists: result,
  //     });
  //     $('#downloadOrders').hide();
  //     $('#submitOrders').show();
  // } catch (error){
  //     handleError(error, "downloadNewOrders")
  // }
  // };
  //When an individual row get clicked, the detail modal will show up.
  handleDetail = () => {
    $("#submitOrderDetail").modal("show");
  };

  periodCheckState = async () => {
    let { orderLists } = this.state;
    //console.log(orderLists)
    let periodCheckInterval = setInterval(async () => {
      let isFinishProcess = true;
      for (let order of orderLists) {
        if (order.OrderHeader.OrderStatus === "NEW") {
          isFinishProcess = false;
        }
      }
      if (isFinishProcess) {
        clearInterval(periodCheckInterval);
        await this.upDateOrders();
        this.setState({ processModalShow: false });
      }
    }, 1000);
  };

  //submit orders which can be fulfilled to lean supply.Once finishing submission, update to our database button show.
  handleSubmit = async () => {
    try {
      this.setState({ processModalShow: true });
      this.periodCheckState();

      this.setState({ isUpLoading: true });
      $("#submitOrders").hide();
      const url = proxyurl + submitOrderURL;
      const data = this.state.orderLists;

      let designKittedList = [];
      if (ENABLE_KITTED_ITEM) {
        const designKittedListRes = await getDesignKittedList();
        designKittedList = designKittedListRes.data;
      }

      const BPHeaders = await getBPheader();

      const request = await data.map(async (d, i) => {
        if (ENABLE_KITTED_ITEM) {
          const needKittedItems = d.OrderDetail.filter((line) => {
            const designCode = line.ItemCode.split("-")[0];
            return designKittedList.some(
              (k) => k.DesignServiceCode === designCode
            );
          });
          //console.log(needKittedItems);
          if (needKittedItems.length !== 0) {
            const addItems = needKittedItems.map((item) => {
              const kitted = designKittedList.find(
                (dk) => item.ItemCode.split("-")[0] === dk.DesignServiceCode
              );
              return {
                ItemCode: kitted.KittedItem,
                ItemName: kitted.KittedItem,
                ItemQty: item.ItemQty,
                ItemDescription: `***Please insert ${kitted.KittedItem} into ${item.ItemCode} as a whole item***`,
              };
            });

            //Add Combo item row to Order Detail line, Need to setup Combo item to Bright Pearl
            //Otherwise add this logical before submit order to Lean supply

            //Once the Journal separate uncomment following code.
            addItems.forEach((a) => {
              d.OrderDetail.push({
                ItemCode: a.ItemCode,
                ItemDescription: a.ItemDescription,
                ItemName: a.ItemName,
                ItemQty: a.ItemQty,
                LineRef: "ASHLIN_KITTED_ITEM_SET",
                OrderLine: (d.OrderDetail.length + 1).toString(),
                TotalLineCost: 0,
                UnitPrice: 0,
                poNo: "",
                uofm: "EACH",
              });
            });
          }
        }

        //BundledItems
        const oderDetailWithBundledItems = replaceBundledItems(
          d.OrderDetail,
          this.state.BundledItems
        );

        await setTimeout(async () => {
          return await this.submitRequest(
            d,
            i,
            url,
            BPHeaders,
            oderDetailWithBundledItems
          );
        }, i * 1000);
      });
      Promise.all(request).then(() => {
        setTimeout(async () => {
          this.setState({ isUpLoading: false });
        }, data.length * 1000);
      });
    } catch (error) {
      handleError(error, "handleSubmit");
    }
  };
  //this action is working on a single order
  //it first check each item in the order, compares the order qty with warehouse on hand qty
  //if we can fulfill the order, we will send order to lean supply for shipment
  //otherwise, it shows no inventory for the SKU
  submitRequest = async (
    obj,
    index,
    url,
    BPHeaders,
    oderDetailWithBundledItems
  ) => {
    try {
      //console.log(obj);
      const data = SubmitOrderModel(LeanSupplyModelMapper(obj));

      //console.log(data);
      let message = "WARNING: ";
      let orderPackageComment = "";
      let bpMessage = "";
      const lists = this.state.orderLists;
      const rowItems = [];
      let channel = "";
      switch (obj.ChannelName) {
        case "Staples.ca":
          channel = "";
          break;
        case "Walmart Canada":
          channel = "";
          break;
        case "Hudson's Bay Company":
          channel = "";
          break;
        case "Best Buy Canada":
          channel = "";
          break;
        default:
          channel = "";
      }
      let totalValue = 0;

      //Check Fulfill with Bundled Items
      const resBundled = oderDetailWithBundledItems.map(async (item) => {
        //console.log(oderDetailWithBundledItems)
        const SKU = item.ItemCode;
        const QTY = item.ItemQty;
        const leanSupplyInventory = await getBlanceByWarehouseCode(14);
        const stock = leanSupplyInventory.find((i) => i.sku === SKU);
        /////////////////           Check Stock at Lean Supply
        if (parseInt(stock.qty) < QTY) {
          //console.log(`checking, can't ${SKU}`)
          return (bpMessage += `${SKU} onHand level is ${stock}, cannot fulfill order QTY ${QTY}`);
        }
        //Add package comment to order header
        const sku = this.props.SKUList.find((i) => i.SKU === SKU);
        //console.log(sku);
        if (!orderPackageComment.includes("Preferred Packaging: CARTON")) {
          if (sku !== undefined && sku.Description !== undefined)
            orderPackageComment = sku.Description.includes(
              "Preferred Packaging: BUBBLE PACK"
            )
              ? "Preferred Packaging Type: BUBBLE PACK"
              : "Preferred Packaging Type: CARTON";
        }
        let kittedItemComment = "";
        if (item.LineRef === "ASHLIN_KITTED_ITEM_SET") {
          kittedItemComment = item.ItemDescription;
        }
        console.log(data.OrderHeader.Comment);
        data.OrderHeader.Comment =
          kittedItemComment +
          data.OrderHeader.Comment +
          orderPackageComment +
          channel;

        return ``;
      });

      //Row Item for BP
      const response = obj.OrderDetail.map(async (item, index) => {
        if (ENABLE_BRIGHTPEARL) {
          const SKU = item.ItemCode;
          const QTY = item.ItemQty;
          let Id;
          if (item.SKUID !== undefined) {
            Id = item.SKUID; //await getIdBySKU(SKU, BPHeaders);
          } else {
            Id = await getIdBySKU(SKU, BPHeaders);
          }

          const province = obj.OrderHeader.ShipTo.State;
          const taxCode = getBrightPearlTaxCode(province);
          const taxRate = getTaxRate(taxCode);
          const rowValue = obj.OrderDetail[index].TotalLineCost;
          const taxValue = rowValue * taxRate;
          totalValue += rowValue;
          totalValue += taxValue;
          rowItems.push({
            productId: Id,
            quantity: {
              magnitude: QTY,
            },
            rowValue: {
              taxCode: taxCode,
              rowNet: {
                value: rowValue,
              },
              rowTax: {
                value: taxValue.toFixed(2),
              },
            },
            nominalCode: "4000",
          });
        }
        return ``;
      });
      if (
        oderDetailWithBundledItems.length > 3 &&
        data.OrderHeader.Comment.includes("Preferred Packaging: BUBBLE PACK")
      ) {
        data.OrderHeader.Comment.replace(
          "Preferred Packaging: BUBBLE PACK",
          "Preferred Packaging: CARTON"
        );
      }
      //check order detail
      return Promise.all(resBundled)
        .then((promise) => promise.join())
        .then(async (promiseValue) => {
          const notFulfill = promiseValue.includes("cannot fulfill");
          //console.log(notFulfill);
          if (!notFulfill) {
            // //Create an empty order on BrightPearl
            try {
              if (ENABLE_BRIGHTPEARL && PRODUCTION) {
                //console.log(data);
                const newBrightOrder = leanSupplyOrderToBrightPearlOrderMapper(
                  data,
                  5
                );
                //console.log(newBrightOrder)

                const BPResponse = await createAnBrightPearlOrder(
                  newBrightOrder,
                  BPHeaders
                );
                const BPOrderId = BPResponse.data.response;
                lists[index].ERPOrderId = BPOrderId;

                rowItems.forEach(async (rowValue, innerIndex) => {
                  // Do work to process file here

                  await setTimeout(async () => {
                    const resAddItem = await addRowItem(
                      BPOrderId,
                      rowValue,
                      BPHeaders
                    );

                    const rowId = resAddItem.data.response;
                    //Insert RowID into reservationDetail - Allocated orders
                    // const reservationDetail = getBrightPearlReservationDetail(rowId, rowValue);
                    // //Reserve onHand item from BrightPearl warehouse
                    // const resReserve = await BPAllocateProduct(BPOrderId, 5, reservationDetail);
                  }, 1000 * innerIndex);
                });

                //add Line total value;
              }
              lists[index].totalValue = totalValue;
              data.OrderDetail = oderDetailWithBundledItems;
              //console.log(data);
              //Send order to Lean Supply
              if (ENABLE_LEANSUPPLY) {
                //Add Loomis Label
                const response = await axios.post(url, data, headers);
                //console.log(response);
                lists[index].OrderHeader.OrderStatus =
                  response.data.CallResponse.ResponseText ===
                  "Order created successfully"
                    ? "Entered"
                    : response.data.CallResponse.ResponseText;
                lists[index].OrderHandle =
                  response.data.OrderConfirm.OrderHandle;
              }
            } catch (e) {
              alert(e);
            }
          } else {
            message += promiseValue;
            lists[index].OrderHeader.OrderStatus = message.slice(
              0,
              message.length - 1
            );
          }
          this.setState({
            orderLists: lists,
          });
        });
    } catch (error) {
      handleError(error, "submitRequest");
    }
  };
  //Update submitted order to Ashlin database SubmitOrderModel Entered
  upDateOrders = async (callback) => {
    try {
      message.loading("saving to database...", 0);

      const processedBy = localStorage.getItem("user");
      const processDate = currentDateTime();
      const data = this.state.orderLists
        .filter((o) => o.OrderHeader.OrderStatus === "Entered")
        .map((order) => {
          return {
            ChannelId: order.ChannelId,
            ChannelOrderId: order.OrderHeader.OrderNumber,
            OrderHandle: order.OrderHandle,
            OrderStatus: order.OrderHeader.OrderStatus,
            FulfillmentStatus: "Received",
            ERPOrderId: order.ERPOrderId,
            ProcessedDate: processDate,
          };
        });
      const orderRecord = this.state.orderLists
        .filter((o) => o.OrderHeader.OrderStatus === "Entered")
        .map((order) => {
          let SaleChannelName;
          switch (order.OrderHeader.MarketplaceCode) {
            case "Walmart Canada": {
              SaleChannelName = "Walmart";
              break;
            }
            case "Staples.ca": {
              SaleChannelName = "Staples";
              break;
            }
            case "Hudson's Bay Company": {
              SaleChannelName = "The Bay";
              break;
            }
            case "StaplesAdvantage CAN": {
              SaleChannelName = "Staples-advantage";
              break;
            }
            case "Mirakl Best Buy Canada Marketplace": {
              SaleChannelName = "BestBuy Marketplace";
              break;
            }
            case "Best Buy Canada": {
              SaleChannelName = "Best Buy";
              break;
            }
            case "Walmart Marketplace": {
              SaleChannelName = "WALMART.CA Marketplace";
              break;
            }
            default: {
              SaleChannelName = "Unknown Sales Channel";
            }
          }
          return {
            BrightpearlOrderId: order.ERPOrderId,
            Carrier: "",
            Currency: "CAD",
            ProcessedDate: processDate,
            SaleChannelName: SaleChannelName,
            SaleChannelOrderId: order.OrderHeader.OrderNumber,
            ShippedDate: "",
            ShippedFrom: "LS14 LeanSupply 3PL",
            Status: "Dispatched",
            TotalValue: order.totalValue.toFixed(2),
            TrackingNumber: "",
            AttnName: order.OrderHeader.ShipTo.ContactName,
            CompanyName: order.OrderHeader.ShipTo.Name,
            PostalCode: order.OrderHeader.ShipTo.Zip,
            ProcessedBy: processedBy,
            EmailAddress: "",
            EmailNotification: "DONE",
          };
        });
      // console.log(data);
      // console.log(orderRecord);
      // const data = [{
      //             'ChannelId': "fc216960-10e3-44df-9ac6-861a7bc07986",
      //             'ChannelOrderId': "713378127",
      //             'OrderHandle': "2448988",
      //             "OrderStatus": "Entered",
      //             'FulfillmentStatus': "Received",
      //             'ERPOrderId': 812189,
      //             'ProcessedDate':processDate
      //       }];
      if (data.length > 0) {
        const response = await axios.put(submittedOrdersURL, data, headers);
        const orderRecordRes = await postOrderRecords(orderRecord);
      }
      await new Promise((resolve, reject) => {
        setTimeout(() => {
          let orderLists = this.state.orderLists.filter(
            (o) => o.OrderHeader.OrderStatus !== "Entered"
          );
          this.setState({ orderLists });
          resolve();
        }, 3000);
      });

      // $('#updateOrders').hide();
      // $('#downloadOrders').show();
      message.destroy();
      message.success("Sent orders are stored into database!");
    } catch (error) {
      handleError(error, "upDateOrders");
      message.destroy();
    }
  };
  //Handle change on the order detail modal
  handleReSubmitOrder = async (e) => {
    try {
      e.preventDefault();
      const url = proxyurl + submitOrderURL;
      let confirmBox = window.confirm("Do you confirm to reIssue this order?");
      if (confirmBox) {
        const selected = this.state.selected;
        const added = $(".order_detail_resubmitted");
        if (added.length !== 0) {
          const index = selected.OrderDetail.length;
          const addedValue = added.map((i) => added[i].value);
          const addItem = {
            ItemCode: addedValue[0],
            ItemDescription: addedValue[1],
            ItemName: addedValue[1],
            ItemQty: parseInt(addedValue[2], 10),
            poNo: addedValue[3],
            uofm: "EACH",
            OrderLine: index + 1,
          };
          selected.OrderDetail[index] = addItem;
        }
        let data = SubmitOrderModel(LeanSupplyModelMapper(selected));
        //console.log(data);
        const reIssueBrightOrder = leanSupplyOrderToBrightPearlOrderMapper(
          data,
          5
        );
        const BPHeaders = await getBPheader();
        const BPResponse = await createAnBrightPearlOrder(
          reIssueBrightOrder,
          BPHeaders
        );
        const BPOrderId = BPResponse.data.response;
        const province = data.OrderHeader.ShipTo.State;
        const taxCode = getBrightPearlTaxCode(province);
        const taxRate = getTaxRate(taxCode);
        data.OrderDetail.map(async (i) => {
          if (i.ItemQty > 0) {
            const Id = await getIdBySKU(i.ItemCode, BPHeaders);
            const rowValue = i.TotalLineCost;
            const rowItem = {
              productId: Id,
              quantity: {
                magnitude: i.ItemQty,
              },
              rowValue: {
                taxCode: taxCode,
                rowNet: {
                  value: rowValue,
                },
                rowTax: {
                  value: (rowValue * taxRate).toFixed(2),
                },
              },
              nominalCode: "4000",
            };
            const resAddItem = await addRowItem(BPOrderId, rowItem, BPHeaders);
            const rowId = resAddItem.data.response;
            //Insert RowID into reservationDetail
            const reservationDetail = getBrightPearlReservationDetail(
              rowId,
              rowItem
            );
            //Reserve onHand item from BrightPearl warehouse
            const resReserve = await BPAllocateProduct(
              BPOrderId,
              5,
              reservationDetail
            );
          }
        });
        //To Lean Supply
        const response = await axios.post(url, data, headers);
        const list = this.state.orderLists;
        const index = list.findIndex(
          (x) => x.OrderHeader.OrderNumber === data.OrderHeader.OrderNumber
        );
        list[index].OrderHeader.OrderStatus =
          response.data.CallResponse.ResponseText ===
          "Order created successfully"
            ? "Entered"
            : response.data.CallResponse.ResponseText;
        list[index].OrderHandle = response.data.OrderConfirm.OrderHandle;
        this.setState(
          {
            orderLists: list,
          },
          () => {
            $("#submitOrderDetail").modal("hide");
            $("#updateOrders").show();
          }
        );
      }
    } catch (error) {
      handleError(error, "handleReSubmitOrder");
    }
  };
  //Handle on change event on the order detail modal, and save entries into the state.selected
  handleOnChange = (e, field, index) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    const selected = this.state.selected;
    const list = this.state.orderLists;
    const editIndex = list.findIndex(
      (i) => i.OrderHeader.OrderNumber === selected.OrderHeader.OrderNumber
    );
    //console.log(this.state);
    //console.log(editIndex);
    if (field === "ShipTo") {
      selected.OrderHeader.ShipTo[name] = value;
      list[editIndex].OrderHeader.ShipTo[name] = value;
    }
    if (field === "OrderDetail") {
      if (name === "ItemQty") {
        selected.OrderDetail[index][name] =
          value !== "" ? parseInt(value, 10) : value;
        list[editIndex].OrderDetail[index][name] =
          value !== "" ? parseInt(value, 10) : value;
      } else {
        selected.OrderDetail[index][name] = value;
        list[editIndex].OrderDetail[index][name] = value;
      }
    } else {
      selected.OrderHeader[name] = value;
      list[editIndex].OrderHeader[name] = value;
    }
    this.setState(
      {
        selected,
        orderLists: list,
      },
      () => {
        console.log(this.state);
      }
    );
  };

  handleStateOnchange = (e) => {
    const name = e.target.name;
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    this.setState({
      [name]: value,
    });
  };

  handleAddItem = (e, value, id) => {
    e.preventDefault();
    //console.log(value);
    $(
      `<div  class="row">` +
        `<div class="col"><span class="font-weight-bold"> Item SKU: </span><input value=${value.ItemCode} name="ItemCode" class="order_detail_resubmitted" /></div>` +
        `<div class="col"><span class="font-weight-bold"> Item Name: </span><input value=${value.ItemName} name="ItemName" class="order_detail_resubmitted" readonly/></div>` +
        `<div class="col"><span class="font-weight-bold"> Item Qty: </span><input value=${value.ItemQty} name="ItemQty" class="order_detail_resubmitted" /></div>` +
        `<div class="col"><span class="font-weight-bold"> Item PO #: </span><input value=${value.poNo} name="ItempoNo" class="order_detail_resubmitted" readonly/></div></div>`
    ).insertAfter(`#${id}`);
  };

  handleEnableWalmartShipmentMethod = (e) => {
    const value = e.target.checked;
    localStorage.setItem("useCPforWalmart", value.toString());
    this.setState({
      useCanadaPostForWalmart: value,
    });
  };

  handleShipLocally = async (orderNumber) => {
    try {
      const manualOrderTrackingNum = this.state.manualOrderTrackingNum;

      if (manualOrderTrackingNum !== "") {
        let confirmBox = window.confirm(`Do you confirm to send this order?`);
        if (confirmBox) {
          const orderLists = this.state.orderLists;
          const index = orderLists.findIndex(
            (o) => o.OrderHeader.OrderNumber === orderNumber
          );

          const order = orderLists.splice(index, 1)[0];

          const rowItems = [];
          //Create order on Brightpearl
          const BPHeaders = await getBPheader();
          const response = order.OrderDetail.map(async (item, index) => {
            const SKU = item.ItemCode;
            const Id = await getIdBySKU(SKU, BPHeaders);
            const QTY = item.ItemQty;

            const province = order.OrderHeader.ShipTo.State;
            const taxCode = getBrightPearlTaxCode(province);
            const taxRate = getTaxRate(taxCode);
            const rowValue = order.OrderDetail[index].TotalLineCost;
            rowItems.push({
              productId: Id,
              quantity: {
                magnitude: QTY,
              },
              rowValue: {
                taxCode: taxCode,
                rowNet: {
                  value: rowValue,
                },
                rowTax: {
                  value: (rowValue * taxRate).toFixed(2),
                },
              },
              nominalCode: "4000",
            });
          });
          Promise.all(response).then(async () => {
            // if (ENABLE_BRIGHTPEARL && PRODUCTION) {
            //     //console.log(data);

            const newBrightOrder = leanSupplyOrderToBrightPearlOrderMapper(
              order,
              2
            );

            const BPResponse = await createAnBrightPearlOrder(
              newBrightOrder,
              BPHeaders
            );
            const BPOrderId = BPResponse.data.response;

            async.each(
              rowItems,
              async function (rowValue, callback) {
                // Do work to process file here
                const resAddItem = await addRowItem(
                  BPOrderId,
                  rowValue,
                  BPHeaders
                );
                // const rowId = resAddItem.data.response;
                // //Insert RowID into reservationDetail
                // const reservationDetail = getBrightPearlReservationDetail(rowId, rowValue);
                // // //Reserve onHand item from BrightPearl warehouse
                // const resReserve = await BPAllocateProduct(BPOrderId, 5, reservationDetail);
                await setTimeout(() => {
                  return callback();
                }, 1000);
              },
              function (err) {}
            );

            //let FulfillmentStatus = "Acknowledged and awaiting shipment";
            let FulfillmentStatus = "Received";
            if (order.OrderHeader.MarketplaceCode === "Hudson's Bay Company") {
              FulfillmentStatus = "Received";
            }

            //Create record in database
            const ashlinData = {
              ChannelId: order.ChannelId,
              ChannelOrderId: order.OrderHeader.OrderNumber,
              OrderHandle: "",
              OrderStatus: "Entered",
              FulfillmentStatus: FulfillmentStatus,
              ERPOrderId: BPOrderId,
              TrackingNumber: manualOrderTrackingNum,
            };

            await axios.put(submittedOrdersURL, [ashlinData], headers);
            this.setState(
              {
                orderLists,
                manualOrderTrackingNum: "",
              },
              () => {
                $("#submitOrderDetail").modal("hide");
              }
            );
          });
        }
      } else {
        alert("Please enter the tracking Number");
      }
    } catch (error) {
      handleError(error, "handleShipLocally");
    }
  };

  handleRemoveOrderLine = (orderHeader, orderLine) => {
    const orderLists = this.state.orderLists;
    const orderIndex = orderLists.findIndex(
      (o) => o.OrderHeader.OrderNumber === orderHeader.OrderNumber
    );
    orderLists[orderIndex].OrderDetail = orderLists[
      orderIndex
    ].OrderDetail.filter((line) => line.ItemCode !== orderLine.ItemCode);
    if (orderLists[orderIndex].OrderDetail.length === 0) {
      orderLists.splice(orderIndex, 1);
    }
    this.setState({
      orderLists,
    });
  };

  render() {
    const { orderLists, SKUList } = this.state;
    //console.log(orderLists);
    return (
      <div className="container-fluid row">
        <div className={"col-3"}>
          <MainSideBar />
        </div>
        <div className={"col"}>
          {this.state.selected !== null ? (
            <SubmitOrderDetailModal
              OrderHeader={this.state.selected.OrderHeader}
              OrderDetail={this.state.selected.OrderDetail}
              OrderDelivery={this.state.selected.OrderDelivery}
              channel={this.state.selected.ChannelName}
              handleReSubmitOrder={this.handleReSubmitOrder}
              handleOnChange={this.handleOnChange}
              handleAddItem={this.handleAddItem}
              handleShipLocally={this.handleShipLocally}
              manualOrderTrackingNum={this.state.manualOrderTrackingNum}
              handleStateOnchange={this.handleStateOnchange}
              handleRemoveOrderLine={this.handleRemoveOrderLine}
            />
          ) : null}
          <br />
          <h1 className={"text-center"}> New Orders</h1>
          <h2 className={"text-right"}>
            <span
              className={"bg-danger text-white"}
              style={{ padding: "0 5px" }}
            >
              <input
                type="checkbox"
                style={{ width: "25px", height: "25px" }}
                name={" useCanadaPostForWalmart"}
                onChange={this.handleEnableWalmartShipmentMethod}
                value={this.state.useCanadaPostForWalmart}
                checked={this.state.useCanadaPostForWalmart}
              />{" "}
              Use Canada Post for Walmart
            </span>
          </h2>
          {this.state.isUpLoading && (
            <div className={"text-center text-success display-4"}>
              <i className="fa fa-spinner fa-spin"></i> &nbsp; Submitting...
            </div>
          )}
          {this.state.isDownLoading && (
            <div className={"text-center text-success display-4"}>
              <i className="fa fa-spinner fa-spin"></i> &nbsp; Downloading new
              Orders...
            </div>
          )}
          <br />
          <hr />

          <div style={{ textAlign: "center" }}>
            <div className="row">
              {/*<div className="col-4">*/}
              {/*<button onClick={this.downloadNewOrders} id='downloadOrders' className={'btn btn-danger btn-block'}>Download New Orders</button>*/}
              {/*</div>*/}
              <div className="col-4">
                {/* <button onClick={this.upDateOrders} id='updateOrders' style={{display:'none'}} className={'btn btn-danger btn-block'}>Save Orders to Database</button> */}
              </div>
              <div className="col-4">
                <button
                  onClick={this.checkFulFill}
                  id="checkFulFill"
                  className={"btn btn-success btn-block"}
                >
                  Verify Orders
                </button>
              </div>
              <div className="col-4">
                <button
                  onClick={this.handleSubmit}
                  id="submitOrders"
                  style={{ display: "none" }}
                  className={"btn btn-primary btn-block"}
                >
                  Submit All Orders
                </button>
              </div>
            </div>

            <ReactTable
              data={orderLists}
              filterable
              defaultFilterMethod={(filter, row) =>
                String(row[filter.id]) === filter.value
              }
              columns={[
                {
                  Header: "Order Information",
                  headerClassName: "ashlin-react-table-header-info",
                  columns: [
                    {
                      Header: "When",

                      id: "Age",
                      accessor: (d) => calculateAge(d.OrderHeader.OrderDate),
                      filterMethod: (filter, row) =>
                        row[filter.id].startsWith(filter.value),
                    },
                    {
                      Header: "ContactName",

                      id: "ContactName",
                      accessor: (d) => d.OrderHeader.ShipTo.ContactName,
                      filterMethod: (filter, row) =>
                        row[filter.id].startsWith(filter.value),
                    },
                    {
                      Header: "Channel",

                      id: "Channel",
                      accessor: (d) => d.ChannelName,
                      Cell: ({ value }) => {
                        let backgroundColor = "";
                        switch (value) {
                          case "Staples.ca":
                            value = "StaplesExchange";
                            backgroundColor = "#e4606d";
                            break;
                          case "Hudson's Bay Company":
                            value = "Hudson's Bay Company";
                            backgroundColor = "#e1b143";
                            break;
                          case "Walmart Canada":
                            value = "WALMART CANADA";
                            backgroundColor = "#167ef6";
                            break;
                          case "Best Buy Canada":
                            value = "Best Buy Canada";
                            backgroundColor = "#0bc7fa";
                            break;
                          case "Mirakl Best Buy Canada Marketplace":
                            value = "BestBuy Marketplace";
                            backgroundColor = "#49fae6";
                            break;
                          case "StaplesAdvantage CAN":
                            value = "StaplesAdvantage";
                            backgroundColor = "#ff4b90";
                            break;
                          case "Walmart Marketplace":
                            value = "Walmart Marketplace";
                            backgroundColor = "#007DC6";
                            break;
                          default:
                            value = "Channel Info Missing";
                            backgroundColor = "#00ff3a";
                        }
                        return (
                          <div
                            style={{
                              backgroundColor: backgroundColor,
                            }}
                          >
                            {value}
                          </div>
                        );
                      },
                      filterMethod: (filter, row) => {
                        if (filter.value === "all") {
                          return true;
                        }
                        if (filter.value === "StaplesExchange") {
                          //Staples.ca is the value show on the table
                          return row[filter.id] === "Staples.ca";
                        }
                        if (filter.value === "The Bay") {
                          return row[filter.id] === "The Bay";
                        }
                        return row[filter.id] === "Item already exists.";
                      },
                      Filter: ({ filter, onChange }) => (
                        <select
                          onChange={(event) => onChange(event.target.value)}
                          style={{ width: "100%" }}
                          value={filter ? filter.value : "all"}
                        >
                          <option value="all">Show All</option>
                          <option value="StaplesExchange">
                            Staples Exchange
                          </option>
                          <option value="Hudson's Bay Company">
                            Hudson's Bay Company
                          </option>
                          <option value="WALMART CANADA">Walmart Canada</option>
                          <option value="Best Buy Canada">
                            Best Buy Canada
                          </option>
                        </select>
                      ),
                    },

                    {
                      Header: "Order Items",
                      id: "Items",

                      accessor: (d) =>
                        d.OrderDetail.length > 1
                          ? "[ Multiple Items ]"
                          : d.OrderDetail[0] !== undefined
                          ? d.OrderDetail[0].ItemCode
                          : "Order Item missing",
                      Cell: ({ value }) => (
                        <div
                          style={{
                            fontWeight:
                              value === "[ Multiple Items ]"
                                ? "bolder"
                                : "bold",
                            backgroundColor:
                              value === "Order Item missing" ? "red" : "",
                          }}
                        >
                          {value}
                        </div>
                      ),
                    },
                    {
                      Header: "Order Number",

                      id: "ThirdPartyOrderNo",
                      accessor: (d) => d.OrderHeader.ThirdPartyOrderNo,
                    },
                    {
                      Header: "Order Date",

                      id: "OrderDate",
                      accessor: (d) => formatDate(d.OrderHeader.OrderDate),
                    },
                    {
                      Header: "Total Price",

                      id: "TotalPrice",
                      accessor: (d) => d.OrderHeader.TotalPrice,
                    },
                    {
                      Header: "Order Rows",

                      id: "OrderQty",
                      accessor: (d) => d.OrderDetail.length,
                    },
                  ],
                },
                {
                  Header: "Order Status",
                  headerClassName: "ashlin-react-table-header-info",
                  columns: [
                    {
                      Header: "Submit Status",
                      id: "Status",
                      accessor: (d) => d.OrderHeader.OrderStatus,
                      Cell: ({ value }) => (
                        <div
                          style={{
                            backgroundColor: value.length > 25 ? "yellow" : "",
                          }}
                        >
                          {value}
                        </div>
                      ),
                      filterMethod: (filter, row) => {
                        if (filter.value === "all") {
                          return true;
                        }
                        if (filter.value === "Entered") {
                          return row[filter.id] === "Entered";
                        }
                        if (filter.value === "Could not Fulfill Order") {
                          return row[filter.id].length > 25;
                        }
                        return row[filter.id] === "Item already exists.";
                      },
                      Filter: ({ filter, onChange }) => (
                        <select
                          onChange={(event) => onChange(event.target.value)}
                          style={{ width: "100%" }}
                          value={filter ? filter.value : "all"}
                        >
                          <option value="all">Show All</option>
                          <option value="Entered">Submit OK</option>
                          <option value="Could not Fulfill Order">
                            Could not Fulfill Order
                          </option>
                          <option value="Item already exists.">Others</option>
                        </select>
                      ),
                    },
                  ],
                },
              ]}
              defaultPageSize={10}
              className="-striped -highlight orderTable"
              getTrProps={(state, rowInfo) => {
                return {
                  onClick: (e) => {
                    //console.log(rowInfo.original)
                    this.setState(
                      {
                        selected: rowInfo.original,
                        manualOrderTrackingNum: "",
                      },
                      this.handleDetail
                    );
                  },
                };
              }}
            />
          </div>
        </div>
        {
          <div>
            <Modal
              title={
                <span>
                  <CarTwoTone twoToneColor="#52c41a" />
                  Sending orders...
                </span>
              }
              visible={this.state.processModalShow}
              footer={null}
              closable={false}
              width={1200}
            >
              {this.state.orderLists.map((order) => {
                if (order.OrderHeader.OrderStatus === "NEW") {
                  return (
                    <Row type="flex" justify="space-around">
                      <Col>
                        {order.OrderHeader.ShipTo.ContactName}'order from{" "}
                        <Tag>{order.ChannelName}</Tag> is submiting to 3PL
                      </Col>
                      <Col>
                        <LoadingOutlined style={{ fontSize: 24 }} spin />
                      </Col>
                    </Row>
                  );
                } else if (order.OrderHeader.OrderStatus === "Entered") {
                  return (
                    <Row type="flex" justify="space-around">
                      <Col>
                        {order.OrderHeader.ShipTo.ContactName}'order from{" "}
                        <Tag>{order.ChannelName}</Tag> has been sent to 3pl
                      </Col>
                      <Col>
                        <CheckCircleTwoTone
                          twoToneColor="green"
                          style={{ fontSize: 24 }}
                        />
                      </Col>
                    </Row>
                  );
                } else {
                  return (
                    <Row type="flex" justify="space-around">
                      <Col>
                        {order.OrderHeader.ShipTo.ContactName}'order from{" "}
                        <Tag>{order.ChannelName}</Tag> got a warning message
                      </Col>
                      <Col>
                        <CloseCircleTwoTone
                          twoToneColor="red"
                          style={{ fontSize: 24 }}
                        />
                      </Col>
                    </Row>
                  );
                }
              })}
            </Modal>
          </div>
        }
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    newOrders: state.newOrders,
    submittedOrders: state.submittedOrders,
    SKUList: state.allSKUs,
  };
};
export default connect(mapStateToProps, actions)(NewOrder);
