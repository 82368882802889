import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const  ModifyPrice = ({channel,price,priceOnchange,changeStartDate,changeEndDate,onClosed, discountStartDate, discountEndDate, addToList})=>{

    return(
        <div className="modal fade" id="ModifyPriceModal" tabIndex="-1" role="dialog" data-backdrop="static"
             aria-labelledby="ModifyPriceModal" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title text-center" id="ModifyPriceModalTitle">Modify Price</h5>
                        <button type="button" className="close" data-dismiss="modal"  onClick={onClosed}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <table className="table table-bordered modifyPriceTable" >
                            <tbody>
                                <tr>
                                    <th>Channel Name</th>
                                    <td>{channel}</td>
                                </tr>
                                <tr>
                                    <th>Currency</th>
                                    <td>{price.currency}</td>
                                </tr>
                                <tr>
                                    <th>Listing/Sales Price (will be new price)</th>
                                    <td><input type={'text'}  name={'listPrice'} style={{textAlign:'center'}} value={price.listPrice}  onChange={priceOnchange}/></td>
                                </tr>
                                <tr>
                                    <th>Regular/Standard Price</th>
                                    <td><input type={'text'}  name={'regularPrice'} style={{textAlign:'center'}} value={price.regularPrice}  onChange={priceOnchange}/></td>
                                </tr>
                                <tr>
                                    <th>Is Discount</th>
                                    <td>{price.discount? "YES" : "NO"}</td>
                                </tr>
                                <tr>
                                    <td colSpan={'2'}> </td>
                                </tr>
                                <tr>
                                    <th style={{color:'red'}}>Apply Discount?</th>
                                    <td><input type={'checkbox'} name={'applyDiscount'} checked={price.applyDiscount} onChange={priceOnchange} style={{width:'30px',height:'30px'}}/> </td>
                                </tr>
                                {
                                    price.applyDiscount &&
                                        <tr>
                                            <th>Sale Start Date</th>
                                            <td>
                                                <DatePicker
                                                    selected={discountStartDate}
                                                    onChange={changeStartDate} />
                                            </td>
                                        </tr>
                                }
                                {
                                    price.applyDiscount &&
                                        <tr>
                                            <th>Sale End Date</th>
                                            <td>
                                                <DatePicker
                                                    selected={discountEndDate}
                                                    onChange={changeEndDate}/>
                                            </td>
                                        </tr>
                                }
                                <tr>
                                    <td colSpan={'2'}>
                                        <button type={'button'} className={'btn btn-success btn-block'} onClick={addToList}>Add to Updating List</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="modal-footer">

                    </div>
                </div>
            </div>
        </div>
    )

};
export default ModifyPrice;
