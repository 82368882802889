import React, {Component}from 'react';
import { connect } from 'react-redux';
import * as actions from '../actions';
import TextInput from './bootstrapComponent/textInput';
import { getAuth } from '../Utils/requestHelper'

class Loading extends Component{
    _mounted = false;
    constructor(props){
        super(props);
        this.state={
            email:'',
            password:''
        }
    }
    onChange =(e)=>{
        const name = e.target.name;
        const value = e.target.value;
       this.setState({
          [name]: value
       })
    };
    componentDidMount(){
        if(!this._mounted){
            localStorage.clear();
            this.props.cleanAuth();
            this._mounted = true;
        }
        //console.log(this.state);

    }

    handleLogin= async()=>{
        await this.props.login(this.state);
        
       if(this.props.authenticated.length > 0){
           const auth = await getAuth();
           //console.log(JSON.stringify(auth));
           localStorage.setItem('auth', JSON.stringify(auth));
           this.props.history.push('/insights');
       }
    };

    render(){
        return(
            <div className={'container'}>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <h1 style={{textAlign:'center', marginTop:'20px'}}>Please login </h1>
                <h2 style={{textAlign:'center', color:'red'}}>{this.props.errorMessage}</h2>
                <br/>
                <br/>
                <form>
                    <TextInput label={'Email Address'} id={'loginEmail'} name={'email'} onChange={this.onChange}/>
                    <TextInput label={'Password'} id={'loginPassword'} name={'password'} type={'password'} onChange={this.onChange}/>
                    {/*<div className="form-check">*/}
                        {/*<input type="checkbox" className="form-check-input" id="exampleCheck1" />*/}
                            {/*<label className="form-check-label" htmlFor="exampleCheck1">remember me</label>*/}
                    {/*</div>*/}
                    <br/>
                    <br/>

                    <button type="button" className="btn btn-primary btn-block" onClick={this.handleLogin}>Login</button>
                </form>
            </div>
        )
    }
}
const mapStateToProps=(state)=>{
    return {
        authenticated:state.authenticated,
        errorMessage: state.errorMessage
    }
};

export default connect(mapStateToProps,actions)(Loading);