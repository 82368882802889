import React, { Component } from 'react';
import axios from 'axios';


class GoogleDriveTest extends Component{

    // handleGetToken = async (e)=>{
    //     e.preventDefault();
    //     const oauth2Client = new google.auth.OAuth2(
    //         "368934265113-hajk3tjdo6f1l1dktioihtdj8u4p70q9.apps.googleusercontent.com",
    //         "TkIRpd1vYLKBsOrcMcrr17sf",
    //         "http://localhost:3000"
    //     );
    //
    //     const scopes = [
    //         'https://www.googleapis.com/auth/drive'
    //     ];
    //
    //     const url = oauth2Client.generateAuthUrl({
    //         // 'online' (default) or 'offline' (gets refresh_token)
    //         //access_type: 'offline',
    //
    //         // If you only need one scope you can pass it as a string
    //         access_type: 'offline',
    //         scope: scopes,
    //         prompt: 'consent'
    //     });
    //     console.log(url);
    // };
    render(){
        return(
            <div>
                <button className={'btn btn-danger'} >Get Token</button>
            </div>
        )
    }
}

export default GoogleDriveTest;