import React, {Component} from 'react';
import $ from "jquery";
import { createShippingPackage } from '../../../Utils/ashlinDesign';
class NewShippingPackage extends Component{
    constructor(props){
        super(props);
        this.state={
            Name:'',
            LengthCM:'',
            WidthCM:'',
            HeightCM:'',
        }
    }
    onChange=(e)=>{
        const name = e.target.name;
        const value = e.target.value;
        this.setState({
            [name]:value
        })
    };
    handleClose=()=>{
        this.setState({
            Name:'',
            LengthCM:'',
            WidthCM:'',
            HeightCM:'',
        },()=>{
            $('#newShippingPackage').modal('hide');
        })
    };
    handleCreateShippingPackage = async()=>{
        const data = this.state;
        data.VolumeCM3 = (parseFloat(data.LengthCM) * parseFloat(data.WidthCM) * parseFloat(data.HeightCM)).toFixed(0);
        data.LengthCM = parseFloat(data.LengthCM).toFixed(2);
        data.WidthCM = parseFloat(data.WidthCM).toFixed(2);
        data.HeightCM = parseFloat(data.HeightCM).toFixed(2);
        const resData = await createShippingPackage(data);
        this.props.addNewShippingPackageToList(resData);
        this.setState({
            Name:'',
            LengthCM:'',
            WidthCM:'',
            HeightCM:'',
        },()=>{
            $('#newShippingPackage').modal('hide');
        })
    };
    render(){
        return(<div className="modal fade" id="newShippingPackage" tabIndex="-1" role="dialog"
                    aria-labelledby="newShippingPackage" aria-hidden="true" style={{backgroundColor:'floralwhite'}}>
            <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div className="modal-content" style={{backgroundColor:'floralwhite'}}>
                    <div className="modal-header">
                        <h3 className="modal-title text-center" id="newShippingPackageTitle" style={{fontWeight:'bold'}}>New Shipping Package</h3>
                        <button type="button" className="close"
                                onClick={this.handleClose}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <table className={'table table-bordered newShippingPackageDetail'}>
                            <thead>
                            </thead>
                            <tbody>
                            <tr>
                                <th>Package Name</th>
                                <td><input type={'text'} onChange={this.onChange}
                                           style={{width:'100%'}}  name={'Name'} value={this.state.Name} /></td>
                            </tr>
                            <tr>
                                <th>Length (CM)</th>
                                <td><input type={'text'} onChange={this.onChange}
                                           style={{width:'100%'}}  name={'LengthCM'} value={this.state.LengthCM} /></td>
                            </tr>
                            <tr>
                                <th>Width (CM)</th>
                                <td><input type={'text'} onChange={this.onChange}
                                           style={{width:'100%'}}  name={'WidthCM'} value={this.state.WidthCM} /></td>
                            </tr>
                            <tr>
                                <th>Height (CM)</th>
                                <td><input type={'text'} onChange={this.onChange}
                                           style={{width:'100%'}}  name={'HeightCM'} value={this.state.HeightCM} /></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="modal-footer row" style={{width:'90%',margin:"0 auto"}}>
                        <div className={'col'}> </div>
                        <div className={'col'}>
                            <button className={'btn btn-danger'} onClick={this.handleCreateShippingPackage} >Create Shipping Package</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
    }
}
export default NewShippingPackage;