import React from 'react';
import { brightPearlPriceList,upDatePriceList,getBPheader  } from '../../Utils/brightpearl';
import SelectInputWithObject from "../bootstrapComponent/selectInputWithObject";
const BrightPearlProductDetailModal =({product,selectedPriceListID,isEditingStock,editWarehouseId,handleInventoryOnChange,updatedInventory,
                                          handlePriceListChange,handleOnchange,handleSave,handleEditButtonClick,handleUpdateInventory,handleRestSkuDetail
                                        })=>{

        const selectedPriceList = product.priceLists.find(p=>p.priceListId === parseInt(selectedPriceListID));
        return (
            <div className="modal fade" id="brightPearlProductDetailModal" tabIndex="-1" role="dialog"
                 aria-labelledby="brightPearlProductDetailModalTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title text-center" id="brightPearlProductDetailModalTitle">Product
                                Information Detail</h1>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleRestSkuDetail}>
                                <span aria-hidden="true" >&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            Name : {product.salesChannels[0].productName}
                            <hr/>
                            <div className={'row'}>
                                <div className={'col'}>
                                    <h3 className={'text-center'}>Price List</h3>
                                    <SelectInputWithObject value={brightPearlPriceList} label={'Select price list to edit'}
                                                           name = {'listName'}
                                                           onChange = {handlePriceListChange}
                                                           style={{}}
                                    />
                                    {selectedPriceList !== undefined &&
                                        <div>
                                            <table className="table">
                                                <thead>
                                                <tr>
                                                    <th scope="col">Break:</th>
                                                    <th scope="col"> Price (Excluding Tax)</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <th scope="row">1+:</th>
                                                    <td><input value={selectedPriceList.quantityPrice["1"]} name={'1'} onChange={handleOnchange}/></td>
                                                </tr>
                                                {selectedPriceList.quantityPrice["6"] !== undefined &&
                                                    <tr>
                                                        <th scope="row">6+:</th>
                                                        <td><input value={selectedPriceList.quantityPrice["6"]} name={'6'} onChange={handleOnchange}/></td>
                                                    </tr>
                                                }
                                                {selectedPriceList.quantityPrice["24"] !== undefined &&
                                                    <tr>
                                                        <th scope="row">24+:</th>
                                                        <td><input value={selectedPriceList.quantityPrice["24"]} name={'24'} onChange={handleOnchange}/></td>
                                                    </tr>
                                                }
                                                {selectedPriceList.quantityPrice["50"] !== undefined &&
                                                    <tr>
                                                        <th scope="row">50+:</th>
                                                        <td><input value={selectedPriceList.quantityPrice["50"]} name={'50'} onChange={handleOnchange}/></td>
                                                    </tr>
                                                }
                                                {selectedPriceList.quantityPrice["100"] !== undefined &&
                                                    <tr>
                                                        <th scope="row">100+:</th>
                                                        <td><input value={selectedPriceList.quantityPrice["100"]} name={'100'} onChange={handleOnchange}/></td>
                                                    </tr>
                                                }
                                                {selectedPriceList.quantityPrice["250"] !== undefined &&
                                                    <tr>
                                                        <th scope="row">250+:</th>
                                                        <td><input value={selectedPriceList.quantityPrice["250"]} name={'250'} onChange={handleOnchange}/></td>
                                                    </tr>
                                                }
                                                {selectedPriceList.quantityPrice["500"] !== undefined &&
                                                    <tr>
                                                        <th scope="row">500+:</th>
                                                        <td><input value={selectedPriceList.quantityPrice["500"]} name={'500'} onChange={handleOnchange}/></td>
                                                    </tr>
                                                }
                                                {selectedPriceList.quantityPrice["1000"] !== undefined &&
                                                    <tr>
                                                        <th scope="row">1000+:</th>
                                                        <td><input value={selectedPriceList.quantityPrice["1000"]} name={'1000'} onChange={handleOnchange}/></td>
                                                    </tr>
                                                }
                                                {selectedPriceList.quantityPrice["2500"] !== undefined &&
                                                    <tr>
                                                        <th scope="row">2500+:</th>
                                                        <td><input value={selectedPriceList.quantityPrice["2500"]} name={'2500'} onChange={handleOnchange}/></td>
                                                    </tr>
                                                }
                                                </tbody>
                                            </table>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-danger" onClick={handleSave}>Save Price</button>
                                            </div>
                                        </div>
                                    }

                                </div>
                                <div className={'col'}>
                                    <h3 className={'text-center'}>Total Stock / Inventory</h3>
                                    <table className="table table-bordered text-center">
                                        <thead>
                                        <tr>
                                            <th scope="col">In Stock</th>
                                            <th scope="col">On Hand</th>
                                            <th scope="col">Allocated</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr className={''}>
                                            <td>{product.inventory.total.inStock}</td>
                                            <td>{product.inventory.total.onHand}</td>
                                            <td>{product.inventory.total.allocated}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    {
                                        <div>
                                            <h4 className={'text-center text-secondary'}>LeanSupply Stock</h4>
                                            <table className="table table-bordered text-center">
                                                <thead>
                                                <tr>
                                                    <th scope="col">In Stock</th>
                                                    <th scope="col">On Hand</th>
                                                    <th scope="col">Allocated</th>
                                                    <th scope="col">Add/Edit</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr className={''}>
                                                    <td>{product.inventory.warehouses['5']!==undefined ? product.inventory.warehouses['5'].inStock:0}</td>
                                                    <td>{product.inventory.warehouses['5']!==undefined ?product.inventory.warehouses['5'].onHand: 0}</td>
                                                    <td>{product.inventory.warehouses['5']!==undefined ? product.inventory.warehouses['5'].allocated:0}</td>
                                                    <td><button onClick={(e)=>handleEditButtonClick(e,5)}>Add/Edit</button></td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    }
                                    {
                                        <div>
                                            <h4 className={'text-center text-secondary'}>Ashlin Stock</h4>
                                            <table className="table table-bordered text-center">
                                                <thead>
                                                <tr>
                                                    <th scope="col">In Stock</th>
                                                    <th scope="col">On Hand</th>
                                                    <th scope="col">Allocated</th>
                                                    <th scope="col">Add/Edit</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr className={''}>
                                                    <td>{product.inventory.warehouses['2']!==undefined ? product.inventory.warehouses['2'].inStock:0}</td>
                                                    <td>{product.inventory.warehouses['2']!==undefined ?product.inventory.warehouses['2'].onHand: 0}</td>
                                                    <td>{product.inventory.warehouses['2']!==undefined ? product.inventory.warehouses['2'].allocated:0}</td>
                                                    <td><button onClick={(e)=>handleEditButtonClick(e,2)}>Add/Edit</button></td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    }
                                    {isEditingStock &&
                                        <div>
                                            <h5 className={'text-center text-danger'}>Add/Remove {editWarehouseId===5?"LeanSupply":"Ashlin"} Stock</h5>
                                            <table className="table table-bordered text-center">
                                                <thead>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <th scope="row">Quantity</th>
                                                    <td><input name={"quantity"} onChange={handleInventoryOnChange} value={updatedInventory.quantity!==undefined?updatedInventory.quantity:""}/></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Cost</th>
                                                    <td><input  name="cost" onChange={handleInventoryOnChange} value={updatedInventory.cost!==undefined?updatedInventory.cost:""}/></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Reason</th>
                                                    <td><input  name="reason" onChange={handleInventoryOnChange} value={updatedInventory.reason!==undefined?updatedInventory.reason:""}/></td>
                                                </tr>
                                                </tbody>
                                            </table>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-danger" onClick={handleUpdateInventory}>Update Inventory</button>
                                            </div>
                                        </div>
                                    }

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    };

export default BrightPearlProductDetailModal