import React from 'react';

const brightpearlPendingOrdersAlertModal = ({pendingOrdersData,dispatchedOrdersData,sendNotification})=>{
    return(
        <div className="modal fade" id="brightpearlPendingOrdersAlertModal" tabIndex="-1" role="dialog" data-backdrop="static"
             aria-labelledby="brightpearlPendingOrdersAlertModalTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title text-center" id="brightpearlPendingOrdersAlertModalTitle">Brightpearl in Processing Orders</h1>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {
                            pendingOrdersData.length > 0 &&
                            <div>
                                <br/>
                                <h3 className={"text-center"}>Pending Orders - Need to dispatch</h3>
                                <table className={'table table-bordered'}>
                                    <thead>
                                    <tr>
                                        <th>Order ID</th>
                                        <th>Sales Channel</th>
                                        <th>Channel Reference</th>
                                        <th>Created Date</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {pendingOrdersData.map((p,i)=>{
                                        return( <tr key={i}>
                                            <td>{p.orderId}</td>
                                            <td>{p.channel}</td>
                                            <td>{p.ref}</td>
                                            <td>{p.createdDate}</td>
                                        </tr>)
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        }

                        {
                            dispatchedOrdersData.length > 0 &&
                            <div>
                                <br/>
                                <h3 className={"text-center"}>Dispatched Orders - Need to track shipment</h3>
                                <table className={'table table-bordered'}>
                                    <thead>
                                    <tr>
                                        <th>Order ID</th>
                                        <th>Sales Channel</th>
                                        <th>Channel Reference</th>
                                        <th>Created Date</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {dispatchedOrdersData.map((p,i)=>{
                                        return( <tr key={i}>
                                            <td>{p.orderId}</td>
                                            <td>{p.channel}</td>
                                            <td>{p.ref}</td>
                                            <td>{p.createdDate}</td>
                                        </tr>)
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        }
                        {
                            ( pendingOrdersData.length>0 || dispatchedOrdersData.length>0 )&&<div>
                                <br/>
                               <button type={'button'} className={'btn btn-success btn-block'} onClick={sendNotification}>Send notification to sales team</button>
                            </div>
                        }
                        {
                            ( pendingOrdersData.length===0 && dispatchedOrdersData.length===0 )&&<div>
                                <h3 className={"text-center"}>No in Processing Orders</h3>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
};
export default brightpearlPendingOrdersAlertModal;