import React, {Component} from 'react';
import MainSideBar from '../sideBar/MainSideBar';

class UniShip extends Component {
    _mounted = false;
    constructor(props){
        super(props);
        this.state={
            isCreatingShipment: true,
            currentStatus: 'NEW',
            shipFrom:{
                addressLine1:'',
                addressLine2:'',
                addressLine3:'',
                city:'',
                state:'',
                countryCode:'',
                zip:'',
                companyName:'',
                contactName:'',
                contactPhone:'',
                contactPhoneExt:''
            },
            shipTo:{
                addressLine1:'',
                addressLine2:'',
                addressLine3:'',
                city:'',
                state:'',
                countryCode:'',
                zip:'',
                companyName:'',
                contactName:'',
                contactPhone:'',
                contactPhoneExt:''
            },
            isResidential: false
        }
    }
    // componentDidMount(){
    //     this._mounted = true;
    //     this.getData();
    // }
    // getData=async()=>{
    //     this.setState({
    //         isDownLoading:true
    //     },async()=>{
    //
    //     })
    // };
    // componentWillUnmount() {
    //     this._mounted = false;
    // }
    handleOnchange=(e)=>{
        const name = e.target.name;
        const value =e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        this.setState({
            [name]:value
        });
    };
    handleComplexOnchange=(field,e)=>{
        const name = e.target.name;
        const value =e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        const property = this.state[field];
        property[name] = value;
        this.setState({
            [field]:property
        });
    };
    handleRenderView=()=>{
        //status:'NEW','ENTER_SHIP_FROM', 'ENTER_SHIP_TO', 'IS_RESIDENTIAL', 'ENTER_PACKAGE_INFO'
        const currentStatus = this.state.currentStatus;
        switch (currentStatus) {
            case 'ENTER_SHIP_FROM':
                return this.renderEnterShipFrom();
            case 'ENTER_SHIP_TO':
                return this.renderEnterShipTo();
            default:
                return this.renderNew();
        }
    };
    renderNew =()=>{
        return <div>
            <br/>
            <h1 style={{margin:'10px', padding:'50px 5px'}} className={'display-4 font-weight-bold text-info'}>Welcome to Uniship shipping management</h1>
            <br/><br/>
            <div className={'row'}>
                <div className={'col-6'}>
                    <div className={'bg-info'} style={{margin:'10px', padding:'50px 5px'}} onClick={()=>this.setState({currentStatus:'ENTER_SHIP_FROM', isCreatingShipment:false})} >
                        <h3>GET RATE ONLY ?</h3>
                    </div>
                </div>
                <div className={'col-6'}>
                    <div className={'bg-info'} style={{margin:'10px', padding:'50px 5px'}} onClick={()=>this.setState({currentStatus:'ENTER_SHIP_FROM'})} >
                        <h3>CREATE SHIPMENT ?</h3>
                    </div>
                </div>
            </div>
        </div>
    };
    renderEnterShipFrom = () =>{
        return <div>
            <h1 style={{padding:'50px 5px'}} className={'display-4 font-weight-bold text-info'}>Please Enter Ship From Information</h1>
            <table className="table table-bordered enter-ship-from" style={{fontSize:'30px'}}>
                <tbody>
                <tr>
                    <th scope="row" style={{padding:'0'}}>Address1</th>
                    <td style={{padding:'0'}}><input type={'text'} style={{width:'100%', height:'100%', boxSizing:'border-box', }} value={this.state.shipFrom.addressLine1} onChange={(e)=>this.handleComplexOnchange('shipFrom',e,)} name={'addressLine1'}/></td>
                </tr>
                <tr>
                    <th scope="row" style={{padding:'0'}}>Address2</th>
                    <td style={{padding:'0'}}><input type={'text'} style={{width:'100%', height:'100%', boxSizing:'border-box', }} value={this.state.shipFrom.addressLine2} onChange={(e)=>this.handleComplexOnchange('shipFrom',e,)} name={'addressLine2'}/></td>
                </tr>
                <tr>
                    <th scope="row" style={{padding:'0'}}>Address3</th>
                    <td style={{padding:'0'}}><input type={'text'} style={{width:'100%', height:'100%', boxSizing:'border-box', }} value={this.state.shipFrom.addressLine3} onChange={(e)=>this.handleComplexOnchange('shipFrom',e,)} name={'addressLine3'}/></td>
                </tr>
                <tr>
                    <th scope="row" style={{padding:'0'}}>City</th>
                    <td style={{padding:'0'}}><input type={'text'} style={{width:'100%', height:'100%', boxSizing:'border-box', }} value={this.state.shipFrom.city} onChange={(e)=>this.handleComplexOnchange('shipFrom',e,)} name={'city'}/></td>
                </tr>
                <tr>
                    <th scope="row" style={{padding:'0'}}>State</th>
                    <td style={{padding:'0'}}><input type={'text'} style={{width:'100%', height:'100%', boxSizing:'border-box', }} value={this.state.shipFrom.state} onChange={(e)=>this.handleComplexOnchange('shipFrom',e,)} name={'state'}/></td>
                </tr>
                <tr>
                    <th scope="row" style={{padding:'0'}}>Country Code</th>
                    <td style={{padding:'0'}}><input type={'text'} style={{width:'100%', height:'100%', boxSizing:'border-box', }} value={this.state.shipFrom.countryCode} onChange={(e)=>this.handleComplexOnchange('shipFrom',e,)} name={'countryCode'}/></td>
                </tr>
                <tr>
                    <th scope="row" style={{padding:'0'}}>Postal Code</th>
                    <td style={{padding:'0'}}><input type={'text'} style={{width:'100%', height:'100%', boxSizing:'border-box', }} value={this.state.shipFrom.zip} onChange={(e)=>this.handleComplexOnchange('shipFrom',e,)} name={'zip'}/></td>
                </tr>
                {/*<tr>*/}
                {/*    <td scope="row" colSpan={'2'} style={{padding:'20px 0'}} className={'text-danger'}>If you only want to get the rates, please ignore the following fields</td>*/}
                {/*</tr>*/}
                {this.state.isCreatingShipment &&
                    <tr>
                        <th scope="row" style={{padding: '0'}}>Company Name</th>
                        <td style={{padding: '0'}}><input type={'text'}
                                                          style={{width: '100%', height: '100%', boxSizing: 'border-box',}}
                                                          value={this.state.shipFrom.companyName}
                                                          onChange={(e) => this.handleComplexOnchange('shipFrom', e,)}
                                                          name={'companyName'}/></td>
                    </tr>
                }
                {this.state.isCreatingShipment &&
                    <tr>
                        <th scope="row" style={{padding:'0'}}>Contact Name</th>
                        <td style={{padding:'0'}}><input type={'text'} style={{width:'100%', height:'100%', boxSizing:'border-box', }} value={this.state.shipFrom.contactName} onChange={(e)=>this.handleComplexOnchange('shipFrom',e,)} name={'contactName'}/></td>
                    </tr>
                }
                {this.state.isCreatingShipment &&
                    <tr>
                        <th scope="row" style={{padding:'0'}}>Contact Phone</th>
                        <td style={{padding:'0'}}><input type={'text'} style={{width:'100%', height:'100%', boxSizing:'border-box', }} value={this.state.shipFrom.contactPhone} onChange={(e)=>this.handleComplexOnchange('shipFrom',e,)} name={'contactPhone'}/></td>
                    </tr>
                }
                {this.state.isCreatingShipment &&
                    <tr>
                        <th scope="row" style={{padding:'0'}}>Contact PhoneExt</th>
                        <td style={{padding:'0'}}><input type={'text'} style={{width:'100%', height:'100%', boxSizing:'border-box', }} value={this.state.shipFrom.contactPhoneExt} onChange={(e)=>this.handleComplexOnchange('shipFrom',e,)} name={'contactPhoneExt'}/></td>
                    </tr>
                }
                <tr>
                    <td colSpan={'2'}>
                        <button className={'btn btn-primary col-4'} style={{width:'80%'}} onClick={()=>this.setState({currentStatus:'NEW', isCreatingShipment : true})} >BACK</button>
                        <span className={'col-1'}> </span>
                        <button className={'btn btn-danger col-2'} style={{width:'80%'}}
                                onClick={()=>this.setState({shipFrom:{
                                    addressLine1:'',
                                    addressLine2:'',
                                    addressLine3:'',
                                    city:'',
                                    state:'',
                                    countryCode:'',
                                    zip:'',
                                    companyName:'',
                                    contactName:'',
                                    contactPhone:'',
                                    contactPhoneExt:''
                                }})}>CLEAR</button>
                        <span className={'col-1'}> </span>
                        <button className={'btn btn-success col-4'} style={{width:'80%'}} onClick={()=>this.setState({currentStatus:'ENTER_SHIP_TO'})} >NEXT</button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    };
    renderEnterShipTo = () =>{
        return <div>
            <h1 style={{padding:'50px 5px'}} className={'display-4 font-weight-bold text-info'}>Please Enter Ship To Information</h1>
            <table className="table table-bordered enter-ship-to" style={{fontSize:'30px'}}>
                <tbody>
                <tr>
                    <th scope="row" style={{padding:'0'}}>Address1</th>
                    <td style={{padding:'0'}}><input type={'text'} style={{width:'100%', height:'100%', boxSizing:'border-box', }} value={this.state.shipTo.addressLine1} onChange={(e)=>this.handleComplexOnchange('shipTo',e,)} name={'addressLine1'}/></td>
                </tr>
                <tr>
                    <th scope="row" style={{padding:'0'}}>Address2</th>
                    <td style={{padding:'0'}}><input type={'text'} style={{width:'100%', height:'100%', boxSizing:'border-box', }} value={this.state.shipTo.addressLine2} onChange={(e)=>this.handleComplexOnchange('shipTo',e,)} name={'addressLine2'}/></td>
                </tr>
                <tr>
                    <th scope="row" style={{padding:'0'}}>Address3</th>
                    <td style={{padding:'0'}}><input type={'text'} style={{width:'100%', height:'100%', boxSizing:'border-box', }} value={this.state.shipTo.addressLine3} onChange={(e)=>this.handleComplexOnchange('shipTo',e,)} name={'addressLine3'}/></td>
                </tr>
                <tr>
                    <th scope="row" style={{padding:'0'}}>City</th>
                    <td style={{padding:'0'}}><input type={'text'} style={{width:'100%', height:'100%', boxSizing:'border-box', }} value={this.state.shipTo.city} onChange={(e)=>this.handleComplexOnchange('shipTo',e,)} name={'city'}/></td>
                </tr>
                <tr>
                    <th scope="row" style={{padding:'0'}}>State</th>
                    <td style={{padding:'0'}}><input type={'text'} style={{width:'100%', height:'100%', boxSizing:'border-box', }} value={this.state.shipTo.state} onChange={(e)=>this.handleComplexOnchange('shipTo',e,)} name={'state'}/></td>
                </tr>
                <tr>
                    <th scope="row" style={{padding:'0'}}>Country Code</th>
                    <td style={{padding:'0'}}><input type={'text'} style={{width:'100%', height:'100%', boxSizing:'border-box', }} value={this.state.shipTo.countryCode} onChange={(e)=>this.handleComplexOnchange('shipTo',e,)} name={'countryCode'}/></td>
                </tr>
                <tr>
                    <th scope="row" style={{padding:'0'}}>Postal Code</th>
                    <td style={{padding:'0'}}><input type={'text'} style={{width:'100%', height:'100%', boxSizing:'border-box', }} value={this.state.shipTo.zip} onChange={(e)=>this.handleComplexOnchange('shipTo',e,)} name={'zip'}/></td>
                </tr>
                {/*<tr>*/}
                {/*    <td scope="row" colSpan={'2'} style={{padding:'20px 0'}} className={'text-danger'}>If you only want to get the rates, please ignore the following fields</td>*/}
                {/*</tr>*/}
                {this.state.isCreatingShipment &&
                    <tr>
                        <th scope="row" style={{padding:'0'}}>Company Name</th>
                        <td style={{padding:'0'}}><input type={'text'} style={{width:'100%', height:'100%', boxSizing:'border-box', }} value={this.state.shipTo.companyName} onChange={(e)=>this.handleComplexOnchange('shipTo',e,)} name={'companyName'}/></td>
                    </tr>
                }
                {this.state.isCreatingShipment &&
                    <tr>
                        <th scope="row" style={{padding:'0'}}>Contact Name</th>
                        <td style={{padding:'0'}}><input type={'text'} style={{width:'100%', height:'100%', boxSizing:'border-box', }} value={this.state.shipTo.contactName} onChange={(e)=>this.handleComplexOnchange('shipTo',e,)} name={'contactName'}/></td>
                    </tr>
                }
                {this.state.isCreatingShipment &&
                    <tr>
                        <th scope="row" style={{padding:'0'}}>Contact Phone</th>
                        <td style={{padding:'0'}}><input type={'text'} style={{width:'100%', height:'100%', boxSizing:'border-box', }} value={this.state.shipTo.contactPhone} onChange={(e)=>this.handleComplexOnchange('shipTo',e,)} name={'contactPhone'}/></td>
                    </tr>
                }
                {this.state.isCreatingShipment &&
                    <tr>
                        <th scope="row" style={{padding:'0'}}>Contact PhoneExt</th>
                        <td style={{padding:'0'}}><input type={'text'} style={{width:'100%', height:'100%', boxSizing:'border-box', }} value={this.state.shipTo.contactPhoneExt} onChange={(e)=>this.handleComplexOnchange('shipTo',e,)} name={'contactPhoneExt'}/></td>
                    </tr>
                }
                <tr>
                    <td colSpan={'2'}>
                        <button className={'btn btn-primary col-4'} style={{width:'80%'}} onClick={()=>this.setState({currentStatus:'ENTER_SHIP_FROM'})} >BACK</button>
                        <span className={'col-1'}> </span>
                        <button className={'btn btn-danger col-2'} style={{width:'80%'}}
                                onClick={()=>this.setState({shipTo:{
                                        addressLine1:'',
                                        addressLine2:'',
                                        addressLine3:'',
                                        city:'',
                                        state:'',
                                        countryCode:'',
                                        zip:'',
                                        companyName:'',
                                        contactName:'',
                                        contactPhone:'',
                                        contactPhoneExt:''
                                    }})}>CLEAR</button>
                        <span className={'col-1'}> </span>
                        <button className={'btn btn-success col-4'} style={{width:'80%'}} onClick={()=>this.setState({currentStatus:'IS_RESIDENTIAL'})} >NEXT</button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    };
    render(){
        return(
            <div className='container-fluid row'>
                <div className={'col-3'}>
                    <MainSideBar/>
                </div>
                <div className={'col-9'} style={{textAlign:'center'}}>
                    <br/>
                    <h1 className={'text-muted display-2 font-weight-bold'}>UniShip</h1>
                    <hr/>
                    <hr/>
                    {this.handleRenderView()}
                </div>
            </div>
        )}
}
export default UniShip;