import React from 'react';
const bundled_item_list_item = ({item,index,deleteBundledItem})=>{
    return(
        <tr>
            <td>
                <span style={{fontWeight:'bold'}}>{item.Sku}</span>
            </td>
            <td>
                {
                    item.Items.map(i=>`${i.Item} * ${i.Qty}`).join(',  ')
                }
            </td>
            <td>
                <button type={'button'} className={'btn-danger'} onClick={()=>deleteBundledItem(index)}>Delete</button>
            </td>
        </tr>
    )

};
export default bundled_item_list_item;