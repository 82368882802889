import React, { Component } from 'react';
import ReactTable from "react-table";
import MainSideBar from '../sideBar/MainSideBar';
import SelectInput from '../bootstrapComponent/selectInput';
import {getListedSkus,postNewImage} from "../../Utils/ashlinDesign";
import ImagesList from './components/imagesList';
import {findProductBySku, getImages, getStoreInfo, deleteProductImage, addImage,getVariants,findProductById } from "../../Utils/bigCommerce";
import axios from "axios/index";
import {baseURL} from "../../Utils/requestHelper";

class ManageImage extends Component{
    constructor(props){
        super(props);
        this.state={
            selectedChannel:"",
            publishedSku:[],
            isDownLoading:false,
            isUpLoading:false,
            isSync:false,
            currentProduct:null,
            selectedSku:null,
            currentImages:[],
            addingNew:false,
            BASE:[0],
            MODEL:[0],
            LIFE:[0],
            GROUP:[0],
            MAIN:[0],
        }
    }
    componentDidMount(){

    }
    handleOnchange=(e)=>{
        const selectedChannel = e.target.value;
        if(selectedChannel !== 'optionDefaultHeader'){
            //get Skus listed on the selected Channel
            this.setState({
                isDownLoading:true,
                publishedSku:[],
                isUpLoading:false,
                isSync:false,
                currentProduct:null,
                selectedSku:null,
                currentImages:[],
                addingNew:false,
                BASE:[0],
                MODEL:[0],
                LIFE:[0],
                GROUP:[0],
                MAIN:[0],
            },async ()=>{
                const listedSkusRes = await getListedSkus(selectedChannel);
                let listedSkus = listedSkusRes.data;
                Promise.all([listedSkusRes]).then(()=>{
                    this.setState({
                        selectedChannel,
                        publishedSku:listedSkus,
                        isDownLoading:false
                    });
                })
            });
        }
    };
    handleDetail=async()=>{
        this.setState({
            currentImages:[],
            addingNew:false,
            BASE:[0],
            MODEL:[0],
            LIFE:[0],
            GROUP:[0],
            MAIN:[0]
        },async()=>{
            const storeInfo = await getStoreInfo(this.state.selectedChannel);
                const productID = this.state.selectedSku.MerchanSku.split('+')[0];
                const findRes = await findProductById (storeInfo,productID);
                const productBG = findRes.data.data;
                const oldProductImagesRes = await getImages(storeInfo,productBG.id);
                const currentImages = oldProductImagesRes.data.data;
                this.setState({
                    currentImages,
                    currentProduct:productBG
                })

        });

    };
    handleDelete = async(productId,imageId)=>{
        let confirmBox = window.confirm(`Do you confirm to delete this image?`);
        if (confirmBox) {
            const currentImages = this.state.currentImages;
            const index = currentImages.findIndex(i=>i.id===imageId);
            const storeInfo = await getStoreInfo(this.state.selectedChannel);
            await deleteProductImage(storeInfo,productId,imageId);
            currentImages.splice(index,1);
            this.setState({
                currentImages
            });
        }
    };
    handleAddNew=(e)=>{
        e.preventDefault();
        this.setState({
            addingNew:true
        })
    };
    handleSubmitImage=async(headers,designCode,image,imageType,lastModified,storeInfo,description,sort)=>{
        /********************* POST TO HOSTINGER START ****************************/
        let data= new FormData();
        data.append("image", image);
        data.append("folder", designCode);
        data.append("vince", "8sHTj5LV527LqdWAQBZSDEXrohTSBx9FXyPnGBamXaj60vNCsDMpUTEsyqXDF4MSuDij4tEoZB7O1vdgVR1H8FLezeBgcRGndRb5MSd70mhYgO5d82wA1lIfPg95W3fXyQn7PVlcisOJFbjSMEOt6P6W2p5Xu4IMFodO5z94Hm25rQGr6iodKTGOwZruXtUX9zv7NFx9");
        await axios.post("https://ashlinapp.xyz/api/image-uploads.php",data,{
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        const hostingerURL = `https://ashlinapp.xyz/ProductMediaContent/${designCode}/${image.name}`;
        //Post new Image to HOSTINGER
        await addImage(storeInfo,this.state.currentProduct.id,false,sort,description+image.name,hostingerURL);
        /************************* POST TO HOSTINGER FINISH ************************/

        /********************* POST TO GOOGLE DRIVE START ****************************/

        /*************Get Folder Path**********************/
        const searchFolderName = designCode;
        //this master Folder ID will be Product Media Content's ID
        const masterFolderID = '0B1wi-RUM_bTlYi1uTnE2eGlsSDQ';
        const query = `q=name+%3d+%27${searchFolderName}%27&mimeType+%3d+%27application/vnd.google-apps.folder%27`;
        const resSearchFolder = await axios.get(`https://www.googleapis.com/drive/v3/files?${query}`, {headers});
        //Destination Folder for new SKU images on Google Drive
        let folder;
        if (resSearchFolder.data.files.length === 0) {
            console.log('Folder does not exist!');
            const newFolder = {
                name: searchFolderName,
                mimeType: "application/vnd.google-apps.folder",
                parents: [masterFolderID],
            };
            const resNewFolder = await axios.post('https://www.googleapis.com/drive/v3/files', newFolder, {headers});
            folder = resNewFolder.data;
        } else {
            console.log('Find folder!');
            folder = resSearchFolder.data.files[0];
        }
        /**********Create New File**********************/
        const newFile={
            name: image.name,
            parents: [folder.id],
        };
        const resNewFile =await axios.post('https://www.googleapis.com/drive/v3/files',newFile,{headers});
        const resUpdatedFile =await axios.patch(`https://www.googleapis.com/upload/drive/v3/files/${resNewFile.data.id}`,image,{headers});
        const googleURL =`https://drive.google.com/uc?export=download&id=${resUpdatedFile.data.id}`;

        /********************* POST TO GOOGLE DRIVE FINISH ****************************/
        const hostingerData = {
            "Sku":this.state.selectedSku.Sku,
            "ImageType":imageType,
            "ImageUrl":hostingerURL,
            "Host":"Hostinger",
            "LastModifiedDate":lastModified
        };
        const googleData = {
            "Sku":this.state.selectedSku.Sku,
            "ImageType":imageType,
            "ImageUrl":googleURL,
            "Host":"Google",
            "LastModifiedDate":lastModified
        };

        /********************* SAVE URL IN ASHLIN DATABASE START ****************************/

        //Save URL in Ashlin Database
        const ashlinInput = [hostingerData,googleData];
        Promise.all([resNewFile,resUpdatedFile]).then(async()=>{
            await postNewImage(ashlinInput);
        });
        /********************** SAVE URL IN ASHLIN DATABASE FINISH ***************************/
    };

    handleUploadImage=(e)=>{
        e.preventDefault();
        this.setState({
            isUpLoading:true
        },async()=>{
            const storeInfo = await getStoreInfo(this.state.selectedChannel);
            let description = this.state.currentProduct.name;
            description = description.replace(/\[.*?\]/g, " ");
            const designCode =this.state.currentProduct.sku.split('-')[0];
            const tokenRes = await axios.get(`${baseURL}api/google-token`);
            const token = tokenRes.data;
            const headers = {
                Authorization: `Bearer ${token}`
            };
            const d = new Date(new Date().toString().split('GMT')[0]+' UTC').toISOString();
            const last = d.slice(0,19);
            //MAIN
            const resMAIN = await this.state.MAIN.map(async image=>{
                if(image !==0) {
                  return await this.handleSubmitImage(headers,designCode,image,'MAIN',last,storeInfo,description,1);
                }
            });

            //BASE
            const resBASE = await this.state.BASE.map(async image=>{
                if(image !==0) {
                    return await this.handleSubmitImage(headers,designCode,image,'BASE',last,storeInfo,description,3);
                }
            });

            //MODEL
            const resMODEL = await this.state.MODEL.map(async image=>{
                if(image !==0) {
                    return await this.handleSubmitImage(headers,designCode,image,'MODEL',last,storeInfo,description,5);
                }
            });

            //LIFE
            const resLIFE = await this.state.LIFE.map(async image=>{
                if(image !==0) {
                    return await this.handleSubmitImage(headers,designCode,image,'LIFE',last,storeInfo,description,7);
                }
            });

            //GROUP
            const resGROUP = await this.state.GROUP.map(async image=>{
                if(image !==0) {
                    return await this.handleSubmitImage(headers,designCode,image,'GROUP',last,storeInfo,description,9);
                }
            });
            Promise.all([...resMAIN,...resBASE,...resMODEL,...resLIFE,...resGROUP]).then(()=>{
                this.setState({
                    addingNew:false,
                    isUpLoading:false
                },()=>{
                    alert("Finish Add new Images");
                })
            });

        });
    };
    handleRemoveSelect=(e,index,imageType)=>{
        e.preventDefault();
        const images = this.state[imageType];
        images.splice(index,1);
        this.setState({
            [imageType]:images
        });

    };
    handleImageOnDrop=(accepted,index,imageType)=>{
        //if(this.state.selectedChannel === "BigCommerce Ashlin") {
            let images= this.state[imageType];
            const todayDate = new Date().toISOString().slice(0,10);
            let fileName = `${this.state.selectedSku.Sku}_${imageType}_${index+1}_${ todayDate}`;
            if(accepted[0].type === 'image/jpeg'){
                fileName +=  '.jpg';
            }else if(accepted[0].type === 'image/png'){
                fileName +=  '.png';
            }
            const newFiles = new File(accepted,fileName,{type: accepted[0].type});
            newFiles.preview = accepted[0].preview;
            images.pop();
            images.push(newFiles);
            images.push(0);
            this.setState({
                [imageType]:images,
            });
       // }
    };

    handleSyncImage = async(e)=> {
        e.preventDefault();
        let confirmBox = window.confirm(`Do you confirm to Sync images of ${this.state.selectedSku.Sku}?`);
            if (confirmBox) {
                this.setState({
                    isSync:true
                },async()=>{
                const storeInfo = await getStoreInfo(this.state.selectedChannel);
                const productID = this.state.selectedSku.MerchanSku.split('+')[0];
                const findRes = await findProductById (storeInfo,productID);
                const productBG = findRes.data.data;
                //console.log(findRes);
                const findVariantRes = await getVariants(storeInfo,productBG.id);
                const variants = findVariantRes.data.data;
                //console.log(variants);


                const oldProductImagesRes = await getImages(storeInfo,productBG.id);

                const oldImages = oldProductImagesRes.data.data;

                const deleteImagesRes  = await oldImages.map(async (image)=>{
                    return deleteProductImage(storeInfo,image.product_id,image.id);
                });

                await Promise.all(deleteImagesRes).then(()=>{
                });

                const addNewImagesRes = variants.map(async(pro,index)=>{
                    const productDetailRes = await axios.get(`${baseURL}api/LegacySku/detail/${pro.sku}`);
                    const product= productDetailRes.data[0];

                    const imageDescription = product.Brand + " DESIGNER | " + product.Name + " " + product.ShortDescription.replace('.','')+" "+product.Sku;
                    let mainImageRes,imagesGROUPRes;

                    if(product.Urls.HostingerUrls.MAIN[0]!==undefined) {
                        if(index === 0) {
                            mainImageRes = await addImage(storeInfo, productBG.id, true, 1, imageDescription, product.Urls.HostingerUrls.MAIN[0]);
                        }else{
                            mainImageRes = await addImage(storeInfo, productBG.id, false, 1, imageDescription, product.Urls.HostingerUrls.MAIN[0]);
                        }
                    }

                    const imagesRes =await product.Urls.GoogleDriveUrls.BASE.map(async (img,index)=>{
                        return await addImage(storeInfo,productBG.id,false,(3),imageDescription+` BASE ${index}`,img);
                    });
                    const imagesModelRes =await product.Urls.GoogleDriveUrls.MODEL.map(async (img,index)=>{
                        return await addImage(storeInfo,productBG.id,false,(5),imageDescription+` MODEL ${index}`,img);
                    });
                    const imagesLIFERes =await product.Urls.GoogleDriveUrls.LIFE.map(async (img,index)=>{
                        return await addImage(storeInfo,productBG.id,false,(7),imageDescription+` LIFE ${index}`,img);
                    });
                    if(index ===0) {
                        imagesGROUPRes = await product.Urls.GoogleDriveUrls.GROUP.map(async (img, index) => {
                            return await addImage(storeInfo, productBG.id, false, (9), imageDescription + ` GROUP ${index}`, img);
                        });
                    }
                    await Promise.all([mainImageRes,imagesRes,imagesModelRes,imagesLIFERes,imagesGROUPRes]).then(()=>{
                    });
                });

                await Promise.all(addNewImagesRes).then(()=>{
                    alert("Finish Sync");
                    this.setState({
                        isSync:false
                    });
                });

            });
        }
    };
    render(){
        return(
            <div>
                <div className='container-fluid row'>
                    <div className={'col-3'}>
                        <MainSideBar/>
                    </div>
                    <div className={'col-9'}>
                        <br/> <br/>
                        <div className={'row justify-content-md-center'}>
                            <div className={'col-md-auto'}>
                                <h1>Manage Images</h1>
                            </div>
                            <div className={'col-md-auto'}>
                                <SelectInput value={['BigCommerce Ashlin','BigCommerce Poppybuzz']} style={{fontWeight:'bold',fontSize:'20px'}} label={"Channel Name"} onChange={this.handleOnchange}/>
                            </div>
                        </div>
                        {this.state.isUpLoading &&<div className={"text-center text-success display-4"}><i className="fa fa-spinner fa-spin"></i> &nbsp; Submitting...</div>}
                        {this.state.isDownLoading &&<div className={"text-center text-success display-4"}><i className="fa fa-spinner fa-spin"></i> &nbsp; Reading data from database...</div>}
                        {this.state.isSync &&<div className={"text-center text-success display-4"}><i className="fa fa-spinner fa-spin"></i> &nbsp; Updating, please wait...<br/><br/></div>}
                        <div className={'row'}>
                            { this.state.publishedSku[0] &&
                            <div className={'col'}>
                                <h2 style={{textAlign:'center'}}>Published SKU List</h2>
                                <ReactTable
                                    data={this.state.publishedSku}
                                    filterable
                                    defaultFilterMethod={(filter, row) =>
                                        String(row[filter.id]) === filter.value}
                                    columns={[
                                        {
                                            Header: "Enter SKU below to Search",
                                            id:'Sku',
                                            accessor: item=>item.Sku,
                                            filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value)

                                        }]
                                    }
                                    defaultPageSize={10}
                                    className="-striped -highlight orderTable"
                                    getTrProps={(state, rowInfo) => {
                                        return {
                                            onClick: () => {
                                                this.setState({
                                                    selectedSku: rowInfo.original
                                                },this.handleDetail);
                                            }
                                        }
                                    }}
                                />

                            </div>}
                            <div className={'col-9'}>
                                {this.state.currentImages[0]&&
                                     <ImagesList
                                        currentImages={this.state.currentImages}
                                        sku={this.state.selectedSku.Sku}
                                        deleteImage={this.handleDelete}
                                        addNew = {this.handleAddNew}
                                        addingNew={this.state.addingNew}
                                        uploadImage = {this.handleUploadImage}
                                        mainImages={this.state.MAIN}
                                        baseImages={this.state.BASE}
                                        modelImages={this.state.MODEL}
                                        lifeImages={this.state.LIFE}
                                        groupImages={this.state.GROUP}
                                        imageOnDrop={this.handleImageOnDrop}
                                        removeSelect={this.handleRemoveSelect}
                                        syncImage = {this.handleSyncImage}
                                     />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ManageImage;