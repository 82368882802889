import React from 'react';
import MainSideBar from '../sideBar/MainSideBar';
const BCOptionSet = ()=>{
    return(
        <div className='container-fluid row'>
            <div className={'col-3'}>
                <MainSideBar/>
            </div>
            <div>
                <h1>Option Set</h1>
            </div>
        </div>
    )
};

export default BCOptionSet;