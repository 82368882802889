import React from 'react';
import ListItem from './fba_location_list_item';
const fba_location_list = (props)=>{
    return(
        <div className={'list-group'}>
            {props.list.map((item,index)=><ListItem item={item} key={index} editingIndex={props.editingIndex} index={index}
                                                   FBALocationOnchange={props.FBALocationOnchange} cancelUpdate={props.cancelUpdate}
                                                    enableFBAEditing={props.enableFBAEditing} updateFBALocation={props.updateFBALocation}
                                            />)}
        </div>
    )
};
export default fba_location_list;