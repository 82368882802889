import React from 'react';
const CurrentImage = ({img,deleteImage})=>{
    // console.log(img);
  return(
      <div className={'col'} style={{border:'1px gray dashed',padding:'2px',marginBottom:'10px'}} >
          <div className={'btn-danger text-center'} onClick={()=>deleteImage(img.product_id,img.id)}>Delete</div>
          <img src={img.url_thumbnail} style={{width:'100px',height:'120px'}}/>


      </div>
  )
};

export default CurrentImage;