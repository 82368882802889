import React from 'react';
import ListItem from './company_info_list_item';
const company_info_list = (props)=>{
    return(
        <table className={'table table-bordered'}>
            <thead>
            <tr>
                <th>Company Name</th>
                <th>Logo URL</th>
                <th>Address 1</th>
                <th>Address 2</th>
                <th>City</th>
                <th>State</th>
                <th>Country</th>
                <th>Postal Code</th>

                <th>Contact</th>
                <th>Phone</th>
                <th>Email</th>
                <th>GST</th>
                <th>FEIN</th>
                <th>ACTION</th>
            </tr>
            </thead>
            <tbody>
            {props.list.map((item,index)=><ListItem item={item} key={index} index={index} updateCompanyInfo={props.updateCompanyInfo}
                                                    companyInfoOnChange={props.companyInfoOnChange}/>)}
            </tbody>
        </table>
    )
};
export default company_info_list;