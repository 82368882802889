import React, {Component} from 'react';
import $ from "jquery";
import {createShippingOption} from '../../../Utils/ashlinDesign';
class NewShippingOption extends Component{
    constructor(props){
        super(props);
        this.state={
            ChannelName:'',
            CarrierName:'',
            OptionLevel:'',
            ServiceType:'',
            BillingAddress:'',
            UserName:'',
            Password:'',
            AccountNum:'',
            ContractNum:'',
            PaidByNum:'',
            APICredentials:''
        }
    }
    newShippingOptionOnChange=(e)=>{
        const name = e.target.name;
        const value = e.target.value;
        this.setState({
            [name]:value
        })
    };
    handleClose=()=>{
      this.setState({
          ChannelName:'',
          CarrierName:'',
          OptionLevel:'',
          ServiceType:'',
          BillingAddress:'',
          UserName:'',
          Password:'',
          AccountNum:'',
          ContractNum:'',
          PaidByNum:'',
          APICredentials:''
      },()=>{
          $('#newShippingOptionDetail').modal('hide');
      })
    };
    handleCreateNewShippingOption=async()=>{
        const d = new Date();
        const n = d.toISOString().slice(0,19);
        const data = this.state;
        data.LastModifiedDate = n;
        const resData = await createShippingOption(data);
        this.props.addNewShippingOptionToList(resData);
        this.setState({
            ChannelName:'',
            CarrierName:'',
            OptionLevel:'',
            ServiceType:'',
            BillingAddress:'',
            UserName:'',
            Password:'',
            AccountNum:'',
            ContractNum:'',
            PaidByNum:'',
            APICredentials:''
        },()=>{
           $('#newShippingOptionDetail').modal('hide');
       })
    };
    render(){
        return(<div className="modal fade" id="newShippingOptionDetail" tabIndex="-1" role="dialog"
                    aria-labelledby="newShippingOptionDetail" aria-hidden="true" style={{backgroundColor:'floralwhite'}}>
            <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div className="modal-content" style={{backgroundColor:'floralwhite'}}>
                    <div className="modal-header">
                        <h3 className="modal-title text-center" id="newShippingOptionDetailTitle" style={{fontWeight:'bold'}}>New Shipping Option</h3>
                        <button type="button" className="close"
                                onClick={this.handleClose}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <table className={'table table-bordered shippingOptionDetail'}>
                            <thead>
                            </thead>
                            <tbody>
                            <tr>
                                <th>Channel Name</th>
                                <td><input type={'text'} onChange={this.newShippingOptionOnChange}
                                           style={{width:'100%'}}  name={'ChannelName'} value={this.state.ChannelName} /></td>
                            </tr>
                            <tr>
                                <th>Carrier Name</th>
                                <td><input type={'text'} onChange={this.newShippingOptionOnChange}
                                           style={{width:'100%'}}  name={'CarrierName'} value={this.state.CarrierName} /></td>
                            </tr>
                            <tr>
                                <th>Option Level</th>
                                <td><input type={'text'} onChange={this.newShippingOptionOnChange}
                                           style={{width:'100%'}}  name={'OptionLevel'} value={this.state.OptionLevel} /></td>
                            </tr>
                            <tr>
                                <th>Service Type</th>
                                <td><input type={'text'} onChange={this.newShippingOptionOnChange}
                                           style={{width:'100%'}}  name={'ServiceType'} value={this.state.ServiceType} /></td>
                            </tr>
                            <tr>
                                <th>BillingAddress</th>
                                <td><input type={'text'} onChange={this.newShippingOptionOnChange}
                                           style={{width:'100%'}}  name={'BillingAddress'} value={this.state.BillingAddress} /></td>
                            </tr>
                            <tr>
                                <th>User Name</th>
                                <td><input type={'text'} onChange={this.newShippingOptionOnChange}
                                           style={{width:'100%'}}  name={'UserName'} value={this.state.UserName} /></td>
                            </tr>
                            <tr>
                                <th>Password</th>
                                <td><input type={'text'} onChange={this.newShippingOptionOnChange}
                                           style={{width:'100%'}}  name={'Password'} value={this.state.Password} /></td>
                            </tr>
                            <tr>
                                <th>Account Number</th>
                                <td><input type={'text'} onChange={this.newShippingOptionOnChange}
                                           style={{width:'100%'}}  name={'AccountNum'} value={this.state.AccountNum} /></td>
                            </tr>
                            <tr>
                                <th>Contract Number</th>
                                <td><input type={'text'} onChange={this.newShippingOptionOnChange}
                                           style={{width:'100%'}}  name={'ContractNum'} value={this.state.ContractNum} /></td>
                            </tr>
                            <tr>
                                <th>PaidBy Number</th>
                                <td><input type={'text'} onChange={this.newShippingOptionOnChange}
                                           style={{width:'100%'}}  name={'PaidByNum'} value={this.state.PaidByNum} /></td>
                            </tr>
                            <tr>
                                <th>API Credentials</th>
                                <td><input type={'text'} onChange={this.newShippingOptionOnChange}
                                           style={{width:'100%'}}  name={'APICredentials'} value={this.state.APICredentials} /></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="modal-footer row" style={{width:'90%',margin:"0 auto"}}>
                        <div className={'col'}> </div>
                        <div className={'col'}>
                            <button className={'btn btn-danger'} onClick={this.handleCreateNewShippingOption} >Create Shipping Option</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
    }
}
export default NewShippingOption;