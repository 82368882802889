import React from 'react';
import $ from "jquery";

const shipping_option_list_item_detail =({item,index,shippingOptionOnChange, updateShippingOption})=>{
    return(<div className="modal fade" id="shippingOptionListItemDetail" tabIndex="-1" role="dialog"
                 aria-labelledby="shippingOptionListItemDetail" aria-hidden="true" style={{backgroundColor:'floralwhite'}}>
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div className="modal-content" style={{backgroundColor:'floralwhite'}}>
                <div className="modal-header">
                    <h3 className="modal-title text-center" id="shippingOptionListItemDetailTitle" style={{fontWeight:'bold'}}>Shipping Option Detail</h3>
                    <button type="button" className="close"
                            onClick={()=>{$('#shippingOptionListItemDetail').modal('hide')}}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <table className={'table table-bordered shippingOptionDetail'}>
                        <thead>
                        </thead>
                        <tbody>
                            <tr>
                                <th>Channel Name</th>
                                <td><input type={'text'} onChange={(e)=>shippingOptionOnChange(e,item)}
                                           style={{width:'100%'}}  name={'ChannelName'} value={item.ChannelName} /></td>
                            </tr>
                            <tr>
                                <th>Carrier Name</th>
                                <td><input type={'text'} onChange={(e)=>shippingOptionOnChange(e,item)}
                                           style={{width:'100%'}}  name={'CarrierName'} value={item.CarrierName} /></td>
                            </tr>
                            <tr>
                                <th>Option Level</th>
                                <td><input type={'text'} onChange={(e)=>shippingOptionOnChange(e,item)}
                                           style={{width:'100%'}}  name={'OptionLevel'} value={item.OptionLevel} /></td>
                            </tr>
                            <tr>
                                <th>Service Type</th>
                                <td><input type={'text'} onChange={(e)=>shippingOptionOnChange(e,item)}
                                           style={{width:'100%'}}  name={'ServiceType'} value={item.ServiceType} /></td>
                            </tr>
                            <tr>
                                <th>BillingAddress</th>
                                <td><input type={'text'} onChange={(e)=>shippingOptionOnChange(e,item)}
                                           style={{width:'100%'}}  name={'BillingAddress'} value={item.BillingAddress} /></td>
                            </tr>
                            <tr>
                                <th>User Name</th>
                                <td><input type={'text'} onChange={(e)=>shippingOptionOnChange(e,item)}
                                           style={{width:'100%'}}  name={'UserName'} value={item.UserName} /></td>
                            </tr>
                            <tr>
                                <th>Password</th>
                                <td><input type={'text'} onChange={(e)=>shippingOptionOnChange(e,item)}
                                           style={{width:'100%'}}  name={'Password'} value={item.Password} /></td>
                            </tr>
                            <tr>
                                <th>Account Number</th>
                                <td><input type={'text'} onChange={(e)=>shippingOptionOnChange(e,item)}
                                           style={{width:'100%'}}  name={'AccountNum'} value={item.AccountNum} /></td>
                            </tr>
                            <tr>
                                <th>Contract Number</th>
                                <td><input type={'text'} onChange={(e)=>shippingOptionOnChange(e,item)}
                                           style={{width:'100%'}}  name={'ContractNum'} value={item.ContractNum} /></td>
                            </tr>
                            <tr>
                                <th>PaidBy Number</th>
                                <td><input type={'text'} onChange={(e)=>shippingOptionOnChange(e,item)}
                                           style={{width:'100%'}}  name={'PaidByNum'} value={item.PaidByNum} /></td>
                            </tr>
                            <tr>
                                <th>API Credentials</th>
                                <td><input type={'text'} onChange={(e)=>shippingOptionOnChange(e,item)}
                                           style={{width:'100%'}}  name={'APICredentials'} value={item.APICredentials} /></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="modal-footer row" style={{width:'90%',margin:"0 auto"}}>
                    <div className={'col'}> </div>
                    <div className={'col'}>
                        <button className={'btn btn-danger'} onClick={()=>updateShippingOption(item)} >Update Shipping Option</button>
                    </div>
                </div>
            </div>
        </div>
    </div>)
};

export default shipping_option_list_item_detail;