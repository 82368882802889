import React from 'react';

const viewManualOrderDetailModal = ({order,handleClose,selectedRow})=>{
    let orderRows;
    if(order.orderRows !== undefined){
        orderRows= Object.values(order.orderRows);
    }
    return(
        <div className="modal fade" id="viewManualOrderDetailModal" tabIndex="-1" role="dialog" data-backdrop="static"
             aria-labelledby="viewManualOrderDetailModalTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title text-center" id="viewManualOrderDetailModalTitle">Manual Order Detail - Order ID: {selectedRow.BrightpearlOrderId}</h5>
                        <button type="button" className="close" onClick={handleClose}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <table className="table table-bordered viewManualOrderDetialTable">
                            <thead>
                            <tr>
                                <th scope="col"> </th>
                                <th scope="col">Company Address</th>
                                <th scope="col">Invoice address</th>
                                <th scope="col">Delivery address</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <th scope="row">Name</th>
                                <td>{(order.parties!==undefined&&order.parties.customer.addressFullName!==undefined)?order.parties.customer.addressFullName:""}</td>
                                <td>{(order.parties!==undefined&&order.parties.billing.addressFullName !==undefined)?order.parties.billing.addressFullName :""}</td>
                                <td>{(order.parties!==undefined&&order.parties.delivery.addressFullName !==undefined)?order.parties.delivery.addressFullName:""}</td>
                            </tr>
                            <tr>
                                <th scope="row">Company</th>
                                <td>{(order.parties!==undefined&&order.parties.customer.companyName !==undefined)?order.parties.customer.companyName:"" }</td>
                                <td>{(order.parties!==undefined&&order.parties.billing.companyName !==undefined)?order.parties.billing.companyName:"" }</td>
                                <td>{(order.parties!==undefined&&order.parties.delivery.companyName !==undefined)?order.parties.delivery.companyName:"" }</td>
                            </tr>
                            <tr>
                                <th scope="row">Street</th>
                                <td>{(order.parties!==undefined&&order.parties.customer.addressLine1 !==undefined)?order.parties.customer.addressLine1:"" }</td>
                                <td>{(order.parties!==undefined&&order.parties.billing.addressLine1 !==undefined)?order.parties.billing.addressLine1:"" }</td>
                                <td>{(order.parties!==undefined&&order.parties.delivery.addressLine1 !==undefined)?order.parties.delivery.addressLine1:"" }</td>
                            </tr>
                            <tr>
                                <th scope="row">Suburb</th>
                                <td>{(order.parties!==undefined&&order.parties.customer.addressLine2 !==undefined)?order.parties.customer.addressLine2:"" }</td>
                                <td>{(order.parties!==undefined&&order.parties.billing.addressLine2 !==undefined)?order.parties.billing.addressLine2:"" }</td>
                                <td>{(order.parties!==undefined&&order.parties.delivery.addressLine2 !==undefined)?order.parties.delivery.addressLine2:"" }</td>
                            </tr>
                            <tr>
                                <th scope="row">City</th>
                                <td>{(order.parties!==undefined&&order.parties.customer.addressLine3 !==undefined)?order.parties.customer.addressLine3:"" }</td>
                                <td>{(order.parties!==undefined&&order.parties.billing.addressLine3 !==undefined)?order.parties.billing.addressLine3:"" }</td>
                                <td>{(order.parties!==undefined&&order.parties.delivery.addressLine3 !==undefined)?order.parties.delivery.addressLine3:"" }</td>
                            </tr>
                            <tr>
                                <th scope="row">State/county</th>
                                <td>{(order.parties!==undefined&&order.parties.customer.addressLine4 !==undefined)?order.parties.customer.addressLine4:"" }</td>
                                <td>{(order.parties!==undefined&&order.parties.billing.addressLine4 !==undefined)?order.parties.billing.addressLine4:"" }</td>
                                <td>{(order.parties!==undefined&&order.parties.delivery.addressLine4 !==undefined)?order.parties.delivery.addressLine4:"" }</td>
                            </tr>
                            <tr>
                                <th scope="row">Postcode</th>
                                <td>{(order.parties!==undefined&&order.parties.customer.postalCode !==undefined)?order.parties.customer.postalCode:"" }</td>
                                <td>{(order.parties!==undefined&&order.parties.billing.postalCode !==undefined)?order.parties.billing.postalCode:"" }</td>
                                <td>{(order.parties!==undefined&&order.parties.delivery.postalCode !==undefined)?order.parties.delivery.postalCode:"" }</td>
                            </tr>
                            <tr>
                                <th scope="row">Country</th>
                                <td>{(order.parties!==undefined&&order.parties.customer.country !==undefined)?order.parties.customer.country:"" }</td>
                                <td>{(order.parties!==undefined&&order.parties.billing.country !==undefined)?order.parties.billing.country:"" }</td>
                                <td>{(order.parties!==undefined&&order.parties.delivery.country !==undefined)?order.parties.delivery.country:"" }</td>
                            </tr>
                            <tr>
                                <th scope="row">Telephone</th>
                                <td>{(order.parties!==undefined&&order.parties.customer.telephone !==undefined)?order.parties.customer.telephone:"" }</td>
                                <td>{(order.parties!==undefined&&order.parties.billing.telephone !==undefined)?order.parties.billing.telephone:"" }</td>
                                <td>{(order.parties!==undefined&&order.parties.delivery.telephone !==undefined)?order.parties.delivery.telephone:"" }</td>
                            </tr>
                            <tr>
                                <th scope="row">Mobile</th>
                                <td>{(order.parties!==undefined&&order.parties.customer.mobileTelephone !==undefined)?order.parties.customer.mobileTelephone:"" }</td>
                                <td>{(order.parties!==undefined&&order.parties.billing.mobileTelephone !==undefined)?order.parties.billing.mobileTelephone:"" }</td>
                                <td>{(order.parties!==undefined&&order.parties.delivery.mobileTelephone !==undefined)?order.parties.delivery.mobileTelephone:"" }</td>
                            </tr>
                            <tr>
                                <th scope="row">Email address</th>
                                <td>{(order.parties!==undefined&&order.parties.customer.email !==undefined)?order.parties.customer.email:"" }</td>
                                <td>{(order.parties!==undefined&&order.parties.billing.email !==undefined)?order.parties.billing.email:"" }</td>
                                <td>{(order.parties!==undefined&&order.parties.delivery.email !==undefined)?order.parties.delivery.email:"" }</td>
                            </tr>
                            </tbody>
                        </table>
                        {
                            orderRows!==undefined&&
                            <table className="table table-bordered pendingOrderTable">
                                <thead>
                                <tr>
                                    <th scope="col">Line#</th>
                                    <th scope="col">SKU</th>
                                    <th scope="col">Product Name</th>
                                    <th scope="col">Qty</th>
                                    <th scope="col">Line Total</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    orderRows.map((ol,index)=>{
                                        return <tr key={index}>
                                            <th scope="row">{index+1}</th>
                                            <td>{ol.productSku}</td>
                                            <td>{ol.productName}</td>
                                            <td>{parseInt(ol.quantity.magnitude)}</td>
                                            <td>{parseFloat(ol.rowValue.rowNet.value).toFixed(2)}</td>
                                        </tr>
                                    })
                                }
                                </tbody>
                            </table>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
};
export default viewManualOrderDetailModal;