import React from 'react';
import _ from "lodash";

const channel_sales_chart=({sales})=>{
    const largest = sales[Object.keys(sales)[0]];
    return(
        <div>
            {
                _.map(sales,(value,key)=>{
                    let present = parseInt(value *100 / largest);
                    if(value>0 && present< 10){
                        present = '10%';
                    }
                    else{
                        present = `${present}%`
                    }
                    let backgroundColor = '';
                    let logoUrl = '';
                    switch(key){
                        case "WalMart Ca":
                            backgroundColor = "#167ef6";
                            logoUrl = "https://ashlinapp.xyz/logo/walmartlogo.jpg";
                            break;
                        case "Hudson's Bay":
                            backgroundColor = "#e1b143";
                            logoUrl = "https://ashlinapp.xyz/logo/thebaylogo.jpg";
                            break;
                        case "Staples Exchange":
                            backgroundColor = "#e4606d";
                            logoUrl = "https://ashlinapp.xyz/logo/stapleslogo.jpg";
                            break;
                        case "Staples Advantage":
                            backgroundColor = "#ff4b90";
                            logoUrl = "https://ashlinapp.xyz/logo/stapleslogo.jpg";
                            break;
                        case "BestBuy MarketPlace":
                            backgroundColor = "#0A4ABF";
                            logoUrl = "https://ashlinapp.xyz/logo/bestbuylogo.png";
                            break;
                        case "BestBuy Ca":
                            backgroundColor = "#1c3ffa";
                            logoUrl = "https://ashlinapp.xyz/logo/bestbuylogo.png";
                            break;
                        default:
                    }
                       return (
                           <div key={key} style={{marginBottom:'12px'}}>
                               <h6 style={{marginBottom:'2px'}}>
                                   <img src={logoUrl} alt={'store logo'} style={{width:'25px',height:'25px'}}/> &nbsp;
                                   {key}
                               </h6>
                                <div className="progress">
                                    {value !== 0 ?
                                        <div className="progress-bar" role="progressbar"
                                             style={{width: present,backgroundColor:backgroundColor}}>${value}</div>:
                                        <div className="progress-bar" role="progressbar"
                                        style={{width: '0%'}}> </div>
                                    }
                                </div>
                           </div>
                       )
                })
            }
        </div>
    )
};

export default channel_sales_chart;