import React from 'react';
const backend_scheduler_list_item = ({item, index,  backEndSchedulerHourOnChange, backEndSchedulerTimeOnChange,updateScheduler})=>{
    return(
        <tr>
            <td>
                <span style={{fontWeight:'bold'}}>{item.Name}</span>
            </td>
            <td>
                <span>{item.Description}</span>
            </td>
            <td>
                <div className="input-group">
                    <input type={'text'} value={item.Hours} className={'text-center form-control'}  onChange={(e)=>backEndSchedulerHourOnChange(e,index)}/>
                        <div className="input-group-append">
                            {item.Hours!==0?<span style={{width:'30px',fontSize:"25px"}} className=" input-group-text badge badge-primary" data-toggle="tooltip" data-placement="top" title={`Scheduler will run every ${item.Hours} hour`}>?</span>
                                :<span style={{width:'30px',fontSize:"25px"}} className="input-group-text badge badge-primary" data-toggle="tooltip" data-placement="top" title={`Scheduler will run every day at ${item.Time}`}>?</span>}
                        </div>
                </div>
            </td>
            <td>
                <input type={'text'} value={item.Time} className={'text-center'}  onChange={(e)=>backEndSchedulerTimeOnChange(e,index)}/>
            </td>
            <td>
                <button className={'btn-danger'} type={'button'} onClick={()=>updateScheduler(item)}>Update</button>
            </td>
        </tr>
    );
};
export default backend_scheduler_list_item;