import React from "react";
import Dropzone from "react-dropzone";

const ImageDropZone = ({ onDrop, file, sizeDescription }) => {
  return (
    <div>
      <div className="dropzone">
        <Dropzone
          accept="image/jpeg, image/png"
          onDrop={onDrop}
          multiple={false}
        >
          {file === undefined ? (
            <div>
              <p style={{ textAlign: "center", color: "red" }}>
                Only *.jpeg and *.png images will be accepted
              </p>
              <p
                style={{
                  textAlign: "center",
                  color: "red",
                  fontWeight: "bold",
                }}
              >
                {sizeDescription}
              </p>
            </div>
          ) : (
            <div
              style={{
                width: "195px",
                height: "195px",
                lineHeight: "195px",
                textAlign: "center",
              }}
            >
              <img
                src={file.preview}
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  display: "inline-block",
                  verticalAlign: "middle",
                }}
              />
            </div>
          )}
        </Dropzone>
      </div>

      {file !== undefined && (
        <p key={file.name} style={{ fontWeight: "bold" }}>
          *{file.name}
        </p>
      )}
    </div>
  );
};

export default ImageDropZone;
