import React, { Component } from 'react';
import axios from 'axios';
import { getChannelList, getListedSkusWithBrightId, legacySkusURL, SkuChannelListingUrl, getUnlistedSku, postSkuChannelListing } from '../../Utils/ashlinDesign';
import { Picky } from 'react-picky';
import 'react-picky/dist/picky.css';
import SkuDetailModal from '../Modal/SkuDetailModal';
import $ from "jquery";
import CheckBox from '../bootstrapComponent/checkBox';
import SelectInput from '../bootstrapComponent/selectInput';
import { WalmartFeedItems, WalmartFeedStatus, FeedBackObject, walmartUpdateStartDate } from '../../Utils/walmartMarketplace';
import MainSideBar from '../sideBar/MainSideBar';
import { createItems } from '../../Utils/bestbuyMarketplace';
import { createNewEggFeedItems } from '../../Utils/newEggMarketplace';
import { addProduct } from '../../Utils/bigCommerce';
import { addBrightPearlProduct } from '../../Utils/brightpearl';
import {
    getAsiCategories,
    getAsiProduct,
    getAsiProductTypes,
    getAuth,
    getCredentials,
    postProduct
} from '../../Utils/asi';
import async from 'async';
class BulkSkuUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            channels: [],
            selectChannel: "",
            arrayValue: [],
            skuList: [],
            selectedSkus: [],
            detailedSku: {},
            channelList: {
                asi: false,
                sage: false,
                bigCommerceAshlin: false,
                bigCommercePoppyBuzz: false,
                brightPearl: false,
                leanSupply: false,
                staples: false,
                theBay: false,
                amazonCa: false,
                amazonCom: false,
                walmart: false,

            },
            isDownLoading: false,
            isUpLoading: false
        };
    }

    componentDidMount() {
        this.loadData();
    }
    loadData = async () => {
        const channelListRes = await getChannelList();
        const channelList = channelListRes.data;
        //  channelList.push('BrightPearl');
        this.setState({
            channels: channelList
        })
    };

    selectMultipleOption = async (value) => {
        this.setState({
            isDownLoading: true
        }, async () => {
            const newAdded = value.filter(added => !this.state.arrayValue.includes(added));
            let selectedSkus = this.state.selectedSkus;
            if (newAdded.length !== 0) {
                const addedDetailRes = await axios.get(legacySkusURL + `/detail/${newAdded}`);
                const addedDetail = addedDetailRes.data[0];
                if (addedDetail !== undefined && !selectedSkus.some(s => s.Sku === addedDetail.Sku)) {
                    selectedSkus.push(addedDetail);
                }
            } else {
                const newRemoved = this.state.arrayValue.filter(removed => !value.includes(removed));
                //console.log("newRemove",newRemoved);
                const index = selectedSkus.findIndex(re => re.Sku === newRemoved[0]);
                //console.log(index);
                if (index >= 0) {
                    selectedSkus.splice(index, 1);
                }
            }
            const selectedValue = selectedSkus.map(s => s.Sku);
            this.setState({
                arrayValue: selectedValue,
                selectedSkus,
                isDownLoading: false
            });
        });

    };

    handleDetail = (e, sku) => {
        e.preventDefault();
        this.setState({
            detailedSku: sku
        }, () => $('#skuDetailModal').modal('show'))
    };

    // handleOnChange= async (event)=>{
    //     const target = event.target;
    //     const value =  target.type === 'checkbox' ? target.checked : target.value;
    //     const name = target.name;
    //
    //     const channelList = this.state.channelList;
    //
    //     channelList[name] = value;
    //     if(name==='bigCommerceAshlin'){
    //         channelList.bigCommercePoppyBuzz = !value;
    //     }
    //     if(name==='bigCommercePoppyBuzz'){
    //         channelList.bigCommerceAshlin = !value;
    //     }
    //     this.setState({
    //         channelList
    //     });
    // };
    handleOnSelect = async (e) => {
        const selectChannel = e.target.value;
        if (selectChannel !== 'optionDefaultHeader') {
            //get Skus listed on the selected Channel -- skuList
            this.setState({
                isDownLoading: true
            }, async () => {
                const listedSkusRes = await getUnlistedSku(selectChannel);
                let listedSkus = listedSkusRes.data;
                Promise.all([listedSkusRes]).then(() => {
                    this.setState({
                        selectChannel,
                        skuList: listedSkus,
                        isDownLoading: false
                    });
                })
            });
        }
    };
    handleUpload = async (e) => {
        e.preventDefault();
        let label;
        if (this.state.selectChannel === "WalmartCa") {
            const feedResponse = await WalmartFeedItems(this.state.selectedSkus, 'create');
            // walmartFeedID = feedResponse.data.feedId;
            //console.log(feedResponse);
            /****************POST new Records to ASHLIN database SKUChannel Listing table ***********************/
            const ashlinRes = await postSkuChannelListing(this.state.selectedSkus, "WalmartCa");
            alert("WalmartCa upload finish");
        }
        else if (this.state.selectChannel === "BestBuy") {

            const bestBuyRes = await createItems(this.state.selectedSkus);
            //console.log(bestBuyRes);
            const ashlinRes = await postSkuChannelListing(this.state.selectedSkus, "BestBuy");
            alert("Best buy upload finish");
        }

        else if (this.state.selectChannel === "BigCommerce Ashlin" || this.state.selectChannel === "BigCommerce Poppybuzz") {
            label = this.state.selectedSkus.map(s => s.Sku).join(" \n");
            let confirmBox = window.confirm(`Do you confirm to upload selected products to ${this.state.selectChannel}:\n${label}?`);
            if (confirmBox) {
                this.setState({
                    isUpLoading: true
                }, () => {
                    async.mapSeries(this.state.selectedSkus, async (data, callback) => {
                        const newProduct = await addProduct(this.state.selectChannel, data);
                        let arr = [];
                        const value = {
                            "Sku": data.Sku,
                            "MerchanSku": newProduct,
                        };
                        arr.push(value);
                        await setTimeout(() => {
                            return callback(null, value);
                        }, 4000);
                    }, async (err, results) => {
                        //console.log(results);
                        const res = await postSkuChannelListing(results, this.state.selectChannel);
                        Promise.all([res]).then(() => {
                            this.setState({
                                isUpLoading: false
                            }, () => alert(`${this.state.selectChannel} upload finish`));
                        })
                    });
                });

            }

        }
        else if (this.state.selectChannel === "BrightPearl") {
            //console.log(this.state.selectedSkus);
            label = this.state.selectedSkus.map(s => s.Sku).join(" \n");
            let confirmBox = window.confirm(`Do you confirm to upload selected products to ${this.state.selectChannel}:\n${label}?`);
            if (confirmBox) {
                this.setState({
                    isUpLoading: true
                }, () => {
                    const res = this.state.selectedSkus.map(async p => {
                        if (p.CostPricingTier !== undefined) {
                            return await addBrightPearlProduct(p);
                        }
                        else {
                            alert(`${p.Sku} need to update Cost table!!`);
                        }
                    });
                    Promise.all(res).then(() => {
                        this.setState({
                            isUpLoading: false
                        }, () => alert(`${this.state.selectChannel} upload finish`));
                    })

                });

            }
        }
        else if (this.state.selectChannel === "ASI") {
            label = this.state.selectedSkus.map(s => s.Sku).join(" \n");
            let confirmBox = window.confirm(`Do you confirm to upload selected products to ${this.state.selectChannel}:\n${label}?`);
            if (confirmBox) {
                this.setState({
                    isUpLoading: true
                }, async () => {
                    const credentials = await getCredentials();
                    const token = await getAuth(credentials);
                    const asiCategories = await getAsiCategories();
                    const asiProductTypes = await getAsiProductTypes();
                    async.mapSeries(this.state.selectedSkus, async (data, callback) => {
                        let arr = [];
                        if (data.CostPricingTier !== undefined) {
                            const IDs = data.Sku.split('-');
                            const XID = `${IDs[0]}-${IDs[1]}`;

                            const asiProduct = await getAsiProduct(XID, token);
                            //(item,asiProducts,AsiCategories,asiProductTypes,token)
                            const value = {
                                "Sku": data.Sku,
                                "MerchanSku": XID,
                            };
                            try {
                                await postProduct(data, asiProduct, asiCategories, asiProductTypes, token);

                            } catch (e) {
                                alert(`${data.Sku} failed upload`);
                            }
                            arr.push(value);
                            await setTimeout(() => {
                                return callback(null, value);
                            }, 4000);
                        }
                        else {
                            alert(`${data.Sku} need to update Cost table!!`);
                        }
                    }, async (err, results) => {
                        const res = await postSkuChannelListing(results, this.state.selectChannel);
                        Promise.all([res]).then(() => {
                            this.setState({
                                isUpLoading: false
                            }, () => alert(`${this.state.selectChannel} upload finish`));
                        })
                    });
                });
            }
        }
        // if(walmartFeedID !== ''){
        //     const feedStatusResponse = await WalmartFeedStatus(walmartFeedID);
        //     console.log(feedStatusResponse);
        //     const feedBackObj = FeedBackObject(feedStatusResponse.data.itemDetails.itemIngestionStatus);
        //     //const insertRes = await axios.post(SkuChannelListingUrl,feedBackObj,{headers:{'Content-Type':'application/json'}});
        //    // console.log(insertRes);
        //     walmartFeedID = '';
        // }

        //List new product on NewEgg
        else if (this.state.selectChannel === "NewEgg") {
            console.log("====")
            // console.log(this.state)
            label = this.state.selectedSkus.map(s => s.Sku).join(" \n");
            let confirmBox = window.confirm(`Do you confirm to upload selected products to ${this.state.selectChannel}:\n${label}?`);
            if (confirmBox) {
                this.setState({
                    isUpLoading: true
                }, async () => {
                    //create API call to list new Sku on NewEgg
                    const feedResponse = await createNewEggFeedItems(this.state.selectedSkus);
                    console.log(feedResponse)


                    //store data into the tables of database





                    this.setState({
                        isUpLoading: false
                    })
                });


            }




        }

    };

    render() {
        return (
            <div className='container-fluid row'>
                <div className={'col-3'}>
                    <MainSideBar />
                </div>
                <div className={'col-9'}>
                    {this.state.detailedSku !== null ? <SkuDetailModal
                        sku={this.state.detailedSku}
                    /> : null}
                    <br />
                    <div className={'row'}>
                        <div className={'col'}>
                            <h1 className={'text-center'}>Bulk Upload Product</h1>
                        </div>

                        <div className={'col'}>
                            {
                                this.state.channels.length !== 0 &&
                                <SelectInput value={this.state.channels}
                                    style={{ fontWeight: 'bold', fontSize: '20px' }}
                                    label={"Channel Name"} onChange={(e) => this.handleOnSelect(e)} />
                            }
                        </div>
                        <div className={'col'}>
                            {this.state.selectedSkus[0] && <button className={'btn btn-danger'} onClick={(e) => this.handleUpload(e)}>Upload Product</button>}
                        </div>
                    </div>
                    <hr />
                    {this.state.isUpLoading && <div className={"text-center text-success display-4"}><i className="fa fa-spinner fa-spin"></i> &nbsp; Submitting...</div>}
                    {this.state.isDownLoading && <div className={"text-center text-success display-4"}><i className="fa fa-spinner fa-spin"></i> &nbsp; Reading data from Database...</div>}
                    <div className={'row'}>
                        <div className={'col-1'}> </div>
                        <div className={'col'}>
                            <h2 className={'text-center'}>Sku List</h2>

                            {this.state.skuList.length !== 0 &&
                                <Picky
                                    value={this.state.arrayValue}
                                    options={this.state.skuList}
                                    onChange={this.selectMultipleOption}
                                    open={true}
                                    valueKey="id"
                                    labelKey="name"
                                    multiple={true}
                                    includeSelectAll={false}
                                    includeFilter={true}
                                    dropdownHeight={580}
                                />
                            }
                        </div>
                        <div className={'col'} >
                            <h2 className={'text-center'}>Selected Sku</h2>
                            <div style={{ maxHeight: '620px', minWidth: '360px', overflowY: 'auto' }}>
                                {this.state.selectedSkus.map((sku, index) => {
                                    return <div key={index}>
                                        <button className={'btn btn-info btn-block'} onClick={(e) => this.handleDetail(e, sku)}>{sku.Sku}</button>
                                    </div>

                                })}
                            </div>
                        </div>
                        <div className={'col-1'}> </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default BulkSkuUpload;

