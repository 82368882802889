import React, { Component } from 'react';
import MainSideBar from '../sideBar/MainSideBar';
import {
    getChannelList,
    getListedSkus,
    getListedSkusWithBrightId,
    getECommerceList,
    sendEmail
} from "../../Utils/ashlinDesign";
import { getInventoryById,getRangeInventory,findInventoryFromInventoryList } from '../../Utils/brightpearl';
import { WalmartInventoryUpdate } from '../../Utils/walmartMarketplace';
import {updateInventory} from "../../Utils/bestbuyMarketplace";
import { staplesCaInventoryFileGenerator,staplesInventoryUpdate } from '../../Utils/staplesCa';
import { CommerceHubWalmartInventoryFileGenerator, CommerceHubInventoryUpdate,CommerceHubBestBuyInventoryFileGenerator,CommerceHubTheBayInventoryFileGenerator } from '../../Utils/CommerceHub';
class BulkInventoryManager extends Component{
    constructor(props){
        super(props);
        this.state = {
            isUpLoading:false,
            isDownLoading:false,
            checkMainWarehouse:true,
            checkLeanSupplyWarehouse:true,
            holidaySetting:false,
            password:'',

            updateCommerceHubWalmart:true,
            updateCommerceHubBestBuy:true,
            updateCommerceHubTheBay :true,
            updateStaplesCaExchange : false,
            updateStaplesCaAdvantage :false,
            updateWalmartCa :true,
            updateBestBuy :true,
            removeZero:false
        };
    }
    componentDidMount(){
        this.loadData();
    }
    loadData = async()=>{

    };

    handleInputChange=(event)=> {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        if(name==="holidaySetting"){
            if(value){
                this.setState({
                    holidaySetting:value,
                    updateCommerceHubWalmart:true,
                    updateCommerceHubBestBuy:true,
                    updateCommerceHubTheBay :true,
                    updateStaplesCaExchange : false,
                    updateStaplesCaAdvantage :false,
                    updateWalmartCa :false,
                    updateBestBuy :false,
                })
            }else{
                this.setState({
                    holidaySetting:value,
                    updateCommerceHubWalmart:true,
                    updateCommerceHubBestBuy:true,
                    updateCommerceHubTheBay :true,
                    updateStaplesCaExchange : false,
                    updateStaplesCaAdvantage :false,
                    updateWalmartCa :true,
                    updateBestBuy :true,
                })
            }
        }else{
            this.setState({
                [name]: value
            });
        }

    };
    handleUpdate=()=>{
        //console.log(this.state);
        this.setState({
            isUpLoading:true
        },async()=>{
            const wareHouseList = {
                main:this.state.checkMainWarehouse,
                leanSupply:this.state.checkLeanSupplyWarehouse
            };
            //always send low inventory notification email
            const lowInventoryList = await this.generateLowInventoryList(wareHouseList);
            const zeroInventoryList = lowInventoryList.filter(l=>l.Inventory === 0);
            const warningList = lowInventoryList.filter(l=>l.Inventory !== 0);
            let attachment = "Zero Inventory Item:\n";
            zeroInventoryList.forEach(i=>{
                attachment += `SKU: ${i.Sku} | Reorder Level-${i.ReorderLevel} | Inventory Main-${i.main} | Inventory LeanSupply-${i.leanSupply} | total = ${i.Inventory} \n`;
            });
            attachment +="\n\n";
            attachment += "Low Inventory Item:\n";
            warningList.forEach(i=>{
                attachment += `SKU: ${i.Sku} | Reorder Level-${i.ReorderLevel} | Inventory Main-${i.main} | Inventory LeanSupply-${i.leanSupply} | total = ${i.Inventory} \n`;
            });
            const recipients = ["archit.sandesara@consulero.com","juanne.kochhar@ashlinbpg.com","nancy.taccone@ashlinbpg.com","ashlin@ashlinbpg.com"];
            const emailBody = "Hi,\nThe attachment is the Low Inventory Report.";
            await sendEmail(recipients,"SKU Low Inventory Report",emailBody,attachment,"LowInventoryReport.txt");
            if(!this.state.holidaySetting){
                //
                const CommerceHubWalmartList = await this.generateInventoryList(this.state.updateCommerceHubWalmart,"CommerceHubWalmart",wareHouseList,this.state.removeZero);
                if(CommerceHubWalmartList !== false){
                    const uploadFile = CommerceHubWalmartInventoryFileGenerator(CommerceHubWalmartList);
                    const res = await CommerceHubInventoryUpdate(uploadFile,"CommerceHubWalmart");
                    if(res.data === "Success"){
                        alert("CommerceHub WalmartCa Inventory Update Finish");
                    }else{
                        alert("CommerceHub WalmartCa  SFTP Connection Failed!");
                    }
                }

                const CommerceHubBestBuyList = await this.generateInventoryList(this.state.updateCommerceHubBestBuy,"CommerceHubBestBuy",wareHouseList,this.state.removeZero);
                if(CommerceHubBestBuyList !== false){
                    const uploadFile =CommerceHubBestBuyInventoryFileGenerator(CommerceHubBestBuyList);
                    const res = await CommerceHubInventoryUpdate(uploadFile,"CommerceHubBestBuy");
                    if(res.data === "Success"){
                        alert("CommerceHub BestBuy Inventory Update Finish");
                    }else{
                        alert("BestBuy SFTP Connection Failed!");
                    }
                }

                const CommerceHubTheBayList = await this.generateInventoryList(this.state.updateCommerceHubTheBay,"CommerceHubTheBay",wareHouseList,this.state.removeZero);
                if(CommerceHubBestBuyList !== false){
                    const uploadFile =CommerceHubTheBayInventoryFileGenerator(CommerceHubTheBayList );
                    const res = await CommerceHubInventoryUpdate(uploadFile,"CommerceHubTheBay");
                    if(res.data === "Success"){
                        alert("CommerceHub TheBay Inventory Update Finish");
                    }else{
                        alert("CommerceHub TheBay  SFTP Connection Failed!");
                    }
                }

                const StaplesCaList = await this.generateInventoryList(this.state.updateStaplesCaExchange,"StaplesCa",wareHouseList,this.state.removeZero);
                if( StaplesCaList !== false){
                    const uploadFile = staplesCaInventoryFileGenerator('143421CA',StaplesCaList);
                    const res = await staplesInventoryUpdate("StaplesCa",uploadFile);
                    if(res.data === "Success"){
                        alert("StaplesCA Inventory Update Finish");
                    }else{
                        alert("StaplesCA  SFTP Connection Failed!");
                    }
                }
                const StaplesCaAdvantageList = await this.generateInventoryList(this.state.updateStaplesCaAdvantage,"StaplesCaAdvantage",wareHouseList,this.state.removeZero);
                if( StaplesCaAdvantageList !== false){
                    const uploadFile = staplesCaInventoryFileGenerator('SAC0143421',StaplesCaAdvantageList);
                    const res = await staplesInventoryUpdate("StaplesCaAdvantage",uploadFile);
                    if(res.data === "Success"){
                        alert("StaplesCaAdvantage Inventory Update Finish");
                    }else{
                        alert("StaplesCaAdvantage  SFTP Connection Failed!");
                    }
                }

                const WalmartCaList = await this.generateInventoryList(this.state.updateWalmartCa,"WalmartCa",wareHouseList,this.state.removeZero);
                if( WalmartCaList !== false){
                    const res = await WalmartInventoryUpdate(WalmartCaList);
                    alert("WalmartCA Market Place Inventory Update Finish, Feed Status ID: " + res.data.feedId);
                }
                const BestBuyList = await this.generateInventoryList(this.state.updateBestBuy,"BestBuy",wareHouseList,this.state.removeZero);
                if( BestBuyList !== false){
                    const res = await updateInventory(BestBuyList);
                    alert("BestBuy Market Place Inventory Update Finish, Import ID: " + res.data.import_id);
                }
                Promise.all([CommerceHubWalmartList,CommerceHubBestBuyList, CommerceHubTheBayList,
                    StaplesCaList,StaplesCaAdvantageList,WalmartCaList,BestBuyList]).then(()=>{
                        this.setState({
                            isUpLoading:false
                        })
                })
            }else{
                //set all inventory to 0
                if(this.state.password === "AshlinPass1"){
                    const CommerceHubWalmartList = await this.generateHolidayInventoryList(this.state.updateCommerceHubWalmart,"CommerceHubWalmart");
                    if(CommerceHubWalmartList !== false){
                        const uploadFile = CommerceHubWalmartInventoryFileGenerator(CommerceHubWalmartList);
                        const res = await CommerceHubInventoryUpdate(uploadFile,"CommerceHubWalmart");
                        if(res.data === "Success"){
                            alert("CommerceHub WalmartCa Inventory Update Finish");
                        }else{
                            alert("CommerceHub WalmartCa  SFTP Connection Failed!");
                        }
                    }

                    const CommerceHubBestBuyList = await this.generateHolidayInventoryList(this.state.updateCommerceHubBestBuy,"CommerceHubBestBuy");
                    if(CommerceHubBestBuyList !== false){
                        const uploadFile =CommerceHubBestBuyInventoryFileGenerator(CommerceHubBestBuyList);
                        const res = await CommerceHubInventoryUpdate(uploadFile,"CommerceHubBestBuy");
                        if(res.data === "Success"){
                            alert("CommerceHub BestBuy Inventory Update Finish");
                        }else{
                            alert("BestBuy SFTP Connection Failed!");
                        }
                    }

                    const CommerceHubTheBayList = await this.generateHolidayInventoryList(this.state.updateCommerceHubTheBay,"CommerceHubTheBay");
                    if(CommerceHubBestBuyList !== false){
                        const uploadFile =CommerceHubTheBayInventoryFileGenerator(CommerceHubTheBayList );
                        const res = await CommerceHubInventoryUpdate(uploadFile,"CommerceHubTheBay");
                        if(res.data === "Success"){
                            alert("CommerceHub TheBay Inventory Update Finish");
                        }else{
                            alert("CommerceHub TheBay  SFTP Connection Failed!");
                        }
                    }

                    const StaplesCaList = await this.generateHolidayInventoryList(this.state.updateStaplesCaExchange,"StaplesCa");
                    if( StaplesCaList !== false){
                        const uploadFile = staplesCaInventoryFileGenerator('143421CA',StaplesCaList);
                        const res = await staplesInventoryUpdate("StaplesCa",uploadFile);
                        if(res.data === "Success"){
                            alert("StaplesCA Inventory Update Finish");
                        }else{
                            alert("StaplesCA  SFTP Connection Failed!");
                        }
                    }
                    const StaplesCaAdvantageList = await this.generateHolidayInventoryList(this.state.updateStaplesCaAdvantage,"StaplesCaAdvantage");
                    if( StaplesCaAdvantageList !== false){
                        const uploadFile = staplesCaInventoryFileGenerator('SAC0143421',StaplesCaAdvantageList);
                        const res = await staplesInventoryUpdate("StaplesCaAdvantage",uploadFile);
                        if(res.data === "Success"){
                            alert("StaplesCaAdvantage Inventory Update Finish");
                        }else{
                            alert("StaplesCaAdvantage  SFTP Connection Failed!");
                        }
                    }

                    Promise.all([CommerceHubWalmartList,CommerceHubBestBuyList, CommerceHubTheBayList,
                        StaplesCaList,StaplesCaAdvantageList]).then(()=>{
                        this.setState({
                            isUpLoading:false
                        })
                    })
                }
            }
        })
    };
    generateInventoryList=async(isGenerate,channelName,wareHouseList,removeZero)=>{
        if(isGenerate){
            const listedSkus  = (await getListedSkusWithBrightId(channelName)).data;
            let Ids = listedSkus.map(i=>i.BrightPearlId);
            Ids = Ids.sort();
            const start = Ids[0];
            const end = Ids[Ids.length-1];
            const inventoryList = await getRangeInventory(start,end);
            let list = [];
            await listedSkus.forEach(async(i)=>{
                const item = await findInventoryFromInventoryList(i,inventoryList,wareHouseList,removeZero);
                list.push(item);
            });
            return list
        }else {
            return isGenerate;
        }
    };
    generateHolidayInventoryList=async(isGenerate,channelName)=>{
        if(isGenerate){
            const listedSkus  = (await getListedSkusWithBrightId(channelName)).data;
            let list = [];
            await listedSkus.forEach(async(item)=>{
                item.Inventory = 0;
                list.push(item);
            });
            return list
        }else {
            return isGenerate;
        }
    };
    generateLowInventoryList = async(wareHouseList)=>{
        const eCommerceList = (await getECommerceList()).data;
        let Ids = eCommerceList.map(i=>i.BrightPearlId);
        Ids = Ids.sort();
        const start = Ids[0];
        const end = Ids[Ids.length-1];
        const inventoryList = await getRangeInventory(start,end);
        let list = [];
        await eCommerceList.forEach(async(i)=>{
            const item = await findInventoryFromInventoryList(i,inventoryList,wareHouseList,true);
            if(item.Inventory <= item.ReorderLevel){
                list.push(item);
            }
        });
        return list;
    };
    render(){

        return(
            <div>
                <div className='container-fluid row'>
                    <div className={'col-3'}>
                        <MainSideBar/>
                    </div>
                    <div className={'col-9'}>
                        <br/>
                        <h1 className={'text-center'}>Bulk Inventory Manager &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <button className={'btn btn-danger'} type={'button'} onClick={this.handleUpdate}>Update Inventory</button>
                        </h1>
                        <br/>
                        <div className={'row'}>
                            <div className={'col-3'}>
                                <h3>WAREHOUSE:</h3>
                                Main Warehouse:<input
                                name="checkMainWarehouse"
                                type="checkbox" style={{width:'20px',height:'20px',marginLeft:'5px'}}
                                checked={this.state.checkMainWarehouse}
                                onChange={this.handleInputChange}/> <br/>
                                4PL &nbsp;&nbsp;Warehouse:<input
                                name="checkLeanSupplyWarehouse"
                                type="checkbox" style={{width:'20px',height:'20px',marginLeft:'5px'}}
                                checked={this.state.checkLeanSupplyWarehouse}
                                onChange={this.handleInputChange}/>
                            </div>
                            <div className={'col-4'}>
                                <h3>SET STOCK TO ACTUAL</h3>
                                Doesn't add 1 for 0 level inventory:&nbsp;&nbsp;<input
                                name="removeZero"
                                type="checkbox" style={{width:'20px',height:'20px',marginLeft:'5px'}}
                                checked={this.state.removeZero}
                                onChange={this.handleInputChange}/> &nbsp;&nbsp;
                                <p>Select this can set stock to actual</p>
                            </div>
                            <div className={'col-5'}>
                                <h3>HOLIDAY SETTINGS for INVENTORY:</h3>
                                Set Inventory = 0 temporarily for channels:<input
                                name="holidaySetting"
                                type="checkbox" style={{width:'20px',height:'20px',marginLeft:'5px'}}
                                checked={this.state.holidaySetting}
                                onChange={this.handleInputChange}/> &nbsp;&nbsp;<br/>
                                Password:&nbsp;&nbsp;<input type={'password'} name={'password'} value={this.state.password} onChange={this.handleInputChange}/>
                            </div>
                        </div>
                        <hr/>
                        <div className={'row'}>
                            <div className={'col-1'}>

                            </div>
                            <div className={'col'}>
                                {this.state.isUpLoading &&<div className={"text-center text-success display-4"}><i className="fa fa-spinner fa-spin"></i> &nbsp; Submitting...</div>}
                                {this.state.isDownLoading &&<div className={"text-center text-success display-4"}><i className="fa fa-spinner fa-spin"></i> &nbsp; Checking Inventory on BrightPearl...</div>}

                            </div>
                            <div className={'col-1'}>

                            </div>
                        </div>
                        <table className="inventoryManagerTable" style={{margin:'16px auto',borderCollapse:'separate',borderSpacing:'0 20px'}}>
                            <tbody>
                                <tr>
                                    <th style={{textAlign:'center'}}>
                                        <input
                                            name="updateCommerceHubWalmart"
                                            type="checkbox" style={{width:'20px',height:'20px',marginRight:'15px'}}
                                            checked={this.state.updateCommerceHubWalmart}
                                            onChange={this.handleInputChange}/>
                                    </th>
                                    <td style={{fontSize:'20px',fontWeight:'bold'}}>CommerceHub Walmart</td>
                                </tr>
                                <tr>
                                    <th  style={{textAlign:'center'}}>
                                        <input
                                            name="updateCommerceHubBestBuy"
                                            type="checkbox" style={{width:'20px',height:'20px',marginRight:'15px'}}
                                            checked={this.state.updateCommerceHubBestBuy}
                                            onChange={this.handleInputChange}/>
                                    </th>
                                    <td  style={{fontSize:'20px',fontWeight:'bold'}}>CommerceHub BestBuy</td>
                                </tr>
                                <tr>
                                    <th style={{textAlign:'center'}}>
                                        <input
                                            name="updateCommerceHubTheBay"
                                            type="checkbox" style={{width:'20px',height:'20px',marginRight:'15px'}}
                                            checked={this.state.updateCommerceHubTheBay}
                                            onChange={this.handleInputChange}/>
                                    </th>
                                    <td style={{fontSize:'20px',fontWeight:'bold'}}>CommerceHub TheBay</td>
                                </tr>
                                {/* <tr>
                                    <th style={{textAlign:'center'}}>
                                        <input
                                            name="updateStaplesCaExchange"
                                            type="checkbox" style={{width:'20px',height:'20px',marginRight:'15px'}}
                                            checked={this.state.updateStaplesCaExchange}
                                            onChange={this.handleInputChange}/>
                                    </th>
                                    <td style={{fontSize:'20px',fontWeight:'bold'}}>Staples Exchange Ca</td>
                                </tr>
                                <tr>
                                    <th style={{textAlign:'center'}}>
                                        <input
                                            name="updateStaplesCaAdvantage"
                                            type="checkbox" style={{width:'20px',height:'20px',marginRight:'15px'}}
                                            checked={this.state.updateStaplesCaAdvantage}
                                            onChange={this.handleInputChange}/>
                                    </th>
                                    <td style={{fontSize:'20px',fontWeight:'bold'}}>Staples Advantage Ca</td>
                                </tr> */}
                                <tr>
                                    <th style={{textAlign:'center'}}>
                                        <input
                                            name="updateWalmartCa"
                                            type="checkbox" style={{width:'20px',height:'20px',marginRight:'15px'}}
                                            checked={this.state.updateWalmartCa}
                                            onChange={this.handleInputChange}/>
                                    </th>
                                    <td style={{fontSize:'20px',fontWeight:'bold'}}>Marketplace WalmartCa</td>
                                </tr>
                                <tr>
                                    <th style={{textAlign:'center'}}>
                                        <input
                                            name="updateBestBuy"
                                            type="checkbox" style={{width:'20px',height:'20px',marginRight:'15px'}}
                                            checked={this.state.updateBestBuy}
                                            onChange={this.handleInputChange}/>
                                    </th>
                                    <td style={{fontSize:'20px',fontWeight:'bold'}}>Marketplace BestBuy</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}
export default BulkInventoryManager;