import React from 'react';
const shipping_package_list_item = ({item, index, shippingPackageOnchange, upDateShippingPackage})=>{
    return(
        <tr>
            <td>
                <input type={'text'} value={item.Name} className={'text-center'} name={"Name"} onChange={(e)=>shippingPackageOnchange(e,index)}/>
            </td>
            <td>
                <input type={'text'} value={item.LengthCM} className={'text-center'} name={"LengthCM"} onChange={(e)=>shippingPackageOnchange(e,index)}/>
            </td>
            <td>
                <input type={'text'} value={item.WidthCM} className={'text-center'} name={"WidthCM"} onChange={(e)=>shippingPackageOnchange(e,index)}/>
            </td>
            <td>
                <input type={'text'} value={item.HeightCM} name={'HeightCM'} className={'text-center'} onChange={(e)=>shippingPackageOnchange(e,index)}/>
            </td>
            {/*<td>*/}
            {/*    <input type={'text'} value={item.VolumeCM3} name={'VolumeCM3'} className={'text-center'} onChange={(e)=>shippingPackageOnchange(e,index)}/>*/}
            {/*</td>*/}
            <td>
                <input type={'text'} value={item.VolumeCM3} name={'VolumeCM3'} className={'text-center'} readOnly />
            </td>
            <td>
                <button className={'btn-danger'} type={'button'} onClick={()=>upDateShippingPackage(item)}>Update</button>
            </td>
        </tr>
    );
};
export default shipping_package_list_item;