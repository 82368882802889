import React, { Component } from 'react';
import MainSideBar from '../sideBar/MainSideBar';
import {getAsiCustomerCreditSummary, getAuth, getCredentials} from '../../Utils/asi';
import {
    searchCustomer,
    getCustomerInfo,
    getBPheader,
    getPostalAddress,
    getIdBySKU,
    getProductPriceListByProductID,
    getTaxCodes, getBrightPearlTaxCode
} from '../../Utils/brightpearl';
import BrightpearlContactList from './component/brightpearl_contact_list';
import axios from 'axios';
import {getAllSKUNumberList} from "../../Utils/ashlinDesign";
import { proxyurl } from '../../Utils/requestHelper';

class ACE extends Component{
    _mounted = false;
    constructor(props){
        super(props);
        this.state={
            asiNumber:"",
            asiToken:"",
            taxCodes:[],
            skuNumberList:[],
            brightpearlHeader:"",
            selectedAsiCustomer:null,
            brightpearlCustomerList:[],
            selectedBPCustomer:null,

            asiExist: true,
            brightPearlExist:false,

            searchFistName:"",
            searchLastName:"",
            searchCompanyName:"",
            searchPhone:"",
            searchEmail:"",
            searchPostCode:"",

            manualFirstName:"",
            manualLastName:"",
            manualEmail:"",
            manualPhone:"",
            manualCompany:"",
            manualAddress1:'',
            manualAddress2:"",
            manualCity:"",
            manualState:"",
            manualCountry:"",
            manualPostalCode:"",
            manualFEINNum:"",

            searchingShipPostCode:false,

            billingFirstName:"",
            billingLastName:"",
            billingEmail:"",
            billingPhone:"",
            billingCompany:"",
            billingAddress1:'',
            billingAddress2:"",
            billingCity:"",
            billingState:"",
            billingCountry:"",
            billingPostalCode:"",

            searchingBillingPostCode:false,

            isBillingAsShipping:false,

            selectedIsRush:false,
            selectedSKUNumber:"optionDefaultHeader",
            selectedQty:"1",
            selectedWithLogo:false
        }
    }
    componentDidMount(){
        this._mounted = true;
        this.loadData();
    };
    loadData = async()=>{
        const credentials = await getCredentials();
        const token = await getAuth(credentials);
        const headers = await getBPheader();
        const skuNumberList = await getAllSKUNumberList();
        const taxCodes = await getTaxCodes(headers);
        Promise.all([credentials,token]).then(()=>{
            if(this._mounted) {
                this.setState({
                    asiToken:token,
                    skuNumberList,
                    taxCodes,
                    brightpearlHeader:headers,
                })
            }
        });
    };
    componentWillUnmount() {
        this._mounted = false;
    }
    handleOnchange=(e)=>{
        const name = e.target.name;
        const value = e.target.type === "checkbox"? e.target.checked: e.target.value;
        if(value!=="optionDefaultHeader"){
            this.setState({
                [name]:value
            },()=>console.log(this.state))
        }
    };
    handleSearchAsiCustomer=async()=>{
        const selectedAsiCustomer = await getAsiCustomerCreditSummary(this.state.asiNumber,this.state.asiToken);
        if(selectedAsiCustomer == null){
            alert(`Asi # ${this.state.asiNumber} not exists!`);
            this.setState({
                asiExist: false,
                brightPearlExist:true
            })
        }else{
            const searchCondition = `companyName=${selectedAsiCustomer.CompanyDetails.Name}`;
            const brightpearlCustomerList = await searchCustomer(searchCondition,this.state.brightpearlHeader);
            this.setState({
                selectedAsiCustomer,
                brightpearlCustomerList
            })
        }
    };
    handleSearchBrightpearlCustomer= async()=>{
        let searchCondition = "";
        if(this.state.searchFistName !== "" || this.state.searchLastName !==""){
            if(this.state.searchFistName !== ""){
                searchCondition += `&firstName=${this.state.searchFistName}`;
            }
            if(this.state.searchLastName !== ""){
                searchCondition += `&lastName=${this.state.searchLastName}`;
            }
        }else{
            if(this.state.searchEmail !== ""){
                searchCondition += `&primaryEmail=${this.state.searchEmail}`;
            }
            if(this.state.searchCompanyName!== ""){
                searchCondition += `&companyName=${this.state.searchCompanyName}`;
            }
        }

        if(searchCondition!==""){
            const brightpearlCustomerList = await searchCustomer(searchCondition,this.state.brightpearlHeader);
            console.log(brightpearlCustomerList);
            if(brightpearlCustomerList.length === 0){
                alert("Could not find by search condition!");
            }else {
                this.setState({
                    // selectedAsiCustomer,
                    brightpearlCustomerList
                })
            }
        }

    };
    handleManuallyEnterCustomer=()=>{
        this.setState({
            asiExist: false,
            brightPearlExist:false,
            brightpearlCustomerList:[]
        })
    };
    handleQtyOnchange=(qty)=>{
        this.setState({
            selectedQty:qty,
        })
    };
    handleSearchPostalCode=(postalCode,postalCodeType)=>{
        console.log(postalCode)
        this.setState({
            //searchingPostCode:true
            [postalCodeType]:true
        }, async () => {
            
                const res = (await axios.get(proxyurl+`https://geocoder.ca/?postal=${postalCode}&geoit=xml&json=1&standard=1&showcountry=1&moreinfo=1`)).data;
            console.log(res);
            if(res.error === undefined){
                console.log("Find Address");
                if(postalCodeType==="searchingShipPostCode"){
                    this.setState({
                        manualCountry:res.country,
                        manualState:res.standard.prov,
                        manualCity: res.standard.city,
                    })
                }else{
                    this.setState({
                        billingCountry:res.country,
                        billingState:res.standard.prov,
                        billingCity: res.standard.city,
                    })
                }

            }else {
                alert(res.error.description);
            }
            this.setState({
                [postalCodeType]:false
            })
        });


    };
    handleSelectContact=async(e,selected)=>{
        const selectedBPCustomer = await getCustomerInfo(selected[0],this.state.brightpearlHeader);
        const DEFAddress = await getPostalAddress(selectedBPCustomer.postAddressIds.DEF,this.state.brightpearlHeader);
        const BILAddress = await getPostalAddress(selectedBPCustomer.postAddressIds.BIL,this.state.brightpearlHeader);
        const DELAddress = await getPostalAddress(selectedBPCustomer.postAddressIds.DEL,this.state.brightpearlHeader);

        Promise.all([selectedBPCustomer,DEFAddress,BILAddress,DELAddress]).then(()=>{
            selectedBPCustomer.DEFAddress = DEFAddress;
            selectedBPCustomer.BILAddress = BILAddress;
            selectedBPCustomer.DELAddress = DELAddress;
            this.setState({
                selectedBPCustomer
            })
        })

    };
    handleAddRow=async()=>{
        const country = this.state.manualCountry;
        const SKU = this.state.selectedSKUNumber;
        const BPID = await getIdBySKU(SKU,this.state.brightpearlHeader);
        const priceLists = await getProductPriceListByProductID(BPID,this.state.brightpearlHeader);
        let priceList,provinceCode,taxCode,rowNet;

        let qtyTier;
        const selectedQty = parseInt(this.state.selectedQty);
        console.log(typeof (selectedQty));

        if(selectedQty >= 2500){
            qtyTier = 2500;
        }else if(selectedQty >= 1000){
            qtyTier = 1000;
        }else if(selectedQty >= 500){
            qtyTier = 500;
        }else if(selectedQty >= 250){
            qtyTier = 250;
        }else if(selectedQty >= 100){
            qtyTier = 100;
        }else if(selectedQty >= 50){
            qtyTier = 50;
        }else if(selectedQty >= 24){
            qtyTier = 24;
        }else if(selectedQty >= 6){
            qtyTier = 6;
        }else{
            qtyTier = 1;
        }
        if(country === "CA"){
            console.log("CA")

            if(this.state.selectedWithLogo  &&  this.state.selectedIsRush){
                //Distributor-NET-RUSH-IMP-CAD
                priceList = priceLists.find(p=>p.priceListId===8);
            }else if(this.state.selectedWithLogo ){
                //Distributor-NET-STD-IMP-CAD
                priceList = priceLists.find(p=>p.priceListId===5);
            }else if(this.state.selectedIsRush ){
                //Distributor-NET-RUSH-BLANK-CAD
                priceList = priceLists.find(p=>p.priceListId===7);
            }else{
                //Distributor-NET-STD-BLANK-CAD
                priceList = priceLists.find(p=>p.priceListId===6);
            }

            rowNet = priceList.quantityPrice[qtyTier];

            if( !this.state.isBillingAsShipping){
                provinceCode = getBrightPearlTaxCode(this.state.manualState);
            }else{
                provinceCode = getBrightPearlTaxCode(this.state.billingState);
            }
            taxCode = this.state.taxCodes.find(tax=>tax.code === provinceCode);
        }else{
            console.log("NOT CA")

            if(this.state.selectedWithLogo  &&  this.state.selectedIsRush){
                //Distributor-NET-RUSH-IMP-USD
                priceList = priceLists.find(p=>p.priceListId===16);
            }else if(this.state.selectedWithLogo ){
                //Distributor-NET-STD-IMP-USD
                priceList = priceLists.find(p=>p.priceListId===13);
            }else if(this.state.selectedIsRush ){
                //Distributor-NET-RUSH-BLANK-USD
                priceList = priceLists.find(p=>p.priceListId===14);
            }else{
                //Distributor-NET-STD-BLANK-USD
                priceList = priceLists.find(p=>p.priceListId===15);
            }

            rowNet = priceList.quantityPrice[qtyTier];
            taxCode = this.state.taxCodes.find(tax=>tax.code === "Z");
            taxCode.rate = 0;
        }

        const data = {
            "productId": BPID,
            "quantity": {
                "magnitude": this.state.selectedQty
            },
            "rowValue": {
                "taxCode": taxCode.code,
                "rowNet": {
                    "value": rowNet
                },
                "rowTax": {
                    "value": (rowNet * taxCode.rate / 100).toFixed(2)
                }
            },
            "nominalCode": "4000"
        };
        console.log(data);

        //FINAL DO RESET
        this.setState({
            selectedIsRush:false,
            selectedSKUNumber:"optionDefaultHeader",
            selectedQty:"1",
            selectedWithLogo:false
        })
    };
    render(){
        const selectedAsiCustomer = this.state.selectedAsiCustomer;
        const selectedBPCustomer = this.state.selectedBPCustomer;
        // console.log(selectedAsiCustomer);
        // console.log(selectedBPCustomer);selectedAsiCustomer
        return(
            <div className='container-fluid row'>
                <div className={'col-3'}>
                    <MainSideBar/>
                </div>
                <div className={'col-9'}>
                    <br/><br/>
                    <h1 className={'text-center'}>ACE MODULE
                        <button type={'button'} className={'btn btn-success'} style={{marginLeft:'100px'}} onClick={()=>{
                            this.setState({
                                asiNumber:"",
                                selectedAsiCustomer:null,
                                brightpearlCustomerList:[],
                                selectedBPCustomer:null,
                                asiExist: true,
                                brightPearlExist:false,

                                searchFistName:"",
                                searchLastName:"",
                                searchCompanyName:"",
                                searchEmail:"",
                                searchPhone:"",
                                searchPostCode:"",

                                manualFirstName:"",
                                manualLastName:"",
                                manualEmail:"",
                                manualPhone:"",
                                manualCompany:"",
                                manualAddress1:'',
                                manualAddress2:"",
                                manualCity:"",
                                manualState:"",
                                manualCountry:"",
                                manualPostalCode:"",
                                manualFEINNum:"",

                                searchingPostCode:false,
                                searchingBillingPostCode:false,

                                billingFirstName:"",
                                billingLastName:"",
                                billingEmail:"",
                                billingPhone:"",
                                billingCompany:"",
                                billingAddress1:'',
                                billingAddress2:"",
                                billingCity:"",
                                billingState:"",
                                billingCountry:"",
                                billingPostalCode:"",


                                isBillingAsShipping:false,

                                selectedIsRush:false,
                                selectedSKUNumber:"optionDefaultHeader",
                                selectedQty:"1",
                                selectedWithLogo:false
                            })
                        }}>Refresh</button>
                    </h1>
                    <table className="table asiCustomerSearchTable" style={{margin:'16px 0 0'}}>
                        <tbody>
                        {
                            this.state.asiExist &&
                            <tr>
                                <th style={{textAlign: 'center', fontSize: "30px", marginTop: '2px'}}>ASI #</th>
                                <td>
                                    <input type="text" name={'asiNumber'} onChange={this.handleOnchange}
                                           style={{width: '100%', fontSize: "30px"}} value={this.state.asiNumber}/>
                                </td>
                                <td>
                                    <button className={'btn btn-success'} type={'button'}
                                            onClick={this.handleSearchAsiCustomer}>Search Customer
                                    </button>
                                </td>
                                <td>
                                    <button className={'btn btn-primary'} type={'button'}
                                            onClick={()=>{
                                                this.setState({
                                                    asiExist: false,
                                                    brightPearlExist:true,
                                                    brightpearlCustomerList:[]
                                                })
                                            }
                                            }>Not an ASI Customer
                                    </button>
                                </td>
                            </tr>
                        }

                        {
                            this.state.brightPearlExist &&
                            <tr>
                                <td colSpan={'2'} style={{textAlign:'center',fontSize:'40px'}}>
                                    Search Customer on Brightpearl
                                </td>
                                <td>
                                    <button className={'btn btn-success'} type={'button'} style={{width:'100%'}} onClick={this.handleSearchBrightpearlCustomer}>Search</button>
                                </td>
                                <td>
                                    <button className={'btn btn-danger'} type={'button'} style={{width:'100%'}} onClick={this.handleManuallyEnterCustomer}>Manually Enter Customer</button>
                                </td>
                            </tr>
                        }
                        {
                            this.state.brightPearlExist &&
                                <tr className="thead-dark">
                                    <th>Email</th>
                                    <th>Company Name</th>
                                    <th>First Name</th>
                                    <th>last Name</th>
                                    {/*<th>Phone Number</th>*/}
                                    {/*<th>Post Code</th>*/}
                                </tr>
                        }
                        {
                            this.state.brightPearlExist &&
                            <tr>
                                <td><input type={'text'} name={'searchEmail'} onChange={this.handleOnchange}/></td>
                                <td><input type={'text'} name={'searchCompanyName'} onChange={this.handleOnchange}/></td>
                                <td><input type={'text'} name={'searchFistName'} onChange={this.handleOnchange}/></td>
                                <td><input type={'text'} name={'searchLastName'} onChange={this.handleOnchange}/></td>
                                {/*<td><input type={'text'} name={'searchPhone'} onChange={this.handleOnchange}/></td>*/}
                                {/*<td><input type={'text'} name={'searchPostCode'} onChange={this.handleOnchange}/></td>*/}
                            </tr>
                        }
                        </tbody>
                    </table>
                    {
                        this.state.brightpearlCustomerList.length !== 0 &&
                        <div className={'row'}>
                            <div className={'col-5'}>
                                <BrightpearlContactList list={this.state.brightpearlCustomerList} selectContact={this.handleSelectContact} />
                            </div>
                            <div className={'col-7'}>
                                <table className="table  table-striped asiCustomerDetailTable" style={{margin:'16px 0 0'}}>
                                    <tbody>
                                    {   selectedAsiCustomer!==null &&
                                        <tr>
                                            <th>Company Name</th>
                                            <td colSpan={'3'}>{selectedAsiCustomer.CompanyDetails.Name}</td>
                                        </tr>
                                    }
                                    {(selectedBPCustomer!==null && selectedAsiCustomer ==null && selectedBPCustomer.organisation.name!==undefined)&&
                                        <tr>
                                            <th>Company Name</th>
                                            <td colSpan={'3'}>{selectedBPCustomer.organisation.name}</td>
                                        </tr>
                                    }
                                    {selectedBPCustomer!==null &&
                                        <tr>
                                            <th>First Name</th>
                                            <td>{selectedBPCustomer.firstName}</td>
                                            <th>Last Name</th>
                                            <td>{selectedBPCustomer.lastName}</td>
                                        </tr>
                                    }
                                    {selectedBPCustomer!==null &&
                                        <tr>
                                            <th>Phone Number</th>
                                            <td>{selectedBPCustomer.communication.telephones.PRI}</td>
                                            <th>Email</th>
                                            <td>{selectedBPCustomer.communication.emails.PRI!==undefined? selectedBPCustomer.communication.emails.PRI.email:""}</td>
                                        </tr>
                                    }
                                    {(selectedBPCustomer!==null && selectedBPCustomer.DEFAddress !==null )&&
                                        <tr>
                                            <th>Address Line1</th>
                                            <td colSpan={'3'}>{selectedBPCustomer.DEFAddress.addressLine1}</td>
                                        </tr>
                                    }
                                    {(selectedBPCustomer!==null && selectedBPCustomer.DEFAddress !==null )&&
                                    <tr>
                                        <th>Address Line2</th>
                                        <td colSpan={'3'}>{selectedBPCustomer.DEFAddress.addressLine2}</td>
                                    </tr>
                                    }
                                    {(selectedBPCustomer!==null && selectedBPCustomer.DEFAddress !==null )&&
                                    <tr>
                                        <th>City</th>
                                        <td>{selectedBPCustomer.DEFAddress.addressLine3}</td>
                                        <th>Province</th>
                                        <td>{selectedBPCustomer.DEFAddress.addressLine4}</td>
                                    </tr>
                                    }
                                    {(selectedBPCustomer!==null && selectedBPCustomer.DEFAddress !==null )&&
                                    <tr>
                                        <th>Post Code</th>
                                        <td>{selectedBPCustomer.DEFAddress.postalCode}</td>
                                        <th>Country</th>
                                        <td>{selectedBPCustomer.DEFAddress.countryIsoCode}</td>
                                    </tr>
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    }

                    {
                        (!this.state.asiExist && !this.state.brightPearlExist && this.state.brightpearlCustomerList.length === 0)&&
                        <div className={'row'}>
                            <table className="table  table-striped  table-dark manuallyEnterCustomerTable col" style={{margin:'16px 0 0', borderRight:'2px soild gray'}}>
                                <tbody>
                                <tr>
                                    <th colSpan={'4'}>Shipping Address:</th>
                                </tr>
                                <tr>
                                    <th>First Name</th>
                                    <td>
                                        <input type="text" name={'manualFirstName'} onChange={this.handleOnchange}
                                               style={{width: '100%'}} value={this.state.manualFirstName}/>
                                    </td>
                                    <th>Last Name</th>
                                    <td>
                                        <input type="text" name={'manualLastName'} onChange={this.handleOnchange}
                                               style={{width: '100%'}} value={this.state.manualLastName}/>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Email</th>
                                    <td>
                                        <input type="email" name={'manualEmail'} onChange={this.handleOnchange}
                                               style={{width: '100%'}} value={this.state.manualEmail}/>
                                    </td>
                                    <th>Phone Number</th>
                                    <td>
                                        <input type="text" name={'manualPhone'} onChange={this.handleOnchange}
                                               style={{width: '100%'}} value={this.state.manualPhone}/>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Company Name</th>
                                    <td colSpan={'3'}>
                                        <input type="text" name={'manualCompany'} onChange={this.handleOnchange}
                                               style={{width: '100%'}} value={this.state.manualCompany}/>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Address Line 1</th>
                                    <td colSpan={'3'}>
                                        <input type="text" name={'manualAddress1'} onChange={this.handleOnchange}
                                               style={{width: '100%'}} value={this.state.manualAddress1}/>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Address Line 2</th>
                                    <td colSpan={'3'}>
                                        <input type="text" name={'manualAddress2'} onChange={this.handleOnchange}
                                               style={{width: '100%'}} value={this.state.manualAddress2}/>
                                    </td>
                                </tr>
                                {
                                    this.state.searchingShipPostCode &&
                                    <tr>
                                        <td colSpan={'4'}><span className={"text-center text-success"}><i className="fa fa-spinner fa-spin"></i> &nbsp; <span style={{fontWeight:'bold',fontSize:'20px'}}>Searching the Postal Code..</span></span></td>
                                    </tr>
                                }

                                <tr>
                                    <th>Postal Code<br/>
                                    </th>
                                    <td>
                                        <input type="text" name={'manualPostalCode'} onChange={this.handleOnchange}
                                               onBlur={()=>this.handleSearchPostalCode(this.state.manualPostalCode,"searchingShipPostCode")}
                                               style={{width: '100%'}} value={this.state.manualPostalCode}/>
                                    </td>
                                    <th>City</th>
                                    <td>
                                        <input type="text" name={'manualCity'} onChange={this.handleOnchange}
                                               style={{width: '100%'}} value={this.state.manualCity}/>
                                    </td>
                                </tr>
                                <tr>
                                    <th>State/Province</th>
                                    <td>
                                        <input type="text" name={'manualState'} onChange={this.handleOnchange}
                                               style={{width: '100%'}} value={this.state.manualState}/>
                                    </td>
                                    <th>Country</th>
                                    <td>
                                        <input type="text" name={'manualCountry'} onChange={this.handleOnchange}
                                               style={{width: '100%'}} value={this.state.manualCountry}/>
                                    </td>
                                </tr>
                                <tr>
                                    {
                                        this.state.manualCountry === "US" &&
                                        <th>FEIN #</th>
                                    }
                                    {
                                        this.state.manualCountry === "US" &&
                                        <td>
                                            <input  type="text" onChange={this.handleOnchange} name={"manualFEINNum"} value={this.state.manualFEINNum}/>
                                        </td>
                                    }
                                    <td colSpan={'4'} style={{verticalAlign:'middle'}}> -- Billing address as the same as shipping address &nbsp;&nbsp;
                                        <input type="checkbox" name={'isBillingAsShipping'} onChange={this.handleOnchange}
                                               checked={this.state.isBillingAsShipping}
                                               style={{width:'20px',height:'20px'}}/>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            {
                                this.state.isBillingAsShipping &&
                                    <table className="table  table-striped billingInfoTable col" style={{margin:'16px 0 0'}}>
                                        <tbody style={{borderLeft:'1px solid gray'}}>
                                        <tr>
                                            <th colSpan={'4'}>Billing Address:</th>
                                        </tr>
                                        <tr>
                                            <th>First Name</th>
                                            <td>
                                                <input type="text" name={'billingFirstName'} onChange={this.handleOnchange}
                                                       style={{width: '100%'}} value={this.state.billingFirstName}/>
                                            </td>
                                            <th>Last Name</th>
                                            <td>
                                                <input type="text" name={'billingLastName'} onChange={this.handleOnchange}
                                                       style={{width: '100%'}} value={this.state.billingLastName}/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Email</th>
                                            <td>
                                                <input type="email" name={'billingEmail'} onChange={this.handleOnchange}
                                                       style={{width: '100%'}} value={this.state.billingEmail}/>
                                            </td>
                                            <th>Phone Number</th>
                                            <td>
                                                <input type="text" name={'billingPhone'} onChange={this.handleOnchange}
                                                       style={{width: '100%'}} value={this.state.billingPhone}/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Company Name</th>
                                            <td colSpan={'3'}>
                                                <input type="text" name={'billingCompany'} onChange={this.handleOnchange}
                                                       style={{width: '100%'}} value={this.state.billingCompany}/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Address Line 1</th>
                                            <td colSpan={'3'}>
                                                <input type="text" name={'billingAddress1'} onChange={this.handleOnchange}
                                                       style={{width: '100%'}} value={this.state.billingAddress1}/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Address Line 2</th>
                                            <td colSpan={'3'}>
                                                <input type="text" name={'billingAddress2'} onChange={this.handleOnchange}
                                                       style={{width: '100%'}} value={this.state.billingAddress2}/>
                                            </td>
                                        </tr>
                                        {
                                            this.state.searchingBillingPostCode &&
                                            <tr>
                                                <td colSpan={'4'}><span className={"text-center text-success"}><i className="fa fa-spinner fa-spin"></i> &nbsp; <span style={{fontWeight:'bold',fontSize:'20px'}}>Searching the Postal Code..</span></span></td>
                                            </tr>
                                        }

                                        <tr>
                                            <th>Postal Code<br/>
                                            </th>
                                            <td>
                                                <input type="text" name={'billingPostalCode'} onChange={this.handleOnchange}
                                                       onBlur={()=>this.handleSearchPostalCode(this.state.billingPostalCode,"searchingBillingPostCode")}
                                                       style={{width: '100%'}} value={this.state.billingPostalCode}/>
                                            </td>
                                            <th>City</th>
                                            <td>
                                                <input type="text" name={'billingCity'} onChange={this.handleOnchange}
                                                       style={{width: '100%'}} value={this.state.billingCity}/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>State/Province</th>
                                            <td>
                                                <input type="text" name={'billingState'} onChange={this.handleOnchange}
                                                       style={{width: '100%'}} value={this.state.billingState}/>
                                            </td>
                                            <th>Country</th>
                                            <td>
                                                <input type="text" name={'billingCountry'} onChange={this.handleOnchange}
                                                       style={{width: '100%'}} value={this.state.billingCountry}/>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>

                            }

                        </div>

                    }


                    <table className="table table-bordered orderDetailTable ">
                        <tbody>
                            <tr>
                                <th colSpan={'3'} className={'text-center'}>Order Information:</th>
                                <th colSpan={'1'} style={{verticalAlign:'middle',textAlign:'right'}}>Rush Order:</th>
                                <td colSpan={'3'}>
                                    Is this a rush order? &nbsp;<input type={'checkbox'} name={'selectedIsRush'}
                                           style={{width:'20px',height:'20px',verticalAlign:'middle'}}
                                           checked={this.state.selectedIsRush} onChange={this.handleOnchange}/>
                                </td>
                            </tr>
                            <tr>
                                <th colSpan={'1'} rowSpan={'2'} style={{verticalAlign:'middle',textAlign:'right'}}>SKU #</th>
                                <td colSpan={'1'} rowSpan={'2'} style={{verticalAlign:'middle'}}>
                                    <select name={'selectedSKUNumber'}  style={{width:'100%'}} onChange={this.handleOnchange} value={this.state.selectedSKUNumber}>
                                        <option value="optionDefaultHeader" >-- select a SKU --</option>
                                        {this.state.skuNumberList.map((op,i)=>{
                                            return <option value={op} key={i}>{op}</option>
                                        })}
                                    </select>
                                </td>
                                <td colSpan={'1'} rowSpan={'2'} style={{verticalAlign:'middle'}}>
                                    <button className={'btn btn-success'} style={{width:'100%'}} type={'button'} onClick={this.handleAddRow}>Add</button>
                                </td>
                                <th colSpan={'1'} style={{verticalAlign:'middle',textAlign:'right'}}>Quantity:</th>
                                <td colSpan={'3'}>
                                    <input type={'text'} name={'selectedQty'} onChange={this.handleOnchange}  value={this.state.selectedQty}/>
                                </td>
                            </tr>
                            <tr>
                                <th colSpan={'1'} rowSpan={'2'} style={{verticalAlign:'middle',textAlign:'right'}}>With Logo:</th>
                                <td colSpan={'3'} rowSpan={'2'}>
                                    Is this a imprinted order? &nbsp;<input type={'checkbox'} name={'selectedWithLogo'}
                                           style={{width:'20px',height:'20px',verticalAlign:'middle'}}
                                           checked={this.state.selectedWithLogo} onChange={this.handleOnchange}/>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

export default ACE;