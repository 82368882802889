import axios from 'axios';
import { proxy } from 'jquery';
import { sleep,CHIT_CHATS_TEST_ENVIRONMENT, proxyurl } from './requestHelper';


const baseURL = CHIT_CHATS_TEST_ENVIRONMENT ?proxyurl+"https://staging.chitchats.com/api/v1/clients/989617":proxyurl+"https://chitchats.com/api/v1/clients/682019";
const headers = CHIT_CHATS_TEST_ENVIRONMENT ?{
    "headers": {
        "Content-Type": "application/json",
        "Authorization": "q7g0i4y7r01z7r1s8x0s7g4x7z",
        "Accept": "application/json"
    }

}:{
    "headers": {
        "Content-Type": "application/json",
        "Authorization": "p71m29m0k5b4h4s0q03v6u8x2q",
        "Accept": "application/json"
    }
};

const brightpearlToChitchatsModel = (bpOrder,shippingInfo,province)=>{
    let totalValue = 0;
    bpOrder.orderRows.forEach(ol=>{
        totalValue += parseFloat(ol.rowValue.rowNet.value);
    });
    let postageType = shippingInfo.ShippableWeightGrams > 450?  "usps_parcel_select" : "usps_first";
    //let postageType = "usps_media_mail";
    const value = parseInt(totalValue);
    //console.log(shippingInfo)
    return {
        "name": bpOrder.parties.delivery.addressFullName,
        "address_1": bpOrder.parties.delivery.addressLine1,
        "address_2": bpOrder.parties.delivery.addressLine2,
        "city": bpOrder.parties.delivery.addressLine3,
        "province_code": province,
        "postal_code": bpOrder.parties.delivery.postalCode,
        "country_code": bpOrder.parties.delivery.countryIsoCode,
        "phone": bpOrder.parties.delivery.telephone,
        "package_contents": "merchandise",
        "description": shippingInfo.ShippingDescription? shippingInfo.ShippingDescription: "None", 
        "value": value===0 ? 1 : value,
        "value_currency": "usd",
        "order_id": bpOrder.id,
        "order_store": "",
        "package_type": "parcel",
        "weight_unit": "g",
        "weight": shippingInfo.ShippableWeightGrams,
        "size_unit": "cm",
        "size_x": shippingInfo.ShippableWidthCm,
        "size_y": shippingInfo.ShippableHeightCm,
        "size_z": shippingInfo.ShippableDepthCm >= 1 ? shippingInfo.ShippableDepthCm : 1,
        "insurance_requested": false,
        "signature_requested": false,
        "postage_type": postageType,
        "tracking_number": "",
        "ship_date": "today"
    }
};

export const createChitChatsShipment = async(bpOrder,shippingInfo,province)=>{
    try {
        const data = brightpearlToChitchatsModel(bpOrder,shippingInfo,province);
       // console.log(data);

        const shipmentRes = (await axios.post(`${baseURL}/shipments`,data,headers)).data;

        //console.log(shipmentRes);

        const buyRes = await axios.patch(`${baseURL}/shipments/${shipmentRes.shipment.id}/buy`,null,headers);
        //console.log(buyRes);
        //const sleepRes = await sleep(8000);


        return shipmentRes.shipment.id;


    }catch (e) {
        //console.log(e);
        return null;
    }
};
export const getChitChatsShipment = async(id)=>{
    try {
        const sleepRes = await sleep(10000);
        const getShipmentRes = await axios.get(`${baseURL}/shipments/${id}`,headers);

        return getShipmentRes.data.shipment;
    }catch (e) {
        //console.log(e);
        return null;
    }
};