import React,{Component} from 'react';
import LocationSearchInput from './locationSearchInput'
import {
    addRowItem,
    createAnBrightPearlOrder,
    getBrightPearlTaxCode,createContact,
    getIdBySKU,createPostalAddress,
    getProductPriceListByProductID, getOrderDetailsByOrderIDList, searchCustomer, getCustomerInfo, getPostalAddress
} from "../../../Utils/brightpearl";
import {getSkuDetail, priceList,createAshlinManualOrder } from "../../../Utils/ashlinDesign";
import { findCountryCode  } from '../../../Utils/IOSCountryCode';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import async from "async";
import {getAsiCustomerCreditSummary} from "../../../Utils/asi";
import BrightpearlContactList from '../../ACE/component/brightpearl_contact_list';
class CreateNewManualOrder extends Component{
    _enableSearch = true;
    constructor(props){
        super(props);
        this.state={
            shippingSearchAddress:"",
            shippingFirstName:"",
            shippingLastName:"",
            shippingEmail:"",
            shippingPhone:"",
            shippingCompany:"",
            shippingAddress1:'',
            shippingAddress2:"",
            shippingCity:"",
            shippingState:"",
            shippingCountry:"",
            shippingPostalCode:"",
            shippingFEINNum:"",


            billingFirstName:"",
            billingLastName:"",
            billingEmail:"",
            billingPhone:"",
            billingCompany:"",
            billingAddress1:'',
            billingAddress2:"",
            billingCity:"",
            billingState:"",
            billingCountry:"",
            billingPostalCode:"",


            selectedIsRush:false,
            selectedSKUNumber:"optionDefaultHeader",
            selectedQty:"1",
            selectedWithLogo:false,
            manualOrderLines:[],
            calculatingPrice:false,

            salesStoreName:'optionDefaultHeader',
            shipWarehouseName:'optionDefaultHeader',
            salesStoreValue:{},
            shipWarehouseValue:{},
            orderPO:'',
            brightPearlOrder:'',
            shippingPaid:'',
            orderDate:moment(),
            paidDate:moment(),


            asiExist: "unSelected",
            brightPearlExist:"unSelected",
            asiNumber:'',
            selectedAsiCustomer:null,
            brightpearlCustomerList:[],
            selectedBPCustomer:null,
            searchFistName:"",
            searchLastName:"",
            searchCompanyName:"",
            searchEmail:"",
            isBillingAsShipping:true,
            assignedTo:'optionDefaultHeader',
        }
    }
    handleOnchange=(e)=>{
        const name = e.target.name;
        const value = e.target.type === "checkbox"? e.target.checked: e.target.value;
        if(value!=="optionDefaultHeader"){
            this.setState({
                [name]:value
            })
        }
    };
    handleSelectSaleStoreOnchange=(e)=>{
        const name = e.target.name;
        const value = e.target.value;
        if(value!=="optionDefaultHeader"){
            const storeValue = this.props.BrightpearlChannelInfos.find(i=>i.ChannelName === value);
            this.setState({
                [name]:value,
                salesStoreValue:storeValue
            })
        }
    };

    handleSelectWarehouseOnchange=(e)=>{
        const name = e.target.name;
        const value = e.target.value;
        if(value!=="optionDefaultHeader"){
            const shipWarehouseValue = this.props.brightPearlWarehouses.find(w=>w.name === value);
            this.setState({
                [name]:value,
                shipWarehouseValue:shipWarehouseValue
            })
        }
    };
    setBillingFormLocation = (address) => {

        const parsedLoc = address.split(',');
        const billingAddress1 = parsedLoc[0].trim();
        const billingCity = parsedLoc[1].trim();
        const billingCountry = parsedLoc[3].trim();

        const str = parsedLoc[2].trim();
        const index = str.indexOf(' ');
        const billingState = str.substring(0, index).trim();
        const billingPostalCode = str.substring(index).trim();
        this.setState({
            billingAddress1,
            billingCity,
            billingState,
            billingCountry,
            billingPostalCode
        })
    };
    setShippingFormLocation= (address) => {
        // The Google result comes back as a comma-separated string:
        // "Austin, TX, USA". Parse it into usable data.

        const parsedLoc = address.split(',');
        const shippingAddress1 = parsedLoc[0].trim();
        const shippingCity = parsedLoc[1].trim();
        const shippingCountry = parsedLoc[3].trim();

        const str = parsedLoc[2].trim();
        const index = str.indexOf(' ');
        const shippingState = str.substring(0, index).trim();
        const shippingPostalCode = str.substring(index).trim();
        this.setState({
            shippingAddress1,
            shippingCity,
            shippingState,
            shippingCountry,
            shippingPostalCode
        })
    };

    handleAddRow=async()=>{
        const SKU = this.state.selectedSKUNumber;
        if(SKU==="optionDefaultHeader"){
            alert("Please Pick a SKU number!")
        }else {
            this.setState({
                calculatingPrice:true
            },async()=> {
                let prices, provinceCode, taxCode, rowNet, orderType,qtyTier;
                const manualOrderLines = this.state.manualOrderLines;
                const country = this.state.shippingCountry;
                const selectedQty = parseInt(this.state.selectedQty);

                if(selectedQty >= 2500){
                    qtyTier = 2500;
                }else if(selectedQty >= 1000){
                    qtyTier = 1000;
                }else if(selectedQty >= 500){
                    qtyTier = 500;
                }else if(selectedQty >= 250){
                    qtyTier = 250;
                }else if(selectedQty >= 100){
                    qtyTier = 100;
                }else if(selectedQty >= 50){
                    qtyTier = 50;
                }else if(selectedQty >= 24){
                    qtyTier = 24;
                }else if(selectedQty >= 6){
                    qtyTier = 6;
                }else{
                    qtyTier = 1;
                }
                if (this.state.isBillingAsShipping) {
                    provinceCode = getBrightPearlTaxCode(this.state.shippingState);
                } else {
                    provinceCode = getBrightPearlTaxCode(this.state.billingState);
                }
                taxCode = this.props.taxCodes.find(tax => tax.code === provinceCode);
                //console.log(taxCode);
                const BPID = await getIdBySKU(SKU, this.props.brightpearlHeader);
                if(BPID!=="N/A") {
                    const priceLists = await getProductPriceListByProductID(BPID, this.props.brightpearlHeader);
                    if (country === "CA" || country === "Canada" || country === "CAN") {
                        if (this.state.selectedWithLogo && this.state.selectedIsRush) {
                            //Distributor-NET-RUSH-IMP-CAD
                            prices = priceLists.find(p => p.priceListId === 8);
                            orderType = "Rush&Imprinted";
                        } else if (this.state.selectedWithLogo) {
                            //Distributor-NET-STD-IMP-CAD
                            prices = priceLists.find(p => p.priceListId === 5);
                            orderType = "Imprinted";
                        } else if (this.state.selectedIsRush) {
                            //Distributor-NET-RUSH-BLANK-CAD
                            prices = priceLists.find(p => p.priceListId === 7);
                            orderType = "Rush";
                        } else {
                            //Distributor-NET-STD-BLANK-CAD
                            prices = priceLists.find(p => p.priceListId === 6);
                            orderType = "Standard&Plain";
                        }
                        rowNet = prices.quantityPrice[qtyTier];

                    } else {
                        if (this.state.selectedWithLogo && this.state.selectedIsRush) {
                            //Distributor-NET-RUSH-IMP-USD
                            prices = priceLists.find(p => p.priceListId === 16);
                            orderType = "Rush&Imprinted";
                        } else if (this.state.selectedWithLogo) {
                            //Distributor-NET-STD-IMP-USD
                            prices = priceLists.find(p => p.priceListId === 13);
                            orderType = "Imprinted";
                        } else if (this.state.selectedIsRush) {
                            //Distributor-NET-RUSH-BLANK-USD
                            prices = priceLists.find(p => p.priceListId === 14);
                            orderType = "Rush";
                        } else {
                            //Distributor-NET-STD-BLANK-USD
                            prices = priceLists.find(p => p.priceListId === 15);
                            orderType = "Standard&Plain";
                        }
                        rowNet = prices.quantityPrice[qtyTier];
                        taxCode = this.props.taxCodes.find(tax => tax.code === "Z");
                        taxCode.rate = 0;
                    }

                    if (isNaN(rowNet)) {
                        console.log("PriceList doesn't exist on Brightpearl, Calculate price based on Database.")
                        const product = await getSkuDetail(SKU);
                        const ashlinPriceList = await priceList(product);
                        if (country === "CA" || country === "Canada" || country === "CAN") {
                            if (this.state.selectedWithLogo && this.state.selectedIsRush) {
                                //Distributor-NET-RUSH-IMP-CAD
                                prices = ashlinPriceList.Distributor_NET_RUSH_IMP_CAD;
                                orderType = "Rush&Imprinted";
                            } else if (this.state.selectedWithLogo) {
                                //Distributor-NET-STD-IMP-CAD
                                prices = ashlinPriceList.Distributor_NET_STD_IMP_CAD;
                                orderType = "Imprinted";
                            } else if (this.state.selectedIsRush) {
                                //Distributor-NET-RUSH-BLANK-CAD
                                prices = ashlinPriceList.Distributor_NET_RUSH_BLANK_CAD;
                                orderType = "Rush";
                            } else {
                                //Distributor-NET-STD-BLANK-CAD
                                prices = ashlinPriceList.Distributor_NET_STD_BLANK_CAD;
                                orderType = "Standard&Plain";
                            }

                            rowNet = prices[`QTY${qtyTier}`];

                        } else {
                            if (this.state.selectedWithLogo && this.state.selectedIsRush) {
                                //Distributor-NET-RUSH-IMP-USD
                                prices = ashlinPriceList.Distributor_NET_RUSH_IMP_USD;
                                orderType = "Rush&Imprinted";
                            } else if (this.state.selectedWithLogo) {
                                //Distributor-NET-STD-IMP-USD
                                prices = ashlinPriceList.Distributor_NET_STD_IMP_USD;
                                orderType = "Imprinted";

                            } else if (this.state.selectedIsRush) {
                                //Distributor-NET-RUSH-BLANK-USD
                                prices = ashlinPriceList.Distributor_NET_RUSH_BLANK_USD;
                                orderType = "Rush";
                            } else {
                                //Distributor-NET-STD-BLANK-USD
                                prices = ashlinPriceList.Distributor_NET_STD_BLANK_USD;
                                orderType = "Standard&Plain";
                            }

                            rowNet = prices[`QTY${qtyTier}`];

                        }
                    }
                }else{
                    console.log("SKU doesn't exist on Brightpearl, Calculate price based on Database.");
                    const product = await getSkuDetail(SKU);
                    const ashlinPriceList = await priceList(product);
                    if (country === "CA" || country === "Canada" || country === "CAN" ) {
                        if (this.state.selectedWithLogo && this.state.selectedIsRush) {
                            //Distributor-NET-RUSH-IMP-CAD
                            prices = ashlinPriceList.Distributor_NET_RUSH_IMP_CAD;
                            orderType = "Rush&Imprinted";
                        } else if (this.state.selectedWithLogo) {
                            //Distributor-NET-STD-IMP-CAD
                            prices = ashlinPriceList.Distributor_NET_STD_IMP_CAD;
                            orderType = "Imprinted";
                        } else if (this.state.selectedIsRush) {
                            //Distributor-NET-RUSH-BLANK-CAD
                            prices = ashlinPriceList.Distributor_NET_RUSH_BLANK_CAD;
                            orderType = "Rush";
                        } else {
                            //Distributor-NET-STD-BLANK-CAD
                            prices = ashlinPriceList.Distributor_NET_STD_BLANK_CAD;
                            orderType = "Standard&Plain";
                        }

                        rowNet = prices[`QTY${qtyTier}`];

                    } else {
                        if (this.state.selectedWithLogo && this.state.selectedIsRush) {
                            //Distributor-NET-RUSH-IMP-USD
                            prices = ashlinPriceList.Distributor_NET_RUSH_IMP_USD;
                            orderType = "Rush&Imprinted";
                        } else if (this.state.selectedWithLogo) {
                            //Distributor-NET-STD-IMP-USD
                            prices = ashlinPriceList.Distributor_NET_STD_IMP_USD;
                            orderType = "Imprinted";

                        } else if (this.state.selectedIsRush) {
                            //Distributor-NET-RUSH-BLANK-USD
                            prices = ashlinPriceList.Distributor_NET_RUSH_BLANK_USD;
                            orderType = "Rush";
                        } else {
                            //Distributor-NET-STD-BLANK-USD
                            prices = ashlinPriceList.Distributor_NET_STD_BLANK_USD;
                            orderType = "Standard&Plain";
                        }

                        rowNet = prices[`QTY${qtyTier}`];
                        taxCode = this.props.taxCodes.find(tax => tax.code === "Z");
                        taxCode.rate = 0;
                    }
                }
                const rowSingle = rowNet;
                rowNet = rowNet * this.state.selectedQty;
                const rowTax = (rowNet * taxCode.rate / 100).toFixed(2);

                const rowTotal = (parseFloat(rowNet) + parseFloat(rowTax)).toFixed(2);

                const line = {
                    sku: SKU,
                    productId: BPID,
                    orderType: orderType,
                    magnitude: this.state.selectedQty,
                    taxCode: taxCode.code,
                    taxRate:taxCode.rate,
                    rowNet: (1 * rowNet).toFixed(2),
                    rowTax: rowTax,
                    rowTotal: rowTotal,
                    rowSingle
                };
                manualOrderLines.push(line);
                //FINAL DO RESET
                this.setState({
                    selectedIsRush: false,
                    selectedSKUNumber: "optionDefaultHeader",
                    selectedQty: "1",
                    selectedWithLogo: false,
                    manualOrderLines,
                    calculatingPrice:false
                })
            })
        }
    };
    handleDeleteOrderLine=(index)=>{
        const manualOrderLines = this.state.manualOrderLines;
        manualOrderLines.splice(index,1);
        this.setState({
            manualOrderLines
        });
    };
    handleRowNetValueOnchange=(e,index)=>{
        const value = e.target.value;
        const manualOrderLines = this.state.manualOrderLines;
        const line = manualOrderLines[index];
        const rowSingle = value;
        const newRowNet = (parseFloat(rowSingle) * line.magnitude).toFixed(2);
        const newRowTax = (newRowNet * line.taxRate/100).toFixed(2);
        const newRowTotal = (parseFloat(newRowNet) + parseFloat(newRowTax)).toFixed(2);
        line.rowSingle = rowSingle;
        line.rowNet = newRowNet;
        line.rowTax = newRowTax;
        line.rowTotal = newRowTotal;
        this.setState({
            manualOrderLines
        })
    };

    handleChangeOrderDate=(date)=>{
        this.setState({
            orderDate:date
        });
    };
    handleChangePaidDate=(date)=>{
        this.setState({
            paidDate:date
        });
    };

    handleCreateOrder=async()=>{
        const salesStoreName = this.state.salesStoreName;
        if(salesStoreName==="optionDefaultHeader"&&this.state.selectedBPCustomer === null&&this.state.brightPearlOrder===""){
            alert("Please Pick a Sale Store!")
        }else {
            const brightpearlHeader = this.props.brightpearlHeader;
            let selectedBPCustomer = this.state.selectedBPCustomer;
            const manualOrderLines = this.state.manualOrderLines;
            const taxCode = manualOrderLines[0].taxCode;
            const taxRate = manualOrderLines[0].taxRate;
            let brightPearlOrderId = this.state.brightPearlOrder;
            const imprintedIndex = manualOrderLines.findIndex(line=>line.orderType.includes("Imprinted"));
            const orderLines = manualOrderLines.map(line=>{
                return line.productId !== "N/A"? {
                    "productId": line.productId,
                    "quantity": {
                        "magnitude": line.magnitude
                    },
                    "rowValue": {
                        "taxCode": line.taxCode,
                        "rowNet": {
                            "value": line.rowNet
                        },
                        "rowTax": {
                            "value": line.rowTax
                        }
                    },
                    "nominalCode": "4000"
                }:{
                    "productName": line.sku,
                    "quantity": {
                        "magnitude": line.magnitude
                    },
                    "rowValue": {
                        "taxCode": line.taxCode,
                        "rowNet": {
                            "value": line.rowNet
                        },
                        "rowTax": {
                            "value": line.rowTax
                        }
                    },
                    "nominalCode": "4000"
                };
            });
            if(imprintedIndex !== -1){
                const rowNet = 78.75; // taxRate === 0? 78.75: 92.5;
                const rowTax = (rowNet * taxRate / 100).toFixed(2);
                orderLines.push({
                    "productId": 2204 ,
                    "quantity": {
                        "magnitude": 1
                    },
                    "rowValue": {
                        "taxCode": taxCode,
                        "rowNet": {
                            "value": rowNet
                        },
                        "rowTax": {
                            "value": rowTax
                        }
                    },
                    "nominalCode": "4000"
                })
            }
            if(brightPearlOrderId ===""){

                //if customer doesn't exist, create the customer first
                const store = this.state.salesStoreValue;
                if(selectedBPCustomer === null){
                    let defaultAddressId,billingAddressId,postAddressIds;

                    console.log("Create new");

                        const defultAddresData = {
                            "addressLine1": this.state.shippingAddress1,
                            "addressLine2": this.state.shippingAddress2,
                            "addressLine3": this.state.shippingCity,
                            "addressLine4": this.state.shippingState,
                            "postalCode": this.state.shippingPostalCode,
                            "countryIsoCode": findCountryCode(this.state.shippingCountry)
                        };
                    defaultAddressId = await createPostalAddress(defultAddresData,brightpearlHeader);
                    postAddressIds = {
                        "DEF": defaultAddressId,
                        "DEL": defaultAddressId,
                        "BIL": defaultAddressId
                    };
                    if (!this.state.isBillingAsShipping){

                        const billAddresData = {
                            "addressLine1": this.state.billingAddress1,
                            "addressLine2": this.state.billingAddress1,
                            "addressLine3": this.state.billingCity,
                            "addressLine4": this.state.billingState,
                            "postalCode": this.state.billingPostalCode,
                            "countryIsoCode": findCountryCode(this.state.billingCountry)
                        };
                        billingAddressId = await createPostalAddress(billAddresData,brightpearlHeader);
                        postAddressIds = {
                            "DEF": defaultAddressId,
                            "DEL": defaultAddressId,
                            "BIL":billingAddressId
                        };
                    }
                    const contactData = {
                        "firstName": this.state.shippingFirstName,
                        "lastName": this.state.shippingLastName,
                        "postAddressIds": postAddressIds,
                        "communication": {
                            "emails": {
                                "PRI": {
                                    "email": this.state.shippingEmail
                                }
                            },
                            "telephones": {
                                "PRI": this.state.shippingPhone
                            }
                        },
                        "organisation": {
                            "name": this.state.isBillingAsShipping?this.state.shippingCompany : this.state.billingCompany
                        },
                        "relationshipToAccount": {
                            "isSupplier": false,
                            "isStaff": false
                        },

                    };
                    //console.log(contactData);
                    const contactID = await createContact(contactData,brightpearlHeader);
                    console.log(contactID)
                    store.ContactId = contactID ;
                }



                //Create New Order

                const warehouse = this.state.shipWarehouseValue;
                const currency = (this.state.shippingCountry==="Canada"||this.state.shippingCountry.toUpperCase()==="CA"||this.state.shippingCountry.toUpperCase()==="CAN")?"CAD":"USD";
                const countryCode = findCountryCode(this.state.shippingCountry);

                const rushIndex = manualOrderLines.findIndex(line=>line.orderType.includes("Rush"));


                const addDate = rushIndex === -1 ? 30 : 10;
                let d = new Date();
                d.setDate(d.getDate() + addDate);
                const dayOfWeek = d.getDay();
                if(dayOfWeek===0){
                    d.setDate(d.getDate() + 1);
                }
                if(dayOfWeek===6){
                    d.setDate(d.getDate() + 2);
                }
                const deliveryDate = d.toISOString().slice(0,10);

                const current = (this.state.assignedTo!=="optionDefaultHeader")?{
                    "channelId": store.ChannelId,
                    "staffOwnerContactId": parseInt(this.state.assignedTo),
                }:{
                    "channelId": store.ChannelId
                };
                let reference,priceListId;

                if(this.state.orderPO === ""){
                    if(imprintedIndex!==-1 && rushIndex !==-1 ){
                        reference = "Rush Delivery with Logo";
                        if(currency === "CAD"){
                            priceListId = 8 ;
                        }else{
                            priceListId = 16;
                        }
                    }else if(imprintedIndex!==-1){
                        reference = "Standard Delivery with Logo";
                        if(currency === "CAD"){
                            priceListId = 5;
                        }else{
                            priceListId = 13;
                        }
                    }else if(rushIndex !== -1){
                        reference = "Rush Delivery with no Logo";
                        if(currency === "CAD"){
                            priceListId = 7;
                        }else{
                            priceListId = 14;
                        }
                    }else{
                        reference = "Standard Delivery with no Logo";
                        if(currency === "CAD"){
                            priceListId = 6;
                        }else{
                            priceListId = 15;
                        }
                    }
                }else{
                    reference = this.state.orderPO;
                    priceListId = 3;
                }

                const order = {
                    "orderTypeCode": "SO",
                    "reference": reference,
                    "priceListId": priceListId,   //If no price list ID is sent in the customer/supplier's assigned price list is used.
                    "orderStatus": {
                        "orderStatusId": 1//20
                    },
                    "placedOn": this.state.orderDate.format('YYYY-MM-DD'),
                    "delivery": {
                        "shippingMethodId": store.ShippingMethodId,
                        "deliveryDate": deliveryDate,
                    },
                    "currency": {
                        "orderCurrencyCode": currency
                    },
                    "parties": {
                        "customer": {
                            "contactId": store.ContactId
                        },
                        "delivery": {
                            "addressFullName": `${this.state.shippingFirstName} ${this.state.shippingLastName}`,
                            "companyName": this.state.shippingCompany,
                            "addressLine1": this.state.shippingAddress1,
                            "addressLine2": this.state.shippingAddress2,
                            "addressLine3": this.state.shippingCity,
                            "addressLine4": this.state.shippingState,
                            "postalCode": this.state.shippingPostalCode,
                            "countryIsoCode": countryCode,
                            "telephone": this.state.shippingPhone,
                            "email": this.state.shippingEmail
                        }
                    },
                    "assignment": {
                        "current":current
                    },
                    "warehouseId": warehouse.id
                };
                //console.log(order);
                const BPResponse = await createAnBrightPearlOrder(order,brightpearlHeader);
                brightPearlOrderId = BPResponse.data.response;
                //console.log(brightPearlOrderId);


                const ashlinManualOrder =  {
                    "SaleChannelId": store.AshlinChannelId.toUpperCase(),
                    "SaleChannelOrderId": this.state.orderPO,
                    "BrightpearlOrderId": brightPearlOrderId,
                    "Warehouse":warehouse.name,
                    "ShippingCarrier": "",
                    "ShippingService": "",
                    "ShippingLabelId": "",
                    "TrackingNumber": "",
                    "LabelDetail":"",
                    "Status": "OrderCreated"
                };
                const newAshlinManualOrder = await createAshlinManualOrder(ashlinManualOrder);
                this.props.handleInsertNewLine(newAshlinManualOrder);

                if(this.state.shippingPaid!==''){
                    orderLines.push({
                        "productId": 3707,
                        "quantity": {
                            "magnitude": 1
                        },
                        "rowValue": {
                            "taxCode": taxCode,
                            "rowNet": {
                                "value": parseFloat(this.state.shippingPaid).toFixed(2)
                            },
                            "rowTax": {
                                "value": (parseFloat(this.state.shippingPaid) * taxRate/100).toFixed(2)
                            }
                        },
                        "nominalCode": "4030"
                    })
                }
                //console.log(orderLines);
                async.each(orderLines, async function(rowValue, callback) {
                    // Do work to process file here
                    await addRowItem(brightPearlOrderId, rowValue,brightpearlHeader );
                    await setTimeout(()=>{
                        return   callback();
                    },1000);
                }, function(err) {

                });
            }else{
                //console.log(orderLines);
                async.each(orderLines, async function(rowValue, callback) {
                    // Do work to process file here
                    await addRowItem(brightPearlOrderId, rowValue,brightpearlHeader );
                    await setTimeout(()=>{
                        return   callback();
                    },1000);
                }, function(err) {

                });
            }
            this.setState({
                shippingSearchAddress:"",
                shippingFirstName:"",
                shippingLastName:"",
                shippingEmail:"",
                shippingPhone:"",
                shippingCompany:"",
                shippingAddress1:'',
                shippingAddress2:"",
                shippingCity:"",
                shippingState:"",
                shippingCountry:"",
                shippingPostalCode:"",
                shippingFEINNum:"",


                billingFirstName:"",
                billingLastName:"",
                billingEmail:"",
                billingPhone:"",
                billingCompany:"",
                billingAddress1:'',
                billingAddress2:"",
                billingCity:"",
                billingState:"",
                billingCountry:"",
                billingPostalCode:"",


                selectedIsRush:false,
                selectedSKUNumber:"optionDefaultHeader",
                selectedQty:"1",
                selectedWithLogo:false,
                manualOrderLines:[],
                calculatingPrice:false,

                salesStoreName:'optionDefaultHeader',
                shipWarehouseName:'optionDefaultHeader',
                salesStoreValue:{},
                shipWarehouseValue:{},
                orderPO:'',
                brightPearlOrder:'',
                shippingPaid:'',
                orderDate:moment(),
                paidDate:moment(),

                asiExist: "unSelected",
                brightPearlExist:"unSelected",
                asiNumber:'',
                selectedAsiCustomer:null,
                brightpearlCustomerList:[],
                selectedBPCustomer:null,
                searchFistName:"",
                searchLastName:"",
                searchCompanyName:"",
                searchEmail:"",
                isBillingAsShipping:true,
                assignedTo:'optionDefaultHeader',
            })
        }
    };
    handleGetBrightPearlOrder=async()=>{
        const id =this.state.brightPearlOrder;
        if(!isNaN(id)&&id.length===6){
            let order = await getOrderDetailsByOrderIDList(id,this.props.brightpearlHeader);
            if(order.length > 0){
                order = order[0];
                const names = order.parties.delivery.addressFullName.split(' ');
                this.setState({
                    shippingFirstName: names[0],
                    shippingLastName: names[1],
                    shippingEmail:order.parties.delivery.email,
                    shippingPhone:order.parties.delivery.telephone,
                    shippingCompany:order.parties.delivery.companyName,
                    shippingAddress1:order.parties.delivery.addressLine1,
                    shippingAddress2:order.parties.delivery.addressLine2,
                    shippingCity:order.parties.delivery.addressLine3,
                    shippingState:order.parties.delivery.addressLine4,
                    shippingCountry:order.parties.delivery.country,
                    shippingPostalCode:order.parties.delivery.postalCode,
                })
            }
        }
    };
    handleReset=()=>{
        this.setState({ shippingSearchAddress:"",
            shippingFirstName:"",
            shippingLastName:"",
            shippingEmail:"",
            shippingPhone:"",
            shippingCompany:"",
            shippingAddress1:'',
            shippingAddress2:"",
            shippingCity:"",
            shippingState:"",
            shippingCountry:"",
            shippingPostalCode:"",
            shippingFEINNum:"",


            billingFirstName:"",
            billingLastName:"",
            billingEmail:"",
            billingPhone:"",
            billingCompany:"",
            billingAddress1:'',
            billingAddress2:"",
            billingCity:"",
            billingState:"",
            billingCountry:"",
            billingPostalCode:"",


            selectedIsRush:false,
            selectedSKUNumber:"optionDefaultHeader",
            selectedQty:"1",
            selectedWithLogo:false,
            manualOrderLines:[],
            calculatingPrice:false,

            salesStoreName:'optionDefaultHeader',
            shipWarehouseName:'optionDefaultHeader',
            salesStoreValue:{},
            shipWarehouseValue:{},
            orderPO:'',
            brightPearlOrder:'',
            shippingPaid:'',
            orderDate:moment(),
            paidDate:moment(),


            asiExist: "unSelected",
            brightPearlExist:"unSelected",
            asiNumber:'',
            selectedAsiCustomer:null,
            brightpearlCustomerList:[],
            selectedBPCustomer:null,
            searchFistName:"",
            searchLastName:"",
            searchCompanyName:"",
            searchEmail:"",
            isBillingAsShipping:true,
            assignedTo:'optionDefaultHeader',
        })
    };
    handleSearchAsi=async()=>{
        const selectedAsiCustomer = await getAsiCustomerCreditSummary(this.state.asiNumber,this.props.asiToken);
        if(selectedAsiCustomer == null){
            alert(`Asi # ${this.state.asiNumber} not exists!`);
            this.setState({
                asiExist: "No",
                brightpearlCustomerList:[]
            })
        }else{
            const searchCondition = `companyName=${selectedAsiCustomer.CompanyDetails.Name}`;
            const brightpearlCustomerList = await searchCustomer(searchCondition,this.props.brightpearlHeader);
            this.setState({
                selectedAsiCustomer,
                brightpearlCustomerList,
                selectedBPCustomer:null
            })
        }
    };
    handleSelectContact=async(e,selected)=>{
        const selectedBPCustomer = await getCustomerInfo(selected[0],this.props.brightpearlHeader);
        const DEFAddress = await getPostalAddress(selectedBPCustomer.postAddressIds.DEF,this.props.brightpearlHeader);
        const BILAddress = await getPostalAddress(selectedBPCustomer.postAddressIds.BIL,this.props.brightpearlHeader);
        const DELAddress = await getPostalAddress(selectedBPCustomer.postAddressIds.DEL,this.props.brightpearlHeader);

        let ChannelId,ShippingMethodId;
        if(DEFAddress.countryIsoCode==="CAN"){
            ChannelId = 14;
            ShippingMethodId = 6;
        }else{
            ChannelId = 13;
            ShippingMethodId = 2;
        }
        const salesStoreValue = {
            "ContactId":selectedBPCustomer.contactId,
            ShippingMethodId,
            ChannelId,
            "AshlinChannelId":"27d3cf14-1f25-46e0-b31d-8a98074cb3f5"
        };
        Promise.all([selectedBPCustomer,DEFAddress,BILAddress,DELAddress]).then(()=>{
            selectedBPCustomer.DEFAddress = DEFAddress;
            selectedBPCustomer.BILAddress = BILAddress;
            selectedBPCustomer.DELAddress = DELAddress;
            this.setState({
                selectedBPCustomer,
                salesStoreValue,
                shippingFirstName:selectedBPCustomer.firstName,
                shippingLastName:selectedBPCustomer.lastName,
                shippingEmail:selectedBPCustomer.communication.emails.PRI.email,
                shippingPhone:selectedBPCustomer.communication.telephones.PRI,
                shippingCompany:selectedBPCustomer.organisation.name,
                shippingAddress1:selectedBPCustomer.DELAddress.addressLine1,
                shippingAddress2:selectedBPCustomer.DELAddress.addressLine2,
                shippingCity:selectedBPCustomer.DELAddress.addressLine3,
                shippingState:selectedBPCustomer.DELAddress.addressLine4,
                shippingCountry:selectedBPCustomer.DELAddress.countryIsoCode,
                shippingPostalCode:selectedBPCustomer.DELAddress.postalCode,
                shippingFEINNum:"",
            },()=>console.log(this.state))
        })

    };
    handleSearchBrightpearlCustomer= async()=>{
        let searchCondition = "";
        if(this.state.searchFistName !== "" || this.state.searchLastName !==""){
            if(this.state.searchFistName !== ""){
                searchCondition += `&firstName=${this.state.searchFistName}`;
            }
            if(this.state.searchLastName !== ""){
                searchCondition += `&lastName=${this.state.searchLastName}`;
            }
        }else{
            if(this.state.searchEmail !== ""){
                searchCondition += `&primaryEmail=${this.state.searchEmail}`;
            }
            if(this.state.searchCompanyName!== ""){
                searchCondition += `&companyName=${this.state.searchCompanyName}`;
            }
        }

        if(searchCondition!==""){
            const brightpearlCustomerList = await searchCustomer(searchCondition,this.props.brightpearlHeader);
            console.log(brightpearlCustomerList);
            if(brightpearlCustomerList.length === 0){
                alert("Could not find by search condition!");
            }else {
                this.setState({
                    // selectedAsiCustomer,
                    brightpearlCustomerList
                })
            }
        }

    };
    render(){
        const selectedAsiCustomer = this.state.selectedAsiCustomer;
        const selectedBPCustomer = this.state.selectedBPCustomer;
        const BrightpearlChannelName = this.props.BrightpearlChannelInfos.map(i=>i.ChannelName);
        const BrightPearlWarehousesName = this.props.brightPearlWarehouses.map(w=>w.name);
        return(
            <div className="modal fade" id="CreateNewManualOrderModal" tabIndex="-1" role="dialog" data-backdrop="static"
                 aria-labelledby="CreateNewManualOrderModalTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title text-center" id="CreateNewManualOrderModalTitle">New Manual Order</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleReset}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">

                            <div className={'row'}>
                                {
                                    this.state.asiExist==="unSelected" && <div className={'col'}>
                                        <h1>Is there an ASI number?</h1>
                                        <button className={'btn btn-success'} type={'button'} style={{margin:'0 10px'}} onClick={()=>{this.setState({asiExist:'Yes'})}}>Yes</button>
                                        <button className={'btn btn-danger'} type={'button'} style={{margin:'0 10px'}} onClick={()=>{this.setState({asiExist:'No'})}}>No</button>
                                    </div>
                                }
                                {
                                    this.state.asiExist==="Yes" && <div className={'col'}>
                                        ASI # <input name={'asiNumber'} onChange={this.handleOnchange} value={this.state.asiNumber} />
                                        <br/>
                                        <br/>
                                        <button className={'btn btn-success'} type={'button'} style={{margin:'0 10px'}} onClick={this.handleSearchAsi}>Search</button>
                                        <button className={'btn'} onClick={this.handleReset} style={{margin:'0 10px'}}>Go Back</button>
                                    </div>
                                }
                                {
                                    (this.state.asiExist==="No" && this.state.brightPearlExist==="unSelected") && <div className={'col'}>
                                        <h1>Is a Brightpearl Customer?</h1>
                                        <button className={'btn btn-success'} type={'button'} style={{margin:'0 10px'}} onClick={()=>{this.setState({brightPearlExist:'Yes'})}}>Yes</button>
                                        <button className={'btn btn-danger'} type={'button'} style={{margin:'0 10px'}} onClick={()=>{this.setState({brightPearlExist:'No'})}}>No</button>
                                    </div>
                                }
                                {
                                    (this.state.brightPearlExist==="Yes")&&<table className={"table  table-striped"} >
                                        <tbody>
                                            <tr>
                                                <th>Email</th>
                                                <td><input type={'text'} name={'searchEmail'} onChange={this.handleOnchange}/></td>
                                            </tr>
                                            <tr>
                                                <th>Company Name</th>
                                                <td><input type={'text'} name={'searchCompanyName'} onChange={this.handleOnchange}/></td>
                                            </tr>
                                            <tr>
                                                <th>First Name</th>
                                                <td><input type={'text'} name={'searchFistName'} onChange={this.handleOnchange}/></td>
                                            </tr>
                                            <tr>
                                                <th>Last Name</th>
                                                <td><input type={'text'} name={'searchLastName'} onChange={this.handleOnchange}/></td>
                                            </tr>
                                            <tr>
                                                <td><button className={'btn btn-success'} type={'button'} style={{margin:'0 10px'}} onClick={this.handleSearchBrightpearlCustomer}>Search</button></td>
                                                <td><button className={'btn'} onClick={this.handleReset} style={{margin:'0 10px'}}>Go Back</button></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                }
                                <br/>
                                {
                                    this.state.brightpearlCustomerList.length !== 0 &&
                                    <div className={'row'} style={{width:'100%',marginTop:'10px'}}>
                                        <div className={'col'}>
                                            <BrightpearlContactList list={this.state.brightpearlCustomerList} selectContact={this.handleSelectContact} />
                                        </div>
                                        <div className={'col'}>
                                            <table className="table  table-striped asiCustomerDetailTable" style={{margin:' 0 0'}}>
                                                <tbody>
                                                {   selectedAsiCustomer!==null &&
                                                <tr>
                                                    <th>Company Name</th>
                                                    <td colSpan={'3'}>{selectedAsiCustomer.CompanyDetails.Name}</td>
                                                </tr>
                                                }
                                                {(selectedBPCustomer!==null && selectedAsiCustomer ==null && selectedBPCustomer.organisation.name!==undefined)&&
                                                <tr>
                                                    <th>Company Name</th>
                                                    <td colSpan={'3'}>{selectedBPCustomer.organisation.name}</td>
                                                </tr>
                                                }
                                                {selectedBPCustomer!==null &&
                                                <tr>
                                                    <th>First Name</th>
                                                    <td>{selectedBPCustomer.firstName}</td>
                                                    <th>Last Name</th>
                                                    <td>{selectedBPCustomer.lastName}</td>
                                                </tr>
                                                }
                                                {selectedBPCustomer!==null &&
                                                <tr>
                                                    <th>Phone Number</th>
                                                    <td>{selectedBPCustomer.communication.telephones.PRI}</td>
                                                    <th>Email</th>
                                                    <td>{selectedBPCustomer.communication.emails.PRI!==undefined? selectedBPCustomer.communication.emails.PRI.email:""}</td>
                                                </tr>
                                                }
                                                {(selectedBPCustomer!==null && selectedBPCustomer.DEFAddress !==null )&&
                                                <tr>
                                                    <th>Address Line1</th>
                                                    <td colSpan={'3'}>{selectedBPCustomer.DEFAddress.addressLine1}</td>
                                                </tr>
                                                }
                                                {(selectedBPCustomer!==null && selectedBPCustomer.DEFAddress !==null )&&
                                                <tr>
                                                    <th>Address Line2</th>
                                                    <td colSpan={'3'}>{selectedBPCustomer.DEFAddress.addressLine2}</td>
                                                </tr>
                                                }
                                                {(selectedBPCustomer!==null && selectedBPCustomer.DEFAddress !==null )&&
                                                <tr>
                                                    <th>City</th>
                                                    <td>{selectedBPCustomer.DEFAddress.addressLine3}</td>
                                                    <th>Province</th>
                                                    <td>{selectedBPCustomer.DEFAddress.addressLine4}</td>
                                                </tr>
                                                }
                                                {(selectedBPCustomer!==null && selectedBPCustomer.DEFAddress !==null )&&
                                                <tr>
                                                    <th>Post Code</th>
                                                    <td>{selectedBPCustomer.DEFAddress.postalCode}</td>
                                                    <th>Country</th>
                                                    <td>{selectedBPCustomer.DEFAddress.countryIsoCode}</td>
                                                </tr>
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                }

                                {((this.state.asiExist === "No" && this.state.brightPearlExist === "No" )|| this.state.selectedBPCustomer!==null)&&
                                <table className="table  table-striped   manualOrderShippingAddressTable col" style={{margin:'16px 0 0', borderRight:'2px soild gray'}}>
                                    <tbody>
                                    <tr>
                                        <th colSpan={'4'}>Shipping Address
                                            {this._enableSearch &&
                                                <LocationSearchInput setFormLocation={this.setShippingFormLocation}/>
                                            }
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>First Name</th>
                                        <td>
                                            <input type="text" name={'shippingFirstName'} onChange={this.handleOnchange}
                                                   style={{width: '100%'}} value={this.state.shippingFirstName}/>
                                        </td>
                                        <th>Last Name</th>
                                        <td>
                                            <input type="text" name={'shippingLastName'} onChange={this.handleOnchange}
                                                   style={{width: '100%'}} value={this.state.shippingLastName}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Email</th>
                                        <td>
                                            <input type="email" name={'shippingEmail'} onChange={this.handleOnchange}
                                                   style={{width: '100%'}} value={this.state.shippingEmail}/>
                                        </td>
                                        <th>Phone Number</th>
                                        <td>
                                            <input type="text" name={'shippingPhone'} onChange={this.handleOnchange}
                                                   style={{width: '100%'}} value={this.state.shippingPhone}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Company Name</th>
                                        <td colSpan={'3'}>
                                            <input type="text" name={'shippingCompany'} onChange={this.handleOnchange}
                                                   style={{width: '100%'}} value={this.state.shippingCompany}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Address Line 1</th>
                                        <td colSpan={'3'}>
                                            <input type="text" name={'shippingAddress1'} onChange={this.handleOnchange}
                                                   style={{width: '100%'}} value={this.state.shippingAddress1}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Address Line 2</th>
                                        <td colSpan={'3'}>
                                            <input type="text" name={'shippingAddress2'} onChange={this.handleOnchange}
                                                   style={{width: '100%'}} value={this.state.shippingAddress2}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>City</th>
                                        <td>
                                            <input type="text" name={'shippingCity'} onChange={this.handleOnchange}
                                                   style={{width: '100%'}} value={this.state.shippingCity}/>
                                        </td>
                                        <th>State/Province</th>
                                        <td>
                                            <input type="text" name={'shippingState'} onChange={this.handleOnchange}
                                                   style={{width: '100%'}} value={this.state.shippingState}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Country</th>
                                        <td>
                                            <input type="text" name={'shippingCountry'} onChange={this.handleOnchange}
                                                   style={{width: '100%'}} value={this.state.shippingCountry}/>
                                        </td>
                                        <th>Postal Code<br/>
                                        </th>
                                        <td>
                                            <input type="text" name={'shippingPostalCode'} onChange={this.handleOnchange}
                                                   style={{width: '100%'}} value={this.state.shippingPostalCode}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        {
                                            this.state.shippingCountry.trim() === "USA" &&
                                            <th>FEIN #</th>
                                        }
                                        {
                                            this.state.shippingCountry.trim() === "USA" &&
                                            <td>
                                                <input  type="text" onChange={this.handleOnchange} name={"shippingFEINNum"} value={this.state.shippingFEINNum}/>
                                            </td>
                                        }
                                        {(this.state.asiExist === "No" && this.state.brightPearlExist === "No" )&&
                                            <td colSpan={'4'} style={{verticalAlign:'middle'}}> -- Billing address as the same as shipping address &nbsp;&nbsp;
                                                <input type="checkbox" name={'isBillingAsShipping'} onChange={this.handleOnchange}
                                                       checked={this.state.isBillingAsShipping}
                                                       style={{width:'20px',height:'20px'}}/>
                                            </td>
                                        }
                                    </tr>
                                    </tbody>
                                </table>
                                }
                                {
                                    !this.state.isBillingAsShipping &&
                                    <table className="table  table-striped table-dark billingInfoTable col" style={{margin:'16px 0 0'}}>
                                        <tbody style={{borderLeft:'1px solid gray'}}>
                                        <tr>
                                            <th colSpan={'4'}>Billing Address:
                                                {this._enableSearch &&
                                                <LocationSearchInput setFormLocation={this.setBillingFormLocation}/>
                                                }
                                            </th>
                                        </tr>
                                        <tr>
                                            <th>First Name</th>
                                            <td>
                                                <input type="text" name={'billingFirstName'} onChange={this.handleOnchange}
                                                       style={{width: '100%'}} value={this.state.billingFirstName}/>
                                            </td>
                                            <th>Last Name</th>
                                            <td>
                                                <input type="text" name={'billingLastName'} onChange={this.handleOnchange}
                                                       style={{width: '100%'}} value={this.state.billingLastName}/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Email</th>
                                            <td>
                                                <input type="email" name={'billingEmail'} onChange={this.handleOnchange}
                                                       style={{width: '100%'}} value={this.state.billingEmail}/>
                                            </td>
                                            <th>Phone Number</th>
                                            <td>
                                                <input type="text" name={'billingPhone'} onChange={this.handleOnchange}
                                                       style={{width: '100%'}} value={this.state.billingPhone}/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Company Name</th>
                                            <td colSpan={'3'}>
                                                <input type="text" name={'billingCompany'} onChange={this.handleOnchange}
                                                       style={{width: '100%'}} value={this.state.billingCompany}/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Address Line 1</th>
                                            <td colSpan={'3'}>
                                                <input type="text" name={'billingAddress1'} onChange={this.handleOnchange}
                                                       style={{width: '100%'}} value={this.state.billingAddress1}/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Address Line 2</th>
                                            <td colSpan={'3'}>
                                                <input type="text" name={'billingAddress2'} onChange={this.handleOnchange}
                                                       style={{width: '100%'}} value={this.state.billingAddress2}/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Postal Code<br/>
                                            </th>
                                            <td>
                                                <input type="text" name={'billingPostalCode'} onChange={this.handleOnchange}
                                                       style={{width: '100%'}} value={this.state.billingPostalCode}/>
                                            </td>
                                            <th>City</th>
                                            <td>
                                                <input type="text" name={'billingCity'} onChange={this.handleOnchange}
                                                       style={{width: '100%'}} value={this.state.billingCity}/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>State/Province</th>
                                            <td>
                                                <input type="text" name={'billingState'} onChange={this.handleOnchange}
                                                       style={{width: '100%'}} value={this.state.billingState}/>
                                            </td>
                                            <th>Country</th>
                                            <td>
                                                <input type="text" name={'billingCountry'} onChange={this.handleOnchange}
                                                       style={{width: '100%'}} value={this.state.billingCountry}/>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>

                                }
                                {(this.state.selectedBPCustomer!== null || (this.state.asiExist === "No" && this.state.brightPearlExist === "No" ) )&& <table className="table  table-striped   manualOrderInfoTable col" style={{margin:'16px 0 0'}}>
                                    <tbody>
                                    <tr>
                                        <th colSpan={'2'}>Order Information
                                        </th>
                                    </tr>
                                    {this.state.selectedBPCustomer === null &&
                                    <tr>
                                        <th>Sales Store</th>
                                        <td>
                                            <select name={'salesStoreName'}  style={{width:'100%',height:'30px'}} onChange={this.handleSelectSaleStoreOnchange} value={this.state.salesStoreName}>
                                                <option value="optionDefaultHeader" >-- select a Store --</option>
                                                {BrightpearlChannelName.map((op,i)=>{
                                                    return <option value={op} key={i}>{op}</option>
                                                })}
                                            </select>
                                        </td>
                                    </tr>
                                    }
                                    <tr>
                                        <th>Ship Warehouse</th>
                                        <td>
                                            <select name={'shipWarehouseName'}  style={{width:'100%',height:'30px'}} onChange={this.handleSelectWarehouseOnchange} value={this.state.shipWarehouseName}>
                                                <option value="optionDefaultHeader" >-- select a Warehouse --</option>
                                                {BrightPearlWarehousesName.map((op,i)=>{
                                                    return <option value={op} key={i}>{op}</option>
                                                })}
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Order PO#</th>
                                        <td>
                                            <input type="text" name={'orderPO'} onChange={this.handleOnchange}
                                                   style={{width: '100%'}} value={this.state.orderPO}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Bright Pearl#</th>
                                        <td>
                                            <input type="text" name={'brightPearlOrder'} onChange={this.handleOnchange} onBlur={this.handleGetBrightPearlOrder} placeholder={'If has a Brightpearl Order #, Enter here'}
                                                   style={{width: '100%'}} value={this.state.brightPearlOrder}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Order Date</th>
                                        <td>
                                            <DatePicker
                                                selected={this.state.orderDate}
                                                onChange={this.handleChangeOrderDate}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Paid Date</th>
                                        <td>
                                            <DatePicker
                                                selected={this.state.paidDate}
                                                onChange={this.handleChangePaidDate}
                                                />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>shipping Paid</th>
                                        <td>
                                            <input type="text" name={'shippingPaid'} onChange={this.handleOnchange}
                                                   style={{width: '100%'}} value={this.state.shippingPaid}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Assigned Person</th>
                                        <td>
                                            <select name={'assignedTo'}  style={{width:'100%',height:'30px'}} onChange={this.handleOnchange} value={this.state.assignedTo}>
                                                <option value="optionDefaultHeader" >-- select assigned person--</option>
                                                <option value="10064">Don Young</option>
                                                <option value="57662">Rohan Sawhney</option>
                                                <option value="10065">Ron Chandler</option>
                                                <option value="13445">Sam Harrison</option>
                                                <option value="4">Juanne Kochhar</option>
                                            </select>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                }
                            </div>
                            <hr/>
                            {(this.state.selectedBPCustomer!== null || (this.state.asiExist === "No" && this.state.brightPearlExist === "No" ) )&&
                                <table className="table table-bordered orderDetailTable ">
                                    <tbody>
                                    <tr>
                                        <th colSpan={'3'} className={'text-center'}>
                                            Order Line Information:
                                            {this.state.calculatingPrice &&
                                            <span className={"text-center text-success"}>
                                                <i className="fa fa-spinner fa-spin"> </i> &nbsp; <span
                                                style={{fontWeight: 'bold', fontSize: '20px'}}>Get Pricing..</span></span>
                                            }
                                        </th>
                                        <th colSpan={'1'} style={{verticalAlign:'middle',textAlign:'right'}}>Rush Order:</th>
                                        <td colSpan={'3'}>
                                            Is this a rush order? &nbsp;<input type={'checkbox'} name={'selectedIsRush'}
                                                                               style={{width:'20px',height:'20px',verticalAlign:'middle'}}
                                                                               checked={this.state.selectedIsRush} onChange={this.handleOnchange}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th colSpan={'1'} rowSpan={'2'} style={{verticalAlign:'middle',textAlign:'right'}}>SKU #</th>
                                        <td colSpan={'1'} rowSpan={'2'} style={{verticalAlign:'middle'}}>
                                            <select name={'selectedSKUNumber'}  style={{width:'100%'}} onChange={this.handleOnchange} value={this.state.selectedSKUNumber}>
                                                <option value="optionDefaultHeader" >-- select a SKU --</option>
                                                {this.props.skuNumberList.map((op,i)=>{
                                                    return <option value={op} key={i}>{op}</option>
                                                })}
                                            </select>
                                        </td>
                                        <td colSpan={'1'} rowSpan={'2'} style={{verticalAlign:'middle'}}>
                                            <button className={'btn btn-success'} style={{width:'100%'}} type={'button'} onClick={this.handleAddRow}>Add</button>
                                        </td>
                                        <th colSpan={'1'} style={{verticalAlign:'middle',textAlign:'right'}}>Quantity:</th>
                                        <td colSpan={'3'}>
                                            <input type={'text'} name={'selectedQty'} onChange={this.handleOnchange}  value={this.state.selectedQty}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th colSpan={'1'} rowSpan={'2'} style={{verticalAlign:'middle',textAlign:'right'}}>With Logo:</th>
                                        <td colSpan={'3'} rowSpan={'2'}>
                                            Is this a imprinted order? &nbsp;<input type={'checkbox'} name={'selectedWithLogo'}
                                                                                    style={{width:'20px',height:'20px',verticalAlign:'middle'}}
                                                                                    checked={this.state.selectedWithLogo} onChange={this.handleOnchange}/>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            }
                            {this.state.manualOrderLines.length > 0 &&
                                <table className="table table-bordered orderLineTable ">
                                    <tbody>
                                    <tr>
                                        <th colSpan={'5'} className={'text-center'}>Order Line Detail</th>
                                    </tr>
                                    <tr>
                                        <th className={'text-center'}>Sku</th>
                                        <th className={'text-center'}>Qty</th>
                                        <th className={'text-center'}>Type</th>
                                        <th className={'text-center'}>Unit Price</th>
                                        <th className={'text-center'}>Total Tax Value</th>
                                        <th className={'text-center'}>Total Value</th>
                                        <th className={'text-center'}>Action</th>
                                    </tr>
                                    {
                                        this.state.manualOrderLines.map((line, index) => {
                                            return <tr key={index}>
                                                <td>{line.sku}</td>
                                                <td>{line.magnitude}</td>
                                                <td>{line.orderType}</td>
                                                <td><input value={line.rowSingle} onChange={(e)=>this.handleRowNetValueOnchange(e,index)}/></td>
                                                <td>{line.rowTax}</td>
                                                <td>{line.rowTotal}</td>
                                                <td><button type={'button'} onClick={()=>this.handleDeleteOrderLine(index)}>DELETE</button></td>
                                            </tr>
                                        })
                                    }
                                    </tbody>
                                </table>
                            }
                            {this.state.manualOrderLines.length > 0 &&
                            <div className="modal-footer row" style={{width:'90%',margin:"0 auto"}}>
                                <button className={'btn col btn-success'} type={'button'}
                                        onClick={this.handleCreateOrder}>Create Order</button>
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            )
        }
}
export default CreateNewManualOrder;