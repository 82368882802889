import axios from "axios";
import React, { Component } from "react";
import { baseURL, proxyurl } from "../requestHelper";
import ImageDropZone from "./imageDropZone";

const GOOGLE_DRIVE_URL = "https://drive.google.com/uc?export=download&id=";
class ImageUpload extends Component {
  constructor() {
    super();
    this.state = {
      accepted: [],
      baseNums: [0],
      imageUploaded: false,
      imageUploading: false,
      sku: "",
      groupNums: [0],
      lifeStyleNums: [0],
      modelNums: [0],
      groupAccepted: [],
      lifeStyleAccepted: [],
      modelAccepted: [],
      templateAccepted: [],
      templateNum: [0],
      mainNum: [0],
      mainAccepted: [],
    };
  }
  handleOnDrop = (accepted, i, field) => {
    // if(this.props.designCode!==undefined && this.props.materialCode!==undefined && this.props.colorCode!==undefined){
    const files = this.state[field];
    const designCode = this.props.designCode;
    const materialCode = this.props.materialCode;
    const colorCode = this.props.colorCode;
    const sku = `${designCode}-${materialCode}-${colorCode}`;
    let fileName = "";
    if (field === "accepted") {
      fileName = `${sku}_BASE_${i + 1}`;
      this.handleAddImageField("baseNums");
    } else if (field === "groupAccepted") {
      fileName = `${sku}_GROUP_${i + 1}`;
      this.handleAddImageField("groupNums");
    } else if (field === "lifeStyleAccepted") {
      fileName = `${sku}_LIFE_${i + 1}`;
      this.handleAddImageField("lifeStyleNums");
    } else if (field === "modelAccepted") {
      fileName = `${sku}_MODEL_${i + 1}`;
      this.handleAddImageField("modelNums");
    } else if (field === "templateAccepted") {
      fileName = `${sku}_LAYOUT_${i + 1}`;
    } else if (field === "mainAccepted") {
      fileName = `${sku}_MAIN`;
    }

    if (accepted[0].type === "image/jpeg") {
      fileName += ".jpg";
    } else if (accepted[0].type === "image/png") {
      fileName += ".png";
    }
    files[i] = new File(accepted, fileName, { type: accepted[0].type });
    files[i].preview = accepted[0].preview;
    this.setState(
      {
        [field]: files,
        sku,
      },
      () => console.log(this.state[field])
    );
    // }else {
    //     alert("Need select design code, material code and color code!");
    // }
  };

  handleUpload = async (e) => {
    e.preventDefault();
    this.setState(
      {
        imageUploading: true,
        imageUploaded: false,
      },
      async () => {
        const tokenRes = await axios.get(`${baseURL}api/google-token`);
        const token = tokenRes.data;
        if (this.state.accepted.length === 0) {
          alert("Please choose image on local drive!!!");
        } else {
          const headers = {
            Authorization: `Bearer ${token}`,
          };
          /*************Get Folder Path**********************/
          const searchFolderName = this.props.designCode;
          //this master Folder ID will be Product Media Content's ID
          //const masterFolderID = '1siaK1CQph5-q_29CncCXJa9Zd2TfASPN';
          const masterFolderID = "0B1wi-RUM_bTlYi1uTnE2eGlsSDQ";
          const query = `q=name+%3d+%27${searchFolderName}%27&mimeType+%3d+%27application/vnd.google-apps.folder%27`;
          const resSearchFolder = await axios.get(
            `https://www.googleapis.com/drive/v3/files?${query}`,
            { headers }
          );
          //console.log(resSearchFolder);
          //Destination Folder for new SKU images on Google Drive
          let folder;
          if (resSearchFolder.data.files.length === 0) {
            //console.log('Folder does not exist!');
            const newFolder = {
              name: searchFolderName,
              mimeType: "application/vnd.google-apps.folder",
              parents: [masterFolderID],
            };
            const resNewFolder = await axios.post(
              "https://www.googleapis.com/drive/v3/files",
              newFolder,
              { headers }
            );
            folder = resNewFolder.data;
          } else {
            //console.log('Find folder!');
            folder = resSearchFolder.data.files[0];
          }

          ////////Upload BASE Images to Hostinger Server  and Google Drive///////////////
          const response = await this.state.accepted.map(
            async (image, index) => {
              //Send Image to google drive:
              if (image !== undefined) {
                return await this.uploadBaseImage(token, image, index, folder);
                //console.log('Finish Base upload', response);
              }
            }
          );
          ////////Upload Group Images to Hostinger Server  and Google Drive///////////////
          //let resGroup = null;
          // if (this.state.groupAccepted.length !== 0) {
          const resGroup = await this.state.groupAccepted.map(
            async (image, index) => {
              //Send image to ashlinapp.xyz for walmart.
              // if( image !== undefined){
              //     data = new FormData();
              //     data.append("image", image);
              //     data.append("folder", this.props.designCode);
              //     data.append("vince", "8sHTj5LV527LqdWAQBZSDEXrohTSBx9FXyPnGBamXaj60vNCsDMpUTEsyqXDF4MSuDij4tEoZB7O1vdgVR1H8FLezeBgcRGndRb5MSd70mhYgO5d82wA1lIfPg95W3fXyQn7PVlcisOJFbjSMEOt6P6W2p5Xu4IMFodO5z94Hm25rQGr6iodKTGOwZruXtUX9zv7NFx9");
              //     const response = await axios.post("https://ashlinapp.xyz/api/image-uploads.php",data,config);
              //     console.log(response);
              // }

              //Send Image to google drive:
              if (image !== undefined) {
                return await this.uploadOtherImage(
                  token,
                  image,
                  index,
                  folder,
                  "group"
                );
                //console.log('Finish Group upload', response);
              }
            }
          );
          // }

          // let resLife = null;
          // if (this.state.lifeStyleAccepted.length !== 0) {
          const resLife = await this.state.lifeStyleAccepted.map(
            async (image, index) => {
              //Send image to ashlinapp.xyz for walmart.
              // if( image !== undefined){
              //     data = new FormData();
              //     data.append("image", image);
              //     data.append("folder", this.props.designCode);
              //     data.append("vince", "8sHTj5LV527LqdWAQBZSDEXrohTSBx9FXyPnGBamXaj60vNCsDMpUTEsyqXDF4MSuDij4tEoZB7O1vdgVR1H8FLezeBgcRGndRb5MSd70mhYgO5d82wA1lIfPg95W3fXyQn7PVlcisOJFbjSMEOt6P6W2p5Xu4IMFodO5z94Hm25rQGr6iodKTGOwZruXtUX9zv7NFx9");
              //     const response = await axios.post("https://ashlinapp.xyz/api/image-uploads.php",data,config);
              //     console.log(response);
              // }

              //Send Image to google drive:
              if (image !== undefined) {
                return await this.uploadOtherImage(
                  token,
                  image,
                  index,
                  folder,
                  "liftStyle"
                );
                //console.log('Finish LifeStyle upload', response );
              }
            }
          );
          // }

          // let resModel = null;
          // if (this.state.modelAccepted.length !== 0) {
          const resModel = await this.state.modelAccepted.map(
            async (image, index) => {
              //Send image to ashlinapp.xyz for walmart.
              // if( image !== undefined){
              //     data = new FormData();
              //     data.append("image", image);
              //     data.append("folder", this.props.designCode);
              //     data.append("vince", "8sHTj5LV527LqdWAQBZSDEXrohTSBx9FXyPnGBamXaj60vNCsDMpUTEsyqXDF4MSuDij4tEoZB7O1vdgVR1H8FLezeBgcRGndRb5MSd70mhYgO5d82wA1lIfPg95W3fXyQn7PVlcisOJFbjSMEOt6P6W2p5Xu4IMFodO5z94Hm25rQGr6iodKTGOwZruXtUX9zv7NFx9");
              //     const response = await axios.post("https://ashlinapp.xyz/api/image-uploads.php",data,config);
              //     console.log(response);
              // }

              //Send Image to google drive:
              if (image !== undefined) {
                return await this.uploadOtherImage(
                  token,
                  image,
                  index,
                  folder,
                  "model"
                );
                // console.log('Finish resModel upload', response );
              }
            }
          );
          // }

          // let resTemplate = null;
          // if (this.state.templateAccepted.length !== 0) {
          const resTemplate = await this.state.templateAccepted.map(
            async (image, index) => {
              //Send image to ashlinapp.xyz for walmart.
              // if( image !== undefined){
              //     data = new FormData();
              //     data.append("image", image);
              //     data.append("folder", this.props.designCode);
              //     data.append("vince", "8sHTj5LV527LqdWAQBZSDEXrohTSBx9FXyPnGBamXaj60vNCsDMpUTEsyqXDF4MSuDij4tEoZB7O1vdgVR1H8FLezeBgcRGndRb5MSd70mhYgO5d82wA1lIfPg95W3fXyQn7PVlcisOJFbjSMEOt6P6W2p5Xu4IMFodO5z94Hm25rQGr6iodKTGOwZruXtUX9zv7NFx9");
              //     const response = await axios.post("https://ashlinapp.xyz/api/image-uploads.php",data,config);
              //     console.log(response);
              // }

              //Send Image to google drive:
              if (image !== undefined) {
                return await this.uploadOtherImage(
                  token,
                  image,
                  index,
                  folder,
                  "template"
                );
                // console.log('Finish resModel upload', response );
              }
            }
          );
          // }

          // let resMain = null;
          // if (this.state.mainAccepted.length !== 0) {
          const resMain = await this.state.mainAccepted.map(
            async (image, index) => {
              //Send image to ashlinapp.xyz for walmart.
              if (image !== undefined) {
                const data = new FormData();
                data.append("image", image);
                data.append("folder", this.props.designCode);
                data.append(
                  "vince",
                  "8sHTj5LV527LqdWAQBZSDEXrohTSBx9FXyPnGBamXaj60vNCsDMpUTEsyqXDF4MSuDij4tEoZB7O1vdgVR1H8FLezeBgcRGndRb5MSd70mhYgO5d82wA1lIfPg95W3fXyQn7PVlcisOJFbjSMEOt6P6W2p5Xu4IMFodO5z94Hm25rQGr6iodKTGOwZruXtUX9zv7NFx9"
                );
                const response = await axios.post(
                  "https://ashlinapp.xyz/api/image-uploads.php",
                  data,
                  {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  }
                );
              }

              //Send Image to google drive:
              if (image !== undefined) {
                return await this.uploadOtherImage(
                  token,
                  image,
                  index,
                  folder,
                  "main"
                );
                // console.log('Finish resModel upload', response );
              }
            }
          );
          // }
          //console.log(response);
          //console.log(resGroup);
          //console.log(resLife);
          //console.log(resModel);
          //console.log(resTemplate);
          //console.log(resGroup);
          //console.log(resMain);
          const final = await Promise.all([
            ...response,
            ...resGroup,
            ...resLife,
            ...resModel,
            ...resTemplate,
            ...resMain,
          ]).then(() => {
            this.setState({
              accepted: [],
              baseNums: [0],
              imageUploaded: true,
              imageUploading: false,
              sku: "",
              groupNums: [0],
              lifeStyleNums: [0],
              modelNums: [0],
              groupAccepted: [],
              lifeStyleAccepted: [],
              modelAccepted: [],
              templateAccepted: [],
              templateNum: [0],
              mainNum: [0],
              mainAccepted: [],
            });
          });

          return final;
        }
      }
    );
  };

  uploadBaseImage = async (token, image, index, folder) => {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    //********************Generate Upc9 and Upc10 file****************//
    let upc9FileName = `${this.props.upc9}_${index + 1}`;
    let upc10FileName = `${this.props.upc10}_${index + 1}`;

    if (image.type === "image/jpeg") {
      upc9FileName += ".jpg";
      upc10FileName += ".jpg";
    } else if (image.type === "image/png") {
      upc9FileName += ".png";
      upc10FileName += ".png";
    }

    /**********Create New File**********************/
    const newFile = {
      name: image.name,
      parents: [folder.id],
    };
    //https://www.googleapis.com/upload/drive/v3/files?uploadType=media
    const resNewFile = await axios.post(
      "https://www.googleapis.com/drive/v3/files",
      newFile,
      { headers }
    );
    //console.log('NewFile',resNewFile);

    /***************************Update File Content***********************************/
    const resUpdatedFile = await axios.patch(
      `https://www.googleapis.com/upload/drive/v3/files/${resNewFile.data.id}`,
      image,
      { headers }
    );
    //console.log('UpdatedFile',resUpdatedFile);
    //set base image url
    this.props.imageURLChange(
      "base",
      GOOGLE_DRIVE_URL + resUpdatedFile.data.id
    );
    /***************************Copy UPC file***************************************/
    const resUPC9 = await axios.post(
      `https://www.googleapis.com/drive/v3/files/${resNewFile.data.id}/copy`,
      { name: upc9FileName },
      { headers }
    );
    //console.log("UPC 9", resUPC9);
    //set upc9 ulr
    this.props.imageURLChange("upc9", GOOGLE_DRIVE_URL + resUPC9.data.id);
    const resUPC10 = await axios.post(
      `https://www.googleapis.com/drive/v3/files/${resNewFile.data.id}/copy`,
      { name: upc10FileName },
      { headers }
    );
    //console.log("UPC 10", resUPC10);
    //set upc10 url
    this.props.imageURLChange("upc10", GOOGLE_DRIVE_URL + resUPC10.data.id);

    /*************************Resize Image*********************/
    let data = new FormData();
    data.append("image", image);
    data.append("width", "2000");
    data.append("height", "2000");
    const resResize = await axios.post(
      proxyurl + "https://heng-resize-image.herokuapp.com/resize_image",
      data
    );
    //console.log(resResize.data);
    let ImageURL = `data:image/png;base64,${resResize.data}`;
    // Split the base64 string in data and contentType
    let block = ImageURL.split(";");
    // Get the content type of the image
    let contentType = block[0].split(":")[1]; // In this case "image/gif"
    // get the real base64 content of the file
    let realData = block[1].split(",")[1]; // In this case "R0lGODlhPQBEAPeoAJosM...."
    // Convert it to a blob to upload
    let blob = this.b64toBlob(realData, contentType);
    //console.log(blob);

    /**********Create New Resize File**********************/
    const smallName = image.name.replace("BASE", "SMALL");
    const newResize = {
      name: smallName,
      parents: [folder.id],
    };

    //https://www.googleapis.com/upload/drive/v3/files?uploadType=media
    const resNewResize = await axios.post(
      "https://www.googleapis.com/drive/v3/files",
      newResize,
      { headers }
    );
    //console.log('NewResize',resNewResize);

    /***************************Update Resize File Content***********************************/
    const resUpdatedResize = await axios.patch(
      `https://www.googleapis.com/upload/drive/v3/files/${resNewResize.data.id}?uploadType=multipart`,
      blob,
      { headers }
    );
    //console.log('UpdatedResize',resUpdatedResize);
    //set small image url
    this.props.imageURLChange(
      "small",
      GOOGLE_DRIVE_URL + resUpdatedResize.data.id
    );

    //Send image to ashlinapp.xyz for walmart.

    let data1 = new FormData();
    let file = new File([blob], image.name);
    data1.append("image", file);
    data1.append("folder", this.props.designCode);
    data1.append(
      "vince",
      "8sHTj5LV527LqdWAQBZSDEXrohTSBx9FXyPnGBamXaj60vNCsDMpUTEsyqXDF4MSuDij4tEoZB7O1vdgVR1H8FLezeBgcRGndRb5MSd70mhYgO5d82wA1lIfPg95W3fXyQn7PVlcisOJFbjSMEOt6P6W2p5Xu4IMFodO5z94Hm25rQGr6iodKTGOwZruXtUX9zv7NFx9"
    );
    const response = await axios.post(
      "https://ashlinapp.xyz/api/image-uploads.php",
      data1,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    //console.log(response);
    alert(`Image Upload Completed!`);
    this.props.imageURLChange(
      "sftp",
      `https://ashlinapp.xyz/ProductMediaContent/${this.props.designCode}/${image.name}`
    );

    const final = await Promise.all([
      resNewFile,
      resUpdatedFile,
      resUPC9,
      resUPC10,
      resResize,
      resNewResize,
      resUpdatedResize,
      response,
    ]);
    return final;
  };
  uploadOtherImage = async (token, image, index, folder, type) => {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    /**********Create New File**********************/
    const newFile = {
      name: image.name,
      parents: [folder.id],
    };
    //https://www.googleapis.com/upload/drive/v3/files?uploadType=media
    const resNewFile = await axios.post(
      "https://www.googleapis.com/drive/v3/files",
      newFile,
      { headers }
    );
    //console.log('NewFile',resNewFile);

    /***************************Update File Content***********************************/
    const resUpdatedFile = await axios.patch(
      `https://www.googleapis.com/upload/drive/v3/files/${resNewFile.data.id}`,
      image,
      { headers }
    );
    //console.log('UpdatedFile',resUpdatedFile);
    //set base image url
    if (type === "main") {
      this.props.imageURLChange(
        "sftpMain",
        `https://ashlinapp.xyz/ProductMediaContent/${this.props.designCode}/${image.name}`
      );
    }
    this.props.imageURLChange(type, GOOGLE_DRIVE_URL + resUpdatedFile.data.id);

    const final = await Promise.all([resNewFile, resUpdatedFile]);
    return final;
  };

  b64toBlob = (b64Data, contentType, sliceSize) => {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    let byteCharacters = atob(b64Data);
    let byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);

      let byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      let byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  };

  handleAddImageField = (field) => {
    //e.preventDefault();
    const nums = this.state[field];
    nums[nums.length] = nums.length;
    this.setState(
      {
        [field]: nums,
      },
      () => console.log(this.state)
    );
  };
  handleCreateSKUOnClick = (e) => {
    this.props.onClick(e);
    this.setState({
      imageUploaded: false,
    });
  };
  render() {
    return (
      <div>
        <h3 style={{ color: "red", background: "yellow" }}>
          Noted: You have to upload images so that you can create new sku!
        </h3>
        {!this.state.imageUploaded && (
          <button
            className={"btn btn-info btn-block"}
            onClick={(e) => this.handleUpload(e)}
          >
            Upload Image
          </button>
        )}
        {this.state.imageUploaded && (
          <button
            type="submit"
            className="btn btn-primary btn-lg btn-block"
            onClick={(e) => this.handleCreateSKUOnClick(e)}
          >
            Create New SKU
          </button>
        )}
        {this.state.imageUploading && (
          <div className={"text-center text-success display-4"}>
            <i className="fa fa-spinner fa-spin"></i> &nbsp; Uploading Images,
            please wait...
          </div>
        )}
        <h2 style={{ textAlign: "center" }}>MAIN IMAGES</h2>
        <div className={"row"}>
          {this.state.mainNum.map((i) => {
            return (
              <div
                key={i}
                className={"col"}
                style={{ marginBottom: "20px", marginLeft: "15px" }}
              >
                <ImageDropZone
                  onDrop={(a) => this.handleOnDrop(a, i, "mainAccepted")}
                  file={this.state.mainAccepted[i]}
                />
              </div>
            );
          })}
        </div>
        <h2 style={{ textAlign: "center" }}>BASE IMAGES</h2>
        <div className={"row"}>
          {this.state.baseNums.map((i) => {
            return (
              <div
                key={i}
                className={"col-2"}
                style={{ marginBottom: "20px", marginLeft: "15px" }}
              >
                <ImageDropZone
                  onDrop={(a) => this.handleOnDrop(a, i, "accepted")}
                  file={this.state.accepted[i]}
                />
              </div>
            );
          })}
          {/*<div className={'col'}>*/}

          {/*<div style={{width: '200px', height: '200px', border:'2px dashed rgb(102, 102, 102)', borderRadius: '5px'}}>*/}
          {/*<button onClick={(e)=>this.handleAddImageField(e,'baseNums')} style={{width:'100%',height:'100%',fontSize:'30px'}}>+</button>*/}
          {/*</div>*/}
          {/*</div>*/}
        </div>

        <hr />
        <h2 style={{ textAlign: "center" }}>GROUP IMAGES</h2>
        <div className={"row"}>
          {this.state.groupNums.map((i) => {
            return (
              <div
                key={i}
                className={"col-2"}
                style={{ marginBottom: "20px", marginLeft: "15px" }}
              >
                <ImageDropZone
                  onDrop={(a) => this.handleOnDrop(a, i, "groupAccepted")}
                  file={this.state.groupAccepted[i]}
                />
              </div>
            );
          })}
          {/*<div className={'col'}>*/}

          {/*<div style={{width: '200px', height: '200px', border:'2px dashed rgb(102, 102, 102)', borderRadius: '5px'}}>*/}
          {/*<button onClick={(e)=>this.handleAddImageField(e,'groupNums')} style={{width:'100%',height:'100%',fontSize:'30px'}}>+</button>*/}
          {/*</div>*/}
          {/*</div>*/}
        </div>
        <hr />
        <h2 style={{ textAlign: "center" }}>LIFESTYLE IMAGES</h2>
        <div className={"row"}>
          {this.state.lifeStyleNums.map((i) => {
            return (
              <div
                key={i}
                className={"col-2"}
                style={{ marginBottom: "20px", marginLeft: "15px" }}
              >
                <ImageDropZone
                  onDrop={(a) => this.handleOnDrop(a, i, "lifeStyleAccepted")}
                  file={this.state.lifeStyleAccepted[i]}
                />
              </div>
            );
          })}
          {/*<div className={'col'}>*/}

          {/*<div style={{width: '200px', height: '200px', border:'2px dashed rgb(102, 102, 102)', borderRadius: '5px'}}>*/}
          {/*<button onClick={(e)=>this.handleAddImageField(e,'lifeStyleNums')} style={{width:'100%',height:'100%',fontSize:'30px'}}>+</button>*/}
          {/*</div>*/}
          {/*</div>*/}
        </div>
        <hr />
        <h2 style={{ textAlign: "center" }}>MODEL IMAGES</h2>
        <div className={"row"}>
          {this.state.modelNums.map((i) => {
            return (
              <div
                key={i}
                className={"col-2"}
                style={{ marginBottom: "20px", marginLeft: "15px" }}
              >
                <ImageDropZone
                  onDrop={(a) => this.handleOnDrop(a, i, "modelAccepted")}
                  file={this.state.modelAccepted[i]}
                />
              </div>
            );
          })}
          {/*<div className={'col'}>*/}

          {/*<div style={{width: '200px', height: '200px', border:'2px dashed rgb(102, 102, 102)', borderRadius: '5px'}}>*/}
          {/*<button onClick={(e)=>this.handleAddImageField(e,'modelNums')} style={{width:'100%',height:'100%',fontSize:'30px'}}>+</button>*/}
          {/*</div>*/}
          {/*</div>*/}
        </div>
        <h2 style={{ textAlign: "center" }}>TEMPLATE IMAGES</h2>
        <div className={"row"}>
          {this.state.templateNum.map((i) => {
            return (
              <div
                key={i}
                className={"col"}
                style={{ marginBottom: "20px", marginLeft: "15px" }}
              >
                <ImageDropZone
                  onDrop={(a) => this.handleOnDrop(a, i, "templateAccepted")}
                  file={this.state.templateAccepted[i]}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
export default ImageUpload;
