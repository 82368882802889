import React from 'react';

const PrintUPCLabel = ({skuNumList,handleClose,SKUNunberOnchange,currencyOnchange,createLabel,onChange,hasMSRP})=>{
    //console.log(skuNumList)
    return(
        <div className="modal fade" id="printUPCLabelModal" tabIndex="-1" role="dialog" data-backdrop="static"
             aria-labelledby="printUPCLabelModalTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title text-center" id="tierCostModalTitle">Print UPC Label</h5>
                        <button type="button" className="close" onClick={handleClose}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <select name={'selectedSKUNumber'}  onChange={SKUNunberOnchange} className={'update-sku-input'} >
                            <option value="optionDefaultHeader" >-- select a SKU --</option>
                            {skuNumList.map((op,i)=>{
                                return <option value={op} key={i}>{op}</option>
                            })}
                        </select>
                        <br/><br/>
                        <span style={{fontWeight:'bold'}}> MSRP: </span><input type={'checkbox'} name={'hasMSRP'} value={hasMSRP} onChange={onChange}/>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        {
                            hasMSRP &&  <select name={'selectedCurrency'}  onChange={currencyOnchange} >
                                <option value={'CAD'} >CAD</option>
                                <option value={'USD'} >USD</option>
                                <option value={'AUD'} >AUD</option>
                                <option value={'EUR'} >EUR</option>
                                <option value={'GBP'} >GBP</option>
                                <option value={'INR'} >INR</option>
                            </select>
                        }

                        <br/><br/>
                        <button className={'btn btn-success'} type={'button'} onClick={createLabel}>Create UPC Label</button>
                    </div>
                    <div className="modal-footer">

                    </div>
                </div>
            </div>
        </div>
    )

};
export default PrintUPCLabel;
