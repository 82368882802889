import React from 'react';
const SelectInputWithObject =({id,label,name,value,onChange,style})=>{
    if(value!=='')
    return(
        <div className='row ' style={{marginBottom:'10px'}}>
            <div className='col-3' style={{margin:'auto'}}>
                <label htmlFor={id} style={style}>{label}:</label>
            </div>
            <div className='col-8'>
                <select name={name} onChange={onChange} className="form-control" id={id}>
                    <option value=''>-- select an option --</option>
                    {value.map((op,i)=>{
                        return <option value={op.value} key={i}>{op.key}</option>
                    })}
                </select>
            </div>
        </div>
    );
    return <br/>
};
export default SelectInputWithObject;