import React, { Component } from 'react';
import {
    base64ToNewTab,
    ENABLE_BRIGHTPEARL,
    ENABLE_LEANSUPPLY,
    getBlanceByWarehouseCode, postLabel, sendOrderToLeanSupply,
    SubmitOrderModel
} from '../../Utils/requestHelper';
import {
    getBPheader,
    searchSKU,createStockTransfer,
    updateOrderStatus, createExternalTransfer,
    updateShipmentMethodAndDeliveryDate
} from '../../Utils/brightpearl';
import {
    getAmazonFBALocations,
    getDesignByCode,
    sendPDFEmail,
    getSkuShippingInfo,
    updateAshlinManualOrder, getShippingPackages
} from '../../Utils/ashlinDesign';
import MainSideBar from '../sideBar/MainSideBar';
import { createInventoryTransfer,voidCanparShipment , getCanparCredentials } from '../../Utils/ShippingService/Canpar';
import $ from "jquery";
import _ from 'lodash';
import axios from "axios/index";
class InventoryTransfer extends Component{
    _mounted = false;
    _FACTOR = 1.16;
    constructor(props) {
        super(props);
        this.state = {
            BPHeaders:null,
            leanSupplyInventory: [],
            shownLeanSupplyInventory:[],
            warehouse:'14',
            FBALocations:[],
            FBALocationsBackUp:[],
            isLoading:true,
            selectedWarehouse:{
                name:"optionDefaultHeader"
            },
            selectedSKU:{
                sku:"optionDefaultHeader",
                qty:"0"
            },
            transferQty:"0",

            OrderDetail:[],
            orderNumber:"",
            OrderDelivery :{
                "CarrierCode": "CANPAR",
                "ServiceCode": "01",
                "FreightCharges": "3RDPARTY",
                "ChargeToAccount" : "42074982"
            },
            shippingInfo:{
                TotalWeight:0,
                TotalVolume:0
            },
            SkuShippingList:[],

            ShippingPackages:[],

            AvailableBoxes:[],
            selectedBox: [],
            canParShipment:{},
            HeightCM:'',
            LengthCM:'',
            WidthCM:'',
            NumNBox:'1'
        };
    }
    componentDidMount(){
        this._mounted = true;
        this.getData();
    }

    getData = async()=>{
        const leanSupplyInventory = await getBlanceByWarehouseCode(this.state.warehouse);
        const FBALocations = await getAmazonFBALocations();
        const BPHeaders = await getBPheader();
        const SkuShippingList = await getSkuShippingInfo();
        const ShippingPackages = await getShippingPackages();
        this.setState({
            BPHeaders,
            leanSupplyInventory,
            ShippingPackages,
            shownLeanSupplyInventory:leanSupplyInventory,
            FBALocations,
            FBALocationsBackUp:FBALocations,
            SkuShippingList,
            isLoading:false,
        });
    };
    componentWillUnmount() {
        this._mounted = false;
    }
    handleOnchange=(e)=>{
        const name = e.target.name;
        const value = e.target.type === "checkbox"? e.target.checked: e.target.value;
        if(value!=="optionDefaultHeader"){
            this.setState({
                [name]:value
            })
        }
    };
    selectWarehouse=(e)=>{
        const value = e.target.value;
        let selectedWarehouse;
        if(value!=="optionDefaultHeader"){
            selectedWarehouse = this.state.FBALocations.find(w=>w.Name === value);
        }else{
            selectedWarehouse = {
                name:"optionDefaultHeader"
            }
        }
        this.setState({
            selectedWarehouse
        })

    };
    selectCountry=(e)=>{
        const value =  e.target.value;
        let FBALocations;
        if(value==="ALL"){
            FBALocations = this.state.FBALocationsBackUp;
        }else{
            FBALocations = this.state.FBALocationsBackUp.filter(l=>l.CountryCode === value);
        }
        this.setState({
            FBALocations
        })
    };
    selectSKU = (e) =>{
        const value =  e.target.value;
        let selectedSKU;
        if(value!=="optionDefaultHeader"){
            selectedSKU = this.state.shownLeanSupplyInventory.find(i=>i.sku === value);
        }else{
            selectedSKU = {
                sku:"optionDefaultHeader",
                qty:"0"
            }
        }
        this.setState({
            selectedSKU
        })
    };
    filterSKU = (e) =>{
        const value =  e.target.value;
        const shownLeanSupplyInventory = this.state.shownLeanSupplyInventory.filter(item=>item.sku.toUpperCase().includes(value.toUpperCase()));
        this.setState({
            shownLeanSupplyInventory
        })
    };
    handleStateOnchange=(e)=>{
        const value = e.target.value;
        const name = e.target.name;
        this.setState({
            [name]:value
        })
    };
    handleAddItem=async()=>{
       const selectedSKU = this.state.selectedSKU;
       const transferQty = parseInt(this.state.transferQty);
       const shippingInfo = this.state.shippingInfo;
       //const designCode = selectedSKU.sku.split('-')[0];
       const brightpearlProduct = await searchSKU (selectedSKU.sku , this.state.BPHeaders );
       const item = this.state.SkuShippingList.find(s=>s.Sku === selectedSKU.sku);
       //console.log(item);

       //height == length
       if(item.ShippableWidthCm > item.ShippableHeightCm){
           const temp = item.ShippableHeightCm;
           item.ShippableHeightCm = item.ShippableWidthCm;
           item.ShippableWidthCm = temp;
       }
       const AvailableBoxes = this.state.ShippingPackages.filter(b=>b.LengthCM > item.ShippableHeightCm && b.WidthCM > item.ShippableWidthCm);
       // console.log(this.state.ShippingPackages) ;
       //console.log(AvailableBoxes)
       if(brightpearlProduct === undefined){
           alert(`${selectedSKU.sku} doesn't exist on Brightpearl!!!`);
       }else {
           const OrderDetail = this.state.OrderDetail;
           let index = OrderDetail.length;
           if (transferQty > parseInt(selectedSKU.qty)) {
               let confirmBox = window.confirm(`Lean Supply Inventory can't fulfill at this time, do you still want to add this item?`);
               if (confirmBox) {
                   const row = {
                       "OrderLine": (++index).toString(),
                       "ItemCode": this.state.selectedSKU.sku,
                       "ItemQty": parseInt(this.state.transferQty),
                       "ItemName": brightpearlProduct[1],
                       "ItemDescription": brightpearlProduct[1],
                       "uofm": "EACH",
                       "per_pack": "1",
                       "ItemComment":brightpearlProduct[0]
                   };
                   OrderDetail.push(row);
               }
           } else {
               const row = {
                   "OrderLine": (++index).toString(),
                   "ItemCode": this.state.selectedSKU.sku,
                   "ItemQty": parseInt(this.state.transferQty),
                   "ItemName": brightpearlProduct[1],
                   "ItemDescription": brightpearlProduct[1],
                   "uofm": "EACH",
                   "per_pack": "1",
                   "ItemComment":brightpearlProduct[0]
               };
               OrderDetail.push(row);
           }
           shippingInfo.TotalWeight += item.ShippableWeightGrams * transferQty;
           shippingInfo.TotalVolume += parseInt((item.ShippableHeightCm * item.ShippableWidthCm * item.ShippableDepthCm * transferQty).toString())

           // const selectedBox = this.state.selectedBox;
           // if(selectedBox.length!==0){
           //     const totalVolume = parseInt((shippingInfo.TotalVolume * this._FACTOR ).toString());
           //     if( totalVolume > selectedBox[selectedBox.length-1].Volume){
           //         this.handleAddBox(selectedBox,AvailableBoxes,shippingInfo);
           //     }
           // }else{
           //     this.handleAddBox(selectedBox,AvailableBoxes,shippingInfo);
           // }

           this.setState({
               OrderDetail,
               selectedSKU: {
                   sku: "optionDefaultHeader",
                   qty: "0"
               },
               AvailableBoxes,
               shippingInfo,
               // selectedBox,
               transferQty: "0",
           })//
       }
    };
    handleAddBox=(selectedBox,AvailableBoxes,shippingInfo)=>{
        let totalVolume;
        totalVolume = parseInt((shippingInfo.TotalVolume * this._FACTOR).toString());
        const tempBox = AvailableBoxes.find( b=>b.VolumeCM3 > totalVolume );
        //console.log(tempBox);
        if(tempBox !== undefined){
            //console.log('Find Box!')
            if(selectedBox.length===0){
                selectedBox.push(tempBox);
            }else if(!selectedBox[selectedBox.length-1].Full){

                selectedBox[selectedBox.length - 1] = tempBox;
            }else {
                selectedBox.push(tempBox);
            }
            return selectedBox;
        }else{
            console.log('Not Find')
            //
            shippingInfo.TotalVolume = shippingInfo.TotalVolume - AvailableBoxes[AvailableBoxes.length-1].VolumeCM3;
            const newBoxes = _.cloneDeep(AvailableBoxes);
            const newBox = newBoxes[newBoxes.length-1];
            newBox.Full = true;
            selectedBox.unshift( newBox );

            return this.handleAddBox(selectedBox,AvailableBoxes,shippingInfo)

        }
    };
    handleRemoveOrderLine=(index)=>{
        const OrderDetail = this.state.OrderDetail;
        OrderDetail.splice(index,1);
        this.setState({
            OrderDetail
        })
    };
    handleSentOrder = async()=>{
        const tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
        const localISOTime = (new Date(Date.now() - tzoffset)).toISOString().slice(0,19).replace("T"," ");
        const order = {
            "OrderHeader": {
                "OrderNumber": `Ashlin-Transfer-${this.state.orderNumber}`,
                "EndCustomerOrderNo": "",
                "ThirdPartyOrderNo": "",
                "OrderDateCode": "CR",
                "OrderDate": localISOTime ,
                "Comment": `Please *** DO NOT use any shipping account ***, Please use the label from: ${this.state.canParShipment.labels}  `,
                "ShipTo": {
                    "Name" : this.state.selectedWarehouse.Name,
                    "AddressLine1"	: this.state.selectedWarehouse.Address1,
                    "AddressLine2"	: this.state.selectedWarehouse.Address2,
                    "AddressLine3"	: "",
                    "City"	:	this.state.selectedWarehouse.City,
                    "State"	: this.state.selectedWarehouse.State,
                    "CountryCode"	:	this.state.selectedWarehouse.CountryCode,
                    "ContactName"	:	"",
                    "ContactPhone"	:	"",
                    "ContactPhoneExt":"",
                    "Zip"	:	this.state.selectedWarehouse.Zip
                },
                "ShipFrom": {
                    "Name" : "WAREHOUSE"
                },
                "WarehouseCode": "14",
                "OrderStatus": "NEW",
                "OrderType": "NO",
                "Priority": "",
                "Version": ""
            },
            "OrderDetail": this.state.OrderDetail,
            "OrderDelivery": this.state.OrderDelivery
        };


        //Send order to lean supply
        const data = SubmitOrderModel(order);
        //console.log(data);
        let confirmBox = window.confirm(`Do you confirm to send this to 3PL?`);
        if (confirmBox) {
            if(ENABLE_LEANSUPPLY ) {
                const leanSupplyRes = await sendOrderToLeanSupply(data);
                //console.log(leanSupplyRes);
            }

            if( ENABLE_BRIGHTPEARL){
                // let targetWarehouseId;
                // switch (this.state.selectedWarehouse.CountryCode){
                //     case "CA" :
                //         targetWarehouseId = 3;
                //         break;
                //     case "US":
                //         targetWarehouseId = 4;
                //         break;
                //     default:
                //         targetWarehouseId = 3;
                // }
                // //Update Brightpearl Warehouse
                // //1 - Stock Transfer
                // //2 - External Transfer
                // //LS warehouse ID: 5
                // // const stockTransferID = await createStockTransfer(5,targetWarehouseId,this.state.BPHeaders);
                // // console.log(stockTransferID);
                // const brightpearlTransferProducts = this.state.OrderDetail.map(item=>{
                //     return {
                //         "productId": item.ItemComment,
                //         "quantity": item.ItemQty,
                //         "fromLocationId": 10
                //     }
                // });
                // const externalTransferData = {
                //     "targetWarehouseId": targetWarehouseId,
                //     "stockTransferId": 131,//stockTransferID,
                //     "shippingMethodId": 1,
                //     "transferredProducts": brightpearlTransferProducts
                // };
                //
                // const transferRes = await createExternalTransfer(5,externalTransferData,this.state.BPHeaders);
                // console.log(transferRes)
            }
        }
    };
    handleCreateCanparLabel = async()=>{
        const shipment = await createInventoryTransfer(this.state.selectedBox,this.state.shippingInfo,this.state.orderNumber,this.state.selectedWarehouse);
        const labelURLs =await shipment.labels.map(async (labelBase64,index)=>{
            const label = base64ToNewTab(labelBase64,'application/png');
            const labelURL = `https://ashlinapp.xyz/Labels/Canpar/InventoryTransfer-${this.state.orderNumber}-${1+index}.png`;
            await postLabel(label, `InventoryTransfer-${this.state.orderNumber}-${1+index}.png`, "Canpar");
            window.open(labelURL, '_blank');
            return labelURL;

        });
        Promise.all(labelURLs ).then((promise) => promise.join(" ,  "))
            .then(async promiseValue => {
                const canParShipment = this.state.canParShipment;
                canParShipment.shipmentId = shipment.shipmentId;
                canParShipment.labels = promiseValue;
                this.setState({
                    canParShipment
                })
        })
    };
    handleVoidCanparLabel = async()=>{
        const credentials = await getCanparCredentials();
        const res = await voidCanparShipment(this.state.canParShipment.shipmentId,credentials);
        //console.log(res);
        if(res==="SUCCESS"){
            this.setState({
                canParShipment:{}
            })
        }else{
            alert(res);
        }
    };
    handleAddContainer = ()=>{
        const selectedBox = this.state.selectedBox;
        const NumNBox = this.state.NumNBox;
        const HeightCM = this.state.HeightCM;
        const WidthCM = this.state.WidthCM;
        const LengthCM = this.state.LengthCM;
        for(let i = 0; i < parseInt(NumNBox); i++){
            selectedBox.push({
                HeightCM,
                WidthCM,
                LengthCM
            })
        }
        this.setState({
            selectedBox
        },()=>console.log(this.state.selectedBox))
    };
    render() {
        let style={
        };
        if(this.state.selectedSKU.sku !== "optionDefaultHeader" && this.state.selectedSKU.qty==0){
            style.backgroundColor = 'red';
        }
        return (
            <div className='container-fluid row'>
                <div className={'col-3'}>
                    <MainSideBar/>
                </div>
                <div className={'col'}>
                    <br />
                    <h1 className={"text-center"}>Inventory Transfer</h1>
                    <table className="table inventoryTransferTable" style={{margin:'16px 0 0'}}>
                        <tbody>
                            <tr>
                                <th >FROM  LEAN SUPPLY</th>
                                <th colSpan={'2'}>
                                    TO: &nbsp;
                                    <select name={'selectedWarehouse'}  style={{width:'90%'}} onChange={this.selectWarehouse} value={this.state.selectedWarehouse.name}>
                                        <option value="optionDefaultHeader" >-- select a Warehouse --</option>
                                        {this.state.FBALocations.map((op,i)=>{
                                            return <option value={op.Name} key={i}>{op.Name}</option>
                                        })}
                                    </select>
                                </th>
                                <th >Filter: &nbsp;
                                    <select name={'selectedCountry'}  onChange={this.selectCountry}>
                                        <option value="ALL" >ALL</option>
                                        <option value="CA" >CANADA</option>
                                        <option value="US" >US</option>
                                    </select>
                                </th>
                            </tr>
                            {
                                this.state.selectedWarehouse.name !== "optionDefaultHeader" &&
                                    <tr>
                                        <th colSpan={'4'} style={{fontWeight:'bold',textAlign:'center',backgroundColor:'#cbcfd6'}}>Ship to Address</th>
                                    </tr>
                            }
                            {
                                this.state.selectedWarehouse.name !== "optionDefaultHeader" &&
                                    <tr>
                                        <td><span style={{fontWeight:'bold'}}>Name: </span>{this.state.selectedWarehouse.Name}</td>
                                        <td colSpan={'2'}><span style={{fontWeight:'bold'}}> Address Line1: </span>{this.state.selectedWarehouse.Address1}</td>
                                        <td><span style={{fontWeight:'bold'}}>Address Line2: </span>{this.state.selectedWarehouse.Address2}</td>
                                    </tr>
                            }
                            {
                                this.state.selectedWarehouse.name !== "optionDefaultHeader" &&
                                <tr>
                                    <td><span style={{fontWeight:'bold'}}>City: </span>{this.state.selectedWarehouse.City}</td>
                                    <td><span style={{fontWeight:'bold'}}>State: </span>{this.state.selectedWarehouse.State}</td>
                                    <td><span style={{fontWeight:'bold'}}>Country: </span>{this.state.selectedWarehouse.CountryCode}</td>
                                    <td><span style={{fontWeight:'bold'}}>PostCode: </span>{this.state.selectedWarehouse.Zip}</td>
                                </tr>
                            }
                            {
                                this.state.selectedWarehouse.name !== "optionDefaultHeader" &&
                                <tr>
                                    <th colSpan={'4'} style={{fontWeight:'bold',textAlign:'center',backgroundColor:'#cbcfd6'}}>
                                        Use following row to add Items to inventory transfer order lines
                                    </th>
                                </tr>
                            }
                            {
                                this.state.selectedWarehouse.name !== "optionDefaultHeader" &&
                                <tr>
                                    <td>
                                        {/*<span>Filter: </span>*/}
                                        {/*<input type={'text'} onChange={this.filterSKU}/>*/}
                                        {/*<br/>*/}
                                        <span style={{fontWeight:'bold'}}>SKU: </span>
                                        <select name={'selectedSKU'}   onChange={this.selectSKU } value={this.state.selectedSKU.sku}>
                                            <option value="optionDefaultHeader" >-- select a SKU --</option>
                                            {this.state.shownLeanSupplyInventory.map((op,i)=>{
                                                return <option value={op.sku} key={i}>{op.sku}</option>
                                            })}
                                        </select>
                                    </td>
                                    <td><span style={{fontWeight:'bold'}}>In Stock: </span>
                                        <span style={style}>{this.state.selectedSKU.qty}</span>
                                    </td>
                                    <td><span style={{fontWeight:'bold'}}>Transfer QTY: </span>
                                        <input name={'transferQty'} onChange={this.handleStateOnchange} value={this.state.transferQty}/>
                                    </td>
                                    <td>
                                        <button className={'btn-success'} type={'button'} onClick={this.handleAddItem}>Add Item</button>
                                    </td>
                                </tr>
                            }
                            {
                                this.state.OrderDetail.length!==0 &&
                                <tr>
                                    <th colSpan={'4'} style={{fontWeight:'bold',textAlign:'center',backgroundColor:'#cbcfd6'}}>
                                        Selected/Transferring Items
                                    </th>
                                </tr>
                            }
                            {
                                this.state.OrderDetail.length!==0 &&
                                this.state.OrderDetail.map((line,index)=>{
                                    return <tr key={index}>
                                        <td>
                                            <span style={{fontWeight:'bold'}}>SKU: </span>
                                            {line.ItemCode}
                                        </td>
                                        <td><span style={{fontWeight:'bold'}}>Description: </span>{line.ItemName}</td>
                                        <td><span style={{fontWeight:'bold'}}>Transfer QTY: </span>
                                            {line.ItemQty}
                                        </td>
                                        <td>
                                            <button className={'btn-danger'} type={'button'} onClick={()=>this.handleRemoveOrderLine(index)}>Remove order line</button>
                                        </td>
                                    </tr>
                                })
                            }
                            {
                                this.state.OrderDetail.length!==0 &&
                                <tr>
                                    <th colSpan={'3'} style={{fontWeight:'bold',textAlign:'center',backgroundColor:'#cbcfd6'}}>
                                        HeightCM: <input name={'HeightCM'} onChange={this.handleStateOnchange} value={this.state.HeightCM} style={{margin:'0 10px 0 0', width:'100px'}}/>
                                        LengthCM: <input name={'LengthCM'} onChange={this.handleStateOnchange} value={this.state.LengthCM} style={{margin:'0 10px 0 0', width:'100px'}}/>
                                        WidthCM: <input name={'WidthCM'} onChange={this.handleStateOnchange} value={this.state.WidthCM} style={{margin:'0 10px 0 0', width:'100px'}}/>
                                        Number of Boxes: <input name={'NumNBox'} onChange={this.handleStateOnchange} value={this.state.NumNBox} style={{margin:'0 10px 0 0', width:'100px'}}/>
                                    </th>
                                    <th colSpan={'1'} style={{fontWeight:'bold',textAlign:'center',backgroundColor:'#cbcfd6'}}>
                                        <button type={'button'} className={'btn-primary'} onClick={this.handleAddContainer}>Add Boxes</button>
                                    </th>
                                </tr>
                            }
                            {
                                (this.state.OrderDetail.length!==0 && this.state.selectedBox.length!==0)&&
                                <tr>
                                    <th colSpan={'4'} style={{fontWeight:'bold',textAlign:'center',backgroundColor:'#cbcfd6'}}>
                                        {!this.state.canParShipment.shipmentId && <button type={'button'} className={'btn-success'} onClick={this.handleCreateCanparLabel}>Create Canpar Label</button>}

                                        {this.state.canParShipment.shipmentId && <button type={'button'} className={'btn-danger'} onClick={this.handleVoidCanparLabel}>Void Canpar Shipment</button> }
                                        &nbsp;&nbsp;&nbsp;Ashlin-Transfer#: <input type={'text'} onChange={this.handleStateOnchange} name={'orderNumber'} value={this.state.orderNumber}/>(Numbers Only)
                                        &nbsp;&nbsp;&nbsp;
                                        <button type={'button'} className={'btn-danger'} onClick={this.handleSentOrder}>Sent Order to Lean Supply</button>
                                    </th>
                                </tr>
                            }

                        </tbody>
                    </table>
                    <br />
                </div>
            </div>
        );
    }
}
export default InventoryTransfer;
