import React, { Component } from 'react';
import MainSideBar from '../sideBar/MainSideBar';
import {withRouter} from "react-router-dom";
import NewSKUInfo from './NewSKUInfo';
import NewHTSCode from './NewHTSCode';
import NewSkuSEO from './NewSKUSEO';
import NewChannelList from './NewChannelList';
import NavItem from '../bootstrapComponent/navItem';
import TabContentItem from '../bootstrapComponent/tabContentItem';
import ImageUpload from '../../Utils/imageUpload/imageUpload';
import validationChecker from '../../Utils/Validation/ValidationChecker';
import { message } from 'antd';
import {
    legacyColoursModelMapper,
    legacyColoursURL,
    legacyDesignsModelMapper, legacyDesignsURL, legacyFamiliesURL,
    legacySubFamiliesModelMapper, legacySubFamiliesURL,
    legacyMaterialsModelMapper,
    legacyMaterialsURL, legacyHtsUsURL, legacyHtsCaModelMapper, legacyHtsUsModelMapper, legacyHtsCaURL,
    legacySKUUpdateModel, legacySkusURL, legacyUpc9, legacyDesignUpdateModel, SkuImageUrl,
    SkuImageUrlModel,SkuChannelListingUrl,SkuChannelListingModel,
    SkuPantoneColorUrl,SkuPantoneColorModel,getCostMatrix ,updateSkuTierCost
} from "../../Utils/ashlinDesign";
import axios from "axios/index";
import {googleTranslate} from "../../Utils/googleTranslate";
import $ from "jquery";
import { proxyurl } from '../../Utils/requestHelper';
class CreateNewSKU extends  Component{
    _mounted = false;
    constructor(props){
        super(props);
        this.state = {
            newSKU:{
                basicInfo:{
                    selectedHardwareColor:"",
                    selectedLiningMaterial:"",
                    selectedHandleMaterial:"",
                    selectedTrim:"matching material",
                    selectedBasePrice:'',
                    selectedMinimumOrder:'',
                    selectedAsiCategory:'',
                    upc9:'',
                    upc10:'',
                    pantoneColor:'',
                    colorR:'',
                    colorG:'',
                    colorB:'',
                    designerColorName:''
                },
                htsCode:{
                },
                channelList:{
                    database:false,
                    asi:false,
                    sage:false,
                    bigCommerceAshlin:false,
                    bigCommercePoppyBuzz:false,
                    brightPearl:false,
                    leanSupply:false,
                    staples:false,
                    theBay:false,
                    amazon:false,
                    walmart:false
                },
                seoContent:{
                },
                imageURL:{
                }
            },
            collections:['ABOU', 'VEGAN', 'CORPORATE', 'ASIB', 'ITALIAN COLLECTION', 'MEN', 'WOMEN', 'TRAVEL', 'HOMEOFFICE', '6IXHIDE', 'SPECIALTY'],
            ashlinDesigns:[],
            selectedDesign:{},
            ashlinMaterials:[],
            selectedMaterial:{},
            ashlinColours:[],
            selectedColor:{},
            ashlinFamilies:[],
            selectedFamily:{},
            ashlinSubFamilies:[],
            selectedSubFamily:{},
            ashlinHtsCa:[],
            ashlinHtsUs:[],
            asiCategory:[],
            selectedSKU:{},
            ashlinUPC9:[],
            imageURLs:{
                base:[],
                group:[],
                liftStyle:[],
                model:[],
                sftp:[],
                upc9:[],
                upc10:[],
                small:[],
                template:[],
                main:[],
                sftpMain:[]
            },
            tierMatrix:null,
            skuCost:null,

        }
    }
    handleGenerateUPC= (e)=>{
        if(e!==undefined&&e.target.type==="submit"){
            e.preventDefault();
        }
        const UPC9 = this.state.ashlinUPC9;
        let iterator = 62618300000;
        let index = 0;
        while (UPC9.includes(iterator)){
            iterator++;
            index++
        }
        const newUPC9 = iterator.toString();
        const checkDig = this.getCheckNum(newUPC9)%10;
        const newUPC10 = `${newUPC9}${checkDig}`;
        const newSKU = this.state.newSKU;
        newSKU.basicInfo.upc9 = newUPC9;
        newSKU.basicInfo.upc10 = newUPC10;
        UPC9.splice(index,0,parseInt(newUPC9));
        this.setState({
            newSKU,
            ashlinUPC9:UPC9
        });

    };
    getCheckNum =(upcCode)=>{
        //console.log('UPC ',upc);
        let indexOdd = 0;
        let indexEven = 1;
        let total = [ 0, 0 ];    // [0] for odd (and also the combine one), [1] for even
        //console.log('t0: ', total[0] );
        //console.log('t1: ', total[1] );
        // odd digits calculation
        while (indexOdd < 11)
        {
            total[0] += upcCode[indexOdd] - '0';
            indexOdd += 2;
            //console.log('total0',total[0] );
        }
        total[0] *= 3;

        // even digits calculation
        while (indexEven < 11)
        {
            total[1] += upcCode[indexEven] - '0';
            indexEven += 2;
            //console.log('total1',total[1] );
        }

        // add together
        total[0] += total[1];
        //console.log('together: ', total[0] );
        // return check digit
        return 10 - total[0] % 10;
    };


    handleImageURLChange=(name,value)=>{
      const urls = this.state.imageURLs;
      urls[name].push(value);
      this.setState({
          imageURLS: urls
      },()=>console.log(this.state.imageURLs));
    };
    componentWillUnmount() {
        this._mounted = false;
    }
    getData = async() =>{
        const ashlinDesignsResponse = await axios.get(legacyDesignsURL);
        const ashlinDesigns = ashlinDesignsResponse.data.map(design=>legacyDesignsModelMapper(design));
        const ashlinMaterialResponse = await axios.get(legacyMaterialsURL);
        const ashlinMaterials = ashlinMaterialResponse.data.map(material=>legacyMaterialsModelMapper(material));
        const ashlinColorResponse = await axios.get(legacyColoursURL);
        const ashlinColours = ashlinColorResponse.data.map(colour=>legacyColoursModelMapper(colour));
        const ashlinFamiliesResponse = await axios.get(legacyFamiliesURL);
        const ashlinFamilies = ashlinFamiliesResponse.data;
        const ashlinSubFamiliesResponse = await axios.get(legacySubFamiliesURL);
        const ashlinSubFamilies = ashlinSubFamiliesResponse.data.map(subFamily=>legacySubFamiliesModelMapper(subFamily));
        const ashlinHtsCaResponse = await axios.get(legacyHtsCaURL);
        const ashlinHtsCa = ashlinHtsCaResponse.data.map(hts=>legacyHtsCaModelMapper(hts));
        const ashlinHtsUsResponse = await axios.get(legacyHtsUsURL);
        const ashlinHtsUs = ashlinHtsUsResponse.data.map(hts => legacyHtsUsModelMapper(hts));
        const asiCategoryResponse = await axios.get(proxyurl+'https://productservice.sand-asicentral.com/api/v5/lookup/categorieslist');
        const asiCategory = asiCategoryResponse.data.categories;

        const ashlinUPC9Response = await axios.get(legacyUpc9);
        const ashlinUPC9 = ashlinUPC9Response.data;
        Promise.all([ashlinDesignsResponse,ashlinMaterialResponse,ashlinColorResponse,ashlinFamiliesResponse,ashlinSubFamiliesResponse,ashlinUPC9Response]).then(()=>{
            if(this._mounted) {
                this.setState({
                    ashlinDesigns: ashlinDesigns,
                    ashlinMaterials: ashlinMaterials,
                    ashlinColours: ashlinColours,
                    ashlinFamilies: ashlinFamilies,
                    ashlinSubFamilies: ashlinSubFamilies,
                    ashlinHtsCa,
                    ashlinHtsUs,
                    asiCategory,
                    ashlinUPC9,
                });
            }
        })
    };
    componentDidMount(){
        this._mounted = true;
        this.getData();
    }
    handleOnChange=(event, filed)=>{
        const target = event.target;
        //console.log(event.target.checked);
        const value =  target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const newSKU = this.state.newSKU;
        value ===''? delete newSKU[filed][name] : newSKU[filed][name] = value;
        if(name==='bigCommerceAshlin'){
            newSKU.channelList.bigCommercePoppyBuzz = !value;
        }
        if(name==='bigCommercePoppyBuzz'){
            newSKU.channelList.bigCommerceAshlin = !value;
        }

        this.setState({
            newSKU
        },()=>{
            console.log(this.state.newSKU);
        })
    };

    handleDesignChange=async(event)=>{
        const target = event.target;
        const value =  target.value;
        const name = target.name;
        let selected;
        let selectedSubFamily = {};
        let selectedFamily = {};
        let selectedSKU = {};
        if(name==='selectedDesign' && value !=="optionDefaultHeader"){
            selected = this.state.ashlinDesigns.find(d=>d.designCode===value);
            selectedSubFamily = this.state.ashlinSubFamilies.find(s=>s.subFamilyCode === selected.pickedSubFamily);
            selectedFamily = this.state.ashlinFamilies.find(f=>f.SuperFamily === selectedSubFamily.pickedFamily);
            const newSKU = this.state.newSKU;
            newSKU.basicInfo.selectedAsiCategory = '';
            //selectedSKU.DesignServiceCode:
            selectedSKU = this.state.selectedSKU;
            selectedSKU.DesignServiceCode = selected.designCode;
            //generate new UPC
            await this.handleGenerateUPC();
            this.setState({
                selectedSubFamily:selectedSubFamily,
                selectedFamily:selectedFamily,
                newSKU,
                selectedSKU,
                [name]: selected
            });
        }else if(name==='selectedMaterial'){
            selected = this.state.ashlinMaterials.find(m=>m.materialCode===value);
            selectedSKU = this.state.selectedSKU;
            selectedSKU.materialCode = selected.materialCode;

            this.setState({
                [name]: selected,
                selectedSKU
            },()=>console.log(this.state));
        }else if(name==='selectedColor'){
            selected = this.state.ashlinColours.find(c=>c.colorCode===value);
            selectedSKU = this.state.selectedSKU;
            selectedSKU.colorCode = selected.colorCode;
            this.setState({
                [name]: selected,
                selectedSKU
            },()=>console.log(this.state));
        }else if(name==='selectedFamily'){
            selectedFamily= this.state.ashlinFamilies.find(f=>f.SuperFamily===value);
            selected = this.state.selectedSubFamily;
            selected.pickedFamily = selectedFamily.SuperFamily;
            this.setState({
                selectedSubFamily: selected,
                selectedFamily:selectedFamily
            },()=>console.log(this.state));
        }else if(name==='selectedSubFamily'){
            selectedSubFamily  = this.state.ashlinSubFamilies.find(s=>s.subFamilyCode===value);
            selectedFamily = this.state.ashlinFamilies.find(f=>f.SuperFamily === selectedSubFamily.pickedFamily);
            selected = this.state.selectedDesign;
            selected.pickedSubFamily = selectedSubFamily.subFamilyCode;
            this.setState({
                selectedSubFamily : selectedSubFamily,
                selectedDesign:selected,
                selectedFamily:selectedFamily
            },()=>console.log(this.state));
        }else if(name==='pickedCollection'){
            selectedFamily = this.state.selectedFamily;
            selectedFamily.Collections = value;
            this.setState({
                selectedFamily:selectedFamily
            },()=>console.log(this.state));
        }else if(name==='selectedBrand'){
            selected = this.state.selectedDesign;
            selected.brand = value;
            this.setState({
                selectedDesign:selected
            },()=>console.log(this.state));
        }else if(name==='selectedHtsCa'){
            const selectedHtsCa = this.state.ashlinHtsCa.find(h=>h.id === value);
            selected = this.state.selectedSubFamily;
            selected.htsCaCode = selectedHtsCa.id;
            selected.htsCaValue = selectedHtsCa.value;
            this.setState({
                selectedSubFamily:selected
            },()=>console.log(this.state));
        }else if(name==='selectedHtsUs'){
            const selectedHtsUs = this.state.ashlinHtsUs.find(h=>h.id === value);
            selected = this.state.selectedSubFamily;
            selected.htsUsCode = selectedHtsUs.id;
            selected.htsUsValue = selectedHtsUs.value;
            this.setState({
                selectedSubFamily:selected
            },()=>console.log(this.state));
        }else if(name==='pricingTier'||name==='ReorderQty'||name==='ReorderLevel'){
            if(parseInt(value) || value==0){
                selected = this.state.selectedSubFamily;
                selected[name] = value;
                this.setState({
                    selectedSubFamily:selected
                },()=>console.log(this.state));
            }else {
                alert('Enter an Integer value!');
            }
        }
    };
    handleSEOOnchange=(event)=>{
        const target = event.target;
        const value =  target.value;
        const name = target.name;
        const selectedDesign = this.state.selectedDesign;
        selectedDesign[name] = value;
        this.setState({
            selectedDesign
        },()=>console.log(this.state.selectedDesign));

    };
    handleTranslateWithField= async(e,state,field)=>{
        e.preventDefault();
        const editState = this.state[state];
        if(field==='options'){
            if(editState.op1ENG!== undefined && editState.op1ENG!=='' ){
                editState.op1FR = await googleTranslate(editState.op1ENG,'fr');
            }
            if(editState.op2ENG!== undefined && editState.op2ENG!=='' ){
                editState.op2FR = await googleTranslate(editState.op2ENG,'fr');
            }
            if(editState.op3ENG!== undefined && editState.op3ENG!=='' ){
                editState.op3FR = await googleTranslate(editState.op3ENG,'fr');
            }
            if(editState.op4ENG!== undefined && editState.op4ENG!=='' ){
                editState.op4FR = await googleTranslate(editState.op4ENG,'fr');
            }
            if(editState.op5ENG!== undefined && editState.op5ENG!=='' ){
                editState.op5FR = await googleTranslate(editState.op5ENG,'fr');
            }
        }else if(field==='SEOSections'){
            if(editState.SEOShortENG!== undefined && editState.SEOShortENG!=='' ){
                editState.SEOShortFR = await googleTranslate(editState.SEOShortENG,'fr');
            }
            if(editState.SEOLongENG!== undefined && editState.SEOLongENG!=='' ){
                editState.SEOLongFR = await googleTranslate(editState.SEOLongENG,'fr');
            }
            if(editState.SEOOp1ENG!== undefined && editState.SEOOp1ENG!=='' ){
                editState.SEOOp1FR = await googleTranslate(editState.SEOOp1ENG,'fr');
            }
            if(editState.SEOOp2ENG!== undefined && editState.SEOOp2ENG!=='' ){
                editState.SEOOp2FR = await googleTranslate(editState.SEOOp2ENG,'fr');
            }
            if(editState.SEOOp3ENG!== undefined && editState.SEOOp3ENG!=='' ){
                editState.SEOOp3FR = await googleTranslate(editState.SEOOp3ENG,'fr');
            }
            if(editState.SEOOp4ENG!== undefined && editState.SEOOp4ENG!=='' ){
                editState.SEOOp4FR = await googleTranslate(editState.SEOOp4ENG,'fr');
            }
            if(editState.SEOOp5ENG!== undefined && editState.SEOOp5ENG!=='' ){
                editState.SEOOp5FR = await googleTranslate(editState.SEOOp5ENG,'fr');
            }

        }
        this.setState({
            [state]:editState
        });
    };
    handleCalculateTierPrice=async(e)=>{
      e.preventDefault();
      if(this.state.selectedSubFamily.pricingTier === undefined){
          alert("Must Enter Price Tier First!");
      }else if(this.state.newSKU.basicInfo.selectedBasePrice === ''){
          alert("Must Enter Base Price First!");
      }else{
          //console.log(this.state.selectedSubFamily.pricingTier);
          const tierMatrixRes = await getCostMatrix(this.state.selectedSubFamily.pricingTier);
          const tierMatrix = tierMatrixRes.data;
          const skuCost = {};
          skuCost.Sku = this.state.selectedDesign.designCode+'-'+this.state.selectedMaterial.materialCode+'-'+this.state.selectedColor.colorCode;
          skuCost.MinimumOrderQuantity="";
          skuCost.KaplUnitCostCadS = 0;
          skuCost.PricingTier = this.state.selectedSubFamily.pricingTier;
          skuCost.ProductId = "";
          this.calculateSkuCost(skuCost,tierMatrix );
          this.setState({
              tierMatrix,
              skuCost
          },()=>{
              $('#tierCostModal').modal('show');
          });
      }
    };
    calculateSkuCost = (skuCost,tierMatrix)=>{
        skuCost.Qty1Price = (parseFloat(skuCost.KaplUnitCostCadS) * tierMatrix._1CStandardDelivery).toFixed(2);
        skuCost.Qty6Price = (parseFloat(skuCost.KaplUnitCostCadS) * tierMatrix._6CStandardDelivery).toFixed(2);
        skuCost.Qty24Price = (parseFloat(skuCost.KaplUnitCostCadS) * tierMatrix._24CStandardDelivery).toFixed(2);
        skuCost.Qty50Price = (parseFloat(skuCost.KaplUnitCostCadS) * tierMatrix._50CStandardDelivery).toFixed(2);
        skuCost.Qty100Price = (parseFloat(skuCost.KaplUnitCostCadS) * tierMatrix._100CStandardDelivery).toFixed(2);
        skuCost.Qty250Price = (parseFloat(skuCost.KaplUnitCostCadS) * tierMatrix._250CStandardDelivery).toFixed(2);
        skuCost.Qty500Price = (parseFloat(skuCost.KaplUnitCostCadS) * tierMatrix._500CStandardDelivery).toFixed(2);
        skuCost.Qty1000Price = (parseFloat(skuCost.KaplUnitCostCadS) * tierMatrix._1000CStandardDelivery).toFixed(2);
        skuCost.Qty2500Price = (parseFloat(skuCost.KaplUnitCostCadS) * tierMatrix._2500CStandardDelivery).toFixed(2);
    };
    handleSkuCostChange=(event)=>{
        event.preventDefault();
        const target = event.target;
        const value =  target.value;
        const name = target.name;
        const skuCost = this.state.skuCost;
        skuCost[name] = value;
        if (name==='KaplUnitCostCadS'){
            this.calculateSkuCost(skuCost,this.state.tierMatrix );
            this.setState({
                skuCost
            })
        }else{
            this.setState({
                skuCost
            },()=>console.log(this.state))
        }
    };
    handlePricingTierOnchange=async(event)=>{
        event.preventDefault();
        const target = event.target;
        const value =  target.value;
        const name = target.name;
        const skuCost = this.state.skuCost;
        skuCost[name] = value;
        const tierMatrixRes = await getCostMatrix(value);
        const tierMatrix = tierMatrixRes.data;
        this.calculateSkuCost(skuCost,tierMatrix );
        this.setState({
            skuCost
        })

    };
    //Submit a single SKU
    handleUpDateCostMatrix =async (e,create)=>{
        e.preventDefault();
        const res = await updateSkuTierCost(this.state.skuCost,create);
        alert("Supplier Tier Cost Price Updating Finished!");
        $('#tierCostModal').modal('hide');
        console.log(res);
    };

    checkFormValidation = (formData) => {
        //is form valid
        let isValid = false
        let {newSKU, selectedColor, selectedDesign, selectedFamily, selectedMaterial, selectedSKU, selectedSubFamily, skuCost, tierMatrix} = formData
        let basicInfo = newSKU.basicInfo
        const formObject = {selectedColor, selectedDesign, selectedFamily, selectedMaterial, selectedSKU,
             selectedSubFamily
            }
        const formString = {
            selectedHardwareColor:basicInfo.selectedHardwareColor,
            selectedLiningMaterial:basicInfo.selectedLiningMaterial,
            selectedHandleMaterial:basicInfo.selectedHandleMaterial,
            designerColorName: basicInfo.designerColorName,
            selectedTrim:basicInfo.selectedTrim,
            selectedAsiCategory:basicInfo.selectedAsiCategory,
            upc9:basicInfo.upc9,
            upc10:basicInfo.upc10,
            pantoneColor:basicInfo.pantoneColor,
        }
        const formInt = {
            colorR:basicInfo.colorR,
            colorG:basicInfo.colorG,
            colorB:basicInfo.colorB,
            selectedMinimumOrder:basicInfo.selectedMinimumOrder,
        }
        const formFloat = {
            selectedBasePrice:basicInfo.selectedBasePrice,
        }
        const checkNullObj = {skuCost, tierMatrix}
        for (let property in formObject){
            if(!validationChecker.checkObj(formObject[property], property)){
                return isValid
            }
        }
        for (let property in checkNullObj){
            if(!validationChecker.checkIsNull(checkNullObj[property], property)){
                return isValid
            }
        }
        for (let property in formString){
            if(!validationChecker.checkString(formString[property], property)){
                return isValid
            }
        }
        for (let property in formInt){
            if(!validationChecker.checkInt(formInt[property], property)){
                return isValid
            }
        }
        for (let property in formFloat){
            if(!validationChecker.checkFloat(formFloat[property], property)){
                return isValid
            }
        }
        isValid = true
        return isValid
    }

    handleSubmit= async (event)=>{
        event.preventDefault();
        let isValidForm = this.checkFormValidation(this.state)
        if (!isValidForm){
            return;
        }
        const inputObj = {
            newSKU:this.state.newSKU,
            material:this.state.selectedMaterial,
            color:this.state.selectedColor,
            design:this.state.selectedDesign,
            family:this.state.selectedFamily,
            subFamily:this.state.selectedSubFamily
        };
        
       /*Make Request to update selected design SEO*/
        const uploadData = legacyDesignUpdateModel(this.state.selectedDesign,"updateDesign");
        const updateResponse = await axios.put(legacyDesignsURL+uploadData.DesignServiceCode,uploadData);
        if(updateResponse.status===204){
            alert("Update successfully!!!");
        }

       const newSKU = legacySKUUpdateModel(inputObj);
       //console.log(newSKU);

        /*******************Insert Pantone Color table*****************/
        const skuPantoneColorObj = SkuPantoneColorModel(this.state.newSKU.basicInfo,newSKU.SkuAshlin);
        //console.log(skuPantoneColorObj);
        const skuPantoneColorResponse = await axios.post(SkuPantoneColorUrl,skuPantoneColorObj );
        console.log(skuPantoneColorResponse);

        /*******************Insert Sku channel listing table*****************/
        /* NOT USE DUE TO BULK UPLOAD FEATURE
        const skuChannelObj = SkuChannelListingModel(this.state.newSKU.channelList,newSKU.SkuAshlin);
        //console.log(skuChannelObj);
        const skuChannelResponse = await axios.post(SkuChannelListingUrl,skuChannelObj );
        console.log(skuChannelResponse);
        */



        /*******************Insert Ulrs to Image Url table*****************/
        const d = new Date(new Date().toString().split('GMT')[0]+' UTC').toISOString();
        const lastModified = d.slice(0,19);
        const imageUrlsObj = SkuImageUrlModel(this.state.imageURLs,newSKU.SkuAshlin,lastModified);
        //console.log(imageUrlsObj);
        const imageUrlResponse = await axios.post(SkuImageUrl,imageUrlsObj);
        console.log(imageUrlResponse);


        /*******************Insert new Sku to Sku table*****************/
       const newSKUResponse = await axios.post(legacySkusURL,newSKU);
       console.log(newSKUResponse);
       await Promise.all([updateResponse,skuPantoneColorResponse,imageUrlResponse,newSKUResponse ]).then(()=>{

           this.setState({
               newSKU:{
                   basicInfo:{
                       selectedHardwareColor:"",
                       selectedLiningMaterial:"",
                       selectedHandleMaterial:"",
                       selectedTrim:"matching material",
                       selectedBasePrice:'',
                       selectedMinimumOrder:'',
                       selectedAsiCategory:'',
                       upc9:'',
                       upc10:'',
                       pantoneColor:'',
                       colorR:'',
                       colorG:'',
                       colorB:'',
                       designerColorName:''
                   },
                   htsCode:{
                   },
                   channelList:{
                       database:false,
                       asi:false,
                       sage:false,
                       bigCommerceAshlin:false,
                       bigCommercePoppyBuzz:false,
                       brightPearl:false,
                       leanSupply:false,
                       staples:false,
                       theBay:false,
                       amazon:false,
                       walmart:false
                   },
                   seoContent:{
                   },
                   imageURL:{
                   }
               },
               collections:['ABOU', 'VEGAN', 'CORPORATE', 'ASIB', 'ITALIAN COLLECTION', 'MEN', 'WOMEN', 'TRAVEL', 'HOMEOFFICE', '6IXHIDE', 'SPECIALTY'],
               selectedDesign:{},
               selectedMaterial:{},
               selectedColor:{},
               selectedFamily:{},
               selectedSubFamily:{},
               selectedSKU:{
                   // DesignServiceCode:"optionDefaultHeader"
               },
               imageURLs:{
                   base:[],
                   group:[],
                   liftStyle:[],
                   model:[],
                   sftp:[],
                   upc9:[],
                   upc10:[],
                   small:[],
                   template:[],
                   main:[],
                   sftpMain:[]
               },
               tierMatrix:null,
               skuCost:null

           },()=>{
               message.success('SKU successfully created!');
           });
        });

    };
    render(){
        return(
            <div className='container-fluid row'>
                {/*<div className={'col-3'}>*/}
                    {/*<MainSideBar/>*/}
                {/*</div>*/}
                <div className={'col'}>
                    <h2 className={'text-secondary'} style={{textAlign:'center'}}>Create New SKU <span style={{color: "red"}}>(You have to fill all info for step 1 and 3)</span></h2>
                    <ul className="nav nav-tabs nav-fill">
                        <NavItem toggleType='tab' id="#newSKUInfo" data="Step1: SKU BASIC INFO" active={true}/>
                        {/*<NavItem toggleType='tab' id="#newHTSCode" data="HTS CODES"  />*/}
                        {/*<NavItem toggleType='tab' id="#newChannelList" data="CHANNEL LIST"  />*/}
                        <NavItem toggleType='tab' id="#newSEOContent" data="Step2: SEO CONTENT" />
                        <NavItem toggleType='tab' id="#newImageURL" data="Step3: IMAGE URL" />
                    </ul>
                    <form className="tab-content" >
                        <TabContentItem id="newSKUInfo"  style={{height:"450px",margin:'30px 0 10px'}} active={true}>
                            { this.state.ashlinDesigns!==[] &&
                                <NewSKUInfo
                                    isUpdating={false}
                                    designChange={this.handleDesignChange}
                                    ashlinDesign = {this.state.ashlinDesigns}
                                    ashlinMaterial = {this.state.ashlinMaterials}
                                    ashlinColor = {this.state.ashlinColours}
                                    ashlinFamily = {this.state.ashlinFamilies}
                                    ashlinSubFamily = {this.state.ashlinSubFamilies}
                                    selectedDesign = {this.state.selectedDesign}
                                    selectedMaterial = {this.state.selectedMaterial}
                                    selectedColor = {this.state.selectedColor}
                                    selectedFamily = {this.state.selectedFamily}
                                    selectedSubFamily = {this.state.selectedSubFamily}
                                    collections = {this.state.collections}
                                    ashlinHtsCa={this.state.ashlinHtsCa}
                                    ashlinHtsUs={this.state.ashlinHtsUs}
                                    onChange = {this.handleOnChange}
                                    basicInfo = {this.state.newSKU.basicInfo}
                                    asiCategory={this.state.asiCategory}
                                    selectedSKU={this.state.selectedSKU}
                                    generateUPC = {this.handleGenerateUPC}
                                    calculateTierPrice = {this.handleCalculateTierPrice}
                                    tierCostMatrix = {this.state.tierMatrix}
                                    skuCost = {this.state.skuCost}
                                    skuCostOnchange = {this.handleSkuCostChange}
                                    upDateCostMatrix = {this.handleUpDateCostMatrix}
                                    pricingTierOnchange={this.handlePricingTierOnchange}
                                />
                        }
                        </TabContentItem>
                        {/*<TabContentItem id="newHTSCode"  style={{height:"500px",margin:'30px 0 10px'}}>*/}
                            {/*<NewHTSCode onChange={this.handleOnChange} value={HTS}/>*/}
                        {/*</TabContentItem>*/}
                        {/*<TabContentItem id="newChannelList"  style={{height:"450px",margin:'30px 0 10px'}}>*/}
                            {/*<NewChannelList onChange={this.handleOnChange} data={this.state.newSKU.channelList} />*/}
                        {/*</TabContentItem>*/}
                        <TabContentItem id="newSEOContent" style={{height:"450px",margin:'30px 0 10px'}}>
                            <NewSkuSEO
                                selectedDesign = {this.state.selectedDesign}
                                onChange = {this.handleSEOOnchange}
                                translate = {this.handleTranslateWithField}
                            />
                        </TabContentItem>
                        <TabContentItem id="newImageURL" style={{height:"450px",margin:'30px 0 10px'}}>
                            <ImageUpload
                                designCode={this.state.selectedDesign.designCode}
                                materialCode={this.state.selectedMaterial.materialCode}
                                colorCode={this.state.selectedColor.colorCode}
                                onClick={(e)=>this.handleSubmit(e)}
                                imageURLChange = {this.handleImageURLChange}
                                upc9 = {this.state.newSKU.basicInfo.upc9}
                                upc10 = {this.state.newSKU.basicInfo.upc10}
                            />

                        </TabContentItem>

                    </form>
                </div>
            </div>
        )
    }
}

export default withRouter(CreateNewSKU);