import React, { Component } from 'react';
import matchSorter from "match-sorter";
import ReactTable from "react-table";
import "react-table/react-table.css";
import $ from 'jquery';
import axios from 'axios';
import {
    SubmitItemModel,
    proxyurl,
    submitItemURL,
    changeItemURL,
    headers,
    localSave,
    getSKUURK
} from '../../Utils/requestHelper';
import MainSideBar from '../sideBar/MainSideBar';

import SubmitItemDetailModal from '../Modal/SubmitItemDetailModal';
import {SKUModelMapper} from "../../Utils/sKUModels";

class SubmitSKU extends Component{
    _mounted = false;
    constructor() {
        super();
        this.state = {
            SKULists: [],
            warehouse:'14',
            selected:null,
            isLoading:false,
            singleSubmit:false
        };
    }
    componentDidMount(){
        this._mounted = true;
        this.downloadSKUs();

    }
    //download SKU from ASHLIN database
    downloadSKUs=async()=>{
        const response = await axios.get(getSKUURK);
        const result = response.data.map(i=>SKUModelMapper(i));
        if(this._mounted){
            this.setState({
                SKULists: result,
            });
            localSave('total_SKUs_number',result.length);
            $('#downloadSKUs').hide();
            $('#submitOneSku').show();
            $('#submitSKUs').show();
        }
    };
    componentWillUnmount() {
        this._mounted = false;
    }

    handleDetail=()=>{
        $('#submitItemDetail').modal('show');
    };
    //For future feature, which can set warehouse
    handleWarehouseChange=(event)=>{
        this.setState({warehouse: event.target.value});
    };

    //change an SKU and resubmit to lean supply, bind to SKU detail modal
    handleReSubmit=async(e,obj)=>{
        e.preventDefault();

        let confirmBox = window.confirm(`Do you confirm to submit ${this.state.selected.SKU} to Lean Supply?`);
        if (confirmBox) {
            const data = SubmitItemModel(obj);
            const selected = this.state.selected;
            let submitURL ='';
            let singleSubmit = this.state.singleSubmit;
            if(singleSubmit){
                submitURL = proxyurl+submitItemURL;
                singleSubmit = false;
            }else {
                submitURL = proxyurl+changeItemURL;
            }
            const response = await axios.post(submitURL,data,headers);
            selected.Status = response.data.ResultInfo.resultMsgDetailed;
            this.setState({
                selected,
                singleSubmit
            });
            $('#submitItemDetail').modal('hide');
            return response;
        }



    };
    //Submit all SKU to lean supply
    handleSubmit = async()=>{
        this.setState({isLoading:true});
        const url= proxyurl+submitItemURL;
        const request = this.state.SKULists.map((d,i)=>{
          return this.submitRequest(d,i,url);
       });
        Promise.all(request).then(()=>this.setState({isLoading:false}));
    };
    //Submit a single SKU to lean supply, show the response to UI
    submitRequest = async(obj,index, url)=>{
        const data = SubmitItemModel(obj);
        const response = await axios.post(url,data,headers);
        const lists = this.state.SKULists;
            lists[index].Status = response.data.ResultInfo.resultMsgDetailed;
            this.setState({
                SKULists:lists
            });
        return response;
    };
    //Handle on change event on SKU detail modal
    handleOnChange=(event)=>{
        const target = event.target;
        const name = target.name;
        const value = target.value;
        const selected = this.state.selected;
        selected[name] = value;
        this.setState({
            selected
        })
    };
    handleSingleSubmit = async(e)=>{
        e.preventDefault();
        this.setState({
            selected:{},
            singleSubmit: true
        },()=>{
            $('#submitItemDetail').modal('show');
        });
        //console.log('click');
    };
    render() {

        const { SKULists } = this.state;
        return (
            <div className='container-fluid row'>
                <div className={'col-3'}>
                    <MainSideBar/>
                </div>
                <div className={'col'}>
                {this.state.selected !== null?
                <SubmitItemDetailModal handleReSubmit={this.handleReSubmit} handleOnChange={this.handleOnChange}
                                       {...this.state.selected}/>:null}
                <br />
                <h1 className={"text-center"}>Current SKUs</h1>

                {this.state.isLoading &&<div className={"text-center text-success display-4"}><i className="fa fa-spinner fa-spin"></i> &nbsp; Submitting...</div>}
                <br />
                <hr/>
                <div style={{textAlign: "center"}}>
                    <div className="row">
                        <div className="col-4">

                        <button onClick={this.downloadSKUs} id='downloadSKUs'  className={'btn btn-danger btn-block'}>Download SKUs</button>

                            {/*<div className='row'>*/}
                                {/*<div className='col-4'>*/}
                                    {/*<label htmlFor="warehouseSelect1">Select Warehouse</label>*/}
                                {/*</div>*/}
                                {/*<div className='col-4 '>*/}
                                    {/*<select value={this.state.warehouse} onChange={this.handleWarehouseChange} className="form-control" id="warehouseSelect1">*/}
                                        {/*<option value={'14'}>14</option>*/}
                                        {/*<option value={'10'}>10</option>*/}
                                    {/*</select>*/}
                                {/*</div>*/}
                            {/*</div>*/}
                        </div>
                        <div className="col-4 ml-auto">
                            <button onClick={(e)=>this.handleSingleSubmit(e)} id='submitOneSku' style={{display:'none'}} className={'btn btn-success btn-block'}>Submit One Item</button>
                        </div>
                        <div className="col-4 ml-auto">
                            <button onClick={this.handleSubmit} id='submitSKUs' style={{display:'none'}} className={'btn btn-primary btn-block'}>Submit All Items</button>
                        </div>
                    </div>
                    <ReactTable
                        data={SKULists}
                        filterable
                        defaultFilterMethod={(filter, row) =>
                            String(row[filter.id]) === filter.value}
                        columns={[
                            {
                                Header: "SKU INFO",
                                columns: [
                                    {
                                        Header: "SKU Number",
                                        accessor: "SKU",
                                        filterMethod: (filter, row) =>
                                            row[filter.id].startsWith(filter.value)
                                    },
                                    {
                                        Header: "UPC",
                                        accessor: "UPC",
                                        filterMethod: (filter, rows) =>
                                            matchSorter(rows, filter.value, { keys: ["UPC"] }),
                                        filterAll: true
                                    },
                                    {
                                        Header: "Price",
                                        accessor: "Price"
                                    }
                                ]
                            },
                            {
                                Header: "Status",
                                columns: [
                                    {
                                        Header: "Submit Status",
                                        accessor: "Status",
                                        id: "Status",
                                        Cell: ({ value }) => (<div
                                            style={{
                                                backgroundColor:
                                                    value.length > 15? "yellow": ""
                                            }}
                                        >{value}</div>),
                                        filterMethod: (filter, row) => {
                                            if (filter.value === "all") {
                                                return true;
                                            }
                                            if (filter.value === "Created OK") {
                                                return row[filter.id] === "Created OK";
                                            }
                                            if (filter.value === "Changed OK"){
                                                return row[filter.id] === "Changed OK";
                                            }
                                            return row[filter.id] === "Item already exists.";
                                        },
                                        Filter: ({ filter, onChange }) =>
                                            <select
                                                onChange={event => onChange(event.target.value)}
                                                style={{ width: "100%" }}
                                                value={filter ? filter.value : "all"}
                                            >
                                                <option value="all">Show All</option>
                                                <option value="Created OK">Created OK</option>
                                                <option value="Item already exists.">Item already exists</option>
                                                <option value="Changed OK">Changed OK</option>
                                            </select>
                                    }
                                ]
                            }
                        ]}
                        defaultPageSize={10}
                        className="-striped -highlight"
                        getTrProps={(state, rowInfo) => {
                        return {
                            onClick: (e) => {
                                this.setState({
                                    selected: rowInfo.original
                                },this.handleDetail);
                            }
                        }
                }}
                    />
                </div>
                <br />
                </div>
            </div>
        );
    }
}

export default SubmitSKU;
