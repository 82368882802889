import { message } from "antd";
class ValidationChecker {

    checkString(str, fieldName){
        if (!str||str.length === 0){
            message.warning(`${fieldName} is Invalid!`)
            return false
        }
        return true
    }

    checkObj(obj, fieldName) {
        if (Object.keys(obj).length === 0){
            message.warning(`${fieldName} is empty!`)
            return false
        }
        return true
    }

    checkIsNull(item, fieldName){
        if (!item){
            message.warning(`${fieldName} is empty!`)
            return false
        }
        return true
    }

    checkArray(arry, fieldName) {
        if (arry.length === 0){
            message.warning(`${fieldName} is empty!`)
            return false
        }
        return true
    }

    checkInt(myInt, fieldName, min=undefined, max=undefined){
        let ismyInt = /^\d+$/.test(myInt);
        if (!ismyInt){
            message.warning(`${fieldName} is not number or it is a decimal number!`)
            return false
        }
        myInt = parseInt(ismyInt, 10)
        if (min&&max){
            if (myInt < min || myInt > max){
                return false
            }
        }
        return true
    }

    checkFloat(floatNum, fieldName){
        let isFloat = /^[+-]?\d+(\.\d+)?$/.test(floatNum)
        if (!isFloat){
            message.warning(`${fieldName} is not decimal number!`)
            return false
        }
        return true
    }

}

let validationChecker = new ValidationChecker()
export default validationChecker 