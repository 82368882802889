import React from 'react';
import CheckBox from '../../bootstrapComponent/checkBox';
import SelectInput from '../../bootstrapComponent/selectInput';
const UpdateFamilyModal = ({id,onChange, updateItem, Activate, DeActivate,addKeyword, deleteKeyword,translate,collections,families,updateFamily })=>{

    const ashlinFamilyCode = families.map(i=>i.familyCode);
    return(
        <div className="modal fade" id={id} tabIndex="-1" role="dialog" aria-labelledby="upDateFamilyModalLabel"
             aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h3 className="modal-title" id="upDateFamilyModalLabel" style={{textAlign:'center !important'}}>Update A Family</h3>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <SelectInput  id={'update_ashlin_family'} label={'Product Family'} name={'familyCode'} width={'450px'}
                        style={{fontWeight:'bold'}}     value={ashlinFamilyCode}    onChange={(e)=>onChange(e,'updateFamily')}/>
                        <SelectInput  id={'update_ashlin_collection'} label={'Collections'} name={'pickedCollection'} width={'450px'}
                        style={{fontWeight:'bold'}}     value={collections}  defaultValue={updateFamily.pickedCollection}   onChange={(e)=>onChange(e,'updateFamily')}/>
                        <br/>
                        <div>
                        <div className={'row'} >
                        <div className={'col-2'}>
                        </div>
                        <div className={'col-4'}>
                        <h5 style={{textAlign:'center'}}>ENGLISH</h5>
                        </div>
                        <div className={'col-2'}>
                        <button className={'btn-info'} style={{fontSize:'15px !important'}} onClick={()=>translate('updateFamily')}>TRANSLATE</button>
                        </div>
                        <div className={'col-4'}>
                        <h5 style={{textAlign:'center'}}>FRENCH</h5>
                        </div>
                        </div>
                        <div className={'row'} >
                        <div className={'col-2'}>
                        <h5 style={{textAlign:'center'}}>Short Description</h5>
                        </div>
                        <div className={'col-4'}>
                        <textarea style={{width:'100%'}} value={updateFamily.shortENG} rows="5"
                        onChange={(e)=>onChange(e,'updateFamily')} name={'shortENG'}>
                        </textarea>
                        </div>
                        <div className={'col-2'}>
                        </div>
                        <div className={'col-4'}>
                        <textarea style={{width:'100%'}} value={updateFamily.shortFR} rows="5"
                        onChange={(e)=>onChange(e,'updateFamily')} name={'shortFR'}>
                        </textarea>
                        </div>
                        </div>
                        <div className={'row'} >
                        <div className={'col-2'}>
                        <h5 style={{textAlign:'center'}}>Long Description</h5>
                        </div>
                        <div className={'col-4'}>
                        <textarea style={{width:'100%'}} value={updateFamily.longENG} rows="7"
                        onChange={(e)=>onChange(e,'updateFamily')} name={'longENG'}>
                        </textarea>
                        </div>
                        <div className={'col-2'}>
                        </div>
                        <div className={'col-4'}>
                        <textarea style={{width:'100%'}} value={updateFamily.longFR} rows="7"
                        onChange={(e)=>onChange(e,'updateFamily')} name={'longFR'}>
                        </textarea>
                        </div>
                        </div>
                            <div className={'row justify-content-center'}>
                                <CheckBox  value={updateFamily.active === 1}  id={'update-family-active'}
                                           onChange={(e)=>onChange(e,'updateFamily')} name={'active'} label={'Is this Family Active'}/>
                            </div>
                            <hr/>
                        </div>
                        <div className={'row'}>
                            <button type="button" className="btn btn-primary btn-block" onClick={(e)=>updateItem(e,'updateFamily')}>Update Family</button>
                        </div>
                        <br/>
                        {/*<div className={'row modal-footer'}>*/}
                            {/*<button type="button" className="btn btn-danger col" onClick={(e)=>DeActivate(e,'updateFamily','familyCode')} >Deactivate Family</button>*/}
                            {/*<button type="button" className="btn btn-success col" onClick={(e)=>Activate(e,'updateFamily','familyCode')}>Activate Family</button>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        </div>
    )
};
export default UpdateFamilyModal;