import React from 'react';
import ListItem from './bundled_item_list_item';
import $ from "jquery";
const bundled_item_list = (props)=>{
    return(
        <table className={'table table-bordered'}>
            <thead>
            <tr>
                <th>SKU</th>
                <th>Bundled Items</th>
                <th>
                    <button className={'btn-success'} onClick={()=>{
                        $('#newBundledItem').modal('show');
                    }}>Add Bundled Product</button>
                </th>
            </tr>
            </thead>
            <tbody>
            {props.list.map((item,index)=><ListItem item={item} index={index} key={index}  deleteBundledItem={props.deleteBundledItem}/>)}
            </tbody>
        </table>
    )
};
export default bundled_item_list;
