import React from 'react';
import {
    Modal,
    ModalHeader,
    ModalBody
} from 'reactstrap';

// 8/27/18 this code works, but suffers from "jumpy" horizontal resizing
const OrderStatusDetailModal = (props) => {
    const order = props.order;
    return (
        <Modal isOpen={props.show} toggle={props.handleClose} size='lg' backdrop={true}>
            <ModalHeader>Order Status Details</ModalHeader>
            <ModalBody>
                {order !== null &&
                    <div>
                        <p className="text-center"><span className='font-weight-bold'>Status: &nbsp; </span>{order.OrderStatus.OrderStatus}</p>
                        <form >
                            <hr />
                            <h4 className="text-center">Order Summary</h4>
                            <div className={'row'}>
                                <p className={'col'} style={{ textAlign: 'center' }}><span className="font-weight-bold"> Order Number:</span> {order.OrderHeader.ThirdPartyOrderNo}</p>
                                <p className={'col'} style={{ textAlign: 'center' }}><span className="font-weight-bold"> Customer Order Number:</span>{order.OrderHeader.EndCustomerOrderNo}</p>
                                <p className={'col'} style={{ textAlign: 'center' }}><span className="font-weight-bold"> Order Date:</span> {order.OrderHeader.OrderDate.slice(0, 10)}</p>
                                <p className={'col'} style={{ textAlign: 'center' }}><span className="font-weight-bold"> Channel Name:</span>{order.OrderHeader.MarketplaceCode} </p>
                            </div>
                            <hr />
                            <h4 className="text-center">Buyer / Recipient Info</h4>
                            <div className={'row'}>
                                <div className={'col-4'}>
                                    <h4>Lean Supply Info:</h4>
                                    <p><span className="font-weight-bold "> Warehouse Code:</span> <input className="float-right" value={order.OrderHeader.WarehouseCode} name={'WarehouseCode'} onChange={props.handleOnChange} /></p>
                                    <p><span className="font-weight-bold"> Priority:</span> <input className="float-right" value={order.OrderHeader.Priority} name={'Priority'} onChange={props.handleOnChange} /></p>
                                    <p><span className="font-weight-bold"> Version:</span> <input className="float-right" value={order.OrderHeader.Version} name={'Version'} onChange={props.handleOnChange} /></p>
                                    <p><span className="font-weight-bold"> Order Handle : </span> <input className="float-right" value={order.OrderStatus.OrderHandle} disabled /></p>

                                </div>
                                <div className={'col-8'}>
                                    <h4>Ship To:</h4>
                                    <div className={'row'}>
                                        <div className={'col'}>
                                            <p><span className="font-weight-bold"> Company Name:</span><input className="float-right" value={order.OrderHeader.ShipTo.Name} name={'Name'} onChange={(e) => props.handleOnChange(e, 'ShipTo')} /></p>
                                            <p><span className="font-weight-bold"> Contact Name:</span><input className="float-right" value={order.OrderHeader.ShipTo.ContactName} name={'ContactName'} onChange={(e) => props.handleOnChange(e, 'ShipTo')} /> </p>
                                            <p><span className="font-weight-bold"> Contact Phone:</span><input className="float-right" value={order.OrderHeader.ShipTo.ContactPhone} name={'ContactPhone'} onChange={(e) => props.handleOnChange(e, 'ShipTo')} /> </p>
                                            <p><span className="font-weight-bold"> Contact PhoneExt:</span><input className="float-right" value={order.OrderHeader.ShipTo.ContactPhoneExt} name={'ContactPhoneExt'} onChange={(e) => props.handleOnChange(e, 'ShipTo')} /> </p>
                                            <p><span className="font-weight-bold"> Contact Email:</span><input className="float-right" value={order.OrderHeader.ShipTo.ContactEmail} name={'ContactEmail'} onChange={(e) => props.handleOnChange(e, 'ShipTo')} /> </p>
                                        </div>
                                        <div className={'col'}>
                                            <p><span className="font-weight-bold"> AddressLine1:</span><input className="float-right" value={order.OrderHeader.ShipTo.AddressLine1} name={'AddressLine1'} onChange={(e) => props.handleOnChange(e, 'ShipTo')} /> </p>
                                            <p><span className="font-weight-bold"> AddressLine2:</span><input className="float-right" value={order.OrderHeader.ShipTo.AddressLine2} name={'AddressLine2'} onChange={(e) => props.handleOnChange(e, 'ShipTo')} /> </p>
                                            <p><span className="font-weight-bold"> AddressLine3:</span><input className="float-right" value={order.OrderHeader.ShipTo.AddressLine3} name={'AddressLine3'} onChange={(e) => props.handleOnChange(e, 'ShipTo')} /> </p>
                                            <p><span className="font-weight-bold"> City:</span><input className="float-right" value={order.OrderHeader.ShipTo.City} name={'City'} onChange={(e) => props.handleOnChange(e, 'ShipTo')} /> </p>
                                            <p><span className="font-weight-bold"> State:</span><input className="float-right" value={order.OrderHeader.ShipTo.State} name={'State'} onChange={(e) => props.handleOnChange(e, 'ShipTo')} /> </p>
                                            <p><span className="font-weight-bold"> Country:</span><input className="float-right" value={order.OrderHeader.ShipTo.CountryCode} name={'CountryCode'} onChange={(e) => props.handleOnChange(e, 'ShipTo')} /> </p>
                                            <p><span className="font-weight-bold"> Zip Code:</span><input className="float-right" value={order.OrderHeader.ShipTo.Zip} name={'Zip'} onChange={(e) => props.handleOnChange(e, 'ShipTo')} /> </p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <hr />
                            <h4 className="text-center">Order Items Detail</h4>
                            {
                                order.OrderDetail.map((o, i) => {
                                    const id = `submitted_order_detail_${i}`;
                                    return (
                                        <div key={i} id={id} className={'row'}>
                                            <div className={'col'}><span className="font-weight-bold"> Item SKU: </span><input value={o.ItemCode} name={'ItemCode'} onChange={(e) => props.handleOnChange(e, 'OrderDetail', i)} /></div>
                                            <div className={'col'}><span className="font-weight-bold"> Item Name: </span>{o.ItemName}</div>
                                            <div className={'col'}><span className="font-weight-bold"> Item Qty: </span><input value={o.ItemQty} name={'ItemQty'} onChange={(e) => props.handleOnChange(e, 'OrderDetail', i)} /></div>
                                            <div className={'col'}><span className="font-weight-bold"> Item PO #: </span>{o.poNo}</div>
                                            {order.OrderStatus.OrderStatus === 'Canceled' && <button className='btn btn-danger' onClick={(e) => props.handleAddItem(e, o, id)}>Add</button>}
                                        </div>
                                    )
                                })
                            }
                            <br />
                            <hr />
                            <div className="form-group">
                                <label htmlFor="comment">Comment</label>
                                <input type="text" className="form-control" id="comment"
                                    value={order.OrderHeader.Comment} name={'Comment'}
                                    onChange={props.handleOnChange} />
                            </div>
                            <br />
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={props.handleClose}>Close</button>
                                {order.OrderStatus.OrderStatus === 'Canceled' && <button type="submit" className="btn btn-danger" onClick={props.handleReIssueOrder}>ReIssue Order</button>}
                                {order.OrderStatus.OrderStatus !== 'Canceled' && <button type="submit" className="btn btn-danger" onClick={props.handleCancelOrder}>Cancel Order</button>}
                            </div>
                        </form>
                    </div>}
            </ModalBody>
        </Modal>
    );
};

/* 8/27/18 old
const OrderStatusDetailModal = (props) => {
    return (
        <div className="modal fade" id="OrderStatusDetailModal" tabIndex="-1" role="dialog" aria-labelledby="OrderStatusDetailModalLabel"
            aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="OrderStatusDetailModalLabel">Order Status Details</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <p className="text-center"><span className='font-weight-bold'>Status: &nbsp; </span>{props.OrderStatus.OrderStatus}</p>
                        <form >
                            <hr />
                            <h4 className="text-center">Order Summary</h4>
                            <div className={'row'}>
                                <p className={'col'} style={{ textAlign: 'center' }}><span className="font-weight-bold"> Order Number:</span> {props.OrderHeader.ThirdPartyOrderNo}</p>
                                <p className={'col'} style={{ textAlign: 'center' }}><span className="font-weight-bold"> Customer Order Number:</span>{props.OrderHeader.EndCustomerOrderNo}</p>
                                <p className={'col'} style={{ textAlign: 'center' }}><span className="font-weight-bold"> Order Date:</span> {props.OrderHeader.OrderDate.slice(0, 10)}</p>
                                <p className={'col'} style={{ textAlign: 'center' }}><span className="font-weight-bold"> Channel Name:</span>{props.OrderHeader.MarketplaceCode} </p>
                            </div>
                            <hr />
                            <h4 className="text-center">Buyer / Recipient Info</h4>
                            <div className={'row'}>
                                <div className={'col-4'}>
                                    <h4>Lean Supply Info:</h4>
                                    <p><span className="font-weight-bold "> Warehouse Code:</span> <input className="float-right" value={props.OrderHeader.WarehouseCode} name={'WarehouseCode'} onChange={props.handleOnChange} /></p>
                                    <p><span className="font-weight-bold"> Priority:</span> <input className="float-right" value={props.OrderHeader.Priority} name={'Priority'} onChange={props.handleOnChange} /></p>
                                    <p><span className="font-weight-bold"> Version:</span> <input className="float-right" value={props.OrderHeader.Version} name={'Version'} onChange={props.handleOnChange} /></p>
                                    <p><span className="font-weight-bold"> Order Handle : </span> <input className="float-right" value={props.OrderStatus.OrderHandle} disabled /></p>

                                </div>
                                <div className={'col-8'}>
                                    <h4>Ship To:</h4>
                                    <div className={'row'}>
                                        <div className={'col'}>
                                            <p><span className="font-weight-bold"> Company Name:</span><input className="float-right" value={props.OrderHeader.ShipTo.Name} name={'Name'} onChange={(e) => props.handleOnChange(e, 'ShipTo')} /></p>
                                            <p><span className="font-weight-bold"> Contact Name:</span><input className="float-right" value={props.OrderHeader.ShipTo.ContactName} name={'ContactName'} onChange={(e) => props.handleOnChange(e, 'ShipTo')} /> </p>
                                            <p><span className="font-weight-bold"> Contact Phone:</span><input className="float-right" value={props.OrderHeader.ShipTo.ContactPhone} name={'ContactPhone'} onChange={(e) => props.handleOnChange(e, 'ShipTo')} /> </p>
                                            <p><span className="font-weight-bold"> Contact PhoneExt:</span><input className="float-right" value={props.OrderHeader.ShipTo.ContactPhoneExt} name={'ContactPhoneExt'} onChange={(e) => props.handleOnChange(e, 'ShipTo')} /> </p>
                                            <p><span className="font-weight-bold"> Contact Email:</span><input className="float-right" value={props.OrderHeader.ShipTo.ContactEmail} name={'ContactEmail'} onChange={(e) => props.handleOnChange(e, 'ShipTo')} /> </p>
                                        </div>
                                        <div className={'col'}>
                                            <p><span className="font-weight-bold"> AddressLine1:</span><input className="float-right" value={props.OrderHeader.ShipTo.AddressLine1} name={'AddressLine1'} onChange={(e) => props.handleOnChange(e, 'ShipTo')} /> </p>
                                            <p><span className="font-weight-bold"> AddressLine2:</span><input className="float-right" value={props.OrderHeader.ShipTo.AddressLine2} name={'AddressLine2'} onChange={(e) => props.handleOnChange(e, 'ShipTo')} /> </p>
                                            <p><span className="font-weight-bold"> AddressLine3:</span><input className="float-right" value={props.OrderHeader.ShipTo.AddressLine3} name={'AddressLine3'} onChange={(e) => props.handleOnChange(e, 'ShipTo')} /> </p>
                                            <p><span className="font-weight-bold"> City:</span><input className="float-right" value={props.OrderHeader.ShipTo.City} name={'City'} onChange={(e) => props.handleOnChange(e, 'ShipTo')} /> </p>
                                            <p><span className="font-weight-bold"> State:</span><input className="float-right" value={props.OrderHeader.ShipTo.State} name={'State'} onChange={(e) => props.handleOnChange(e, 'ShipTo')} /> </p>
                                            <p><span className="font-weight-bold"> Country:</span><input className="float-right" value={props.OrderHeader.ShipTo.CountryCode} name={'CountryCode'} onChange={(e) => props.handleOnChange(e, 'ShipTo')} /> </p>
                                            <p><span className="font-weight-bold"> Zip Code:</span><input className="float-right" value={props.OrderHeader.ShipTo.Zip} name={'Zip'} onChange={(e) => props.handleOnChange(e, 'ShipTo')} /> </p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <hr />
                            <h4 className="text-center">Order Items Detail</h4>
                            {
                                props.OrderDetail.map((o, i) => {
                                    const id = `submitted_order_detail_${i}`;
                                    return (
                                        <div key={i} id={id} className={'row'}>
                                            <div className={'col'}><span className="font-weight-bold"> Item SKU: </span><input value={o.ItemCode} name={'ItemCode'} onChange={(e) => props.handleOnChange(e, 'OrderDetail', i)} /></div>
                                            <div className={'col'}><span className="font-weight-bold"> Item Name: </span>{o.ItemName}</div>
                                            <div className={'col'}><span className="font-weight-bold"> Item Qty: </span><input value={o.ItemQty} name={'ItemQty'} onChange={(e) => props.handleOnChange(e, 'OrderDetail', i)} /></div>
                                            <div className={'col'}><span className="font-weight-bold"> Item PO #: </span>{o.poNo}</div>
                                            {props.OrderStatus.OrderStatus === 'Canceled' && <button className='btn btn-danger' onClick={(e) => props.handleAddItem(e, o, id)}>Add</button>}
                                        </div>
                                    )
                                })
                            }
                            <br />
                            <hr />
                            <div className="form-group">
                                <label htmlFor="comment">Comment</label>
                                <input type="text" className="form-control" id="comment"
                                    value={props.OrderHeader.Comment} name={'Comment'}
                                    onChange={props.handleOnChange} />
                            </div>
                            <br />
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                {props.OrderStatus.OrderStatus === 'Canceled' && <button type="submit" className="btn btn-danger" onClick={props.handleReIssueOrder}>ReIssue Order</button>}
                                {props.OrderStatus.OrderStatus !== 'Canceled' && <button type="submit" className="btn btn-danger" onClick={props.handleCancelOrder}>Cancel Order</button>}
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    );
};
*/

export default OrderStatusDetailModal;