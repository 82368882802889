export const LeanSupplyModelMapper = (obj) => {
  //const detail = getOrderDetail(obj.OrderDetail);
  let comment =
    obj.OrderHeader.ShipTo.AddressLine2.includes("Walmart") ||
    obj.OrderHeader.ShipTo.AddressLine2.includes("Supercentre")
      ? "Please place a YELLOW STICKER on OUTSIDE OF PARCEL. "
      : "";
  if (obj.OrderDelivery.CarrierCode === "LMEF_ND") {
    comment =
      "Courier: Loomis Express | Service: Next Day | Billing: 3rd Party | Acc#RN2685 |  Special Requirements: No Signature Required must be selected. Please place a YELLOW STICKER on OUTSIDE OF PARCEL. ";
  } else if (obj.OrderDelivery.CarrierCode === "LMEF_G2") {
    comment =
      "Courier: Loomis Express | Service: Basic Service | Billing: 3rd Party | Acc#RN2685 |  Special Requirements: No Signature Required must be selected. Please place a YELLOW STICKER on OUTSIDE OF PARCEL. ";
  }
  const OrderHeader = obj.OrderHeader;
  OrderHeader.OrderDate = OrderDateHelper(obj.OrderHeader.OrderDate);
  OrderHeader.WarehouseCode = "14";
  OrderHeader.Comment = comment;
  //console.log(obj.OrderDelivery);
  // Ship To Address in Ontario or Quebec – Use Canpar
  // Ship to Address Anywhere else is Canada – Use Loomis
  const useCPforWalmart = localStorage.getItem("useCPforWalmart");
  //console.log("useCPforWalmart===\"false\"",useCPforWalmart==="false")
  if (
    useCPforWalmart === "false" &&
    !obj.OrderHeader.ShipTo.AddressLine1.toUpperCase().includes("BOX")
  ) {
    if (
      obj.ChannelName === "Walmart Canada" &&
      (obj.OrderDelivery.CarrierCode === "CG" ||
        obj.OrderDelivery.CarrierCode === "CG_01" ||
        obj.OrderDelivery.CarrierCode === "CG_SE" ||
        obj.OrderDelivery.CarrierCode === "CG_SE_01")
    ) {
      if (
        OrderHeader.ShipTo.State === "ON" ||
        OrderHeader.ShipTo.State === "QC"
      ) {
        if (
          obj.OrderDelivery.CarrierCode === "CG_SE" ||
          obj.OrderDelivery.CarrierCode === "CG_SE_01" ||
          obj.OrderDelivery.CarrierCode === "CNPL_SE" ||
          obj.OrderDelivery.CarrierCode === "LMEF_ND"
        ) {
          obj.OrderDelivery.CarrierCode = "CNPL_SE";
          obj.OrderDelivery.ServiceCode = "Canpar - 2nd Day";
          obj.OrderDelivery.ChargeToAccount = "42092770";
        } else {
          obj.OrderDelivery.CarrierCode = "CNPL";
          obj.OrderDelivery.ServiceCode = "Canpar";
          obj.OrderDelivery.ChargeToAccount = "42092770";
        }
      } else {
        // Ship to Address Anywhere else is Canada – Use Loomis
        if (
          obj.OrderDelivery.CarrierCode === "CG_SE" ||
          obj.OrderDelivery.CarrierCode === "CG_SE_01" ||
          obj.OrderDelivery.CarrierCode === "CNPL_SE" ||
          obj.OrderDelivery.CarrierCode === "LMEF_ND"
        ) {
          obj.OrderDelivery.CarrierCode = "LMEF_ND";
          obj.OrderDelivery.ServiceCode = "Loomis Express - Next Day";
          obj.OrderDelivery.ChargeToAccount = "RN2685";
        } else {
          obj.OrderDelivery.CarrierCode = "LMEF_G2";
          obj.OrderDelivery.ServiceCode = "Loomis Express - Basic Service";
          obj.OrderDelivery.ChargeToAccount = "RN2685";
        }
      }
    }
  }
  if (obj.ChannelName === "Mirakl Best Buy Canada Marketplace") {
    OrderHeader.OrderDate = OrderHeader.OrderDate.replace("Z", "");
  }
  return {
    ChannelId: obj.ChannelId,
    ChannelName: obj.ChannelName,
    OrderHeader: OrderHeader,
    OrderDetail: obj.OrderDetail,
    OrderDelivery: obj.OrderDelivery,
  };
};

const OrderDateHelper = (str) => {
  return str.replace("T", " ");
};
const TotolPriceHelper = (str) => {
  return `$${str}`;
};
const StapleShipAddress = (obj) => {
  return {
    Name: obj.ship_to_company_name,
    AddressLine1: obj.ship_to_line1,
    AddressLine2: obj.ship_to_line2,
    AddressLine3: obj.ship_to_line3,
    City: obj.ship_to_city,
    State: obj.ship_to_state,
    CountryCode: obj.ship_to_country_code,
    ContactName: obj.ship_to_name,
    ContactPhone: obj.ship_to_telephone,
    ContactPhoneExt: obj.ship_to_party_telephone_ext,
    ContactEmail: obj.ship_to_email,
    Zip: obj.ship_to_postalcode,
  };
};

// 8/20/18 no longer needed; this also impedes maintenance when the back end sourced model is updated
export const SubmittedOrderModel = (obj) => {
  return {
    leanSupplyOrderId: obj.LeanSupplyOrderId,
    channelOrderId: obj.ChannelOrderId,
    orderHandle: obj.OrderHandle,
    orderStatus: obj.OrderStatus,
    reissueNumber: obj.ReissueNumber,
    channelId: obj.ChannelId,
    ERPOrderId: obj.ERPOrderId,
    FulfillmentStatus: obj.FulfillmentStatus,
  };
};

export const StapleOrderModelMapper = (obj) => {
  const detail = obj.po_line_number.map((item, index) => {
    return {
      OrderLine: obj.po_line_number[index],
      ItemCode: obj.vendor_sku[index],
      ItemQty: obj.open_quantity[index],
      ItemName: obj.product_name[index],
      ItemDescription: obj.product_name[index],
      uofm: "EACH",
      poNo: obj.po_li_id[index],
    };
  });
  const comment =
    obj.ship_to_line3.includes("Walmart") ||
    obj.ship_to_line3.includes("Supercentre")
      ? "Please attach Ashlin yellow label for Walmart customer pick up!!!"
      : "none";

  return {
    OrderHeader: {
      OrderNumber: obj.po_id,
      EndCustomerOrderNo: obj.po_number,
      ThirdPartyOrderNo: obj.channel_name.toUpperCase(),
      OrderDateCode: "DL",
      OrderDate: OrderDateHelper(obj.OrderDate),
      Comment: comment,
      ShipTo: StapleShipAddress(obj),
      ShipFrom: "WAREHOUSE",
      WarehouseCode: "14",
      OrderStatus: "NEW",
      OrderType: "",
      Priority: "",
      Version: "",
      TotalPrice: TotolPriceHelper(obj.total_po_cost),
    },
    OrderDetail: detail,
    OrderDelivery: {
      ServiceType: "STD",
      FreightCharges: "STD",
    },
  };
};
