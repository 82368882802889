import React from 'react';
import {
    ListGroup,
    ListGroupItem,
    Modal,
    ModalHeader,
    ModalBody
} from 'reactstrap';

const getOrderDispatchResultsList = (orderDispatchResults) => {
    //console.log(orderDispatchResults);
    if (orderDispatchResults !== null && orderDispatchResults !== undefined) {
        const listGroupItems = orderDispatchResults.map((orderDispatchResult) =>
            <ListGroupItem key={orderDispatchResult.LeanSupplyOrderDispatchResultId}>
                {orderDispatchResult.Message}
            </ListGroupItem>);
        return listGroupItems;
    }
    return null;
};

// displays a modal screen showing all of the results from the previous "Save Order Statuses" operation
const OrderDispatchResultsModal = (props) => {
    return (
        <Modal isOpen={props.show} toggle={props.handleClose} size='lg' backdrop={true}>
            <ModalHeader>Order Dispatch Results</ModalHeader>
            <ModalBody>
                <ListGroup>
                    {getOrderDispatchResultsList(props.orderDispatchResults)}
                </ListGroup>
            </ModalBody>
        </Modal>
    );
};

export default OrderDispatchResultsModal;