import React, { Component } from 'react';
import ReactTable from "react-table";
import "react-table/react-table.css";
import { getBlanceByWarehouseCode } from '../../Utils/requestHelper';
import MainSideBar from '../sideBar/MainSideBar';
class LeanSupplyInventory extends Component{
    _mounted = false;
    constructor() {
        super();
        this.state = {
            leanSupplyInventory: [],
            warehouse:'14',
            isLoading:true,
        };
    }
    componentDidMount(){
        this._mounted = true;
        this.getInventory();
    }

    getInventory=async()=>{
        const leanSupplyInventory = await getBlanceByWarehouseCode(this.state.warehouse);
        this.setState({
            leanSupplyInventory,
            isLoading:false,
        });
    };
    componentWillUnmount() {
        this._mounted = false;
    }

    render() {
        const { leanSupplyInventory } = this.state;
        return (
            <div className='container-fluid row'>
                <div className={'col-3'}>
                    <MainSideBar/>
                </div>
                <div className={'col'}>
                    <br />
                    <br />
                    <h1 className={"text-center"}>Lean Supply Inventory</h1>
                    {this.state.isLoading &&<div className={"text-center text-success display-4"}><i className="fa fa-spinner fa-spin"></i> &nbsp; Downloading Data...</div>}
                    <hr/>
                    <div style={{textAlign: "center"}}>
                        <ReactTable
                            data={leanSupplyInventory}
                            filterable
                            defaultFilterMethod={(filter, row) =>
                                String(row[filter.id]) === filter.value}
                            columns={[
                                {
                                    Header: "SKU Number",
                                    accessor: "sku",
                                    filterMethod: (filter, row) =>
                                        row[filter.id].startsWith(filter.value)
                                },
                                {
                                    Header: "Quantity on Hand",
                                    accessor: "qty",
                                    Cell: ({ value }) =>{
                                        let backGroundColor;
                                        const stock = parseInt(value);
                                        if(stock === 0){
                                            backGroundColor = 'red'
                                        }else if(stock < 5){
                                            backGroundColor = 'yellow'
                                        }else{
                                            backGroundColor = ''
                                        }

                                        return(<div
                                        style={{
                                            backgroundColor:backGroundColor
                                        }}
                                         >{value}</div>)
                                        },
                                    }
                            ]}
                            defaultPageSize={10}
                            className="-striped -highlight"
                        />
                    </div>
                    <br />
                </div>
            </div>
        );
    }
}
export default LeanSupplyInventory;
