import React from 'react';
const discount_detail_list_item = ({item,channelOnSelect,index,discountPriceOnchange,discountPercentage})=>{
    if(item.discountPrice === undefined){
        item.discountPrice = (item.originalPrice * ( 100-discountPercentage ) / 100 ).toFixed(2);
    }
    return(
        <tr>
            <td>{item.sku}</td>
            <td>{item.originalPrice}</td>
            <td>
                <input value={item.discountPrice} name={'discountPrice'} onChange={(e)=>discountPriceOnchange(e,index)}/>
            </td>
            <td>
                {item.detail.map((d,i)=>{
                    if(d.isChecked === undefined)
                        d.isChecked = true;
                    return <span key={i}>&nbsp;<input type={'checkbox'} checked={d.isChecked} onChange={(e)=>channelOnSelect(e,index,i)}/>{d.ChannelName}&nbsp;</span>
                })}
            </td>
        </tr>
    )};

export default discount_detail_list_item;
