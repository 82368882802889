import React,{Component} from 'react';
import AddMaterialModal from './addMaterialModal';
import UpdateMaterialModal from './updateMaterialModal';
import { legacyMaterialsURL, legacyMaterialsModelMapper } from '../../../Utils/ashlinDesign';
import axios from 'axios';
import { proxyurl } from '../../../Utils/requestHelper';
class ProductMaterials extends Component{
    constructor(props){
        super(props);
        this.state={
            asiMaterials:[],
            ashlinMaterials:[]
        }
    }
    handleGetMaterial = async () => {
        const asiResponse = await axios.get(proxyurl+'https://sandbox-productservice.asicentral.com/api/v5/lookup/materials');
        const ashlinResponse = await axios.get(legacyMaterialsURL);
        const ashlinMaterials = ashlinResponse.data.map(material=>legacyMaterialsModelMapper(material));
        Promise.all([asiResponse,ashlinResponse]).then(()=>{
            this.setState({
                asiMaterials:asiResponse.data.materials,
                ashlinMaterials:ashlinMaterials
            })
        })
    };
    render(){
        return(
            <div className='container-fluid'>
                <br/>
                <h2 className={'text-secondary'} style={{textAlign:'center'}}>Manager Materials</h2>
                <br/>
                <br/>
                <div className={'row justify-content-center'}>
                    <button className={' btn-primary '} style={{borderRadius:'50%',width:"200px",height:"200px",fontSize:'30px'}} data-toggle="modal" data-target="#add_new_material" onClick={this.handleGetMaterial} >Add Material</button>
                    <AddMaterialModal id={'add_new_material'}   ashlinMaterials={this.state.ashlinMaterials}
                          addMaterial={this.props.addMaterial} materials = {this.state.asiMaterials}
                          onChange={this.props.onChange} addItem = {this.props.addItem}  translate={this.props.translate}
                          Activate={this.props.Activate} DeActivate={this.props.DeActivate}/>

                    <div className={'col-1'}> </div>
                    <button className={'btn-primary '} style={{borderRadius:'50%',width:"200px",height:"200px", fontSize:'30px'}} data-toggle="modal" data-target="#update_material" onClick={this.handleGetMaterial}>Update Material</button>
                    <UpdateMaterialModal id={'update_material'}
                         asiMaterials = {this.state.asiMaterials} updateMaterial={this.props.updateMaterial} ashlinMaterials={this.state.ashlinMaterials}
                         addItem = {this.props.addItem} onChange={(a,b)=>this.props.onChange(a,b,this.state.ashlinMaterials)}
                         Activate={this.props.Activate} DeActivate={this.props.DeActivate}  translate={this.props.translate}
                         updateItem={this.props.updateItem} addKeyword={this.props.addKeyword} deleteKeyword={this.props.deleteKeyword}
                    />
                </div>
            </div>
        )
    }
}

export default ProductMaterials;