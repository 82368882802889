import React from 'react';
const  BulkModifyProductDetailModal = ({item, onChange,addToList,removeFromList,modifiedList,handleRestSkuDetail})=>{
    const isAdded = modifiedList.findIndex(i=>i.Sku === item.Sku);
    return(
        <div className="modal fade" id="BulkModifyProductDetailModal" tabIndex="-1" role="dialog"
             aria-labelledby="BulkModifyProductDetailTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title text-center" id="BulkModifyProductDetailTitle">Product Information Detail</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleRestSkuDetail}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className={'row'}>
                            <div className={'col'}>
                                <span className='font-weight-bold '>SKU: </span>{item.Sku === undefined? "": item.Sku}
                            </div>
                            <div className={'col'}>
                                <span className='font-weight-bold '>Brand: </span>{item.Brand === undefined? "": item.Brand}
                            </div>
                            <div className={'col'}>
                                <span className='font-weight-bold '>UPC: </span>{item.Upc === undefined? "": item.Upc }
                            </div>
                        </div>
                        <hr/>
                        <div className={'row'}>
                            <div className={'col'}>
                                <span className='font-weight-bold '>Material: </span>{item.Material === undefined? "": item.Material }
                            </div>
                            <div className={'col'}>
                                <span className='font-weight-bold '>Color: </span>{item.Color === undefined? "": item.Color }
                            </div>
                            <div className={'col'}>
                                <span className='font-weight-bold '>Gender: </span>{item.Gender === undefined? "": item.Gender}
                            </div>
                        </div>
                        <hr/>
                        <div className={'row'}>
                            <div className={'col'}>
                                <span className='font-weight-bold '>Product Name: </span><input value={item.ProductName === undefined? "": item.ProductName} name='ProductName' onChange={onChange} style={{width:'100%'}}/>
                            </div>
                            <div className={'col-3'}>
                                <span className='font-weight-bold '>Price: </span><input value={item.Price === undefined? "": item.Price} name='Price' onChange={onChange}/>
                            </div>
                        </div>
                        <hr/>
                        <span className='font-weight-bold '>Long Description: </span>{item.ExtendedDescription === undefined? "": item.ExtendedDescription}
                        <hr/>
                        { item.Urls !==undefined &&
                        [...item.Urls.HostingerUrls.MAIN,...item.Urls.HostingerUrls.BASE].map((url,index)=>{
                            return <img src={url} key={index} style={{width:'250px',height:'250px'}} className={'col'} />
                        })
                        }
                    </div>
                    <div className="modal-footer row">
                        { isAdded !== -1 &&  <button type="button" className="btn btn-secondary col" data-dismiss="modal" onClick={removeFromList}>Remove fromL Modified List</button>}
                        {isAdded === -1 && <button type="button" className="btn btn-danger col" data-dismiss="modal" onClick={addToList}>Add to Modified List</button> }
                    </div>
                </div>
            </div>
        </div>
    )

};
export default BulkModifyProductDetailModal;
