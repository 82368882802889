import React, { Component } from 'react';
import MainSideBar from '../sideBar/MainSideBar';
import {
    getDistinctSku,
    postSkuChannelListing,
    searchChannelSKU,
    postProductPromotionan,
    sendEmail,getPromotionEndProducts,getSKU,getChannelSKU,putdateProductPromotion
} from '../../Utils/ashlinDesign';
import DiscountSkuList from './components/discount_sku_list';
import DiscountDetailList from './components/discount_detail_list';
import {getAsiProduct, getAuth, getCredentials} from "../../Utils/asi";
import {
    calculateBulKPrice,
    filterBulkPriceRule,
    findProductById,
    getStoreInfo,
    updateProduct,
    getBulkPricingRules,
    updateBulkPricingRule
} from "../../Utils/bigCommerce";
import DatePicker from 'react-datepicker';
import async from "async";
import {getBestBuyOffer, updateOffers} from "../../Utils/bestbuyMarketplace";
import { WalmartDiscountMapper,WalmartDiscount } from "../../Utils/walmartMarketplace";


const ENABLE_BIGCOMMERCE_ASHLIN_DISCOUNT = true;
const ENABLE_WALMART_CA_MARKETPLACE_DISCOUNT = true;
const ENABLE_BESTBUY_CA_MARKETPLACE_DISCOUNT = true;
class BulkDiscount extends Component{
    _mounted = false;
    constructor(){
        super();
        this.state={
            isDownLoading:true,
            isUpLoading:false,
            skuWholeList:[],
            skuShownList:[],
            searchBox:"",
            skuUpdateList:[],

            discountStartDate:null,
            discountEndDate:null,
            discountPercentage:0,

            bigCommerceAshlinUploading:false,
            walmartCaMarketplaceUploading:false,
            bestbuyCaMarketplaceUploading:false
        }
    }
    componentDidMount(){
        this._mounted = true;
        this.loadData();
    };
    loadData = async()=>{
        const list = await getDistinctSku();
        const skuWholeList = list.map((item,index)=>{
           return {
               sku:item.Sku,
               isSelected:false,
               index,
               detail:item.Details
           }
        });
        if(this._mounted) {
            this.setState({
                isDownLoading:false,
                skuWholeList,
                skuShownList:skuWholeList
            });
        }
    };
    componentWillUnmount() {
        this._mounted = false;
    }


    handleStateOnchange=(e)=>{
        const value = e.target.value;
        const name = e.target.name;
        this.setState({
            [name]:value
        })
    };
    handleSkuOnSelect=async(e,wholeIndex,shownIndex,sku)=>{

        const isSelected = e.target.checked;

        const skuWholeList = this.state.skuWholeList;
        const skuShownList = this.state.skuShownList;
        const skuUpdateList = this.state.skuUpdateList;

        // skuWholeList[wholeIndex].isSelected = isSelected;
        // skuShownList[shownIndex].isSelected = isSelected;

        if(isSelected){
            const toUpdate = skuWholeList.find(item=>item.sku === sku);
            const bigCommerceIndex = toUpdate.detail.findIndex(item=>item.ChannelName === "BigCommerce Ashlin");

            if(bigCommerceIndex === -1){
                const searchSkus = await searchChannelSKU("ASI",sku);
                if(searchSkus.length > 0){
                    const XID = searchSkus[0].MerchanSku;
                    const credentials = await getCredentials();
                    const token = await getAuth(credentials);
                    const asiProduct = await getAsiProduct(XID,token);
                    const price = asiProduct.PriceGrids.find(p=>p.Description.includes("Blank Pricing, 20-25 business days"));
                    const originalPrice = price !== undefined ? ((price.Prices[0].ListPrice)/0.85).toFixed(2):0;
                    if(originalPrice !== 0){
                        skuWholeList[wholeIndex].isSelected = isSelected;
                        skuShownList[shownIndex].isSelected = isSelected;
                        toUpdate.originalPrice = originalPrice;
                        skuUpdateList.push(toUpdate);
                    }else {
                        alert("Product price missing on ASI.");
                    }
                }else{
                    alert("Could not find product price on BigCommerce Ashlin or ASI.");
                }
            }else{
                const bigCommerceAshlinMerchanSku = toUpdate.detail[bigCommerceIndex].MerchanSku.split("+")[0];
                try{
                    const storeInfo =await getStoreInfo("BigCommerce Ashlin");
                    const findProductRes = await findProductById (storeInfo,bigCommerceAshlinMerchanSku);
                    toUpdate.originalPrice = (findProductRes.data.data.price / 0.85).toFixed(2);
                    skuWholeList[wholeIndex].isSelected = isSelected;
                    skuShownList[shownIndex].isSelected = isSelected;
                    skuUpdateList.push(toUpdate);
                }catch (e) {
                    alert("Could not find product on BigCommerce Ashlin");
                }
            }
        }else{
            const toRemoveIndex = skuUpdateList.findIndex(item=>item.sku === sku);
            skuWholeList[wholeIndex].isSelected = isSelected;
            skuShownList[shownIndex].isSelected = isSelected;
            skuUpdateList.splice(toRemoveIndex,1)
        }
        this.setState({
            skuWholeList,
            skuShownList,
            skuUpdateList
        })

    };
    handleChannelOnSelect=(e,ListIndex,ItemIndex)=>{
        const isChecked = e.target.checked;
        const skuUpdateList = this.state.skuUpdateList;
        skuUpdateList[ListIndex].detail[ItemIndex].isChecked = isChecked;
        this.setState({
            skuUpdateList
        })
    };
    handleSearchOnchange=(e)=>{
        const value = e.target.value;
        const name = e.target.name;
        const skuShownList = this.state.skuWholeList.filter(item=>item.sku.toUpperCase().includes(value.toUpperCase()));
        this.setState({
            [name]:value,
            skuShownList
        })
    };

    handleChangeStartDate=(date)=>{
        this.setState({
            discountStartDate:date
        });
    };
    //When use the date, apply.format('YYYY-MM-DD')
    handleChangeEndDate=(date)=>{
        this.setState({
            discountEndDate:date
        });
    };
    handleApplyDiscount=()=>{
        //console.log(this.state.skuUpdateList)
        const d = new Date(new Date().toString().split('GMT')[0]+' UTC').toISOString();
        const last = d.slice(0,19);
        const skuUpdateList = this.state.skuUpdateList;
        const BigCommerceAshlin=[];
        const BestBuyMarketplace = [];
        const WalmartCaMarketplace = [];

        async.mapSeries(skuUpdateList,async (data,callback)=>{
            let value = null;
            data.detail.map(d=>{
                const ashlinData = {
                    "StartDate": this.state.discountStartDate.format('YYYY-MM-DD'),
                    "EndDate": this.state.discountEndDate.format('YYYY-MM-DD'),
                    "Sku":d.Sku,
                    "Status":"NEW",
                    "ChannelName": d.ChannelName,
                    "OriginalPrice":data.originalPrice,
                    "DiscountPrice":data.discountPrice,
                    "LastModifiedDate":last
                };
                if(d.ChannelName === "BigCommerce Ashlin" && d.isChecked){
                    BigCommerceAshlin.push(ashlinData);
                    d.DiscountPrice = data.discountPrice;
                    value = d;
                }else if(d.ChannelName === "BestBuy" && d.isChecked){
                    BestBuyMarketplace.push(ashlinData)
                }else if(d.ChannelName === "WalmartCa" && d.isChecked){
                    WalmartCaMarketplace.push(ashlinData)
                }else{

                }
            });
            await setTimeout(()=>{
                return callback(null,value);
            },0);
        },async(err, BigCommerData) => {


            if(ENABLE_BIGCOMMERCE_ASHLIN_DISCOUNT && BigCommerceAshlin.length >0){
                this.setState({
                    bigCommerceAshlinUploading:true
                },async()=>{
                    const storeInfo =await getStoreInfo("BigCommerce Ashlin");
                    async.mapSeries(BigCommerData,async (data,callback)=>{
                        try{
                            if(data!==null){
                                //Update product price on BigCommerce
                                const bigCommerceAshlinID = (data.MerchanSku.split("+"))[0];
                                const bigCommerceProduct = (await findProductById(storeInfo,bigCommerceAshlinID)).data.data;
                                const price = (data.DiscountPrice * 0.85).toFixed(2);
                                const bulkPricingRules = (await getBulkPricingRules(storeInfo,bigCommerceAshlinID)).data.data;
                                const updateBulkRule = await bulkPricingRules.map(async(rule,index)=>{
                                    const discountPrice = parseFloat(price);
                                    if(rule.amount > discountPrice ){
                                        rule.amount = discountPrice - index*0.01;
                                        return await updateBulkPricingRule(storeInfo,bigCommerceAshlinID,rule.id,rule);
                                    }
                                });
                                Promise.all(updateBulkRule).then(async()=>{
                                    bigCommerceProduct.sale_price = price;
                                    return  await updateProduct(storeInfo,bigCommerceAshlinID,bigCommerceProduct)
                                });
                            }
                            await setTimeout(()=>{
                                return callback(null,null);
                            },3000);
                        }catch (e) {
                            console.log(`${data.Sku} not found on BigCommerce Ashlin`);
                        }

                    },async (err, results) => {
                        //BigCommerceAshlin is the data post to AshlinDatabase
                        const ashlinRes = await postProductPromotionan(BigCommerceAshlin);
                        Promise.all([ashlinRes]).then(()=>{
                            this.setState({
                                bigCommerceAshlinUploading:false,
                            })
                        });
                    });
                })
            }


            //BEST BUY MARKET PLACE UPLOAD
            if( ENABLE_BESTBUY_CA_MARKETPLACE_DISCOUNT && BestBuyMarketplace.length > 0){
                this.setState({
                    bestbuyCaMarketplaceUploading:true
                },()=>{
                    async.mapSeries(BestBuyMarketplace,async (data,callback)=>{
                        try{
                            const offer = await getBestBuyOffer (data.Sku);
                            const value = {
                                "available_ended": null,
                                "available_started": null,
                                "description": null,
                                "internal_description": null,
                                "min_quantity_alert": null,
                                "price": parseFloat(data.OriginalPrice).toFixed(2)*1,
                                "product_id": offer.product_references[0].reference,
                                "product_id_type": "UPC-A",
                                "quantity": offer.quantity,
                                "shop_sku": offer.shop_sku,
                                "state_code":"11",
                                "update_delete": "update",
                                "discount": {
                                    "end_date": this.state.discountEndDate.format('YYYY-MM-DD'),
                                    "price": parseFloat(data.DiscountPrice).toFixed(2)*1,
                                    "start_date": this.state.discountStartDate.format('YYYY-MM-DD')
                                }
                            };
                            await setTimeout(()=>{
                                return callback(null,value);
                            },0);
                        }catch (e) {
                            console.log(`${data.Sku} not found on Bestbuy Marketplace`);
                        }
                    },async (err, bestBuyData) => {
                        //Update offer to best buy
                        const bestbuyRes = await updateOffers(bestBuyData);
                        //BestBuyMarketplace is the data post to AshlinDatabase
                        const ashlinRes = await postProductPromotionan(BestBuyMarketplace);
                        Promise.all([bestbuyRes,ashlinRes]).then(()=>{
                            this.setState({
                                bestbuyCaMarketplaceUploading:false
                            })
                        });
                    });
                })
            }


            //WALMART CA UPLOAD
            if( ENABLE_WALMART_CA_MARKETPLACE_DISCOUNT && WalmartCaMarketplace.length > 0){
                this.setState({
                    walmartCaMarketplaceUploading:true
                },()=>{
                    async.mapSeries(WalmartCaMarketplace,async (data,callback)=>{
                        const value =  {
                            "itemIdentifier": {
                                "sku": {
                                    "_text": data.Sku
                                }
                            },
                            "pricingList": {
                                "pricing": {
                                    "currentPrice": {
                                        "value": {
                                            "_attributes": {
                                                "currency": "CAD",
                                                "amount": data.DiscountPrice
                                            }
                                        }
                                    }
                                }
                            }
                        };
                        await setTimeout(()=>{
                            return callback(null,value);
                        },0);
                    },async (err, results) => {
                        const uploadJson = WalmartDiscountMapper(results);
                        const walmartRes = await WalmartDiscount(uploadJson);
                        //console.log(walmartRes);
                        //WalmartCaMarketplace is the data post to AshlinDatabase
                        //console.log(WalmartCaMarketplace);
                        const ashlinRes = await postProductPromotionan(WalmartCaMarketplace);
                        Promise.all([walmartRes,ashlinRes]).then(()=>{
                            this.setState({
                                walmartCaMarketplaceUploading:false
                            })
                        });
                    });

                })
            }

            //SEND EMAIL REPORT
            if(ENABLE_BIGCOMMERCE_ASHLIN_DISCOUNT || ENABLE_BESTBUY_CA_MARKETPLACE_DISCOUNT || ENABLE_WALMART_CA_MARKETPLACE_DISCOUNT){
                let report = `Promotional Products Detail --- Start Date: ${this.state.discountStartDate.format('YYYY-MM-DD')} ~ End Date: ${this.state.discountEndDate.format('YYYY-MM-DD')} \n`;

                BigCommerceAshlin.forEach((p,i)=>{
                    if(i===0){
                        report += `\nChannel Name: ${p.ChannelName} \n`;
                    }
                    report += `SKU: ${p.Sku} | Original Price-${p.OriginalPrice} | Promotional Price-${p.DiscountPrice} | Discount-${ (parseFloat(p.OriginalPrice) - parseFloat(p.DiscountPrice)).toFixed(2)}\n`;
                });
                BestBuyMarketplace.forEach((p,i)=>{
                    if(i===0){
                        report += `\nChannel Name: Bestbuy.Ca Marketplace \n`;
                    }
                    report += `SKU: ${p.Sku} | Original Price-${p.OriginalPrice} | Promotional Price-${p.DiscountPrice} | Discount-${ (parseFloat(p.OriginalPrice) - parseFloat(p.DiscountPrice)).toFixed(2)}\n`;
                });
                WalmartCaMarketplace.forEach((p,i)=>{
                    if(i===0){
                        report += `\nChannel Name: Walmart.ca Marketplace \n`;
                    }
                    report += `SKU: ${p.Sku} | Original Price-${p.OriginalPrice} | Promotional Price-${p.DiscountPrice} | Discount-${ (parseFloat(p.OriginalPrice) - parseFloat(p.DiscountPrice)).toFixed(2)}\n`;
                });

                const recipients = ["intern1002@ashlinbpg.com"]//"juanne.kochhar@ashlinbpg.com","nancy.taccone@ashlinbpg.com","ashlin@ashlinbpg.com"];
                const emailTitle = "Promotional Products Report";
                const emailBody = "Hi,\nThe attachment is the Promotional Products Report.";
                await sendEmail(recipients,emailTitle,emailBody,report,"Promotional Products Report.txt");
            }
        });
    };
    handleDiscountPriceOnchange=(e,index)=>{
        const value = e.target.value;
        const name = e.target.name;
        const skuUpdateList = this.state.skuUpdateList;
        skuUpdateList[index][name] = value;
        this.setState({
            skuUpdateList
        })
    };
    handleRemoveDiscount=async()=>{
        const products = await getPromotionEndProducts();
        const bigCommerceProducts = products.filter(p=>p.ChannelName === "BigCommerce Ashlin");
        //console.log(bigCommerceProducts);
        let toUpdate;
        if(bigCommerceProducts.length > 5){
            toUpdate = bigCommerceProducts.slice(0,5);
        }else{
            toUpdate = bigCommerceProducts;
        }
        //console.log(toUpdate);
        this.setState({
            bigCommerceAshlinUploading:true
        },async()=>{
            const storeInfo =await getStoreInfo("BigCommerce Ashlin");
            const d = new Date(new Date().toString().split('GMT')[0]+' UTC').toISOString();
            const last = d.slice(0,19);
            async.mapSeries(toUpdate ,async (data,callback)=>{
                try{
                    if(data!==null){
                        //Update product price on BigCommerce
                        const channelListing =await getChannelSKU("BigCommerce Ashlin",data.Sku);
                        const bigCommerceAshlinID = (channelListing .MerchanSku.split("+"))[0];
                        const bigCommerceProduct = (await findProductById(storeInfo,bigCommerceAshlinID)).data.data;
                        //console.log(bigCommerceProduct);

                        const product = await getSKU(data.Sku);
                        //console.log(product);
                        const minOrder = product.MinOrder === undefined ? '1': product.MinOrder;
                        const bulkPrice = await calculateBulKPrice((product.BasePrice),product.PricingTier);
                        //console.log(bulkPrice);
                        const bulkPriceRules = filterBulkPriceRule(bulkPrice.Bulk,parseInt(minOrder,10));
                        //console.log(bulkPriceRules);

                        const bulkPricingRules = (await getBulkPricingRules(storeInfo,bigCommerceAshlinID)).data.data;
                        //console.log(bulkPricingRules);

                        const updateBulkRule = await bulkPricingRules.map(async(rule,index)=>{

                                rule.amount = parseFloat(bulkPriceRules[index].amount);
                                rule.quantity_max = bulkPriceRules[index].quantity_max;
                                rule.quantity_min = bulkPriceRules[index].quantity_min;
                                //console.log(rule);
                                return await updateBulkPricingRule(storeInfo,bigCommerceAshlinID,rule.id,rule);

                        });
                        data.LastModifiedDate = last;
                        data.Status = "END";
                        const databaseRes = await putdateProductPromotion(data);

                        Promise.all([...updateBulkRule,databaseRes]).then(async()=>{
                            bigCommerceProduct.sale_price = 0;
                            //console.log(bigCommerceProduct);
                            return  await updateProduct(storeInfo,bigCommerceAshlinID,bigCommerceProduct)
                        });
                    }
                    await setTimeout(()=>{
                        return callback(null,null);
                    },5000);
                }catch (e) {
                    console.log(`${data.Sku} not found on BigCommerce Ashlin`);
                }

            },async (err, results) => {

                    this.setState({
                        bigCommerceAshlinUploading:false,
                    })

            });
        })
    };
    render(){
        return(
            <div className='container-fluid row'>
                <div className={'col-3'}>
                    <MainSideBar/>
                </div>
                <div className={'col'}>
                    <br />
                    <h1 className={"text-center"}>Bulk Discount  <button type={'button'} className={'btn btn-danger'} onClick={this.handleRemoveDiscount}>Remove Old Discount</button>
                    </h1>
                        {(this.state.bigCommerceAshlinUploading || this.state.bestbuyCaMarketplaceUploading || this.state.walmartCaMarketplaceUploading)
                            &&<div className={"text-center text-success display-4"}><i className="fa fa-spinner fa-spin"> </i> &nbsp; Submitting...</div>}
                        {this.state.isDownLoading &&<div className={"text-center text-success display-4"}><i className="fa fa-spinner fa-spin"> </i> &nbsp; Downloading Data...</div>}
                    <br/>

                    <div className={'row'}>
                        <div className={'col-3'}>
                            <span className='font-weight-bold '>Start Date: </span>
                            <DatePicker
                                selected={this.state.discountStartDate}
                                onChange={this.handleChangeStartDate}
                            />
                        </div>
                        <div className={'col-3'}>
                            <span className='font-weight-bold '>End Date: </span>
                            <DatePicker
                                selected={this.state.discountEndDate}
                                onChange={this.handleChangeEndDate}
                            />
                        </div>
                        <div className={'col-3'}>
                            <div className='font-weight-bold '>% OFF: </div>
                            <input type={'text'} name={'discountPercentage'} value={this.state.discountPercentage} onChange={this.handleStateOnchange}/>
                        </div>
                        <div className={'col-3'}>
                            { this.state.skuUpdateList.length>0 &&
                            <button className={'btn btn-danger btn-block'} type={'button'} onClick={this.handleApplyDiscount}>Apply Discount</button>}
                        </div>
                    </div>
                    {
                        (this.state.discountStartDate!==null && this.state.discountEndDate !==null)&&
                        <div className={'row'}>
                            <div className={'col-4'}>
                                <DiscountSkuList list={this.state.skuShownList} searchBox={this.state.searchBox}
                                                 onChange={this.handleSearchOnchange}
                                                 skuOnSelect = {this.handleSkuOnSelect}
                                />
                            </div>
                            <div className={'col-8'}>
                                <DiscountDetailList list={this.state.skuUpdateList} discountPriceOnchange={this.handleDiscountPriceOnchange}
                                                    channelOnSelect = {this.handleChannelOnSelect} discountPercentage={this.state.discountPercentage}
                                />
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }
}
export default BulkDiscount;