import React, { Component } from 'react';
import { getOffer,createItems,updateInventory } from '../bestbuyMarketplace';



class BestbuyApi extends Component{

    handleGetItem = async (e)=>{
      e.preventDefault();
      const res = await getOffer('GOLF06-00-01');
      //console.log(res);
    };
    handleCreateItem =async (e)=>{
       e.preventDefault();
       const res = await createItems();
       //console.log(res);
    };
    handleCreateOffer=async (e)=>{
        e.preventDefault();
        const res = await updateInventory([{Sku:'6316-07-01',Inventory:27}]);
        //console.log(res);
    };
    render(){
        return(
            <div>
                <h1 style={{textAlign:'center'}}>Bestbuy API Test</h1>
                <button className={'btn btn-success'} onClick={this.handleGetItem}>Get Item</button>
                {/*<button className={'btn btn-success'} onClick={this.handleCreateItem }>Create Item</button>*/}
                <hr/>
                <button className={'btn btn-success'} onClick={this.handleCreateOffer }>Update Inventory</button>
            </div>)
    }
}

export default BestbuyApi;