import React, { Component } from 'react';
import MainSideBar from '../sideBar/MainSideBar';
import {getChannelList, getListedSkus,getListedSkusWithBrightId} from "../../Utils/ashlinDesign";
import SelectInput from '../bootstrapComponent/selectInput';
import InventoryTable from './components/inventoryTable';
import { getInventoryById,getRangeInventory,findInventoryFromInventoryList } from '../../Utils/brightpearl';
import { WalmartInventoryUpdate } from '../../Utils/walmartMarketplace';
import {updateInventory} from "../../Utils/bestbuyMarketplace";
import { staplesCaInventoryFileGenerator,staplesInventoryUpdate } from '../../Utils/staplesCa';
import { CommerceHubWalmartInventoryFileGenerator, CommerceHubInventoryUpdate,CommerceHubBestBuyInventoryFileGenerator,CommerceHubTheBayInventoryFileGenerator } from '../../Utils/CommerceHub';
class BulkUpdateInventory extends Component{
    constructor(props){
        super(props);
        this.state = {
            channelList:[],
            selectChannel:"",
            skuList:[],
            listedSkus:[],
            isUpLoading:false,
            isDownLoading:false,
            readyForUpload:false,
            readyForCheck:false,
            checkMainWarehouse:false,
            checkLeanSupplyWarehouse:false
        };
    }
    componentDidMount(){
        this.loadData();
    }
    loadData = async()=>{
        const channelList = await getChannelList();
        this.setState({
            channelList :channelList.data
        })
    };
    handleOnchange=async(e)=>{
        const selectChannel = e.target.value;
        if(selectChannel !== 'optionDefaultHeader'){
            //get Skus listed on the selected Channel
            const listedSkusRes = await getListedSkusWithBrightId(selectChannel);
            let listedSkus = listedSkusRes.data;
            Promise.all([listedSkusRes ]).then(()=>{
                this.setState(
                    {
                        selectChannel,
                        listedSkus,
                        readyForCheck:true,
                        skuList:[],
                        readyForUpload:false,
                        checkMainWarehouse:true,
                        checkLeanSupplyWarehouse:true
                    },()=>console.log(this.state));
                });
        }
    };
    handleCheckInventory=()=>{
        this.setState({
            isDownLoading:true
        },async ()=>{

            let listedSkus = this.state.listedSkus;

            let Ids = listedSkus.map(i=>i.BrightPearlId);
            Ids = Ids.sort();
            const start = Ids[0];
            const end = Ids[Ids.length-1];

            //GET ALL INVENTORY INFO FROM BRIGHT PEARL
            const inventoryList = await getRangeInventory(start,end);
            //console.log(inventoryList);

            //GENERATE WAREHOUSES THAT NEED TO CHECK
            const wareHouseList = {
                main:this.state.checkMainWarehouse,
                leanSupply:this.state.checkLeanSupplyWarehouse
            };
           // console.log(wareHouseList);
            let list = [];
            const inventoryRes = await listedSkus.map(async(i)=>{
                 const item = await findInventoryFromInventoryList(i,inventoryList,wareHouseList,false);
                 list.push(item);
                 return item;
            });

            Promise.all(inventoryRes).then(()=>{
                this.setState({
                    skuList:list,
                    isDownLoading:false,
                    readyForUpload:true
                });
            })
        });
    };
    handleUpdate= async (e)=>{
      e.preventDefault();
      this.setState({
          isUpLoading:true
    },async()=>{
          if(this.state.selectChannel === "WalmartCa"){
              const res = await WalmartInventoryUpdate(this.state.skuList);
              alert("WalmartCA Market Place Inventory Update Finish, Feed Status ID: " + res.data.feedId);
              this.setState({
                  isUpLoading:false,
                  readyForUpload:false
              })
          }else if(this.state.selectChannel === "BestBuy"){
              const res = await updateInventory(this.state.skuList);
              alert("BestBuy Market Place Inventory Update Finish, Import ID: " + res.data.import_id);
              this.setState({
                  isUpLoading:false,
                  readyForUpload:false
              })
          }else if(this.state.selectChannel === "StaplesCa"){
              const uploadFile = staplesCaInventoryFileGenerator('143421CA',this.state.skuList);
              const res = await staplesInventoryUpdate("StaplesCa",uploadFile);
              if(res.data === "Success"){
                  alert("StaplesCA Inventory Update Finish");
              }else{
                  alert("StaplesCA  SFTP Connection Failed!");
              }
              this.setState({
                  isUpLoading:false,
                  readyForUpload:false
              })
          }
          else if(this.state.selectChannel === "StaplesCaAdvantage"){
                  const uploadFile = staplesCaInventoryFileGenerator('SAC0143421',this.state.skuList);
                  const res = await staplesInventoryUpdate("StaplesCaAdvantage",uploadFile);
                  if(res.data === "Success"){
                      alert("StaplesCaAdvantage Inventory Update Finish");
                  }else{
                      alert("StaplesCaAdvantage  SFTP Connection Failed!");
                  }
                  this.setState({
                      isUpLoading:false,
                      readyForUpload:false
                  })
          }else if(this.state.selectChannel === "CommerceHubWalmart"){
              const uploadFile =CommerceHubWalmartInventoryFileGenerator(this.state.skuList);
              const res = await CommerceHubInventoryUpdate(uploadFile,"CommerceHubWalmart");
              if(res.data === "Success"){
                  alert("CommerceHub WalmartCa Inventory Update Finish");
              }else{
                  alert("CommerceHub WalmartCa  SFTP Connection Failed!");
              }
              this.setState({
                  isUpLoading:false,
                  readyForUpload:false
              })
          }
          else if(this.state.selectChannel === "CommerceHubBestBuy"){
              const uploadFile =CommerceHubBestBuyInventoryFileGenerator(this.state.skuList);
              const res = await CommerceHubInventoryUpdate(uploadFile,"CommerceHubBestBuy");
              if(res.data === "Success"){
                  alert("CommerceHub BestBuy Inventory Update Finish");
              }else{
                  alert("BestBuy SFTP Connection Failed!");
              }
              this.setState({
                  isUpLoading:false,
                  readyForUpload:false
              })
          }
          else if(this.state.selectChannel === "CommerceHubTheBay"){
              const uploadFile =CommerceHubTheBayInventoryFileGenerator(this.state.skuList);
              const res = await CommerceHubInventoryUpdate(uploadFile,"CommerceHubTheBay");
              if(res.data === "Success"){
                  alert("CommerceHub TheBay Inventory Update Finish");
              }else{
                  alert("CommerceHub TheBay  SFTP Connection Failed!");
              }
              this.setState({
                  isUpLoading:false,
                  readyForUpload:false
              })
          }
      });

    };
    handleInputChange=(event)=> {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    };
    render(){
        //console.log(this.state.skuList[0]);
        return(
            <div>
                <div className='container-fluid row'>
                    <div className={'col-3'}>
                        <MainSideBar/>
                    </div>
                    <div className={'col-9'}>
                        <br/>
                        <h1 className={'text-center'}>Bulk Inventory Update</h1>
                        <br/>
                        <div className={'row'}>
                            <div className={'col'}>
                                {
                                    this.state.channelList.length !==0 &&
                                    <SelectInput value={this.state.channelList}
                                                 style={{fontWeight:'bold',fontSize:'20px'}}
                                                 label={"Channel Name"} onChange={(e)=>this.handleOnchange(e)}/>
                                }
                            </div>
                            {this.state.readyForCheck &&
                                <div className={'col'}>
                                    <h3>Warehouse:</h3>
                                    Main Warehouse:<input
                                    name="checkMainWarehouse"
                                    type="checkbox"
                                    checked={this.state.checkMainWarehouse}
                                    onChange={this.handleInputChange}/> &nbsp;&nbsp;
                                    4PL Warehouse:<input
                                    name="checkLeanSupplyWarehouse"
                                    type="checkbox"
                                    checked={this.state.checkLeanSupplyWarehouse}
                                    onChange={this.handleInputChange}/>
                                </div>
                            }
                            <div className={'col'}>
                                { (this.state.readyForCheck && !this.state.readyForUpload) && <button className={'btn btn-primary'} onClick={this.handleCheckInventory}>Check Inventory</button>}
                                { this.state.readyForUpload && <button className={'btn btn-danger'} onClick={(e)=>this.handleUpdate(e)}>Update Inventory</button>}
                            </div>
                        </div>
                        <hr/>
                        <div className={'row'}>
                            <div className={'col-1'}>

                            </div>
                            <div className={'col'}>
                                {this.state.isUpLoading &&<div className={"text-center text-success display-4"}><i className="fa fa-spinner fa-spin"></i> &nbsp; Submitting...</div>}
                                {this.state.isDownLoading &&<div className={"text-center text-success display-4"}><i className="fa fa-spinner fa-spin"></i> &nbsp; Checking Inventory on BrightPearl...</div>}
                                { this.state.skuList[0] &&
                                    <InventoryTable skuList={this.state.skuList}/>
                                }
                            </div>
                            <div className={'col-1'}>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default BulkUpdateInventory;