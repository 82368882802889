import React from 'react';
const shipping_matrix_list_item = ({item, index,  onChange , update })=>{
    return(
        <tr>
            <th><input type={'text'} onChange={(e)=>onChange(e,index)}  style={{width:'100%'}} name={'Carrier'} value={item.Carrier} /></th>
            <td><input type={'text'} onChange={(e)=>onChange(e,index)}  style={{width:'100%'}} name={'Origin'} value={item.Origin} /></td>
            <td><input type={'text'} onChange={(e)=>onChange(e,index)}  style={{width:'100%'}} name={'Destination'} value={item.Destination} /></td>
            <td><input type={'text'} onChange={(e)=>onChange(e,index)}  style={{width:'100%'}} name={'ServiceCode'} value={item.ServiceCode} /></td>
            <td><input type={'text'} onChange={(e)=>onChange(e,index)}  style={{width:'100%'}} name={'DisplayedName'} value={item.DisplayedName} /></td>
            <td><input type={'checkbox'} onChange={(e)=>onChange(e,index)}  style={{width:'20px', height:'20px'}} name={'IsExpress'} checked={item.IsExpress} /></td>

            <td><input type={'text'} onChange={(e)=>onChange(e,index)}  style={{width:'100%'}} name={'ChannelBrightPearlCode'} value={item.ChannelBrightPearlCode} /></td>
            <td><input type={'text'} onChange={(e)=>onChange(e,index)}  style={{width:'100%'}} name={'ChannelBigCommerceCode'} value={item.ChannelBigCommerceCode} /></td>
            <td><input type={'text'} onChange={(e)=>onChange(e,index)}  style={{width:'100%'}} name={'Channel3PLCode'} value={item.Channel3PLCode} /></td>
            <td><input type={'text'} onChange={(e)=>onChange(e,index)}  style={{width:'100%'}} name={'ChannelAmazonCode'} value={item.ChannelAmazonCode} /></td>
            <td><input type={'text'} onChange={(e)=>onChange(e,index)}  style={{width:'100%'}} name={'ChannelEbayCode'} value={item.ChannelEbayCode} /></td>

            <td>
                <button className={'btn-danger'} type={'button'} onClick={()=>update(item)}>Update</button>
            </td>
        </tr>
    );
};
export default shipping_matrix_list_item;