import React from 'react';
import { SketchPicker } from 'react-color';
import ImageDropZone from '../../Utils/imageUpload/imageDropZone';

const BigCommerceModifyProductDetailModal =({item, onChange,calculateBulkPriceRule,SkuSwatch,changeSwatch,UpdateSKUSwatch,ImageOnDrop,SwatchColorOnDrop,
                                SelectOptionColor,changeImage,UpdateSKUImage, ChangeSwatchColor,CancelUpdateSKUSwatch,CancelUpdateSKUImage,
                                updateBigCommerceProduct,deleteBigCommerceProduct, handleRestSkuDetail})=>{

    return(
            <div className="modal fade" id="BigCommerceModifyProductDetailModal" tabIndex="-1" role="dialog"
                 aria-labelledby="BigCommerceModifyProductDetailModalTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title text-center" id="BigCommerceModifyProductDetailModalTitle">BigCommerce Product Detail</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleRestSkuDetail}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className={'row'}>
                                <div className={'col'}>
                                    <span className='font-weight-bold '>Name: </span>{item.bigCommerceDesign === undefined? "": item.bigCommerceDesign.name }
                                </div>
                            </div>
                            <hr style={{margin:"0.5em 0"}}/>

                            <div className={'row'}>
                                {
                                    item.bigCommerceSku !==undefined &&
                                    <div className={'col'}>
                                        <img src={item.bigCommerceSku.image_url}  alt={'Sku image'} style={{width:'195px',height:'195px'}}/>
                                    </div>
                                }
                                {
                                    (item.bigCommerceProductImages !==undefined &&!SkuSwatch.isChangeImage) &&
                                    <div className={'col'}>
                                        <img src={item.bigCommerceProductImages[0].url_standard}  alt={'Sku image'} style={{width:'195px',height:'195px'}}/>
                                    </div>
                                }

                                { !SkuSwatch.isChangeImage &&
                                    <div className={'col'}>
                                        <br/>
                                        <span className='font-weight-bold '>SKU: </span>{item.bigCommerceSku !== undefined? item.bigCommerceSku.sku: item.bigCommerceDesign !== undefined? item.bigCommerceDesign.sku:"" }
                                        <br/><br/>
                                        <span className='font-weight-bold '>Base Price (CAD): </span><input value={item.bigCommerceDesign === undefined?  "": item.priceCa } name='priceCa' onChange={(e)=>onChange(e,'bigCommerceDesign')}/>
                                    </div>
                                }
                                { !SkuSwatch.isChangeImage &&
                                    <div className={'col'}>
                                        <br/>
                                        <button  className={'btn btn-primary'} onClick={e=>calculateBulkPriceRule(e,item.ashlinSku,item.ashlinSku.PricingTier,item.priceCa)}>Calculate Bulk Price</button>
                                        <br/><br/>
                                        <button className={'btn btn-block btn-primary'}
                                                onClick={changeImage}>Change SKU Image</button>
                                    </div>
                                }
                                {
                                    (item.bigCommerceProductImages !==undefined && SkuSwatch.isChangeImage) &&
                                    <div className={'col-7'}>
                                        {item.bigCommerceProductImages.map((img,i)=>{
                                            return <div key={i} className={'row'}>
                                                        <img  className={'col'} src={item.bigCommerceProductImages[i].url_standard}  alt={'Sku image'} style={{width:'195px',height:'195px'}}/>
                                                        <ImageDropZone className={'col'} onDrop={(accepted)=>ImageOnDrop(accepted,item.bigCommerceProductImages[i].id,i)} file={SkuSwatch.imageFile!==undefined ? SkuSwatch.imageFile[i]:undefined} sizeDescription={"**MAXIMUM SIZE: 1MB**"} />
                                                   </div>
                                        })}
                                    </div>
                                }
                                { (item.bigCommerceProductImages ===undefined && SkuSwatch.isChangeImage) &&
                                    <div className={'col'}>
                                        <h5>Select New SKU Image</h5>
                                        <ImageDropZone onDrop={ImageOnDrop} file={SkuSwatch.imageFile} sizeDescription={"**MAXIMUM SIZE: 1MB**"} />
                                    </div>
                                }
                                { SkuSwatch.isChangeImage &&
                                    <div className={'col'}>
                                        <br/> <br/>
                                        <button className="btn btn-danger" style={{width:'100%'}} onClick={UpdateSKUImage}>Update Image</button>
                                        <br/> <br/>
                                        <button className="btn btn-secondary" style={{width:'100%'}}  onClick={CancelUpdateSKUImage}>Cancel Update</button>
                                    </div>
                                }
                            </div>
                            <hr style={{margin:"0.5em 0"}}/>
                            { !SkuSwatch.isChangeSwatch &&
                                <div className={'row'}>
                                    <div className={'col'}>
                                        <button className={'btn  btn-primary'} onClick={changeSwatch}>Change Swatch</button>
                                    </div>
                                    <div className={'col'}>
                                        <button type="button" className="btn btn-danger " onClick={updateBigCommerceProduct}>Update Product </button>
                                    </div>
                                    <div className={'col'}>
                                        <button type="button" className="btn btn-danger "   onClick={deleteBigCommerceProduct}>Delete Product </button>
                                    </div>
                                </div>

                            }
                            { (SkuSwatch.isChangeSwatch && item.bigCommerceProductImages ===undefined)&&
                            <div className={'row'}>
                                    <div className={'col'}>
                                        <h5>Current Swatch Color</h5>
                                        <br/>
                                        {SkuSwatch.currentSwatchValue.value_data.image_url !== undefined && <img src={SkuSwatch.currentSwatchValue.value_data.image_url} alt={'current swatch image'}/>}
                                        {SkuSwatch.currentSwatchValue.value_data.colors !== undefined && <div style={{background:SkuSwatch.currentSwatchValue.value_data.colors[0],width:"200px",height:"200px",borderRadius:'50%'}}> </div>}
                                    </div>
                                    <div className={'col'}>
                                        <h5>File name must be </h5>
                                        <h5>-DESIGN-COLOR.jpg</h5>
                                        {/*<SketchPicker*/}
                                            {/*color={ SkuSwatch.swatchColor }*/}
                                            {/*onChangeComplete={ ChangeSwatchColor }*/}
                                        {/*/>*/}
                                        <ImageDropZone onDrop={SwatchColorOnDrop} file={SkuSwatch.swatchColor} sizeDescription={"**MAXIMUM SIZE: 1MB**"} />
                                    </div>
                                    <div className={'col'}>
                                        <br/><br/>
                                        <button className="btn btn-danger" style={{width:'100%'}} onClick={UpdateSKUSwatch}>Update Swatch</button>
                                        <br/><br/><br/>
                                        <button className="btn btn-secondary" style={{width:'100%'}}  onClick={CancelUpdateSKUSwatch}>Cancel Update</button>
                                    </div>
                                </div>
                            }
                            { (SkuSwatch.isChangeSwatch && item.bigCommerceProductImages !==undefined)&&
                                <div className={'row'}>
                                    <div className={'col'}>
                                        <h5>Current Available Color</h5>
                                        <p style={{color:'red'}}>--click the color button to change color</p>
                                        {SkuSwatch.currentSwatchValue!==undefined ? SkuSwatch.currentSwatchValue.option_values.map(c=>{
                                            return <button  key={c.id} style={{width:'50px',height:'50px',backgroundColor:c.value_data.colors[0],marginRight:'7px'}} onClick={(e)=>SelectOptionColor(e,c.id)}> </button>
                                        }):<p>No Color Options, can't change</p>}
                                    </div>
                                    { SkuSwatch.currentSelectId !==""&&
                                        <div className={'col'}>
                                            <h5>Select New Swatch Color</h5>
                                            <SketchPicker
                                                color={ SkuSwatch.swatchColor }
                                                onChangeComplete={ ChangeSwatchColor }
                                            />
                                            {/*<ImageDropZone onDrop={SwatchColorOnDrop} file={SkuSwatch.swatchColor} sizeDescription={"**MAXIMUM SIZE: 1MB**"} />*/}
                                        </div>
                                    }
                                    <div className={'col'}>
                                        <br/><br/>
                                        <button className="btn btn-danger" style={{width:'100%'}} onClick={UpdateSKUSwatch}>Update Swatch</button>
                                        <br/><br/><br/>
                                        <button className="btn btn-secondary" style={{width:'100%'}}  onClick={CancelUpdateSKUSwatch}>Cancel Update</button>
                                    </div>
                                </div>
                            }
                            <hr style={{margin:"0.5em 0"}}/>
                            <div className={'row'}>
                                <div className={'col'}>
                                    <div dangerouslySetInnerHTML={{__html: item.bigCommerceDesign.description}} />
                                </div>
                            </div>
                            {/*<hr/>*/}
                            {/*<button type="button" className="btn btn-danger col" onClick={updateBigCommerceProduct}>Update Product </button>*/}
                        </div>
                        {/*<div className="modal-footer row">*/}
                           {/*<button type="button" className="btn btn-danger col"   onClick={deleteBigCommerceProduct}>Delete Product </button>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        )
};

export default BigCommerceModifyProductDetailModal;


