import React from 'react';
import CurrentImage from './currentImage';
import AddImages from './addImages';

const ImagesList = ({ currentImages,sku, deleteImage, addNew,addingNew,uploadImage,removeSelect,syncImage,
                        mainImages,baseImages,modelImages,lifeImages,groupImages,imageOnDrop})=>{
    return(
        <div>
            <h2 className={''}>{sku} -
                {!addingNew && <span> Current Images &nbsp; <button className={'btn btn-success'} onClick={addNew}>Add New Images</button>&nbsp; <button className={'btn btn-danger'} onClick={syncImage}>Sync Images</button></span>}
                {addingNew &&  <span> New Images &nbsp;<button className={'btn btn-success'} onClick={uploadImage}>Upload Images</button></span>}

            </h2>
            {!addingNew &&
                <div className={'row'}>
                    {currentImages.map((img,index)=>{
                        return(
                            <span key={index}>
                                <CurrentImage img={img} deleteImage={deleteImage}/>
                            </span>
                        )
                    })}
                </div>
            }
            {addingNew &&
                <AddImages
                    mainImages={mainImages}
                    baseImages={baseImages}
                    modelImages={modelImages}
                    lifeImages={lifeImages}
                    groupImages={groupImages}
                    imageOnDrop={imageOnDrop}
                    removeSelect={removeSelect}
                />
            }
        </div>
    )
};

export default ImagesList;