import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
import 'popper.js/dist/umd/popper';
import 'bootstrap/dist/js/bootstrap.js';
import 'font-awesome/css/font-awesome.min.css';
import 'antd/dist/antd.css';
import './js/site.js';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './components/App';
import createAppStore from './store';
import { PersistGate } from 'redux-persist/es/integration/react';
import LoadingView from './components/Loading';

const { persistor, store } = createAppStore();

ReactDOM.render(
    <Provider store={store}>
        <PersistGate persistor={persistor} loading={<LoadingView />}>
            <App />
        </PersistGate>
    </Provider>, document.querySelector("#root"));