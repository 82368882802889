import React from 'react';
const TextInputParallel =({className,id,label,value,name,onChange,placeholder,width,readonly})=>{
    return(
        <div className={`${className} row`}>
            <label htmlFor={id} className={'col-4'} style={{margin:'auto 0',fontWeight:'bold', textAlign:'right'}}>{label}:</label>
            <input type="text" className="form-control col-7" id={id} value={value} readOnly = {readonly}
                   onChange={onChange} name={name} placeholder={placeholder}  style={{width:width}}/>
        </div>
    )
};
export default TextInputParallel;