import axios from 'axios';
import momentTimeZone from 'moment-timezone';
import {baseURL,CANPAR_TEST_ENVIRONMENT, proxyurl } from "../requestHelper";

const ratingServiceURL = CANPAR_TEST_ENVIRONMENT ? proxyurl +"https://sandbox.canpar.com/canshipws/services/CanparRatingService?wsdl" : proxyurl+"https://canship.canpar.com/canshipws/services/CanparRatingService?wsdl";
const busniessServiceURL = CANPAR_TEST_ENVIRONMENT ? proxyurl + "https://sandbox.canpar.com/canshipws/services/CanshipBusinessService?wsdl" : proxyurl +"https://canship.canpar.com/canshipws/services/CanshipBusinessService?wsdl";
const headsers = {
    headers:{ 'Content-Type': 'application/soap+xml' }
};
export const getCanparCredentials = async()=>{
    if(CANPAR_TEST_ENVIRONMENT){
        return {
            'account':'99999992',
            'username':'ASHLIN@DEMO.COM',
            'password':'demo'
        }
    }  else{
        const res =  (await axios.get(`${baseURL}api/canpar`)).data;
        //console.log(res);
        return res;
    }
};

export const getTodayDate = ()=>{
    const d = new Date();
    const myTimezone = "America/Toronto";
    return momentTimeZone(d).tz(myTimezone).format();
};

export const getCanparRates = async(data,credentials,isExpress,ShippingMatrices,shippingMethodsFilter)=>{
    const today = getTodayDate();
    const residential = data.ShipTo.Name === "";
    const xml = `<soap:Envelope xmlns:soap="http://www.w3.org/2003/05/soap-envelope" xmlns:ws="http://ws.onlinerating.canshipws.canpar.com" xmlns:xsd="http://ws.dto.canshipws.canpar.com/xsd" xmlns:xsd1="http://dto.canshipws.canpar.com/xsd">
                   <soap:Header/>
                   <soap:Body>
                      <ws:rateShipmentAllServices>
                         <ws:request>
                            <xsd:password>${credentials.password}</xsd:password>
                            <xsd:shipment>
                               <delivery_address xmlns="http://dto.canshipws.canpar.com/xsd">
                                    <address_line_1>${data.ShipTo.AddressLine1}</address_line_1>
                                    <address_line_2>${data.ShipTo.AddressLine2}</address_line_2>
                                    <address_line_3>${data.ShipTo.AddressLine3}</address_line_3>
                                    <attention/>
                                    <city>${data.ShipTo.City}</city>
                                    <province>${data.ShipTo.State}</province>
                                    <country>${data.ShipTo.CountryCode}</country>
                                    <postal_code>${data.ShipTo.Zip}</postal_code>
                                    <name>${data.ShipTo.ContactName}</name>
                                    <phone>${data.ShipTo.ContactPhone}</phone>
                                    <extension>${data.ShipTo.ContactPhoneExt}</extension>
                                    <email></email>
                                    <residential>${residential}</residential>
                               </delivery_address>
                             
                               <pickup_address xmlns="http://dto.canshipws.canpar.com/xsd">
                                    <address_line_1>${data.ShipFrom.AddressLine1}</address_line_1>
                                    <address_line_2>${data.ShipFrom.AddressLine2}</address_line_2>
                                    <address_line_3>${data.ShipFrom.AddressLine3}</address_line_3>
                                    <attention/>
                                    <city>${data.ShipFrom.City}</city>
                                    <country>${data.ShipFrom.CountryCode}</country>
                                    <email></email>
                                    <extension>${data.ShipFrom.ContactPhoneExt}</extension>
                                    <name>${data.ShipFrom.ContactName}</name>
                                    <phone>${data.ShipFrom.ContactPhone}</phone>
                                    <postal_code>${data.ShipFrom.Zip}</postal_code>
                                    <province>${data.ShipFrom.State}</province>
                                    <residential>false</residential>
                               </pickup_address>
                                
                               <packages xmlns="http://dto.canshipws.canpar.com/xsd">
                                    <height>${data.PackageInfo.Height}</height>
                                    <length>${data.PackageInfo.Length}</length>
                                    <min_weight_flag>false</min_weight_flag>
                                    <reported_weight>${data.PackageInfo.Weight}</reported_weight>
                                    <width>${data.PackageInfo.Width}</width>
                                </packages>
                               
                               <xsd1:reported_weight_unit>KG</xsd1:reported_weight_unit>
                               <ns1:dimention_unit xmlns:ns1="http://dto.canshipws.canpar.com/xsd">CM</ns1:dimention_unit>
                              
                               <xsd1:shipper_num>${credentials.account}</xsd1:shipper_num>
                            </xsd:shipment>
                            <xsd:user_id>${credentials.username}</xsd:user_id>
                            <ns2:shipping_date xmlns:ns2="http://dto.canshipws.canpar.com/xsd">${today}</ns2:shipping_date>
                         </ws:request>
                      </ws:rateShipmentAllServices>
                   </soap:Body>
                </soap:Envelope>`;

    try {
        const res = (await axios.post(ratingServiceURL,xml,headsers)).data;

        const document =  new DOMParser().parseFromString(res, 'application/xml');

        const shipments = [...document.getElementsByTagName('ax27:shipment')];

        const result =  shipments.map(q=>{
            const serviceCode = q.getElementsByTagName('ax27:service_type')[0].textContent;
            const service = ShippingMatrices.find(s=>s.ServiceCode === serviceCode);
            if(service)
                return {
                    serviceCode:q.getElementsByTagName('ax27:service_type')[0].textContent,
                    serviceName: service.DisplayedName,
                    isExpress: service.IsExpress,
                    totalCost:parseFloat(q.getElementsByTagName('ax27:total')[0].textContent).toFixed(2),
                    deliverByDate:(q.getElementsByTagName('ax27:estimated_delivery_date')[0].textContent).slice(0,10),
                };
            return service;
        });
        if(shippingMethodsFilter.length > 0){
            const conditions = shippingMethodsFilter.filter(s=>s.Carrier==="CANPAR").map(s=>s.ServiceCode);
            return  result.filter(r=>r && conditions.some(el=> r.serviceCode.includes(el)))
        }

        return result.filter(r=>r && r.isExpress === isExpress);
    }catch (e) {
        return [];
    }
};

export const processCanparShipment = async(data,credentials)=>{
    const today = getTodayDate();
    //console.log(data);
    let value = data.PackageInfo.TotalValue !== undefined ? data.PackageInfo.TotalValue : 1;
    if(data.DeclareValueInsurance) {
        value = value > 500? 500 : value;
    }else{
        value = value > 100? 100 : value;
    }

    const numberOfBox = parseInt(data.NumberOfBox);
    const weight = (data.PackageInfo.Weight/ numberOfBox ).toFixed(2);
    let packages = [];

    for (let i = 1; i <= numberOfBox; i++) {
        const p =  `<packages xmlns="http://dto.canshipws.canpar.com/xsd">
                                <alternative_reference/>
                                <barcode/>
                                <declared_value>${ (parseFloat( value)/numberOfBox).toFixed(0)}</declared_value>
                                <height>${data.PackageInfo.Height}</height>
                                <length>${data.PackageInfo.Length}</length>
                                <width>${data.PackageInfo.Width}</width>
                                <reported_weight>${weight}</reported_weight>
                                <dim_weight_flag>false</dim_weight_flag>
                                <id>${i}</id>
                                <min_weight_flag>false</min_weight_flag>
                                <package_num>${i}</package_num>
                                <package_reference>${i}</package_reference>
                                <reference>${data.Reference1!==''?data.Reference1 : data.Order.id.toString()}</reference>
                                <store_num/>
                                <xc>false</xc>
                            </packages>`;
        packages.push(p);
    }


    const residential = data.ShipTo.Name === "";
    const xml = `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
                    <soapenv:Envelope xmlns:soapenv="http://www.w3.org/2003/05/soap-envelope">
                    <soapenv:Body>
                    <ns3:processShipment xmlns:ns3="http://ws.business.canshipws.canpar.com">
                    <ns3:request>
                        <ns2:password xmlns:ns2="http://ws.dto.canshipws.canpar.com/xsd">${credentials.password}</ns2:password>
                        <ns2:user_id xmlns:ns2="http://ws.dto.canshipws.canpar.com/xsd">${credentials.username}</ns2:user_id>
                        <shipment xmlns="http://ws.dto.canshipws.canpar.com/xsd">

                            <delivery_address xmlns="http://dto.canshipws.canpar.com/xsd">
                            <address_line_1>${data.ShipTo.AddressLine1}</address_line_1>
                            <address_line_2>${data.ShipTo.AddressLine2}</address_line_2>
                            <address_line_3>${data.ShipTo.AddressLine3}</address_line_3>
                            <attention>${data.ShipTo.ContactName}</attention>
                            <city>${data.ShipTo.City}</city>
                            <province>${data.ShipTo.State}</province>
                            <country>${data.ShipTo.CountryCode}</country>
                            <postal_code>${data.ShipTo.Zip}</postal_code>
                            <name>${data.ShipTo.Name}</name>
                            <phone>${data.ShipTo.ContactPhone}</phone>
                            <extension>${data.ShipTo.ContactPhoneExt}</extension>
                            <email></email>
                            <residential>${residential}</residential>
                            </delivery_address>
                            <pickup_address xmlns="http://dto.canshipws.canpar.com/xsd">
                            <address_line_1>${data.ShipFrom.AddressLine1}</address_line_1>
                            <address_line_2>${data.ShipFrom.AddressLine2}</address_line_2>
                            <address_line_3>${data.ShipFrom.AddressLine3}</address_line_3>
                            <attention/>
                            <city>${data.ShipFrom.City}</city>
                            <country>${data.ShipFrom.CountryCode}</country>
                            <email></email>
                            <extension>${data.ShipFrom.ContactPhoneExt}</extension>
                            <name>${data.ShipFrom.ContactName}</name>
                            <phone>${data.ShipFrom.ContactPhone}</phone>
                            <postal_code>${data.ShipFrom.Zip}</postal_code>
                            <province>${data.ShipFrom.State}</province>
                            <residential>false</residential>
                            </pickup_address>

                            <ns1:shipper_num xmlns:ns1="http://dto.canshipws.canpar.com/xsd">${credentials.account}</ns1:shipper_num>
                            <ns1:shipping_date xmlns:ns1="http://dto.canshipws.canpar.com/xsd">${today}</ns1:shipping_date>
                            <ns1:service_type xmlns:ns1="http://dto.canshipws.canpar.com/xsd">${data.Service}</ns1:service_type>
                            <ns1:nsr xmlns:ns1="http://dto.canshipws.canpar.com/xsd">false</ns1:nsr>
                            <ns1:user_id xmlns:ns1="http://dto.canshipws.canpar.com/xsd"/>

                            <ns1:dimention_unit xmlns:ns1="http://dto.canshipws.canpar.com/xsd">CM</ns1:dimention_unit>
                            <ns1:reported_weight_unit xmlns:ns1="http://dto.canshipws.canpar.com/xsd">KG</ns1:reported_weight_unit>

                            ${packages.join('\n')}

                            <ns1:collect_shipper_num xmlns:ns1="http://dto.canshipws.canpar.com/xsd"/>
                            <ns1:cos xmlns:ns1="http://dto.canshipws.canpar.com/xsd">false</ns1:cos>
                            <ns1:cos_charge xmlns:ns1="http://dto.canshipws.canpar.com/xsd">0.0</ns1:cos_charge>
                            <ns1:description xmlns:ns1="http://dto.canshipws.canpar.com/xsd"/>
                            <ns1:id xmlns:ns1="http://dto.canshipws.canpar.com/xsd">${data.Order.id}</ns1:id>
                            <ns1:manifest_num xmlns:ns1="http://dto.canshipws.canpar.com/xsd" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:nil="1"/>
                            <ns1:voided xmlns:ns1="http://dto.canshipws.canpar.com/xsd">false</ns1:voided>
                        </shipment>
                    </ns3:request>
                    </ns3:processShipment>
                    </soapenv:Body>
                    </soapenv:Envelope>
                    `;
    //console.log(xml);
    try{
        const res = (await axios.post(busniessServiceURL,xml,headsers)).data;
        const shipmentDOM =  new DOMParser().parseFromString(res, 'application/xml');
        //console.log(shipmentDOM);
        const Ids = shipmentDOM .getElementsByTagName('ax211:id');
        let shipmentId;
        //console.log(Ids);
        for (let j = 0; j < Ids.length; j++) {
            if(Ids[j].parentNode.nodeName === "ax211:shipment"){
                shipmentId = Ids[j].textContent;
                break;
            }
        }
        //console.log(shipmentId);
        const labelRes = await getCanparLabel(shipmentId,credentials);
        const labelDOM =  new DOMParser().parseFromString(labelRes, 'application/xml');
        let labels = [];
        const labelsDOM =  labelDOM.getElementsByTagName('ax29:labels');
        for(let i = 0; i < labelsDOM.length; i++){
            labels.push(labelsDOM[i].textContent)
        }

        let trackings = [];
        const trackingDOM = shipmentDOM .getElementsByTagName('ax211:barcode');
        for(let i = 0; i < trackingDOM.length; i++){
            trackings.push(trackingDOM[i].textContent)
        }

        return {
            "shipmentId": shipmentId,
            "tracking": trackings.join(' | '),
            "labels":labels
        };

    }
    catch (e) {
        return {
            "shipmentId" : undefined,
            "error" : e
        }
    }
};

export const createInventoryTransfer = async(selectedBox,shippingInfo,orderNumber,selectedWarehouse)=>{
    const today = getTodayDate();
    const credentials = await getCanparCredentials();
    const packages = selectedBox.map( (box,index)=>{
        const weight = (shippingInfo.TotalWeight/ (1000 * selectedBox.length) ).toFixed(2);
        //console.log(weight);
        return ` <packages xmlns="http://dto.canshipws.canpar.com/xsd">
                                <alternative_reference/>
                                <barcode/>
                                <declared_value>0.0</declared_value>
                                <height>${parseFloat(box.HeightCM).toFixed(1)}</height>
                                <length>${parseFloat(box.LengthCM).toFixed(1)}</length>
                                <width>${parseFloat(box.WidthCM).toFixed(1)}</width>
                                <reported_weight>${weight}</reported_weight>
                                <dim_weight_flag>false</dim_weight_flag>
                                <id>-1</id>
                                <min_weight_flag>false</min_weight_flag>
                                <package_num>${1+index}</package_num>
                                <package_reference>${roughScale(orderNumber,16)}</package_reference>
                                <reference/>
                                <store_num/>
                                <xc>false</xc>
                            </packages>`;
    });
    //console.log(packages);
    const xml = `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
                    <soapenv:Envelope xmlns:soapenv="http://www.w3.org/2003/05/soap-envelope">
                    <soapenv:Body>
                    <ns3:processShipment xmlns:ns3="http://ws.business.canshipws.canpar.com">
                    <ns3:request>
                        <ns2:password xmlns:ns2="http://ws.dto.canshipws.canpar.com/xsd">${credentials.password}</ns2:password>
                        <ns2:user_id xmlns:ns2="http://ws.dto.canshipws.canpar.com/xsd">${credentials.username}</ns2:user_id>
                        <shipment xmlns="http://ws.dto.canshipws.canpar.com/xsd">

                            <delivery_address xmlns="http://dto.canshipws.canpar.com/xsd">
                            <address_line_1>${selectedWarehouse.Address1}</address_line_1>
                            <address_line_2></address_line_2>
                            <address_line_3></address_line_3>
                            <attention>${selectedWarehouse.Name.replace('-',' ').toUpperCase()}</attention>
                            <city>${selectedWarehouse.City}</city>
                            <province>${selectedWarehouse.State}</province>
                            <country>${selectedWarehouse.CountryCode}</country>
                            <postal_code>${selectedWarehouse.Zip.replace(/\s/g,'').toUpperCase()}</postal_code>
                            <name>${selectedWarehouse.Name.replace('-',' ').toUpperCase()}</name>
                            <phone></phone>
                            <extension></extension>
                            <email></email>
                            <residential>false</residential>
                            </delivery_address>

                            <pickup_address xmlns="http://dto.canshipws.canpar.com/xsd">
                            <address_line_1>3100 Caravelle Dr</address_line_1>
                            <address_line_2></address_line_2>
                            <address_line_3></address_line_3>
                            <attention>Customer Service Tedlo</attention>
                            <city>Mississauga</city>
                            <country>CA</country>
                            <email/>
                            <extension></extension>
                            <name>Lean Supply Solutions</name>
                            <phone>4167488982</phone>
                            <postal_code>L4V1K9</postal_code>
                            <province>ON</province>
                            <residential>false</residential>
                            </pickup_address>

                            <ns1:shipper_num xmlns:ns1="http://dto.canshipws.canpar.com/xsd">${credentials.account}</ns1:shipper_num>
                            <ns1:shipping_date xmlns:ns1="http://dto.canshipws.canpar.com/xsd">${today}</ns1:shipping_date>
                            <ns1:service_type xmlns:ns1="http://dto.canshipws.canpar.com/xsd">1</ns1:service_type>
                            <ns1:nsr xmlns:ns1="http://dto.canshipws.canpar.com/xsd">false</ns1:nsr>
                            <ns1:user_id xmlns:ns1="http://dto.canshipws.canpar.com/xsd"/>

                            <ns1:dimention_unit xmlns:ns1="http://dto.canshipws.canpar.com/xsd">CM</ns1:dimention_unit>
                            <ns1:reported_weight_unit xmlns:ns1="http://dto.canshipws.canpar.com/xsd">KG</ns1:reported_weight_unit>


                            ${packages}

                            <ns1:collect_shipper_num xmlns:ns1="http://dto.canshipws.canpar.com/xsd"/>
                            <ns1:cos xmlns:ns1="http://dto.canshipws.canpar.com/xsd">false</ns1:cos>
                            <ns1:cos_charge xmlns:ns1="http://dto.canshipws.canpar.com/xsd">0.0</ns1:cos_charge>
                            <ns1:description xmlns:ns1="http://dto.canshipws.canpar.com/xsd"/>
                            <ns1:id xmlns:ns1="http://dto.canshipws.canpar.com/xsd">${roughScale(orderNumber,16)}</ns1:id>
                            <ns1:manifest_num xmlns:ns1="http://dto.canshipws.canpar.com/xsd" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:nil="1"/>
                            <ns1:voided xmlns:ns1="http://dto.canshipws.canpar.com/xsd">false</ns1:voided>
                        </shipment>
                    </ns3:request>
                    </ns3:processShipment>
                    </soapenv:Body>
                    </soapenv:Envelope>
                    `;
    //console.log(xml);
    const res = (await axios.post(busniessServiceURL,xml,headsers)).data;
    //console.log(res)
    const shipmentDOM =  new DOMParser().parseFromString(res, 'application/xml');
    //console.log(shipmentDOM);
    const Ids = shipmentDOM .getElementsByTagName('ax211:id');
    let shipmentId;
    for (let j = 0; j < Ids.length; j++) {
        if(Ids[j].parentNode.nodeName === "ax211:shipment"){
            shipmentId = Ids[j].textContent;
            break;
        }
    }
    //console.log(shipmentId);
    const labelRes = await getCanparLabel(shipmentId,credentials);
    const labelDOM =  new DOMParser().parseFromString(labelRes, 'application/xml');
    let labels = [];
    const labelsDOM =  labelDOM.getElementsByTagName('ax29:labels');
    for(let i = 0; i < labelsDOM.length; i++){
        labels.push(labelsDOM[i].textContent)
    }
    return {
        shipmentId,
        labels
    };

};

const getCanparLabel = async(shipmentId,credentials)=>{
    const xml =`<?xml version="1.0" encoding="UTF-8" standalone="no"?>
                    <soapenv:Envelope xmlns:soapenv="http://www.w3.org/2003/05/soap-envelope">
                    <soapenv:Body>
                    <ns3:getLabels xmlns:ns3="http://ws.business.canshipws.canpar.com">
                    <ns3:request>
                    <ns2:id xmlns:ns2="http://ws.dto.canshipws.canpar.com/xsd">${shipmentId}</ns2:id>
                    <ns2:password xmlns:ns2="http://ws.dto.canshipws.canpar.com/xsd">${credentials.password}</ns2:password>
                    <ns2:thermal xmlns:ns2="http://ws.dto.canshipws.canpar.com/xsd">true</ns2:thermal>
                    <ns2:user_id xmlns:ns2="http://ws.dto.canshipws.canpar.com/xsd">${credentials.username}</ns2:user_id>
                    </ns3:request>
                    </ns3:getLabels>
                    </soapenv:Body>
                    </soapenv:Envelope>`;
    return (await axios.post(busniessServiceURL,xml,headsers)).data;
};

export const voidCanparShipment = async(shipmentId,credentials)=>{

    const xml = `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
                <soapenv:Envelope xmlns:soapenv="http://www.w3.org/2003/05/soap-envelope">
                    <soapenv:Body>
                    <ns3:voidShipment xmlns:ns3="http://ws.business.canshipws.canpar.com">
                    <ns3:request>
                    <ns2:id xmlns:ns2="http://ws.dto.canshipws.canpar.com/xsd">${shipmentId}</ns2:id>
                    <ns2:password xmlns:ns2="http://ws.dto.canshipws.canpar.com/xsd">${credentials.password}</ns2:password>
                    <ns2:user_id xmlns:ns2="http://ws.dto.canshipws.canpar.com/xsd">${credentials.username}</ns2:user_id>
                    </ns3:request>
                    </ns3:voidShipment>
                    </soapenv:Body>
                </soapenv:Envelope>`;

    const res = (await axios.post(busniessServiceURL,xml,headsers)).data;
    const voidDOM =  new DOMParser().parseFromString(res, 'application/xml');
    const error = voidDOM.getElementsByTagName('ax29:error')[0].textContent;
    return error === "" ? "SUCCESS" : error;
};

export const postCanparEndOfDay = async (credentials)=>{
    const xml = `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
                    <soapenv:Envelope xmlns:soapenv="http://www.w3.org/2003/05/soap-envelope">
                    <soapenv:Body>
                    <ns3:endOfDay xmlns:ns3="http://ws.business.canshipws.canpar.com">
                    <ns3:request>
                    <ns2:date xmlns:ns2="http://ws.dto.canshipws.canpar.com/xsd">${getTodayDate()}</ns2:date>
                    <ns2:password xmlns:ns2="http://ws.dto.canshipws.canpar.com/xsd">${credentials.password}</ns2:password>
                    <ns2:payment_info xmlns:ns2="http://ws.dto.canshipws.canpar.com/xsd" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:nil="1"/>
                    <ns2:shipper_num xmlns:ns2="http://ws.dto.canshipws.canpar.com/xsd">${credentials.account}</ns2:shipper_num>
                    <ns2:user_id xmlns:ns2="http://ws.dto.canshipws.canpar.com/xsd">${credentials.username}</ns2:user_id>
                    </ns3:request>
                    </ns3:endOfDay>
                    </soapenv:Body>
                </soapenv:Envelope>`;
    //console.log(xml);
    const res = (await axios.post(busniessServiceURL,xml,headsers)).data;
    const endOfDayDOM =  new DOMParser().parseFromString(res, 'application/xml');
    const error = endOfDayDOM.getElementsByTagName('ax29:error')[0].textContent;
    const manifest = endOfDayDOM.getElementsByTagName('ax29:manifest_num')[0].textContent;
    return error === "" ? manifest : error;
};

export const getCanparManifest = async(manifestId,credentials)=>{
    const xml = `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
                    <soapenv:Envelope xmlns:soapenv="http://www.w3.org/2003/05/soap-envelope">
                    <soapenv:Body>
                    <ns3:getManifest xmlns:ns3="http://ws.business.canshipws.canpar.com">
                    <ns3:request>
                    <ns2:manifest_num xmlns:ns2="http://ws.dto.canshipws.canpar.com/xsd">${manifestId}</ns2:manifest_num>
                    <ns2:password xmlns:ns2="http://ws.dto.canshipws.canpar.com/xsd">${credentials.password}</ns2:password>
                    <ns2:shipper_num xmlns:ns2="http://ws.dto.canshipws.canpar.com/xsd">${credentials.account}</ns2:shipper_num>
                    <ns2:type xmlns:ns2="http://ws.dto.canshipws.canpar.com/xsd">F</ns2:type>
                    <ns2:user_id xmlns:ns2="http://ws.dto.canshipws.canpar.com/xsd">${credentials.username}</ns2:user_id>
                    </ns3:request>
                    </ns3:getManifest>
                    </soapenv:Body>
                    </soapenv:Envelope>`;
    const res = (await axios.post(busniessServiceURL,xml,headsers)).data;
    const manifestDOM =  new DOMParser().parseFromString(res, 'application/xml');
    const error = manifestDOM.getElementsByTagName('ax29:error')[0].textContent;
    const manifest = manifestDOM.getElementsByTagName('ax29:manifest')[0].textContent;
    return error === "" ? manifest : error;
};

const roughScale =(x, base) =>{
    let parsed = parseInt(x, base);
    if (isNaN(parsed)) { return 0 }
    return parsed * 100;
};


const serviceMapper = (c)=>{
    switch (c) {
        case "1":
            return {
                "name":"GROUND",
                "isExpress":false};
        case "2":
            return {
                "name":"U.S.A.",
                "isExpress":false};
        case "3":
            return {
                "name":"SELECT LETTER",
                "isExpress":true};
        case "4":
            return {
                "name":"SELECT PAK",
                "isExpress":true};
        case "5":
            return {
                "name":"SELECT",
                "isExpress":true};
        case "C":
            return {
                "name":"OVERNIGHT LETTER",
                "isExpress":true};
        case "D":
            return {
                "name":"OVERNIGHT PAK",
                "isExpress":true};
        case "E":
            return {
                "name":"OVERNIGHT",
                "isExpress":true};
        case "F":
            return {
                "name":"U.S.A. LETTER",
                "isExpress":false};
        case "G":
            return {
                "name":"U.S.A. PAK",
                "isExpress":true};
        case "H":
            return {
                "name":"SELECT U.S.A.",
                "isExpress":true};
        case "I":
            return {
                "name":"INTERNATIONAL",
                "isExpress":false};
        default:
            return {
                "name":"Can't find service",
                "isExpress":false};

    }
};
