import React from 'react';
import ListItem from './brightpearl_contact_list_item';
const brightpearl_contact_list = (props)=>{
    //console.log(props);
    return(
        <div className={'list-group'} style={{height:'343px',overflowY:'scroll',width:'100%'}}>
            {props.list.map((item,index)=><ListItem item={item} index={index} key={index}
                                                    selectContact={props.selectContact}
            />)}
        </div>
    )
};
export default brightpearl_contact_list;