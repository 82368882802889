import React, {Component} from 'react';
import $ from "jquery";
import { createShippingMatrix } from '../../../Utils/ashlinDesign';
class NewShippingMatrix extends Component{
    constructor(props){
        super(props);
        this.state={
            Carrier:'',
            Origin:'',
            Destination:'',
            ServiceCode:'',
            DisplayedName:'',
            IsExpress:false,
            ChannelBrightPearlCode:'',
            ChannelBigCommerceCode:'',
            Channel3PLCode:'',
            ChannelAmazonCode:'',
            ChannelEbayCode:'',
        }
    }
    onChange=(e)=>{
        const name = e.target.name;
        const value =  e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        this.setState({
            [name]:value
        })
    };
    handleClose=()=>{
        this.setState({
            Carrier:'',
            Origin:'',
            Destination:'',
            ServiceCode:'',
            DisplayedName:'',
            IsExpress:false,
            ChannelBrightPearlCode:'',
            ChannelBigCommerceCode:'',
            Channel3PLCode:'',
            ChannelAmazonCode:'',
            ChannelEbayCode:'',
        },()=>{
            $('#newShippingMatrix').modal('hide');
        })
    };
    handleCreateShippingMatrix = async()=>{
        const data = this.state;
        const resData = await createShippingMatrix(data);
        this.props.addNewShippingMatrixToList(resData);
        this.setState({
            Carrier:'',
            Origin:'',
            Destination:'',
            ServiceCode:'',
            DisplayedName:'',
            IsExpress:false,
            ChannelBrightPearlCode:'',
            ChannelBigCommerceCode:'',
            Channel3PLCode:'',
            ChannelAmazonCode:'',
            ChannelEbayCode:'',
        },()=>{
            $('#newShippingMatrix').modal('hide');
        })
    };
    render(){
        return(<div className="modal fade" id="newShippingMatrix" tabIndex="-1" role="dialog"
                    aria-labelledby="newShippingMatrix" aria-hidden="true" style={{backgroundColor:'floralwhite'}}>
            <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div className="modal-content" style={{backgroundColor:'floralwhite'}}>
                    <div className="modal-header">
                        <h3 className="modal-title text-center" id="newShippingMatrixTitle" style={{fontWeight:'bold'}}>New Shipping Matrix</h3>
                        <button type="button" className="close"
                                onClick={this.handleClose}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <table className={'table table-bordered ewShippingMatrixDetail'}>
                            <thead>
                            </thead>
                            <tbody>
                            <tr>
                                <th>Carrier Name</th>
                                <td><input type={'text'} onChange={this.onChange}
                                           style={{width:'100%'}}  name={'Carrier'} value={this.state.Carrier} /></td>
                            </tr>
                            <tr>
                                <th>Origin</th>
                                <td><input type={'text'} onChange={this.onChange}
                                           style={{width:'100%'}}  name={'Origin'} value={this.state.Origin} /></td>
                            </tr>
                            <tr>
                                <th>Destination</th>
                                <td><input type={'text'} onChange={this.onChange}
                                           style={{width:'100%'}}  name={'Destination'} value={this.state.Destination} /></td>
                            </tr>
                            <tr>
                                <th>ServiceCode</th>
                                <td><input type={'text'} onChange={this.onChange}
                                           style={{width:'100%'}}  name={'ServiceCode'} value={this.state.ServiceCode} /></td>
                            </tr>
                            <tr>
                                <th>DisplayedName</th>
                                <td><input type={'text'} onChange={this.onChange}
                                           style={{width:'100%'}}  name={'DisplayedName'} value={this.state.DisplayedName} /></td>
                            </tr>
                            <tr>
                                <th>IsExpress</th>
                                <td><input type={'checkbox'} onChange={this.onChange}
                                           style={{width:'20px', height:'20px'}}  name={'IsExpress'} checked={this.state.IsExpress} /></td>
                            </tr>
                            <tr>
                                <th>BrightPearl Code</th>
                                <td><input type={'text'} onChange={this.onChange}
                                           style={{width:'100%'}}  name={'ChannelBrightPearlCode'} value={this.state.ChannelBrightPearlCode} /></td>
                            </tr>
                            <tr>
                                <th>BigCommerce Code</th>
                                <td><input type={'text'} onChange={this.onChange}
                                           style={{width:'100%'}}  name={'ChannelBigCommerceCode'} value={this.state.ChannelBigCommerceCode} /></td>
                            </tr>
                            <tr>
                                <th>3PL Code</th>
                                <td><input type={'text'} onChange={this.onChange}
                                           style={{width:'100%'}}  name={'Channel3PLCode'} value={this.state.Channel3PLCode} /></td>
                            </tr>
                            <tr>
                                <th>Amazon Code</th>
                                <td><input type={'text'} onChange={this.onChange}
                                           style={{width:'100%'}}  name={'ChannelAmazonCode'} value={this.state.ChannelAmazonCode} /></td>
                            </tr>
                            <tr>
                                <th>Ebay Code</th>
                                <td><input type={'text'} onChange={this.onChange}
                                           style={{width:'100%'}}  name={'ChannelEbayCode'} value={this.state.ChannelEbayCode} /></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="modal-footer row" style={{width:'90%',margin:"0 auto"}}>
                        <div className={'col'}> </div>
                        <div className={'col'}>
                            <button className={'btn btn-danger'} onClick={this.handleCreateShippingMatrix } >Create Shipping Matrix</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
    }
}
export default NewShippingMatrix;